/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/prop-types */
import _ from "lodash";
import { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { defineMessages } from "react-intl";
import { connect } from "react-redux";
import { getMonthOptions } from "../../../../../constants/monthsConstants";
import ButtonGeneratePdf from "../../../../Common/ButtonGeneratePdf";
import AnalyticsEvaluations from "../../../../Common/GoalsAndBonus/AnalyticsEvaluations";
import BonusManagementTable from "../../../../Common/GoalsAndBonus/BonusManagementTable";
import MaterialSingleSelect from "../../../../Common/MaterialSingleSelect";
import {
  getActionsPlanStates,
  getGoalsBonusStates,
  getSelectedToolStates,
} from "../../../../customMapStates";

import LoadingDialog from "components/Layouts/LoadingDialog";
import { groupAllActionsByRelated } from "../../../../../utils/actionsPlan/filter";
import { getOnlySublevelArrays } from "../../../../../utils/ArrayUtils";
import { useFilteredEntities } from "../../../../../utils/entities/hooks";
import gbUtils from "../../../../../utils/goalsBonus";
import { useSimpleToolAdministration } from "../../../../../utils/goalsBonus/hooks";
import { getIndicatorsWithActions } from "../../../../../utils/skillsAssessment/filter";
import { translatedText } from "../../../../../utils/translationUtils";
import ActionsPlanOverview from "../../../../Common/ActionPlan/ActionsPlanOverview";

const {
  getGoalsRelationTree,
  getAllParticipantsManagementInfo,
  getTopLevelUserAccess,
  filterOnlyGoalsOnTree,
} = gbUtils;

const messages = defineMessages({
  gb_tab_administration_goals: { id: "gb_tab_administration_goals" },
  gb_tab_management_details: { id: "gb_tab_management_details" },
  gb_filters_title: { id: "gb_filters_title" },
  gb_filters_tab_1_value_1: { id: "gb_filters_tab_1_value_1" },
  gb_filters_tab_1_value_2: { id: "gb_filters_tab_1_value_2" },
  gb_filters_tab_1_value_3: { id: "gb_filters_tab_1_value_3" },
  gb_filters_tab_1_value_4: { id: "gb_filters_tab_1_value_4" },
  gb_filters_tab_1_value_5: { id: "gb_filters_tab_1_value_5" },
  gb_filters_tab_1_value_6: { id: "gb_filters_tab_1_value_6" },
  gb_filters_tab_1_value_7: { id: "gb_filters_tab_1_value_7" },
  gb_filters_tab_1_value_8: { id: "gb_filters_tab_1_value_8" },
  gb_filters_tab_2_value_1: { id: "gb_filters_tab_2_value_1" },
  gb_filters_tab_2_value_2: { id: "gb_filters_tab_2_value_2" },
  gb_filters_tab_2_value_3: { id: "gb_filters_tab_2_value_3" },
  gb_filters_tab_2_value_4: { id: "gb_filters_tab_2_value_4" },
  gb_filters_tab_2_value_5: { id: "gb_filters_tab_2_value_5" },
  gb_table_text_goals: { id: "gb_table_text_goals" },
  gb_options_gt_indicator: { id: "gb_options_gt_indicator" },
  gb_acp_table_indicators: { id: "gb_acp_table_indicators" },
  acp_main_title: { id: "acp_main_title" },
});

const translation = (id) => translatedText(id, messages);

const GoalsAndBonus = ({
  selectedTool = {},
  allGoalBonusAdministration,
  allActionsPlans,
  filterData = {},
  isLoading,
  selectAndOpenInsight,
  intl,
  ...props
}) => {
  const [activeTab, setActiveTab] = useState("goalsTab");
  const { goalBonusAdministration } = useSimpleToolAdministration({
    selectedTool,
    allGoalBonusAdministration,
    isLoading,
  });
  const [displayParticipants, setDisplayParticipants] = useState([]);
  const [allTotalBonus, setAllTotalBonus] = useState(0);
  const [totalLimitBonus, setTotalLimitBonus] = useState(0);
  const [filteredGoals, setFilteredGoals] = useState([]);
  const [periodFilter, setPeriodFilter] = useState({});
  const [calcType, setCalc] = useState("target");

  const { periodCycle = {} } = goalBonusAdministration || {};

  const { entities } = useFilteredEntities({
    selectedTool,
    allSceneries: props.allSceneries,
    allThemes: props.allThemes,
    allQuestions: props.allQuestions,
    allAnswers: props.allAnswers,
  });

  const { allSceneries, allThemes, allQuestions, allAnswers } = entities;

  const filteredInsights = getOnlySublevelArrays(allAnswers);
  let { themes: filterThemes = [], questions: filterQuestions = [] } =
    filterData;

  filterThemes = filterThemes?.length > 0 ? filterThemes : ["nothing"];
  filterQuestions = filterQuestions?.length > 0 ? filterQuestions : ["nothing"];

  const isPeriodFilterActive = !_.isEqual(periodFilter, periodCycle);

  const getFilteredParticipantsInfo = (relationTree) => {
    const primaryFilter = {
      themes: filterThemes,
      questions: filterQuestions,
    };

    const {
      participants = [],
      allTotalBonus = 0,
      totalLimitBonus: participantsTotalBonusLimit = 0,
    } = getAllParticipantsManagementInfo(
      goalBonusAdministration,
      relationTree,
      primaryFilter,
      periodFilter
    );

    return {
      participants,
      allTotalBonus,
      participantsTotalBonusLimit,
    };
  };

  const handleUpdateTotals = (newTotalBonus, newLimitBonus) => {
    if (allTotalBonus !== newTotalBonus) setAllTotalBonus(newTotalBonus);
    if (totalLimitBonus !== newLimitBonus) setTotalLimitBonus(newLimitBonus);
  };

  // Filtragem a partir da Estrutura filtrada
  useEffect(() => {
    const newGoalsRelationTree = getGoalsRelationTree(
      selectedTool.id,
      allSceneries,
      allThemes,
      allQuestions,
      allAnswers
    );

    const {
      participants = [],
      allTotalBonus,
      participantsTotalBonusLimit,
    } = getFilteredParticipantsInfo(newGoalsRelationTree);

    handleUpdateTotals(allTotalBonus, participantsTotalBonusLimit);

    if (!_.isEqual(displayParticipants, participants)) {
      const fGoals = filterOnlyGoalsOnTree(participants);
      if (!_.isEqual(fGoals, filteredGoals)) setFilteredGoals(fGoals);

      setDisplayParticipants(participants);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    allSceneries,
    allThemes,
    allQuestions,
    allAnswers,
    selectedTool.id,
    goalBonusAdministration,
    filterThemes,
    filterQuestions,
    periodFilter,
  ]);

  useEffect(() => {
    if (periodCycle && !periodFilter.start) setPeriodFilter(periodCycle);
  }, [periodCycle]);

  const updateFilter = (filterSlug = "start", newValue) => {
    setPeriodFilter({ ...periodFilter, [filterSlug]: newValue });
  };

  const resetFilter = () => {
    setPeriodFilter(periodCycle);
  };

  // Filtros de Acesso e Estrutura
  const userAccess = getTopLevelUserAccess();
  const isAdmin = userAccess.accesses.indexOf("ADMIN") > -1;

  const essencial = {
    goalBonusAdministration,
    periodFilter,
    isPeriodFilterActive,
    calcType,
    displayParticipants,
    filteredGoals,
    selectAndOpenInsight,
  };

  const bonusManagement = {
    allTotalBonus,
    totalLimitBonus,
    userAccess,
    isAdmin,
    filterThemes,
    allSceneries,
    allThemes,
    allQuestions,
    allAnswers,
    companyMembersAndParticipants: props.companyMembersAndParticipants,
  };

  const renderTranslations = (ids) => {
    return (
      <ul>
        {ids.map((id) => (
          <li key={id}>{translation(id)}</li>
        ))}
      </ul>
    );
  };

  const getFilterMessage = () => {
    const tab_1_filters = [
      "gb_filters_tab_1_value_1",
      "gb_filters_tab_1_value_2",
      "gb_filters_tab_1_value_3",
      "gb_filters_tab_1_value_4",
      "gb_filters_tab_1_value_5",
      "gb_filters_tab_1_value_6",
      "gb_filters_tab_1_value_7",
      "gb_filters_tab_1_value_8",
    ];
    const tab_2_filters = [
      "gb_filters_tab_2_value_1",
      "gb_filters_tab_2_value_2",
      "gb_filters_tab_2_value_3",
      "gb_filters_tab_2_value_4",
      "gb_filters_tab_2_value_5",
    ];

    if (activeTab === "goalsTab") return renderTranslations(tab_1_filters);
    if (activeTab === "detailsTab") return renderTranslations(tab_2_filters);

    return null;
  };

  const displayFilters = () => {
    const monthOptions = getMonthOptions();

    return (
      <div className="row hidden-print">
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className="col-xs-6 col-md-3" style={{ paddingLeft: "0px" }}>
            <MaterialSingleSelect
              id="startPeriodFilter"
              label="Início"
              options={monthOptions}
              value={periodFilter.start}
              onChange={(e) => updateFilter("start", e.target.value)}
              defaultOption={false}
            />
          </div>
          <div className="col-xs-6 col-md-3">
            <MaterialSingleSelect
              id="endPeriodFilter"
              label="Fim"
              options={monthOptions}
              value={periodFilter.end}
              onChange={(e) => updateFilter("end", e.target.value)}
              defaultOption={false}
            />
          </div>
          <div className="col-xs-6 col-md-3">
            <button
              className="btn"
              onClick={resetFilter}
              disabled={!isPeriodFilterActive}
            >
              Limpar filtro
            </button>
          </div>
          <div
            align="right"
            className="goals-filter-buttons hidden-print col-xs-12 col-md-6"
            style={{ paddingTop: "22px", paddingRight: "0px" }}
          >
            <button
              className={calcType === "target" ? "active" : ""}
              onClick={() => setCalc("target")}
            >
              Atingimento de Metas
            </button>
            <button
              className={calcType === "bonus" ? "active" : ""}
              onClick={() => setCalc("bonus")}
            >
              Atingimento de Alcance
            </button>
          </div>
        </div>
        {isPeriodFilterActive && (
          <div className="col-xs-8">
            <div className="row">
              <h5>{translation("gb_filters_title")}</h5>
              {getFilterMessage()}
            </div>
          </div>
        )}
      </div>
    );
  };

  const goalsTabTitle = (
    <div>
      <i className="fas fa-tasks" />
      &nbsp;&nbsp; {translation("gb_tab_administration_goals")}
    </div>
  );

  const detailsTabTitle = (
    <div>
      <i className="far fa-chart-bar" />
      &nbsp;&nbsp; {translation("gb_tab_management_details")}
    </div>
  );

  const actionsPlanTabTitle = (
    <div>
      <i className="fas fa-list-ol" />
      &nbsp;&nbsp; {translation("acp_main_title")}
    </div>
  );

  return (
    <>
      <div className="custom-primary-tabs">
        <Tabs
          id="goalsBonusManagement"
          activeKey={activeTab}
          onSelect={(key) => setActiveTab(key)}
        >
          {displayFilters()}
          <Tab eventKey="goalsTab" title={goalsTabTitle}>
            <div className="name-tab-pdf">
              <i className="fas fa-tasks" />
              &nbsp;&nbsp; {translation("gb_tab_administration_goals")}
            </div>
            <ButtonGeneratePdf />
            {activeTab === "goalsTab" && (
              <div className="tab-content" style={{ marginTop: "10px" }}>
                <div className="col-xs-12" style={{ padding: "0px" }}>
                  <BonusManagementTable {...essencial} {...bonusManagement} />
                </div>
              </div>
            )}
          </Tab>
          <Tab eventKey="detailsTab" title={detailsTabTitle}>
            <div className="name-tab-pdf">
              <i className="far fa-chart-bar" />
              &nbsp;&nbsp; {translation("gb_tab_management_details")}
            </div>
            <ButtonGeneratePdf />
            {activeTab === "detailsTab" && (
              <div className="tab-content" style={{ marginTop: "10px" }}>
                <AnalyticsEvaluations {...essencial} />
              </div>
            )}
          </Tab>
          <Tab eventKey="actionsPlanTab" title={actionsPlanTabTitle}>
            {activeTab === "actionsPlanTab" && (
              <ActionsPlanOverview
                allActionsPlans={groupAllActionsByRelated(allActionsPlans)}
                filteredInsights={filteredInsights}
                overviewTableTitle={translation("acp_main_title").toUpperCase()}
                overviewTableInsightsLabel={translation(
                  "gb_table_text_goals"
                ).toUpperCase()}
                insightsTableTitle={translation("Ações por Meta").toUpperCase()}
                insightsTableInsightsLabel={translation("Meta").toUpperCase()}
                selectedTool={selectedTool}
                selectAndOpenInsight={selectAndOpenInsight}
                totalCount={getIndicatorsWithActions(filteredInsights).count}
              />
            )}
          </Tab>
        </Tabs>
      </div>
      <LoadingDialog open={isLoading} />
    </>
  );
};

const mapStateToProps = (state) => {
  const { selectedTool, allSceneries, allThemes, allQuestions, allAnswers } =
    getSelectedToolStates(state);
  const { allGoalBonusAdministration, isLoading } = getGoalsBonusStates(state);
  const { allActionsPlans = [] } = getActionsPlanStates(state);

  return {
    selectedTool,
    allSceneries,
    allThemes,
    allQuestions,
    allAnswers,
    allGoalBonusAdministration,
    allActionsPlans,
    isLoading,
  };
};

export default connect(mapStateToProps)(GoalsAndBonus);
