import _ from "lodash";
import moment from "moment";
import { Popover } from "react-bootstrap";
import { defineMessages } from "react-intl";
import {
  getAreaOptions,
  getEducationOptions,
  getGenderOptions,
  getLanguageOptions,
  getLeadingOptions,
  getPerfomanceEvaluationOptions,
  getRoleOptions,
} from "../../../../../constants/peopleEvaluation";
import { translatedText } from "../../../../../utils/translationUtils";
import {
  addError,
  customFilter,
  getLabeledInfo,
  isHeadersValidated,
} from "../../utils";

const messages = defineMessages({
  measu_editable: {
    id: "measu_editable",
  },
  measu_no: {
    id: "measu_no",
  },
  measu_yes: {
    id: "measu_yes",
  },
  measu_example_format: {
    id: "measu_example_format",
  },
  measu_invalid_doc: {
    id: "measu_invalid_doc",
  },
  measu_invalid_date: {
    id: "measu_invalid_date",
  },
  measu_invalid_performance_note: {
    id: "measu_invalid_performance_note",
  },
  measu_invalid_genre: {
    id: "measu_invalid_genre",
  },
  measu_invalid_area: {
    id: "measu_invalid_area",
  },
  measu_invalid_leading: {
    id: "measu_invalid_leading",
  },
  measu_invalid_current_position: {
    id: "measu_invalid_current_position",
  },
  measu_invalid_formation: {
    id: "measu_invalid_formation",
  },
  measu_invalid_performance_status: {
    id: "measu_invalid_performance_status",
  },
  measu_invalid_language: {
    id: "measu_invalid_language",
  },
  professional: {
    id: "global.professional",
  },
  gender_male: {
    id: "pb_gender_male",
  },
  gender_female: {
    id: "pb_gender_female",
  },
  form_gender: {
    id: "pb_form_gender",
  },
  form_birth_date: {
    id: "pb_form_birth_date",
  },
  form_entry_date: {
    id: "pb_form_entry_date",
  },
  form_area: {
    id: "pb_form_area",
  },
  pb_table_current: {
    id: "pb_table_current",
  },
  pb_position_start_date: {
    id: "pb_position_start_date",
  },
  pb_current_position: {
    id: "pb_current_position",
  },
  pb_current_area: {
    id: "pb_current_area",
  },
  pb_form_education: {
    id: "pb_form_education",
  },
  form_fluent_languages: {
    id: "pb_form_fluent_languages",
  },
  table_lb_evaluations_average: {
    id: "pb_table_lb_evaluations_average",
  },
  table_lb_evaluations_leading: {
    id: "pb_table_lb_evaluations_leading",
  },
  table_lb_evaluations_performance: {
    id: "pb_table_lb_evaluations_performance",
  },
  area_financial: {
    id: "pb_area_financial",
  },
  area_administrative: {
    id: "pb_area_administrative",
  },
  area_human_resources: {
    id: "pb_area_human_resources",
  },
  area_information_technology: {
    id: "pb_area_information_technology",
  },
  area_planning: {
    id: "pb_area_planning",
  },
  area_marketing: {
    id: "pb_area_marketing",
  },
  area_commercial: {
    id: "pb_area_commercial",
  },
  area_support: {
    id: "pb_area_support",
  },
  area_operational: {
    id: "pb_area_operational",
  },
  area_production: {
    id: "pb_area_production",
  },
  area_services: {
    id: "pb_area_services",
  },
  rule: {
    id: "global.rule",
  },
  company: {
    id: "global.company",
  },
  workspace: {
    id: "global.workspace",
  },
  scenery: {
    id: "global.cenary",
  },
  theme: {
    id: "global.theme",
  },
  question: {
    id: "global.question",
  },
});

const translation = (id, values) => translatedText(id, messages, values);

const getHeaders = () => {
  return [
    { header: "id", key: "id", hidden: true },
    { header: "companyName", key: "companyName", width: 32 },
    { header: "workspaceName", key: "workspaceName", width: 25 },
    { header: "sceneryName", key: "sceneryName", width: 25 },
    { header: "themeName", key: "themeName", width: 25 },
    { header: "questionName", key: "questionName", width: 25 },
    { header: "professionalName", key: "professionalName", width: 32 },
    { header: "birthDate", key: "birthDate", width: 20 },
    { header: "gender", key: "gender", width: 15 },
    { header: "entryDate", key: "entryDate", width: 25 },
    { header: "currentArea", key: "currentArea", width: 30 },
    { header: "currentPosition", key: "currentPosition", width: 20 },
    {
      header: "currentPositionStartDate",
      key: "currentPositionStartDate",
      width: 20,
    },
    { header: "educationLevel", key: "educationLevel", width: 20 },
    { header: "fluentLanguages", key: "fluentLanguages", width: 20 },
    { header: "performanceNote", key: "performanceNote", width: 20 },
    { header: "performanceStatus", key: "performanceStatus", width: 20 },
    { header: "currentLeading", key: "currentLeading", width: 20 },
  ];
};

const getAllOptions = (intl) => {
  const leadingOptions = getLeadingOptions(intl).map((option) => {
    return option.value;
  });

  const areaOptions = getAreaOptions(intl).map((option) => {
    return option.value;
  });

  const positionOptions = getRoleOptions(intl).map((option) => {
    return option.value;
  });

  const educationOptions = getEducationOptions(intl).map((option) => {
    return option.value;
  });

  const languageOptions = getLanguageOptions(intl).map((option) => {
    return option.value;
  });

  const performanceOptions = getPerfomanceEvaluationOptions(intl).map(
    (option) => {
      return option.value;
    }
  );

  const genderOptions = getGenderOptions(intl).map((option) => {
    return option.value;
  });

  return {
    leadingOptions,
    areaOptions,
    positionOptions,
    educationOptions,
    languageOptions,
    performanceOptions,
    genderOptions,
  };
};

const getExampleBody = (intl) => {
  const {
    leadingOptions = [],
    areaOptions = [],
    positionOptions = [],
    educationOptions = [],
    languageOptions = [],
    performanceOptions = [],
    genderOptions = [],
  } = getAllOptions(intl);

  return [
    {
      custom: translation("measu_editable"),
      professionalName: translation("measu_no"),
      birthDate: translation("measu_yes"),
      gender: translation("measu_yes"),
      entryDate: translation("measu_yes"),
      currentArea: translation("measu_yes"),
      currentPosition: translation("measu_yes"),
      currentPositionStartDate: translation("measu_yes"),
      educationLevel: translation("measu_yes"),
      fluentLanguages: translation("measu_yes"),
      performanceNote: translation("measu_yes"),
      performanceStatus: translation("measu_yes"),
      currentLeading: translation("measu_yes"),
    },
    {
      custom: translation("measu_example_format"),
      professionalName: "",
      birthDate: "YYYY-MM-DD",
      gender: genderOptions,
      entryDate: "YYYY-MM-DD",
      currentArea: areaOptions,
      currentPosition: positionOptions,
      currentPositionStartDate: "YYYY-MM-DD",
      educationLevel: educationOptions,
      fluentLanguages: languageOptions,
      performanceNote: "6",
      performanceStatus: performanceOptions,
      currentLeading: leadingOptions,
    },
  ];
};

const handleErrorMessage = (errorType = "") => {
  let customBody = "";

  switch (errorType) {
    case "peopleID":
      customBody = translation("measu_invalid_doc");
      break;
    case "header":
      customBody = translation("measu_invalid_doc");
      break;
    case "performanceNote":
      customBody = translation("measu_invalid_performance_note");
      break;
    case "gender":
      customBody = translation("measu_invalid_genre");
      break;
    case "currentArea":
      customBody = translation("measu_invalid_area");
      break;
    case "currentLeading":
      customBody = translation("measu_invalid_leading");
      break;
    case "currentPosition":
      customBody = translation("measu_invalid_current_position");
      break;
    case "educationLevel":
      customBody = translation("measu_invalid_formation");
      break;
    case "performanceStatus":
      customBody = translation("measu_invalid_performance_status");
      break;
    case "fluentLanguages":
      customBody = translation("measu_invalid_language");
      break;
    case "birthDate":
    case "entryDate":
    case "currentPositionStartDate":
      customBody = translation("measu_invalid_date");
      break;
    default:
      break;
  }
  return (
    <Popover
      id="popoverInformation"
      title="Descrição"
      style={{ zIndex: "99999" }}
    >
      {customBody}
    </Popover>
  );
};

const getFakeHeader = (intl) => {
  return {
    companyName: intl && intl.formatMessage(messages.company),
    workspaceName: intl && intl.formatMessage(messages.workspace),
    sceneryName: intl && intl.formatMessage(messages.scenery),
    themeName: intl && intl.formatMessage(messages.theme),
    questionName: intl && intl.formatMessage(messages.question),
    professionalName: intl && intl.formatMessage(messages.professional),
    birthDate: intl && intl.formatMessage(messages.form_birth_date),
    gender: intl && intl.formatMessage(messages.form_gender),
    entryDate: intl && intl.formatMessage(messages.form_entry_date),
    currentArea: intl && intl.formatMessage(messages.pb_current_area),
    currentPosition: intl && intl.formatMessage(messages.pb_current_position),
    currentPositionStartDate:
      intl && intl.formatMessage(messages.pb_position_start_date),
    educationLevel: intl && intl.formatMessage(messages.pb_form_education),
    fluentLanguages: intl && intl.formatMessage(messages.form_fluent_languages),
    performanceNote:
      intl && intl.formatMessage(messages.table_lb_evaluations_average),
    performanceStatus:
      intl && intl.formatMessage(messages.table_lb_evaluations_performance),
    currentLeading:
      intl && intl.formatMessage(messages.table_lb_evaluations_leading),
  };
};

const formatSingleDate = (unformattedDate = "", format = "YYYY-MM-DD") => {
  const currentDate = _.cloneDeep(unformattedDate);

  if (currentDate && currentDate.length > 0 && moment(currentDate).isValid())
    return moment(currentDate).format(format).toString();

  return "";
};

const getformattedDataFields = (data = []) => {
  const updatedFields = [...data];

  data.forEach((person, index) => {
    updatedFields[index].birthDate = formatSingleDate(
      person.birthDate,
      "YYYY-MM-DD"
    );

    updatedFields[index].currentPositionStartDate = formatSingleDate(
      person.currentPositionStartDate,
      "YYYY-MM-DD"
    );

    updatedFields[index].entryDate = formatSingleDate(
      person.entryDate,
      "YYYY-MM-DD"
    );
  });

  return updatedFields;
};

const handleDateFormat = (date = "") => {
  const currentDate = _.cloneDeep(date);

  if (currentDate.length > 0 && moment(currentDate).isValid()) {
    return currentDate;
  }

  return null;
};

const handleRestLanguageType = (languages = []) => {
  if (languages.length > 0) {
    const arrayLanguages = languages.split(";");

    return [...arrayLanguages];
  }

  return null;
};

const handleEmptyOptions = (option = "") => {
  if (option.length > 0) {
    return option;
  }

  return null;
};
const updateProfessionalProfile = (evaluation = {}, person = {}) => {
  const updatedFields = { ...evaluation };

  const { professionalProfile = null } = evaluation.peopleEvaluation || {};

  if (professionalProfile) {
    professionalProfile.birthDate = handleDateFormat(person.birthDate);
    professionalProfile.entryDate = handleDateFormat(person.entryDate);
    professionalProfile.education.level = handleEmptyOptions(
      person.educationLevel
    );
    professionalProfile.fluentLanguages = handleRestLanguageType(
      person.fluentLanguages
    );

    professionalProfile.gender = handleEmptyOptions(person.gender);
    professionalProfile.area.value = null;
    professionalProfile.role.value = null;

    updatedFields.peopleEvaluation.professionalProfile = professionalProfile;
  }

  return updatedFields;
};

const updateSalaryTrajectory = (evaluation = {}, person = {}) => {
  const updatedFields = { ...evaluation };

  const { salaryTrajectory = {} } = evaluation.peopleEvaluation || {};

  let { professionalAscentions = null } = salaryTrajectory || {};

  if (professionalAscentions) {
    const { filteredObject: currentStatus, indexToUpdate } = customFilter(
      professionalAscentions,
      "currentRole",
      true
    );

    const {
      fromDate = "",
      toAreaText = "",
      toPositionText = "",
    } = currentStatus || {};

    const updatedCurrentStatus = { ...currentStatus };

    if (
      fromDate !== person.currentPositionStartDate ||
      toAreaText !== person.currentArea ||
      toPositionText !== person.currentPosition
    ) {
      delete updatedCurrentStatus.professionalLeader;

      professionalAscentions[indexToUpdate] = {
        ...professionalAscentions[indexToUpdate],
        currentRole: false,
      };

      updatedCurrentStatus.fromDate = handleDateFormat(
        person.currentPositionStartDate
      );

      updatedCurrentStatus.toAreaText = handleEmptyOptions(person.currentArea);
      updatedCurrentStatus.toPositionText = handleEmptyOptions(
        person.currentPosition
      );

      updatedCurrentStatus.toArea = null;
      updatedCurrentStatus.toRole = null;
      updatedCurrentStatus.currentRole = true;

      professionalAscentions = [
        ...professionalAscentions,
        { ...updatedCurrentStatus, id: null },
      ];

      updatedFields.peopleEvaluation.salaryTrajectory.professionalAscentions =
        professionalAscentions;
    }
  }

  return updatedFields;
};

const updateEvaluationTarget = (evaluation = {}, person = {}) => {
  const updatedFields = { ...evaluation };

  const { evaluationTarget = {} } = evaluation.peopleEvaluation || {};

  const { lastEvaluations = null } = evaluationTarget || {};

  if (lastEvaluations) {
    lastEvaluations.leading.current = handleEmptyOptions(person.currentLeading);
    lastEvaluations.performance.current = handleEmptyOptions(
      person.performanceStatus
    );
    lastEvaluations.average.current = person.performanceNote;

    updatedFields.peopleEvaluation.evaluationTarget.lastEvaluations =
      lastEvaluations;
  }

  return updatedFields;
};

const handleEvaluationMassUpdate = (data = [], allPeopleEvaluation = []) => {
  let updatedEvaluations = [];

  const updatedData = getformattedDataFields(data);

  updatedData.forEach((person) => {
    let updatedEvaluation = {};

    const currentEvaluation =
      allPeopleEvaluation.filter(({ peopleEvaluation }) => {
        return peopleEvaluation.id === person.id;
      })[0] || {};

    updatedEvaluation = { ...currentEvaluation };

    //Atualiza birthDate, entryDate, education, fluentLanguages, gender
    updatedEvaluation = updateProfessionalProfile(
      _.cloneDeep(updatedEvaluation),
      person
    );

    // Atualiza currentPositionStartDate, currentPosition, currentArea
    updatedEvaluation = updateSalaryTrajectory(
      _.cloneDeep(updatedEvaluation),
      person
    );

    // Atualiza leading, average e performance
    updatedEvaluation = updateEvaluationTarget(
      _.cloneDeep(updatedEvaluation),
      person
    );

    updatedEvaluations = [...updatedEvaluations, updatedEvaluation];
  });

  return updatedEvaluations;
};

const handleMassUpdate = (updateData = {}, { allPeopleEvaluation }) => {
  const { sheet0 = [] } = updateData || {};

  const evaluationInfo = handleEvaluationMassUpdate(
    sheet0,
    allPeopleEvaluation
  );

  return { evaluationInfo };
};

const getStaticHeader = (intl) => {
  return {
    id: "",
    custom: intl && intl.formatMessage(messages.rule),
    ...getFakeHeader(intl),
  };
};

const validateAllDates = (allDates = {}) => {
  let error = false;
  const dateformat = /^\d{4}[-/](0?[1-9]|1[012])[-/](0?[1-9]|[12][0-9]|3[01])$/;

  Object.keys(allDates).forEach((dateSlug) => {
    const date = String(allDates[dateSlug]);

    if (date.length > 0) {
      if (date.match(dateformat) && date.match(dateformat)[0]) return;

      error = dateSlug;
    }
  });

  return error;
};

const validateOption = (option = "", options = []) => {
  let validated = true;

  if (String(option).length > 0) {
    if (options.indexOf(String(option)) === -1) validated = false;
  }

  return validated;
};

const validateLanguageOption = (options = "", languageOptions = []) => {
  let validated = true;

  if (options.length > 0) {
    const splitedLanguages = options.split(";");

    splitedLanguages.forEach((option) => {
      if (!validateOption(option, languageOptions)) validated = false;
    });
  }

  return validated;
};

const validationLineByLine = (peopleData = [], allPeopleEvaluation = []) => {
  let validatedData = [];
  let nonValidatedData = [];

  peopleData.forEach((line) => {
    const {
      id = "",
      birthDate = "",
      entryDate = "",
      currentPositionStartDate = "",
      performanceNote = "",
      gender,
      currentArea,
      currentPosition,
      educationLevel,
      fluentLanguages,
      performanceStatus,
      currentLeading,
    } = line;

    const {
      leadingOptions = [],
      educationOptions = [],
      languageOptions = [],
      performanceOptions = [],
      genderOptions = [],
    } = getAllOptions();

    if (
      allPeopleEvaluation.filter(
        ({ peopleEvaluation }) => peopleEvaluation.id === id
      ).length === 0
    ) {
      nonValidatedData = addError(nonValidatedData, "peopleID", line);

      return;
    }

    // Validação de Datas
    const error = validateAllDates({
      birthDate,
      entryDate,
      currentPositionStartDate,
    });

    if (error) {
      nonValidatedData = addError(nonValidatedData, error, line);

      return;
    }

    if (!validateOption(gender, genderOptions)) {
      nonValidatedData = addError(nonValidatedData, "gender", line);
      return;
    }

    if (!currentArea) {
      nonValidatedData = addError(nonValidatedData, "currentArea", line);
      return;
    }

    if (!currentPosition) {
      nonValidatedData = addError(nonValidatedData, "currentPosition", line);
      return;
    }

    if (!validateOption(educationLevel, educationOptions)) {
      nonValidatedData = addError(nonValidatedData, "educationLevel", line);
      return;
    }

    if (!validateLanguageOption(fluentLanguages, languageOptions)) {
      nonValidatedData = addError(nonValidatedData, "fluentLanguages", line);
      return;
    }

    // Nota De Desempenho
    if (isNaN(performanceNote)) {
      nonValidatedData = addError(nonValidatedData, "performanceNote", line);

      return;
    }

    if (!validateOption(currentLeading, leadingOptions)) {
      nonValidatedData = addError(nonValidatedData, "currentLeading", line);
      return;
    }

    if (!validateOption(performanceStatus, performanceOptions)) {
      nonValidatedData = addError(nonValidatedData, "performanceStatus", line);
      return;
    }

    validatedData = [...validatedData, line];
  });

  return { validatedData, nonValidatedData };
};

const handleEvaluationValidations = (data = [], clonedDeepAllPeople = []) => {
  // Validações Macro -  Todas as Ferramentas
  // Validação de Header

  if (!isHeadersValidated(data, getStaticHeader()))
    return {
      nonValidatedData: addError([], "header", {}),
      validatedData: [],
    };

  //Validação Linha por Linha
  const { validatedData = [], nonValidatedData = [] } = validationLineByLine(
    data,
    clonedDeepAllPeople
  );

  return {
    nonValidatedData,
    validatedData,
  };
};

const handleValidation = (
  { data: sheetsInfo = {} },
  { allPeopleEvaluation = [] }
) => {
  const clonedDeepAllPeople = _.cloneDeep(allPeopleEvaluation);

  const { sheet0 = {} } = sheetsInfo || {};
  const { data: evaluationData = [] } = sheet0;

  const evaluationInfo = handleEvaluationValidations(
    evaluationData,
    clonedDeepAllPeople
  );

  return {
    validated: {
      sheet0: [...evaluationInfo.validatedData],
    },
    nonValidated: {
      sheet0: [...evaluationInfo.nonValidatedData],
    },
  };
};

const getCSVDataFormat = (allPeopleEvaluation = []) => {
  let data = [];

  allPeopleEvaluation.forEach((currentEvaluation) => {
    const {
      peopleEvaluation = {},
      companyName = "",
      workspaceName = "",
      sceneryName = "",
      themeName = "",
      questionName = "",
    } = currentEvaluation;

    const {
      professional = {},
      birthDate: unformattedBirthDate = "",
      entryDate: unformattedEntryDate = "",
      gender = "",
      education = {},
      fluentLanguages: fluentLanguageArray = [],
    } = peopleEvaluation.professionalProfile;

    const { professionalAscentions = [] } = peopleEvaluation.salaryTrajectory;

    const searchCurrentRole = professionalAscentions.filter(
      (ascention) => ascention.currentRole === true
    );
    let unformattedPositionStartDate = "";
    let currentPosition = "";
    let currentArea = "";

    if (searchCurrentRole.length > 0) {
      const {
        fromDate = "",
        toPositionText = "",
        toArea = "",
        toRole = "",
        toAreaText = "",
      } = searchCurrentRole[0] || {};

      unformattedPositionStartDate = fromDate;
      currentPosition = toPositionText || toRole;
      currentArea = toAreaText || toArea;
    }

    const { lastEvaluations = {} } = peopleEvaluation.evaluationTarget;
    const { average = {}, leading = {}, performance = {} } = lastEvaluations;
    const { current: performanceNote = "" } = average;
    const { current: performanceStatus = "" } = performance;
    const { current: currentLeading = "" } = leading || {};
    const { member = null, participant = null } = professional || {};

    const { level: educationLevel = "" } = education;
    const professionalName = member?.name || participant?.name;

    const fluentLanguages = fluentLanguageArray
      ? fluentLanguageArray.join(";")
      : "";

    const { id = "" } = peopleEvaluation;

    const birthDate = formatSingleDate(unformattedBirthDate);
    const entryDate = formatSingleDate(unformattedEntryDate);
    const currentPositionStartDate = formatSingleDate(
      unformattedPositionStartDate
    );

    if (professionalName?.length) {
      data = [
        ...data,
        {
          id,
          companyName,
          workspaceName,
          sceneryName,
          themeName,
          questionName,
          professionalName,
          birthDate,
          gender,
          entryDate,
          currentArea,
          currentPosition,
          currentPositionStartDate,
          educationLevel,
          fluentLanguages,
          performanceNote,
          performanceStatus,
          currentLeading,
        },
      ];
    }
  });

  return data;
};

const getUpdatedEvaluations = (
  allPeopleEvaluation = [],
  selectedToolStates = {}
) => {
  const { workspaceId = "" } = selectedToolStates || {};

  const peopleEvaluation = allPeopleEvaluation.filter(
    (evaluation) => evaluation.workspaceId === workspaceId
  );

  let updatedList = [];

  const {
    labeledSceneries = {},
    labeledThemes = {},
    labeledQuestions = {},
    workspaceName = "",
    companyName = "",
  } = getLabeledInfo(peopleEvaluation, selectedToolStates);

  peopleEvaluation.forEach((evaluation) => {
    const sceneryName = labeledSceneries[evaluation.cenaryId];
    const themeName = labeledThemes[evaluation.themeId];
    const questionName = labeledQuestions[evaluation.questionId];

    updatedList = [
      ...updatedList,
      {
        ...evaluation,
        sceneryName,
        themeName,
        questionName,
        workspaceName,
        companyName,
      },
    ];
  });

  return updatedList;
};

const getOnePageDependencies = (
  allPeopleEvaluation = [],
  intl,
  selectedToolStates = {}
) => {
  let dataToExport = {};
  let exampleBody = {};

  const updatedPeopleEvaluation = getUpdatedEvaluations(
    allPeopleEvaluation,
    selectedToolStates
  );

  const rowsConfig = [{ line: 2, fill: "eeeeee" }];

  const staticHeader = getStaticHeader(intl);

  dataToExport = {
    sheet0: {
      rowsConfig,
      staticHeader,
      headers: getHeaders(),
      fakeHeader: getFakeHeader(intl),
      data: getCSVDataFormat(updatedPeopleEvaluation),
    },
  };

  const dateTime = moment().format("YYYY-MM-DDTHH:mm:ss.SSS");

  exampleBody = {
    sheet0: {
      title: "",
      headers: staticHeader,
      data: getExampleBody(intl),
    },
  };

  return {
    dataToExport,
    filename: `One-Page-${dateTime}`,
    handleValidation,
    handleMassUpdate,
    additionalData: { allPeopleEvaluation },
    staticHeader: getStaticHeader(intl),
    exampleBody,
    handleErrorMessage,
  };
};

export { getHeaders, getOnePageDependencies, handleValidation };
