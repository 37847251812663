/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import store from "../../Store";
import {
  getKpiStates,
  getSelectedToolStates,
} from "../../components/customMapStates";

import { getObjectInfoById, getUpdatedDeleteArrayByIndex } from "../ArrayUtils";
import { floatNumber } from "../MathUtils";
import { getValidId } from "../validations";

import { getFrequencyPeriodsInfo, getIndicatorValidPeriods } from "./calc2";
import { getKpiConfigBySelectedToolId } from "./filter";

const currentTodayDate = new Date();
const currentFullYear = currentTodayDate.getFullYear();

export const getFloatArrayEntity = (valuesArray = [], replaceNullWith = "") => {
  const arrayEntity = [];

  if (Array.isArray(valuesArray)) {
    valuesArray.forEach(({ id, value: originValue, index }) => {
      const newValue =
        !originValue && originValue !== 0
          ? replaceNullWith
          : floatNumber(originValue);

      arrayEntity.push({ id, value: newValue, index });
    });
  }

  return arrayEntity.sort((a, b) => a.index - b.index);
};

export const getFloatArrayWithMissingLines = (valuesArray, frequency) => {
  const { periods: totalPeriods } = getFrequencyPeriodsInfo(frequency);

  const missing = totalPeriods - valuesArray.length;

  const finalArray = [...valuesArray];

  if (missing > 0) {
    for (let index = valuesArray.length; index <= totalPeriods; index++) {
      finalArray.push({ id: null, value: "", index });
    }
  }

  return getFloatArrayEntity(finalArray);
};

export const transformValuesArray = (arrayValues, frequency) => {
  const finalTargets = [];

  const { periods: totalPeriods } = getFrequencyPeriodsInfo(frequency);

  if (Array.isArray(arrayValues)) {
    arrayValues.forEach(({ id, value, index }) => {
      if (index + 1 <= totalPeriods)
        finalTargets.push({ id: id || null, index, value });
    });
  }

  return finalTargets;
};

const addPeriods = (addRows = 12, defaultValue = "") => {
  const newArray = [];

  for (let rowIndex = 0; rowIndex < addRows; rowIndex++) {
    newArray.push({ id: null, value: defaultValue, index: rowIndex });
  }

  return newArray;
};

export const getDefaultFrequencyInfo = (frequency = "MONTHLY") => {
  const { monthsPerPeriod, periods } = getFrequencyPeriodsInfo(frequency);
  return { monthsPerPeriod, periods, periodTargets: addPeriods(periods, "") };
};

export const getDefaultFrequencyArray = (frequency = "MONTHLY") => {
  const { periodTargets } = getDefaultFrequencyInfo(frequency);
  return periodTargets;
};

export const getDistributionDivision = (measurement, periods = 12) => {
  let division = 0;

  if (measurement === "SUM") division = 100 / periods;
  if (measurement === "AVERAGE" || measurement === "BALANCE") division = 100;

  return { division };
};

export const getGoalDistributionByPeriods = (goalInfo) => {
  const goalDistribution = [];

  const validPeriods = getIndicatorValidPeriods(goalInfo, false);
  const { division } = getDistributionDivision(
    goalInfo.measurement,
    validPeriods.length
  );

  if (goalInfo && goalInfo.target && Array.isArray(goalInfo.target.actual)) {
    goalInfo.target.actual.forEach((value, index) => {
      goalDistribution[index] = { id: null, value: 0, index };

      if (getObjectInfoById(index, validPeriods, "index", true) > -1) {
        goalDistribution[index] = { id: null, value: division, index };
      }
    });
  }

  return { goalDistribution };
};

export const getInitialPersistDistribution = (goalInfo) => {
  const { goalDistribution } = getGoalDistributionByPeriods({
    ...goalInfo,
    period: { ...goalInfo.period, year: 1500 },
  });
  return goalDistribution;
};

export const getDefaultLastPeriod = (frequency) => {
  const lastPeriod = [];

  const { periodTargets } = getDefaultFrequencyInfo(frequency);

  periodTargets.forEach((period, index) => {
    lastPeriod.push({ id: null, value: "", index });
  });

  return { lastPeriod };
};

export const goalEntityBuild = (
  goalInfo,
  defaultStringValue = "",
  defaultArrayValue = []
) => {
  const validatedGoal = { ...goalInfo };

  validatedGoal.id = getValidId(validatedGoal.id);
  validatedGoal.relatedInsights =
    validatedGoal.relatedInsights || defaultArrayValue;
  validatedGoal.title = validatedGoal.title || defaultStringValue;
  validatedGoal.description = validatedGoal.description || defaultStringValue;
  validatedGoal.sourceDescription =
    validatedGoal.sourceDescription || defaultStringValue;
  validatedGoal.generalDetails =
    validatedGoal.generalDetails || defaultStringValue;
  validatedGoal.measurementUnity =
    validatedGoal.measurementUnity || defaultStringValue;
  validatedGoal.type = validatedGoal.type || defaultStringValue;
  validatedGoal.relationshipType =
    validatedGoal.relationshipType || defaultStringValue;
  validatedGoal.editable = validatedGoal.editable || false;
  validatedGoal.frequency = validatedGoal.frequency || defaultStringValue;
  validatedGoal.measurement = validatedGoal.measurement || defaultStringValue;
  validatedGoal.period = validatedGoal.period || {};
  validatedGoal.period.year =
    parseInt(validatedGoal.period.year, 10) || defaultStringValue;
  // Normalizando alterações e previnindo bugs relacionados ao goals.period.endYear
  validatedGoal.period.start = floatNumber(
    validatedGoal.period.start,
    defaultStringValue
  );
  validatedGoal.period.end = floatNumber(
    validatedGoal.period.end,
    defaultStringValue
  );
  validatedGoal.target = validatedGoal.target || {};
  validatedGoal.target.gate = floatNumber(
    validatedGoal.target.gate,
    defaultStringValue
  );
  validatedGoal.target.appropriated = floatNumber(
    validatedGoal.target.appropriated,
    defaultStringValue
  );
  validatedGoal.target.exceeded = floatNumber(
    validatedGoal.target.exceeded,
    defaultStringValue
  );
  validatedGoal.target.actual =
    validatedGoal.target.actual || defaultArrayValue;
  validatedGoal.target.actual =
    validatedGoal.target.actual.length > 0
      ? getFloatArrayWithMissingLines(
          validatedGoal.target.actual,
          validatedGoal.frequency
        )
      : getDefaultFrequencyArray(validatedGoal.frequency);
  validatedGoal.target.distribution =
    validatedGoal.target.distribution &&
    validatedGoal.target.distribution.length > 0
      ? getFloatArrayWithMissingLines(
          validatedGoal.target.distribution,
          validatedGoal.frequency
        )
      : getInitialPersistDistribution(validatedGoal);
  validatedGoal.target.lastPeriod =
    validatedGoal.target.lastPeriod &&
    validatedGoal.target.lastPeriod.length > 0
      ? getFloatArrayWithMissingLines(
          validatedGoal.target.lastPeriod,
          validatedGoal.frequency
        )
      : getDefaultLastPeriod(validatedGoal.frequency).lastPeriod;
  validatedGoal.target.otherMeasurement =
    validatedGoal.otherMeasurement || defaultStringValue;
  validatedGoal.linkedIndicator = validatedGoal.linkedIndicator || null;

  return validatedGoal;
};

export const buildAdmConfigBody = (
  kpiAdministration,
  defaultStringValue = "",
  defaultArrayValue = [],
  defaultIdValue = null
) => {
  const {
    id,
    selectedToolId,
    administrativeRoles = defaultArrayValue,
    goals = defaultArrayValue,
    goalTargets = {},
    periodCycle = {},
    themes = defaultArrayValue,
    groups = defaultArrayValue,
    processes = defaultArrayValue,
    zeroWhenNegative = false,
  } = kpiAdministration || {};

  goalTargets.gate = goalTargets.gate || defaultStringValue;
  goalTargets.appropriated = goalTargets.appropriated || defaultStringValue;
  goalTargets.exceeded = goalTargets.exceeded || defaultStringValue;

  for (const key in administrativeRoles) {
    administrativeRoles[key].id = getValidId(administrativeRoles[key].id);
    administrativeRoles[key].access =
      administrativeRoles[key].access || defaultArrayValue;
    administrativeRoles[key].members =
      administrativeRoles[key].members || defaultArrayValue;
    administrativeRoles[key].role =
      administrativeRoles[key].role || defaultArrayValue;
  }

  for (const key in goals) {
    goals[key] = goalEntityBuild(
      goals[key],
      defaultStringValue,
      defaultArrayValue
    );
  }

  for (const key in themes) {
    themes[key].id = getValidId(themes[key].id);
    themes[key].title = themes[key].title || defaultStringValue;
    themes[key].description = themes[key].description || defaultStringValue;
  }

  for (const key in groups) {
    groups[key].id = getValidId(groups[key].id);
    groups[key].title = groups[key].title || defaultStringValue;
    groups[key].description = groups[key].description || defaultStringValue;
  }

  for (const key in processes) {
    processes[key].id = getValidId(processes[key].id);
    processes[key].title = processes[key].title || defaultStringValue;
    processes[key].description =
      processes[key].description || defaultStringValue;
  }

  const vPeriodCycle = periodCycle || {};
  vPeriodCycle.start = vPeriodCycle.start || 0;
  vPeriodCycle.end = vPeriodCycle.end || 11;

  return {
    id: getValidId(id, defaultIdValue),
    selectedToolId: getValidId(selectedToolId, defaultIdValue),
    administrativeRoles,
    goals,
    goalTargets,
    periodCycle: vPeriodCycle,
    themes,
    groups,
    processes,
    zeroWhenNegative,
  };
};

export const buildFormAdmConfigBody = (kpiAdministration) => {
  return buildAdmConfigBody(kpiAdministration, "", []);
};

export const buildRestAdmConfigBody = (kpiAdministration) => {
  return buildAdmConfigBody(kpiAdministration, null, []);
};

export const buildKpiAdministrationFinalBody = (
  administration,
  defaultArrayValue = []
) => {
  const administrationEntity = buildFormAdmConfigBody(administration);
  const { goals = defaultArrayValue } = administrationEntity;

  for (const key in goals) {
    // eslint-disable-next-line
    goals[key].target.id = goals[key].target.id;
    goals[key].target.actual = goals[key].target.actual || defaultArrayValue;
    goals[key].target.actual =
      goals[key].target.actual.length > 0
        ? transformValuesArray(goals[key].target.actual, goals[key].frequency)
        : getDefaultFrequencyArray(goals[key].frequency);
    goals[key].target.distribution = transformValuesArray(
      goals[key].target.distribution,
      goals[key].frequency
    );
    goals[key].target.lastPeriod = transformValuesArray(
      goals[key].target.lastPeriod,
      goals[key].frequency
    );
  }

  return {
    ...administrationEntity,
    goals,
  };
};

export const buildKpiAdministrationDefaultSave = () => {
  const defaultInitialRestSave = buildRestAdmConfigBody({});

  return {
    ...defaultInitialRestSave,
    goalTargets: {
      ...defaultInitialRestSave.goalTargets,
      gate: 70,
      appropriated: 100,
      exceeded: 130,
    },
  };
};

export const inputAdminUserOnKpiAdministration = (
  defaultInitialRestSave,
  loggedUserId
) => {
  return {
    ...defaultInitialRestSave,
    administrativeRoles: [
      {
        id: null,
        role: "ADMIN",
        members: [loggedUserId],
        access: ["ADMIN"],
      },
    ],
  };
};

export const getDefaultDistribution = (measurement, periods = 12) => {
  const defaultDistribution = [];
  const { division } = getDistributionDivision(measurement, periods);

  for (let i = 0; i < periods; i++) {
    defaultDistribution.push({ id: null, value: division, index: i });
  }

  return { defaultDistribution, division };
};

export const getDefaultFrequencyDistribution = (
  frequency,
  measurement = "SUM"
) => {
  const { periods } = getDefaultFrequencyInfo(frequency);
  const { defaultDistribution } = getDefaultDistribution(measurement, periods);

  return defaultDistribution;
};

export const getNewKpiDefaultOptions = (periodCycle = {}) => {
  const defaultPeriod = {
    start: periodCycle.start || 0,
    end: periodCycle.end || 11,
    year: currentFullYear,
  };

  const defaultGoal = buildFormAdmConfigBody({
    goals: [{ id: null, period: defaultPeriod }],
  }).goals[0];

  return {
    ...defaultGoal,
    measurementUnity: "MONETARY_VALUE",
    type: "INDICATOR",
    frequency: "MONTHLY",
    measurement: "SUM",
    target: {
      ...defaultGoal.target,
      actual: getDefaultFrequencyArray("MONTHLY"),
      distribution: getDefaultDistribution("SUM", 12).defaultDistribution,
    },
    period: { ...defaultPeriod },
    relationshipType: "INDIVIDUAL",
  };
};

export const getEntityReplaceConfig = (
  access = false,
  insightAccess = false,
  allowActions = false
) => {
  return {
    cenary: {
      registrations: access,
      blockArchive: !allowActions,
      blockDelete: !allowActions,
    },
    theme: {
      registrations: access,
      blockArchive: !allowActions,
      blockDelete: !allowActions,
    },
    question: {
      registrations: access,
      blockArchive: !allowActions,
      blockDelete: !allowActions,
    },
    insight: {
      registrations: insightAccess,
      blockArchive: !allowActions,
      blockDelete: !allowActions,
    },
  };
};

export const getCurrentToolConfig = () => {
  const state = store.getState();

  const { user } = state;

  const { allKpiAdministration } = getKpiStates(state);
  const { selectedTool = {} } = getSelectedToolStates(state);

  let toolKpiConfig = getKpiConfigBySelectedToolId(
    selectedTool.id,
    allKpiAdministration
  );
  toolKpiConfig = buildFormAdmConfigBody(toolKpiConfig);

  return { user, toolKpiConfig, allKpiAdministration, selectedTool };
};

export const deleteRowOnAdministration = (
  currentAdministration,
  parentName = "",
  itemId,
  searchForSlug = "id"
) => {
  const parentArray = currentAdministration[parentName];

  if (currentAdministration.id && Array.isArray(parentArray)) {
    const itemIndex = getObjectInfoById(
      itemId,
      parentArray,
      searchForSlug,
      true
    );

    const updatedParent = getUpdatedDeleteArrayByIndex(parentArray, itemIndex);

    return {
      ...currentAdministration,
      [parentName]: updatedParent,
    };
  }

  return currentAdministration;
};

export const deleteScenarioOnAdminitration = (
  currentAdministration,
  themeId
) => {
  return deleteRowOnAdministration(currentAdministration, "themes", themeId);
};

export const deleteThemeOnAdminitration = (currentAdministration, groupId) => {
  return deleteRowOnAdministration(currentAdministration, "groups", groupId);
};

export const deleteSubthemeOnAdminitration = (
  currentAdministration,
  processId
) => {
  return deleteRowOnAdministration(
    currentAdministration,
    "processes",
    processId
  );
};

export const deleteGoalOnAdministration = (currentAdministration, goalId) => {
  return deleteRowOnAdministration(currentAdministration, "goals", goalId);
};
