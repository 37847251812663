import React, { useEffect, useState } from "react";
import {
  createActivity,
  createChecklistItem,
  deleteActivity,
  deleteChecklistItem,
  searchActivities,
  updateActivity,
  updateChecklistItem,
} from "../../../../../actions/entity-documents/rookau_tool_processes_activity";
import {
  formatFrequency,
  formatPeriodDemandGrowth,
  formatPeriodicity,
} from "../../../../../utils/rookauToolProcesses/activities/format";
import {
  SortMenu,
  getDefaultAddTableLine,
  getDefaultDuplicateColumn,
  getDefaultEditColumn,
  getDefaultRemoveColumn,
} from "../../../../../utils/tableUtils";
import ConfirmationDialog from "../../../../Common/ConfirmationDialog";
import SimpleCheckbox from "../../../../Common/SimpleCheckbox";
import SimpleLoader from "../../../../SimpleLoader";
import ActivityModal from "./ActivityModal";
import ChecklistModal from "./ChecklistModal";

const defaultConfirmDialog = {
  title: "",
  onConfirm: null,
  onCancel: null,
  open: false,
};

const defaultEditingDocument = { document_id: null, document_data: null };
const defaultEditingChecklist = { document_id: null, item: null };

export default function ActivitiesTable({
  insightId,
  companyMembers,
  onAddCollaborator,
}) {
  const [expanded, setExpanded] = useState([]);
  const [activities, setActivities] = useState([]);
  const [collaborators, setCollaborators] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [editingDocument, setEditingDocument] = useState(
    defaultEditingDocument
  );
  const [editingChecklist, setEditingChecklist] = useState(
    defaultEditingChecklist
  );
  const [confirmationDialog, setConfirmationDialog] = useState({
    ...defaultConfirmDialog,
  });
  const [newActivityOpen, setNewActivityOpen] = useState(false);

  function setSortActivitiesByCreatedDate(activities) {
    setActivities(
      activities.sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
    );
    const collaboratorsIds = activities?.map(
      (activity) => activity?.document_data?.responsibleId
    );
    setCollaborators(collaboratorsIds);
  }

  const setSortActivitiesByOrder = (activities, asc) => {
    if (asc) {
      setActivities(
        activities.sort((a, b) => a.document_data.order - b.document_data.order)
      );
    } else {
      setActivities(
        activities.sort((a, b) => b.document_data.order - a.document_data.order)
      );
    }
    const collaboratorsIds = activities?.map(
      (activity) => activity?.document_data?.responsibleId
    );
    setCollaborators(collaboratorsIds);
  };

  function setSortActivitiesAlphabeticallyAsc(activities) {
    setActivities(
      activities.sort((a, b) =>
        a.document_data.title.localeCompare(b.document_data.title)
      )
    );
    const collaboratorsIds = activities?.map(
      (activity) => activity?.document_data?.responsibleId
    );
    setCollaborators(collaboratorsIds);
  }

  function setSortActivitiesAlphabeticallyDesc(activities) {
    setActivities(
      activities.sort((a, b) =>
        b.document_data.title.localeCompare(a.document_data.title)
      )
    );
    const collaboratorsIds = activities?.map(
      (activity) => activity?.document_data?.responsibleId
    );
    setCollaborators(collaboratorsIds);
  }

  async function fetchInitialData() {
    setIsLoading(true);

    try {
      const { data: fetchedActivites } = await searchActivities({
        insight_id: insightId,
      });

      setSortActivitiesByOrder(fetchedActivites, true);
    } catch (err) {
      console.error(err);
    }

    setIsLoading(false);
  }

  useEffect(() => {
    if (insightId) fetchInitialData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insightId]);

  function toggleExpandedDocument(document_id) {
    setExpanded(
      expanded.includes(document_id)
        ? expanded.filter((id) => id !== document_id)
        : [...expanded, document_id]
    );
  }

  const resetConfirmDialog = () => {
    setConfirmationDialog({ ...defaultConfirmDialog });
  };

  function handleAddNewAcitivy() {
    setNewActivityOpen(true);
  }

  function handleUpdatedDocument(updatedDocument) {
    if (editingDocument.document_id) {
      setEditingDocument({
        document_id: updatedDocument.id,
        document_data: updatedDocument.document_data,
      });
    }

    setSortActivitiesByOrder(
      activities.map((document) => {
        if (document.id === updatedDocument.id) return updatedDocument;
        return document;
      }),
      true
    );

    onAddCollaborator && onAddCollaborator();
  }

  async function addNewActivity(newActivity) {
    try {
      setIsLoading(true);

      const { data: created } = await createActivity({
        insight_id: insightId,
        document_data: { ...newActivity, collaborators },
      });

      setEditingDocument({
        document_id: created.id,
        document_data: created.document_data,
      });
      setSortActivitiesByOrder([...activities, created], true);
      setNewActivityOpen(false);
      onAddCollaborator && onAddCollaborator();
    } catch (err) {
      console.error(err);
    }

    setIsLoading(false);
  }

  async function addNewChecklist(newItem) {
    try {
      setIsLoading(true);
      const { data: updatedDocument } = await createChecklistItem({
        insight_id: insightId,
        document_id: editingChecklist.document_id,
        item: newItem,
      });

      handleUpdatedDocument(updatedDocument);
      setEditingChecklist(defaultEditingChecklist);
    } catch (err) {
      console.error(err);
    }

    setIsLoading(false);
  }

  function handleOpenEditDocumentData(document_id, document_data) {
    setEditingDocument({
      document_id,
      document_data,
    });
  }

  function handleOpenEditChecklist(document_id, item) {
    setEditingChecklist({
      document_id,
      item,
    });
  }

  async function saveEditedDocumentData(updatedDocumentData) {
    try {
      const updatedCollaboratorId = updatedDocumentData.responsibleId;
      const editingCollaboratorId = editingDocument.document_data.responsibleId;
      setIsLoading(true);

      let finalCollaborators = [];

      if (editingCollaboratorId !== updatedCollaboratorId) {
        const filteredCollaborators = collaborators.filter(
          (collaborator) => collaborator !== editingCollaboratorId
        );

        finalCollaborators = [...filteredCollaborators, updatedCollaboratorId];
      } else {
        finalCollaborators = collaborators;
      }

      const { data: updatedDocument } = await updateActivity({
        insight_id: insightId,
        document_id: editingDocument.document_id,
        document_data: {
          ...updatedDocumentData,
          collaborators: finalCollaborators,
        },
      });

      handleUpdatedDocument(updatedDocument);
    } catch (err) {
      console.error(err);
    }

    setIsLoading(false);
  }

  async function saveEditedChecklistItem(documentId, updatedChecklistItem) {
    try {
      setIsLoading(true);
      const { data: updatedDocument } = await updateChecklistItem({
        insight_id: insightId,
        document_id: documentId,
        item: updatedChecklistItem,
      });

      handleUpdatedDocument(updatedDocument);
      setEditingChecklist(defaultEditingChecklist);
    } catch (err) {
      console.error(err);
    }

    setIsLoading(false);
  }

  function handleCancelEditing(hasChanges) {
    if (hasChanges) {
      setConfirmationDialog({
        open: true,
        title: "Tem certeza que deseja descartar as alterações?",
        confirmText: "Voltar",
        cancelText: "Descartar alterações",
        onConfirm: () => {
          resetConfirmDialog();
        },
        onCancel: () => {
          setEditingDocument(defaultEditingDocument);
          resetConfirmDialog();
        },
      });
    } else {
      setEditingDocument(defaultEditingDocument);
    }
  }

  function handleAddNewAcitivyChecklist(document_id) {
    setEditingChecklist({
      document_id,
      item: null,
    });
  }

  const handleDuplicatedChecklist = async (document) => {
    setIsLoading(true);

    try {
      const { data: duplicate } = await createActivity({
        insight_id: insightId,
        document_data: {
          title: document?.document_data?.title,
          description: document?.document_data?.description,
          checklist: [],
          collaborators,
        },
      });
      setEditingDocument({
        document_id: duplicate.id,
        document_data: duplicate.document_data,
      });
      setSortActivitiesByOrder([...activities, duplicate], true);
      setNewActivityOpen(false);
      onAddCollaborator && onAddCollaborator();
    } catch (err) {
      console.error(err);
    }

    resetConfirmDialog();
    setIsLoading(false);
  };

  const handleDuplicatedDocument = (document) => {
    setConfirmationDialog({
      open: true,
      title: "Deseja duplicar a atividade inteira?",
      confirmText: "Sim",
      hideCancel: true,
      onConfirm: async () => {
        setIsLoading(true);
        try {
          const { data: duplicate } = await createActivity({
            insight_id: insightId,
            document_data: { ...document.document_data, collaborators },
          });
          setEditingDocument({
            document_id: duplicate.id,
            document_data: duplicate.document_data,
          });
          setSortActivitiesByOrder([...activities, duplicate], true);
          setNewActivityOpen(false);
          onAddCollaborator && onAddCollaborator();
        } catch (err) {
          console.error(err);
        }
        resetConfirmDialog();
        setIsLoading(false);
      },
      onCancel: () => resetConfirmDialog(),
      optionalButton: {
        text: "Não",
        onClick: () => handleDuplicatedChecklist(document),
      },
    });
  };

  function handleDeleteDocument(editingDocument) {
    const { document_id, document_data } = editingDocument;
    const { responsibleId } = document_data;

    const filteredCollaborators = collaborators.filter(
      (collaborator) => collaborator !== responsibleId
    );

    setConfirmationDialog({
      open: true,
      title: "Tem certeza que deseja remover essa atividade?",
      onConfirm: async () => {
        setIsLoading(true);

        try {
          await deleteActivity({
            insight_id: insightId,
            document_id,
            collaborators: filteredCollaborators,
          });

          setSortActivitiesByOrder(
            activities.filter(({ id }) => id !== document_id),
            true
          );
          setEditingDocument(defaultEditingDocument);
          onAddCollaborator && onAddCollaborator();
        } catch (err) {
          console.error(err);
        }

        resetConfirmDialog();
        setIsLoading(false);
      },
      onCancel: () => resetConfirmDialog(),
    });
  }

  function handleDeleteChecklistItem(documentId, checklistId) {
    setConfirmationDialog({
      open: true,
      title: "Tem certeza que deseja remover este item?",
      onConfirm: async () => {
        setIsLoading(true);

        try {
          const { data: updatedDocument } = await deleteChecklistItem({
            insight_id: insightId,
            document_id: documentId,
            checklist_id: checklistId,
          });

          handleUpdatedDocument(updatedDocument);
          setEditingChecklist(defaultEditingChecklist);
        } catch (err) {
          console.error(err);
        }

        resetConfirmDialog();
        setIsLoading(false);
      },
      onCancel: () => resetConfirmDialog(),
    });
  }

  return (
    <div>
      {isLoading && (
        <center>
          Aguarde enquanto trazemos as atualizações...
          <SimpleLoader color="#6b42a9" />
        </center>
      )}
      {newActivityOpen && (
        <ActivityModal
          open
          title="Nova atividade"
          confirmText="Continuar"
          onConfirm={(newActivity) => addNewActivity(newActivity)}
          onCancel={() => setNewActivityOpen(false)}
          companyMembers={companyMembers}
          isLoading={isLoading}
        />
      )}
      {editingDocument?.document_id && (
        <ActivityModal
          open
          title="Editar atividade"
          confirmText="Salvar"
          cancelText="Voltar"
          onConfirm={(updated) => saveEditedDocumentData(updated)}
          onCancel={(hasChanges) => handleCancelEditing(hasChanges)}
          editingDocument={editingDocument}
          companyMembers={companyMembers}
          handleDeleteDocument={() => handleDeleteDocument(editingDocument)}
          handleDuplicatedDocument={() =>
            handleDuplicatedDocument(editingDocument)
          }
          handleAddNewAcitivyChecklist={() =>
            handleAddNewAcitivyChecklist(editingDocument.document_id)
          }
          handleDeleteChecklistItem={(checklistId) =>
            handleDeleteChecklistItem(editingDocument.document_id, checklistId)
          }
          isLoading={isLoading}
        />
      )}
      {editingChecklist?.document_id && (
        <ChecklistModal
          open
          title={editingChecklist?.item?.id ? "Editar tarefa" : "Nova tarefa"}
          confirmText="Salvar"
          cancelText="Voltar"
          editingChecklist={editingChecklist}
          onConfirm={
            editingChecklist?.item?.id
              ? (updated) =>
                  saveEditedChecklistItem(editingChecklist.document_id, updated)
              : (created) => addNewChecklist(created)
          }
          onCancel={() => setEditingChecklist(defaultEditingChecklist)}
          handleDeleteChecklistItem={() =>
            handleDeleteChecklistItem(
              editingChecklist.document_id,
              editingChecklist.item.id
            )
          }
          isLoading={isLoading}
        />
      )}
      {confirmationDialog.open && (
        <ConfirmationDialog
          open={confirmationDialog.open}
          title={confirmationDialog.title}
          description={confirmationDialog.description}
          onConfirm={confirmationDialog.onConfirm}
          confirmText={confirmationDialog.confirmText}
          cancelText={confirmationDialog.cancelText}
          onCancel={confirmationDialog.onCancel}
          hideCancel={confirmationDialog.hideCancel}
          confirmColor={confirmationDialog.confirmColor}
          optionalButton={confirmationDialog.optionalButton}
        />
      )}
      <table
        className="simple-table"
        style={{
          width: "100%",
          opacity: isLoading ? 0.5 : 1,
          pointerEvents: isLoading ? "none" : "all",
        }}
      >
        <thead>
          {getDefaultAddTableLine(
            handleAddNewAcitivy,
            null,
            null,
            SortMenu({
              onSortAlphabeticallyAsc: setSortActivitiesAlphabeticallyAsc,
              onSortAlphabeticallyDesc: setSortActivitiesAlphabeticallyDesc,
              onSortByCreatedDate: setSortActivitiesByCreatedDate,
              onSortByOrder: setSortActivitiesByOrder,
              activities,
            })
          )}
        </thead>
        <tbody>
          <tr className="thead" style={{ fontSize: "10px" }}>
            <td>Ordem</td>
            <td>Ator</td>
            <td>Atividade</td>
            <td>Responsável</td>
            <td>Editar</td>
            <td>Relevância</td>
            <td>Complexidade</td>
            <td>Frequência</td>
            <td>Periodicidade</td>
            <td>Tempo médio</td>
            <td>Demanda média</td>
            <td>Crescimento da demanda</td>
            <td>Tarefas</td>
            <td>Duplicar</td>
          </tr>
          {activities.map(({ id: document_id, document_data: activity }) => {
            const { checklist = [] } = activity;

            const completedChecklist = checklist.filter(
              ({ completed }) => completed === true
            );
            const checklistIndicator = `(${checklist.length}/${completedChecklist.length})`;

            const responsible = companyMembers.find(
              (member) => member.id === activity.responsibleId
            );

            const toggle = () => toggleExpandedDocument(document_id);
            const isExpanded = expanded.includes(document_id);

            return (
              <React.Fragment key={document_id}>
                <tr style={{ cursor: "pointer" }}>
                  <td
                    style={{
                      fontWeight: "bold",
                      borderLeft: isExpanded
                        ? "5px solid #ccc"
                        : "1px solid #ccc",
                    }}
                    onClick={toggle}
                  >
                    {activity?.order}
                  </td>
                  <td
                    style={{
                      fontWeight: "bold",
                      height: "50px",
                      cursor: "pointer",
                      wordBreak: "break-word",
                    }}
                    onClick={toggle}
                  >
                    {activity?.area}
                  </td>
                  <td onClick={toggle}>
                    <b
                      style={{
                        wordBreak: "break-word",
                      }}
                    >
                      {activity.title}
                    </b>
                  </td>
                  <td onClick={toggle}>
                    <div>
                      <div style={{ fontWeight: "bold" }}>
                        {responsible?.name}
                      </div>
                      <div style={{ fontSize: "10px" }}>
                        {responsible?.profile?.companyPosition?.title}
                      </div>
                    </div>
                  </td>
                  <td>
                    {getDefaultEditColumn(() =>
                      handleOpenEditDocumentData(document_id, activity)
                    )}
                  </td>
                  <td className="text-right" onClick={toggle}>
                    {activity.relevance}
                  </td>
                  <td className="text-right" onClick={toggle}>
                    {activity?.complexity}
                  </td>
                  <td className="text-right" onClick={toggle}>
                    {formatFrequency(activity?.frequency)}
                  </td>
                  <td className="text-right" onClick={toggle}>
                    {formatPeriodicity(activity?.periodicity)}
                  </td>
                  <td className="text-right" onClick={toggle}>
                    {activity?.extimatedTime?.med &&
                      `${activity.extimatedTime.med}h`}
                  </td>
                  <td className="text-right" onClick={toggle}>
                    {activity?.demand?.med}
                  </td>
                  <td className="text-right" onClick={toggle}>
                    {formatPeriodDemandGrowth(activity?.periodDemandGrowth)}
                  </td>
                  <td className="text-right" onClick={toggle}>
                    {checklistIndicator}
                  </td>
                  <td className="text-right" onClick={toggle}>
                    {getDefaultDuplicateColumn(() =>
                      handleDuplicatedDocument({
                        document_id,
                        document_data: activity,
                      })
                    )}
                  </td>
                </tr>
                {isExpanded && (
                  <tr className="block-hover">
                    <td
                      colSpan="100"
                      style={{ padding: 0, border: "2px solid #ccc" }}
                    >
                      <div className="col-xs-12" style={{ paddingTop: "15px" }}>
                        <div
                          className="col-xs-12"
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <button
                            className="btn btn-sm btn-gray"
                            onClick={() =>
                              handleOpenEditDocumentData(document_id, activity)
                            }
                          >
                            <i
                              className="fas fa-pen"
                              style={{ marginRight: "5px", color: "#666" }}
                            />{" "}
                            Editar atividade
                          </button>
                        </div>
                        <div className="row">
                          <h5 className="col-xs-12">Detalhes</h5>
                          <div className="col-xs-12">
                            <table className="simple-table">
                              <tbody>
                                <tr
                                  className="thead"
                                  style={{ fontSize: "10px" }}
                                >
                                  <td width="240">
                                    Tempo{" "}
                                    <span style={{ color: "#999" }}>
                                      (em horas)
                                    </span>
                                  </td>
                                  <td width="240">
                                    Quantidade de demanda{" "}
                                    <span style={{ color: "#999" }}>
                                      (em quantidade)
                                    </span>
                                  </td>
                                  <td width="80">Relevância</td>
                                  <td width="80">Complexidade</td>
                                  <td width="80">Crescimento da demanda</td>
                                  <td width="80">Frequência</td>
                                  <td>Periodicidade</td>
                                </tr>
                                <tr className="block-hover">
                                  <td style={{ padding: 0, border: "none" }}>
                                    <table
                                      className="simple-table"
                                      style={{ margin: 0 }}
                                    >
                                      <tbody>
                                        <tr
                                          className="thead"
                                          style={{ fontSize: "10px" }}
                                        >
                                          <td
                                            width="40"
                                            className="text-center"
                                          >
                                            Min.
                                          </td>
                                          <td
                                            width="40"
                                            className="text-center"
                                          >
                                            Med.
                                          </td>
                                          <td
                                            width="40"
                                            className="text-center"
                                          >
                                            Max.
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="text-right">
                                            {activity?.extimatedTime?.min ||
                                              "-"}
                                          </td>
                                          <td className="text-right">
                                            {activity?.extimatedTime?.med ||
                                              "-"}
                                          </td>
                                          <td className="text-right">
                                            {activity?.extimatedTime?.max ||
                                              "-"}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                  <td style={{ padding: 0, border: "none" }}>
                                    <table
                                      className="simple-table"
                                      style={{ margin: 0 }}
                                    >
                                      <tbody>
                                        <tr
                                          className="thead"
                                          style={{ fontSize: "10px" }}
                                        >
                                          <td
                                            width="40"
                                            className="text-center"
                                          >
                                            Min.
                                          </td>
                                          <td
                                            width="40"
                                            className="text-center"
                                          >
                                            Med.
                                          </td>
                                          <td
                                            width="40"
                                            className="text-center"
                                          >
                                            Max.
                                          </td>
                                        </tr>
                                        <tr>
                                          <td className="text-right">
                                            {activity?.demand?.min || "-"}
                                          </td>
                                          <td className="text-right">
                                            {activity?.demand?.med || "-"}
                                          </td>
                                          <td className="text-right">
                                            {activity?.demand?.max || "-"}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </td>
                                  <td className="text-right">
                                    {activity.relevance}
                                  </td>
                                  <td className="text-right">
                                    {activity.complexity}
                                  </td>
                                  <td className="text-right">
                                    {formatPeriodDemandGrowth(
                                      activity.periodDemandGrowth
                                    )}
                                  </td>
                                  <td className="text-right">
                                    {formatFrequency(activity?.frequency)}
                                  </td>
                                  <td>
                                    {formatPeriodicity(activity?.periodicity)}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="col-xs-12">
                            <h6>Descrição</h6>
                            <p style={{ wordBreak: "break-word" }}>
                              {activity.description}
                            </p>
                          </div>
                        </div>
                        <div className="col-xs-12">
                          <table
                            className="simple-table"
                            style={{ width: "100%" }}
                          >
                            <thead>
                              {getDefaultAddTableLine(
                                () => handleAddNewAcitivyChecklist(document_id),
                                {},
                                <h5>Tarefas (etapas) {checklistIndicator}</h5>
                              )}
                            </thead>
                            <tbody>
                              <tr className="thead">
                                <td width="40" />
                                <td>Título</td>
                                <td width="50">Remover</td>
                                <td width="30">Editar</td>
                              </tr>
                              {checklist
                                .sort((a, b) => a.title.localeCompare(b.title))
                                .map((item) => {
                                  return (
                                    <tr key={item.id}>
                                      <td style={{ padding: 0 }}>
                                        <SimpleCheckbox
                                          checked={item.completed}
                                          onChange={(e) => {
                                            const updatedChecklist = {
                                              ...item,
                                              completed: !e.checked,
                                            };

                                            saveEditedChecklistItem(
                                              document_id,
                                              updatedChecklist
                                            );
                                          }}
                                          styles={{ padding: 10 }}
                                        />
                                      </td>
                                      <td>{item.title}</td>
                                      {getDefaultRemoveColumn(() =>
                                        handleDeleteChecklistItem(
                                          document_id,
                                          item.id
                                        )
                                      )}
                                      {getDefaultEditColumn(() =>
                                        handleOpenEditChecklist(
                                          document_id,
                                          item
                                        )
                                      )}
                                    </tr>
                                  );
                                })}
                              {checklist.length === 0 && (
                                <tr>
                                  <td colSpan="100" className="text-center">
                                    Nenhum item
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            );
          })}
          {activities.length === 0 && (
            <tr>
              <td colSpan="100" className="text-center">
                Nenhuma atividade
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
}
