import { useEffect, useState } from "react";
import { defineMessages, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { useCompanyPositionsTree } from "../../../../utils/customHooks";
import gbUtils from "../../../../utils/goalsBonus";
import utils from "../../../../utils/toolUtils";
import {
  getCompanyStructureStates,
  getSelectedCompanyStates,
} from "../../../customMapStates";
import FormDialog from "../../FormDialog";
import MaterialMultiSelect from "../../MaterialMultiSelect";
import MaterialTextField from "../../MaterialTextField";
import SelectTreeModal from "../../SelectTreeModal";

const {
  getUpdatedDeleteArrayByIndex,
  iterationSearch,
  getOnlySlugValues,
  getErrorMessage,
} = utils;

const { getRoleBonusInfoById } = gbUtils;

const messages = defineMessages({
  gb_form_job_role: { id: "gb_form_job_role" },
  gb_table_text_gate: { id: "gb_table_text_gate" },
  gb_table_text_appropriated: { id: "gb_table_text_appropriated" },
  gb_table_text_exceeded: { id: "gb_table_text_exceeded" },
  gb_table_text_salaries_quantity_short_lower: {
    id: "gb_table_text_salaries_quantity_short_lower",
  },
  gb_form_access_hierarchy: { id: "gb_form_access_hierarchy" },
  gb_form_can_view: { id: "gb_form_can_view" },
  gb_form_can_workflow: { id: "gb_form_can_workflow" },
  gb_dialog_title_new_role_bonus: { id: "gb_dialog_title_new_role_bonus" },
  gb_dialog_title_edit_role_bonus: { id: "gb_dialog_title_edit_role_bonus" },
  gb_error_duplicated_role: { id: "gb_error_duplicated_role" },
  modalTextCancel: {
    id: "btn_modal_cancel",
  },
  modalTextConfirm: {
    id: "btn_modal_confirm",
  },
});

const initialForm = {
  id: "",
  gate: 0,
  appropriated: 0,
  exceeded: 0,
  role: "",
  roleAccess: [],
  roleViewAccess: [],
  companyPositionId: 0,
};

const NewRoleBonusDialog = (props) => {
  const [form, setForm] = useState(initialForm);
  const [selectPositionType, setSelectPositionType] = useState({ type: null });
  const [selectPositionModal, setSelectPositionModal] = useState(false);
  const [customPositionText, setCustomPositionText] = useState("");
  const { companyPositionsTree } = useCompanyPositionsTree({
    selectedCompany: props.selectedCompany,
    allPositions: props.allPositions,
  });

  const {
    open = false,
    editingForm = {},
    afterCancel,
    afterConfirm,
    goalBonusAdministration,
    getToolConfig,
    intl,
  } = props;

  useEffect(() => {
    if (
      editingForm.type === "editRoleBonus" &&
      goalBonusAdministration.roleBonuses
    ) {
      const roleBonusInfo = getRoleBonusInfoById(
        editingForm.params.roleBonusId,
        goalBonusAdministration.roleBonuses,
        false
      );

      if (roleBonusInfo) setForm(roleBonusInfo);
    }
  }, [editingForm, goalBonusAdministration]);

  const resetForm = () => {
    setForm(initialForm);
  };

  const resetSelectPositionType = () => {
    setSelectPositionType({ type: null });
  };

  const cancelSelectPosition = () => {
    setSelectPositionModal(false);
    resetSelectPositionType();
    setCustomPositionText("");
  };

  const updateFormField = (field, newValue) => {
    setForm({ ...form, [field]: newValue });
  };

  const handleUpdatePosition = (newPosition = {}) => {
    if (newPosition.title && newPosition.title) {
      setForm({
        ...form,
        role: newPosition.title,
        companyPositionId: newPosition.id,
      });
    } else if (newPosition.title) {
      updateFormField("role", newPosition.title);
    }

    cancelSelectPosition();
  };

  const handleSave = async () => {
    const data = await getToolConfig();
    let newConfig = goalBonusAdministration;

    if (data && data.id) {
      if (editingForm.type !== "editRoleBonus") {
        newConfig = {
          ...data,
          roleBonuses: [...data.roleBonuses, { ...form }],
        };
      } else {
        const editingRoleBonusIndex = getRoleBonusInfoById(
          editingForm.params.roleBonusId,
          data.roleBonuses,
          true
        );

        const updatedRoleBonuses = [...data.roleBonuses];
        updatedRoleBonuses[editingRoleBonusIndex] = form;

        newConfig = {
          ...data,
          roleBonuses: updatedRoleBonuses,
        };
      }

      if (typeof afterConfirm === "function") afterConfirm(newConfig);
    }

    resetForm();
  };

  const handleCancel = () => {
    resetForm();

    if (typeof afterCancel === "function") afterCancel();
  };

  const getTypeButton = (title, next = () => {}) => {
    return (
      <div className="simple-selection-button" onClick={next}>
        {title}
      </div>
    );
  };

  const displaySelectPositionTypeModal = () => {
    return (
      <FormDialog
        open={selectPositionModal}
        title="Selecione um tipo"
        onConfirm={() => {}}
        onCancel={cancelSelectPosition}
        hideConfirm
      >
        <div className="row">
          <div className="col-xs-6">
            {getTypeButton("Estrutura de Cargos", () =>
              setSelectPositionType({ type: "COMPANY_POSITION" })
            )}
          </div>
          <div className="col-xs-6">
            {getTypeButton("Customizado", () =>
              setSelectPositionType({ type: "CUSTOM_POSITION" })
            )}
          </div>
        </div>
      </FormDialog>
    );
  };

  const displayCustomPositionModal = () => {
    return (
      <FormDialog
        open={selectPositionType.type === "CUSTOM_POSITION"}
        title="Cargo customizado"
        onConfirm={() => handleUpdatePosition({ title: customPositionText })}
        onCancel={cancelSelectPosition}
        blockConfirm={!customPositionText}
      >
        <MaterialTextField
          id="customPositionModal"
          label={intl.formatMessage(messages.gb_form_job_role)}
          value={customPositionText}
          onChange={(e) => setCustomPositionText(e.target.value)}
          required
        />
      </FormDialog>
    );
  };

  const getFormErrors = () => {
    let errors = [];

    if (form.role.length < 2) errors.push({ slug: "roleMinLength" });
    /*    if (!form.gate) errors.push({ slug: "invalidGate" });
    if (!form.appropriated) errors.push({ slug: "invalidAppropriated" });
    if (!form.exceeded) errors.push({ slug: "invalidExceeded" }); */

    const currentRoles = goalBonusAdministration.roleBonuses;

    if (editingForm.type === "editRoleBonus") {
      const editingRoleBonusIndex = getRoleBonusInfoById(
        editingForm.params.roleBonusId,
        goalBonusAdministration.roleBonuses,
        true
      );

      const filteredRoles = getUpdatedDeleteArrayByIndex(
        currentRoles,
        editingRoleBonusIndex
      );
      const onlyRoleNames = getOnlySlugValues(filteredRoles, "role");
      const isDuplicated = iterationSearch(form.role, onlyRoleNames);

      if (isDuplicated) {
        errors.push({
          slug: "duplicatedRole",
          message: intl.formatMessage(messages.gb_error_duplicated_role),
        });
      }
    } else {
      const onlyRoleNames = getOnlySlugValues(currentRoles, "role");
      const isDuplicated = iterationSearch(form.role, onlyRoleNames);

      if (isDuplicated) {
        errors.push({
          slug: "duplicatedRole",
          message: intl.formatMessage(messages.gb_error_duplicated_role),
        });
      }
    }

    return errors;
  };

  const accessRoleOptions = goalBonusAdministration.roleBonuses.map(
    ({ id, role }) => {
      return { value: id || "", text: role };
    }
  );

  const dialogTitle =
    editingForm.type !== "editRoleBonus"
      ? intl.formatMessage(messages.gb_dialog_title_new_role_bonus)
      : intl.formatMessage(messages.gb_dialog_title_edit_role_bonus);

  const formErrors = getFormErrors();

  return (
    <FormDialog
      open={open}
      title={dialogTitle}
      onConfirm={handleSave}
      onCancel={handleCancel}
      blockConfirm={formErrors.length > 0}
    >
      <div className="row">
        <div className="col-xs-12">
          <MaterialTextField
            id="role"
            label={intl.formatMessage(messages.gb_form_job_role)}
            value={form.role}
            onClick={() => setSelectPositionModal(true)}
            inputStyle={{ cursor: "pointer" }}
          />
          {getErrorMessage(["duplicatedRole"], formErrors)}
          <SelectTreeModal
            open={selectPositionType.type === "COMPANY_POSITION"}
            title="Selecione o Cargo"
            modalTextCancel={intl.formatMessage(messages.modalTextCancel)}
            modalTextConfirm={intl.formatMessage(messages.modalTextConfirm)}
            treeList={companyPositionsTree.treeList}
            treeOrder={companyPositionsTree.treeOrder}
            onConfirm={(selected) => handleUpdatePosition(selected[0])}
            onCancel={cancelSelectPosition}
            singleSelect={true}
            canSelectAnyLevel={true}
            dialogClassName="select-position-dialog"
          />
          {displaySelectPositionTypeModal()}
          {displayCustomPositionModal()}
        </div>
        <div className="col-xs-12">
          <h4 style={{ margin: "0px" }}>
            {intl.formatMessage(
              messages.gb_table_text_salaries_quantity_short_lower
            )}
          </h4>
        </div>
        <div className="col-xs-4" style={{ paddingTop: "0px" }}>
          <MaterialTextField
            id="gate"
            label={intl.formatMessage(messages.gb_table_text_gate)}
            value={form.gate}
            onChange={(e) => updateFormField("gate", e.target.value)}
            type="number"
          />
        </div>
        <div className="col-xs-4" style={{ paddingTop: "0px" }}>
          <MaterialTextField
            id="appropriated"
            label={intl.formatMessage(messages.gb_table_text_appropriated)}
            value={form.appropriated}
            onChange={(e) => updateFormField("appropriated", e.target.value)}
            type="number"
          />
        </div>
        <div className="col-xs-4" style={{ paddingTop: "0px" }}>
          <MaterialTextField
            id="exceeded"
            label={intl.formatMessage(messages.gb_table_text_exceeded)}
            value={form.exceeded}
            onChange={(e) => updateFormField("exceeded", e.target.value)}
            type="number"
          />
        </div>
        <div className="col-xs-12">
          <h4 style={{ margin: "15px 0px 0px 0px" }}>
            {intl.formatMessage(messages.gb_form_access_hierarchy)}
          </h4>
          <MaterialMultiSelect
            id="viewAccess"
            label={intl.formatMessage(messages.gb_form_can_view)}
            options={accessRoleOptions}
            getData={(selectedOptions) =>
              updateFormField("roleViewAccess", selectedOptions)
            }
            initialOptions={form.roleViewAccess}
          />
          <MaterialMultiSelect
            id="workflowAccess"
            label={intl.formatMessage(messages.gb_form_can_workflow)}
            options={accessRoleOptions}
            getData={(selectedOptions) =>
              updateFormField("roleAccess", selectedOptions)
            }
            initialOptions={form.roleAccess}
          />
        </div>
      </div>
    </FormDialog>
  );
};

const mapStateToProps = (state) => {
  const { selectedCompany = {} } = getSelectedCompanyStates(state);
  const { allPositions } = getCompanyStructureStates(state);

  return {
    allPositions,
    selectedCompany,
  };
};

export default injectIntl(connect(mapStateToProps)(NewRoleBonusDialog));
