import { Tooltip } from "@material-ui/core";
import _ from "lodash";
import { useEffect, useState } from "react";
import { Col, OverlayTrigger, Row } from "react-bootstrap";
import { defineMessages } from "react-intl";
import Avatar from "../../../../../../Common/Avatar";

import { getProjectPhaseOptions } from "../../../../../../../utils/projectEvaluation";
import { getPopoverByProject } from "../../../../../../../utils/projectUtils";
import MaterialMultiSelect from "../../../../../../Common/MaterialMultiSelect";
import {
  AnswerText,
  Bar,
  CardContainer,
  CardHeader,
  CardList,
  Container,
  EvaluationText,
  FooterBox,
  FooterProgress,
  List,
  ListContainer,
  ListContent,
  ListFooter,
  ListHeader,
  ListObjectiveTitle,
  ListTextStatic,
  Progress,
} from "../../../../../../Common/TemplateKanban/kanbanStyle";

const messages = defineMessages({
  in: {
    id: "tool.project.in",
  },
  out: {
    id: "tool.project.out",
  },
  next: {
    id: "tool.project.next",
  },
  not_scheduled: {
    id: "tool.project.not_scheduled",
  },
  planning: {
    id: "tool.project.phase.planning",
  },
  ongoing: {
    id: "tool.project.phase.ongoing",
  },
  done: {
    id: "tool.project.phase.done",
  },
  standby: {
    id: "tool.project.phase.standby",
  },
  canceled: {
    id: "tool.project.phase.canceled",
  },
  deliveryDate: {
    id: "tool.project.deliveryDate",
  },
  realizedPlannedActivities: {
    id: "tool.project.plannedRealizedActivities",
  },
  budget: {
    id: "tool.project.budget",
  },
  spent: {
    id: "tool.project.spent",
  },
  physicalAdvance: {
    id: "tool.project.physical_progress",
  },
  replannedProject: {
    id: "tool.project.replannedProject",
  },
  newBudget: {
    id: "tool.project.new_budget",
  },
  selectResponsible: {
    id: "select.responsible",
  },
});

const KanbanMap = ({
  intl,
  kanbanMapData,
  kanbanResponsibles,
  kanbanSubstages,
  selectAndOpenInsight,
}) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const equalStates = _.isEqual(data, kanbanMapData);
    if (!equalStates) setData(kanbanMapData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kanbanMapData]);

  const kanbanMapConfig = getProjectPhaseOptions(intl);

  const getCardConfig = (value) => {
    let cardConfig;
    const valueUpperCase = value.toUpperCase();

    kanbanMapConfig.forEach((item) => {
      if (item.value === valueUpperCase.toString()) {
        cardConfig = item;
      }
    });

    return cardConfig || {};
  };

  const getBorderColor = (projectStatus) => {
    let color = null;

    if (projectStatus.toUpperCase() === "DENTRO") {
      color = "#0c9247";
    } else if (projectStatus.toUpperCase() === "FORA") {
      color = "#f54e4e";
    } else if (projectStatus.toUpperCase() === "PROXIMO") {
      color = "#ffc107";
    }
    return color;
  };

  const displayCardContent = (content) => {
    return content.map((item) => {
      return (
        <List key={item?.questionId}>
          <ListHeader>
            <ListTextStatic>SUBTEMA</ListTextStatic>
            <ListObjectiveTitle>{item.questionText}</ListObjectiveTitle>
          </ListHeader>

          {item.evaluations.map((project, index) => {
            const {
              activities = {},
              projectName = "",
              projectStatus = "",
              answerId = "",
              replanned = "",
              avatar = "",
              responsibleName = "",
              physicalProgress = 0,
              projectStageRelation = "",
            } = project || {};

            const { description: stageRelation = false, parent = false } =
              projectStageRelation || {};

            const borderColor = projectStatus
              ? getBorderColor(projectStatus)
              : "grey";

            const renderSubstageInfo = () => {
              if (!stageRelation || !parent) return;

              return (
                <ListFooter style={{ marginTop: "5px" }}>
                  <FooterBox style={{ textAlign: "left" }}>
                    <p>Substágio</p>
                    <span style={{ fontSize: "13px", fontWeight: 500 }}>
                      {stageRelation}
                    </span>
                  </FooterBox>
                </ListFooter>
              );
            };

            return (
              <div key={index} style={{ position: "relative" }}>
                {replanned && (
                  <i
                    className="fas fa-flag"
                    style={{
                      position: "absolute",
                      color: "#172b4d",
                      top: "5px",
                      right: "15px",
                      zIndex: 1000,
                      fontSize: "10px",
                    }}
                  />
                )}
                <OverlayTrigger
                  placement="right"
                  overlay={getPopoverByProject(null, project, intl)}
                >
                  <ListContainer color={borderColor}>
                    <div className="status-bar" />
                    <ListContent>
                      <Avatar
                        src={avatar}
                        name={responsibleName}
                        style={{
                          width: "30px",
                          height: "30px",
                          marginBottom: "5px",
                          marginRight: "5px",
                        }}
                      />
                      <AnswerText
                        style={{ cursor: "pointer" }}
                        onClick={() => selectAndOpenInsight(answerId)}
                      >
                        {projectName}
                      </AnswerText>
                    </ListContent>
                    <ListFooter>
                      <FooterBox>
                        <p>Plano</p>
                        <span style={{ fontSize: "13px", fontWeight: 500 }}>
                          {activities.planned}
                        </span>
                      </FooterBox>
                      <FooterBox>
                        <p>Realizado</p>
                        <span style={{ fontSize: "13px", fontWeight: 500 }}>
                          {activities.realized}
                        </span>
                      </FooterBox>
                      <FooterProgress>
                        <div
                          style={{
                            margin: "0px",
                            border: "none",
                            height: "18px",
                            width: "140px",
                          }}
                          className="goal-list-item"
                        >
                          <div style={{ color: "#333", marginRight: "5px" }}>
                            <EvaluationText>
                              {physicalProgress.toFixed(0)}%
                            </EvaluationText>
                          </div>
                          <Bar
                            className="bar"
                            style={{
                              width: "100%",
                              background: "#dedede",
                              height: "10px",
                              margin: 0,
                            }}
                          >
                            <Progress
                              className="progress"
                              color={borderColor}
                              style={{
                                width: `${physicalProgress}%`,
                                backgroundColor: "#9dcef0",
                                height: "10px",
                              }}
                            />
                          </Bar>
                        </div>
                      </FooterProgress>
                    </ListFooter>
                    {renderSubstageInfo()}
                  </ListContainer>
                </OverlayTrigger>
              </div>
            );
          })}
        </List>
      );
    });
  };

  const displayCardSubtitles = () => {
    return (
      <Row>
        <Row style={{ display: "flex" }}>
          <div style={{ margin: "10px" }}>
            <Tooltip title="Verde" placement="bottom">
              <div>
                <span className="greenDot" />
                <span> {intl.formatMessage(messages.in)}</span>
              </div>
            </Tooltip>
          </div>
          <div style={{ margin: "10px" }}>
            <Tooltip title="Vermelho" placement="bottom">
              <div>
                <span className="redDot" />
                <span> {intl.formatMessage(messages.out)}</span>
              </div>
            </Tooltip>
          </div>
          <div style={{ margin: "10px" }}>
            <Tooltip title="Amarelo" placement="bottom">
              <div>
                <span className="yellowDot" />
                <span> {intl.formatMessage(messages.next)}</span>
              </div>
            </Tooltip>
          </div>
          <div style={{ margin: "10px" }}>
            <Tooltip title="Cinza" placement="bottom">
              <div>
                <span className="greyDot" />
                <span> {intl.formatMessage(messages.not_scheduled)}</span>
              </div>
            </Tooltip>
          </div>
          <div style={{ margin: "10px" }}>
            <i className="fas fa-flag" />{" "}
            {intl.formatMessage(messages.replannedProject)}{" "}
          </div>
        </Row>
      </Row>
    );
  };

  const getKanbanLength = (stageConfig = []) => {
    let count = 0;

    stageConfig.forEach(({ evaluations = [] }) => {
      const currentLength = evaluations?.length || 0;

      count += currentLength;
    });

    return count;
  };

  const displayKanbanCards = () => {
    return Object.keys(data).map((card, index) => {
      const cardConfig = getCardConfig(card);

      return (
        <CardContainer className="flex-card" key={index}>
          <CardHeader>
            <div>{cardConfig && cardConfig.label && cardConfig.label}</div>
            <div>{getKanbanLength(data[card])}</div>
          </CardHeader>
          <CardList>{displayCardContent(data[card] || [])}</CardList>
        </CardContainer>
      );
    });
  };

  const responsibleOptions = kanbanResponsibles.map(
    ({ id, responsibleName }) => {
      return { value: id, text: responsibleName };
    }
  );

  const responsibleInitialOptions = [
    ...responsibleOptions.map(({ value }) => value),
  ];

  const substageOptions = kanbanSubstages.map(({ id, substageName }) => {
    return { value: id, text: substageName };
  });

  const substageInitialOptions = [...substageOptions.map(({ value }) => value)];

  const handleResponsibles = (selectedOptions = []) => {
    let filteredData = _.cloneDeep(kanbanMapData);

    Object.keys(kanbanMapData).forEach((stage) => {
      kanbanMapData[stage].forEach((line, index) => {
        const { evaluations = [] } = line;

        const clonedEvaluations = _.cloneDeep(evaluations);

        const newEvaluations = clonedEvaluations.filter((evaluation) => {
          const { responsible = {} } = evaluation || {};
          const { id = "" } = responsible || {};

          if (selectedOptions.indexOf(id) > -1) {
            return true;
          } else {
            return false;
          }
        });

        const kanbanStage = filteredData[stage];
        kanbanStage[index].evaluations = [...newEvaluations];

        filteredData = { ...filteredData, [stage]: [...kanbanStage] };
      });
    });

    setData(filteredData);
  };

  const essentialInfo = ({
    projectStageRelation = {},
    projectStage = false,
  }) => {
    const { description = false, parent = {} } = projectStageRelation || {};

    const { description: parentDescription = false } = parent || {};

    const name = description || projectStage || false;

    if (!name) return {};

    const parentName = parentDescription
      ? parentDescription?.toUpperCase()
      : false;

    return { name: name?.toUpperCase(), parentName };
  };

  const handleSubstages = (selectedOptions = []) => {
    let filteredData = _.cloneDeep(kanbanMapData);

    if (selectedOptions.length === 0) return setData(filteredData);

    Object.keys(kanbanMapData).forEach((stage) => {
      kanbanMapData[stage].forEach(({ evaluations = [] }, index) => {
        const clonedEvaluations = _.cloneDeep(evaluations);

        let newEvaluations = clonedEvaluations.filter((evaluation) => {
          const { name = false, parentName = false } =
            essentialInfo(evaluation);

          if (!name) return false;

          let include = false;

          selectedOptions.forEach((option) => {
            const splittedOption = option.split("[separator]");

            const newName = splittedOption[0] || "0";
            const newParentName = splittedOption[1] || "0";

            if (newParentName !== "0") {
              if (newName === name) include = true;
              return;
            }

            if (
              newName === name ||
              newName === parentName ||
              newParentName === name ||
              newParentName === parentName
            )
              include = true;
          });

          if (include) {
            return true;
          } else {
            return false;
          }
        });

        const kanbanStage = filteredData[stage];
        kanbanStage[index].evaluations = [...newEvaluations];

        filteredData = { ...filteredData, [stage]: [...kanbanStage] };
      });
    });

    setData(filteredData);
  };

  return (
    <div>
      <Row>
        <Col md={4} className="projectResponsibleSelect">
          <MaterialMultiSelect
            allButton
            selectAll
            setDefaultValue
            id="responsiblesSelector"
            options={responsibleOptions}
            initialOptions={responsibleInitialOptions}
            label={intl.formatMessage(messages.selectResponsible)}
            getData={(selectedOptions) => handleResponsibles(selectedOptions)}
          />
        </Col>
        <Col md={4} className="projectSubstageSelect">
          <MaterialMultiSelect
            allButton
            selectAll
            setDefaultValue
            id="substageSelect"
            options={substageOptions}
            initialOptions={substageInitialOptions}
            label={"Selecione um subestágio"}
            getData={(selectedOptions) => handleSubstages(selectedOptions)}
          />
        </Col>
      </Row>
      {displayCardSubtitles()}
      <Container>{displayKanbanCards()}</Container>
    </div>
  );
};

export default KanbanMap;
