import { connect } from "react-redux";
import { selectInsight } from "../../../../../../actions/insightActions";
import { changeShowInsightResume } from "../../../../../../actions/toolsConfigurationsActions";
import { getRelativePercentage } from "../../../../../../utils/MathUtils";
import utils from "../../../../../../utils/toolUtils";
import FilterEvaluationCard from "../../../../../Common/FilterEvaluationCard";

const { getFullReadinessValues, getLabelBySlug } = utils;

const MapReadyField = (props) => {
  const {
    filteredEvaluations,
    readinessOptions,
    subtitleColor,
    messages,
    intl,
    selectAndOpenInsight,
  } = props;

  let people = [];
  let evaluations = {
    JOB: [],
    TRAIL: [],
  };

  filteredEvaluations.forEach(({ peopleEvaluation, insightId }) => {
    const { professionalProfile, evaluationTarget } = peopleEvaluation;
    const { professional } = professionalProfile;
    const { carrearPath } = evaluationTarget;
    const { mainTrail } = carrearPath;

    if (mainTrail) {
      evaluations =
        mainTrail.path === "JOB"
          ? { ...evaluations, JOB: [...evaluations.JOB, mainTrail.readiness] }
          : {
              ...evaluations,
              TRAIL: [...evaluations.TRAIL, mainTrail.readiness],
            };

      people = [...people, { ...professional, mainTrail, insightId }];
    }
  });

  const READY = {
    JOB: getFullReadinessValues(evaluations.JOB).READY,
    TRAIL: getFullReadinessValues(evaluations.TRAIL).READY,
  };

  const totalEvaluations = READY.JOB + READY.TRAIL;

  const headerFilters = [
    {
      label: intl.formatMessage(messages.global_total),
      value: totalEvaluations,
      slug: "all",
      bgColor: "#ddd",
    },
    {
      label: intl.formatMessage(messages.career_job),
      value: READY.JOB,
      bottomLabel: `${getRelativePercentage(READY.JOB, totalEvaluations)}%`,
      slug: "job",
    },
    {
      label: intl.formatMessage(messages.career_trail),
      value: READY.TRAIL,
      bottomLabel: `${getRelativePercentage(READY.TRAIL, totalEvaluations)}%`,
      slug: "trail",
    },
  ];

  const openInsight = (id) => {
    selectAndOpenInsight(id);
  };

  const bodyTable = {
    title: intl.formatMessage(messages.labels_professionals),
    values: [
      ...people.map(({ insightId, name, mainTrail, avatarBlobId }) => {
        return {
          label: name,
          avatarBlobId,
          clickFunction: () => openInsight(insightId),
          iconLetter: mainTrail.path === "JOB" ? "J" : "T",
          slugs: mainTrail.path === "JOB" ? ["job", "all"] : ["trail", "all"],
        };
      }),
    ],
  };

  return (
    <FilterEvaluationCard
      cardTitle={getLabelBySlug("READY", readinessOptions).toUpperCase()}
      headerFilters={headerFilters}
      bodyTable={bodyTable}
      fillColor={subtitleColor}
      undefinedMessage={intl.formatMessage(messages.global_undefined)}
    />
  );
};

export default connect(null, { selectInsight, changeShowInsightResume })(
  MapReadyField
);
