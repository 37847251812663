import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button, OverlayTrigger } from "react-bootstrap";
import { defineMessages, injectIntl } from "react-intl";

import { translatedText } from "../../utils/translationUtils";
import {
  getCompanyMembersAndParticipants,
  getWorkspacesStates,
  getSelectedToolStates,
  getAccessStates,
} from "../customMapStates";
import GenericTable from "./GenericTable";
import FormDialog from "./FormDialog";
import {
  handleRepeatedMembers,
  getMembersByIds,
  patternSearch,
  mountCenaryPermissions,
  getUserRoleStyle,
  changeUserField,
  isLastRole,
  genericTip,
  getTipMessage,
} from "../../utils/accessLevels";
import {
  removeAccess,
  fetchEntityUsersWithRole,
} from "../../actions/accessLevelsActions";
import {
  grantSceneryAccess,
  removeSceneryAccess,
} from "../../actions/accessControllerActions";

const messages = defineMessages({
  accessLevels_manage_leaders: {
    id: "accessLevels_manage_leaders",
  },
  accessLevels_name: {
    id: "accessLevels_name",
  },
  accessLevels_permissions: {
    id: "global.permissions",
  },
  accessLevels_leader: {
    id: "global.leader",
  },
  new_leader: {
    id: "accessLevels_new_leader",
  },
  accessLevels_manage_permissions: {
    id: "accessLevels_manage_permissions",
  },
  accessLevels_viewer: {
    id: "global.viewer",
  },
  removeAccessFrom: {
    id: "removeAccessFrom",
  },
  youCantDeleteLast: {
    id: "youCantDeleteLast",
  },
  manageLeadersAndViewers: {
    id: "manageLeadersAndViewers",
  },
  scenerie: {
    id: "global.cenary",
  },
  changePermissionFrom: {
    id: "changePermissionFrom",
  },
});

const translation = (id, values) => translatedText(id, messages, values);

const defaultAccessOpt = {
  showModal: false,
  user: null,
  oldRole: null,
  newRole: null,
  isLast: false,
};

const PermissionModal = ({
  show,
  onConfirm,
  onCancel,
  companyMembers,
  removeSceneryAccess,
  removeAccess,
  scenerieData = {},
  buildedAccess = false,
  entityAccess = {},
  fetchEntityUsersWithRole,
  grantSceneryAccess,
}) => {
  const [leaders, setLeaders] = useState([]);
  const [searchWords, setSearchWords] = useState("");
  const [removeAccessOpt, setRemoveAccessOpt] = useState(defaultAccessOpt);
  const [changeAccessOpt, setChangeAccessOpt] = useState(defaultAccessOpt);
  const [fetchedCenarys, setFetchedCenarys] = useState(false);

  useEffect(() => {
    const fetchCenaryAccess = async () => {
      await fetchEntityUsersWithRole(scenerieData.id, "CENARY");
      setFetchedCenarys(true);
    };

    if (buildedAccess === true && scenerieData.id) fetchCenaryAccess();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buildedAccess, scenerieData]);

  useEffect(() => {
    if (buildedAccess === true && fetchedCenarys && leaders.length === 0) {
      const cenaryAccess = mountCenaryPermissions(entityAccess.cenary);

      setLeaders(cenaryAccess);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buildedAccess, fetchedCenarys, entityAccess]);

  useEffect(() => {
    if (!show) setLeaders([]);
  }, [show]);

  const { id: selectedScenerieId } = scenerieData;
  const headers = { name: translation("accessLevels_name"), email: "E-mail" };

  const changeUserAccess = () => {
    const {
      user = {},
      oldRole = null,
      newRole = null,
      access,
    } = changeAccessOpt;

    const { id: userId = 0, domainUserId = 0 } = user;

    // Adiciona acessos em cascata
    grantSceneryAccess(userId, newRole, access);

    //Exclui a role antiga
    if (oldRole) {
      const removeBody = {
        entityId: selectedScenerieId,
        entityType: "CENARY",
        role: oldRole.toUpperCase(),
      };

      removeAccess(removeBody, domainUserId);
    }

    const updatedLeaders = changeUserField(
      leaders,
      userId,
      "role",
      newRole.toLowerCase()
    );

    setLeaders([...updatedLeaders]);

    cleanChangeAccessOpt();
  };

  const handleRoleChange = (user = {}, newRole = "", access = "") => {
    if (isLastLeader(user)) return;

    const { id: userId = 0, role: oldRole = null } = user;

    if (oldRole === "choose") grantSceneryAccess(userId, newRole, access);

    if (newRole !== "choose" && oldRole !== "choose") {
      setChangeAccessOpt({
        showModal: true,
        user,
        newRole,
        oldRole,
        access,
      });

      return;
    }

    const updatedLeaders = changeUserField(
      leaders,
      userId,
      "role",
      newRole.toLowerCase()
    );

    setLeaders([...updatedLeaders]);
  };

  const onAddLeader = (leaderIds = []) => {
    const allSelectedLeaders = getMembersByIds(leaderIds, companyMembers);

    setLeaders([...leaders, ...allSelectedLeaders]);
  };

  const onDeleteLeader = (user = {}) => {
    let isLast = false;

    if (isLastLeader(user)) isLast = true;

    setRemoveAccessOpt({ showModal: true, user, isLast });
  };

  const removeUserAccess = () => {
    const { user = {}, isLast } = removeAccessOpt;

    if (isLast) return;

    const { id: userId = 0, role = null } = user;

    let accessType = null;

    if (role === "viewer") accessType = "READ";

    if (userId && role) {
      const removeBody = {
        id: userId,
        role: role.toUpperCase(),
        entityId: selectedScenerieId,
        accessType,
      };

      removeSceneryAccess(removeBody);
    }

    const filteredLeaders = leaders.filter(
      ({ id }) => Number(id) !== Number(userId)
    );

    setLeaders([...filteredLeaders]);

    cleanRemoveAccessOpt();
  };

  const isLastLeader = (user = {}) => {
    return isLastRole(user, leaders, "leader");
  };

  const getCustomTipMessage = (
    user = {},
    role = "",
    complement = "",
    counterComplement = complement
  ) => {
    if (isLastLeader(user))
      return getTipMessage("youCantDeleteLast", counterComplement);

    if (user.role === role) return getTipMessage("delete", complement);

    return getTipMessage("add", complement);
  };

  const customActionsButtons = (user = {}) => {
    const { role = null } = user;

    return (
      <div className="indexZZ">
        <OverlayTrigger
          placement="top"
          overlay={genericTip(
            getCustomTipMessage(
              user,
              "leader",
              translation("accessLevels_leader")
            )
          )}
        >
          <Button
            className={getUserRoleStyle(role, "leader")}
            onClick={() => handleRoleChange(user, "LEADER", "EDIT")}
          >
            <i className="fas fa-user-tie" />{" "}
            {translation("accessLevels_leader")}
          </Button>
        </OverlayTrigger>
        <OverlayTrigger
          placement="top"
          overlay={genericTip(
            getCustomTipMessage(
              user,
              "viewer",
              translation("accessLevels_viewer"),
              translation("accessLevels_leader")
            )
          )}
        >
          <Button
            className={getUserRoleStyle(role, "viewer")}
            onClick={() => handleRoleChange(user, "VIEWER", "READ")}
          >
            <i className="fas fa-user" /> {translation("accessLevels_viewer")}
          </Button>
        </OverlayTrigger>
      </div>
    );
  };

  const defaultPermissions = (user = {}) => {
    return (
      <div>
        <a
          onClick={() => handleRoleChange(user, "choose")}
          style={{ cursor: "pointer", fontSize: "15px", color: "#6b42a9" }}
        >
          {translation("accessLevels_manage_permissions")}
        </a>
      </div>
    );
  };

  const handleAddPermissions = (user = {}) => {
    const { role = null } = user;

    if (role) return customActionsButtons(user);

    return defaultPermissions(user);
  };

  const customActions = [
    {
      title: translation("accessLevels_permissions"),
      actions: handleAddPermissions,
    },
  ];

  const cleanRemoveAccessOpt = () => {
    setRemoveAccessOpt(defaultAccessOpt);
  };

  const cleanChangeAccessOpt = () => {
    setChangeAccessOpt(defaultAccessOpt);
  };

  const RemoveUserModal = () => {
    const { showModal = false, user = {}, isLast = false } = removeAccessOpt;

    const { name: userName = "" } = user || {};

    let modalTitle = translation("removeAccessFrom") + userName;

    if (isLast)
      modalTitle =
        translation("youCantDeleteLast") + translation("accessLevels_leader");

    return (
      <FormDialog
        open={showModal}
        title={modalTitle}
        onConfirm={() => removeUserAccess()}
        onCancel={() => cleanRemoveAccessOpt()}
        bodyStyle={{ padding: "0px" }}
        hideConfirm={isLast}
      >
        <div></div>
      </FormDialog>
    );
  };

  const translations = {
    VIEWER: translation("accessLevels_viewer"),
    LEADER: translation("accessLevels_leader"),
  };

  const getTranslatedRole = (role = "") => {
    if (!role) return "";

    return translations[role.toUpperCase()].toUpperCase();
  };

  const ChangeUserModal = () => {
    const {
      showModal = false,
      user = {},
      oldRole = null,
      newRole = null,
    } = changeAccessOpt;

    const { name: userName = "" } = user || {};

    const modalTitle = (
      <div>
        <i className="fas fa-user" /> {userName}
      </div>
    );

    const translatedOldRole = getTranslatedRole(oldRole);
    const translatedNewRole = getTranslatedRole(newRole);

    return (
      <FormDialog
        open={showModal}
        title={modalTitle}
        onConfirm={() => changeUserAccess()}
        onCancel={() => cleanChangeAccessOpt()}
        bodyStyle={{ padding: "0 15px 0 15px" }}
      >
        <div>
          <h5>
            {translation("changePermissionFrom")}
            {translatedOldRole} <i className="fas fa-arrow-right" />{" "}
            {translatedNewRole} ?
          </h5>
        </div>
      </FormDialog>
    );
  };

  const title = (
    <div className="genericTableTitle">
      <span>
        {translation("manageLeadersAndViewers")}
        <hr />
      </span>
    </div>
  );

  const { name: sceneryName } = scenerieData;

  return (
    <>
      <FormDialog
        open={show}
        title={translation("scenerie") + ": " + sceneryName}
        onConfirm={() => onConfirm()}
        onCancel={() => onCancel()}
        bodyStyle={{ padding: "0 15px 15px 15px" }}
        dialogClassName="leadersModal"
        hideConfirm={true}
      >
        <GenericTable
          headers={headers}
          title={title}
          data={patternSearch(leaders, searchWords)}
          memberModalTitle={translation("new_leader")}
          memberModalData={handleRepeatedMembers(companyMembers, leaders)}
          onAdd={onAddLeader}
          onDelete={onDeleteLeader}
          onSearch={setSearchWords}
          customActionsTitle={translation("accessLevels_permissions")}
          customActions={customActions}
        />
      </FormDialog>
      <RemoveUserModal />
      <ChangeUserModal />
    </>
  );
};

const mapStateToProps = (state) => {
  const { companyMembers } = getCompanyMembersAndParticipants(state);
  const { selectedWorkspace = {} } = getWorkspacesStates(state);
  const { selectedToolID = {} } = getSelectedToolStates(state);
  const { entityAccess = {}, buildedAccess = false } = getAccessStates(state);

  return {
    companyMembers,
    selectedWorkspace,
    selectedToolID,
    buildedAccess,
    entityAccess,
  };
};

export default injectIntl(
  connect(mapStateToProps, {
    removeSceneryAccess,
    fetchEntityUsersWithRole,
    removeAccess,
    grantSceneryAccess,
  })(PermissionModal)
);
