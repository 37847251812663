import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { deletePlan, postPlan, putPlan } from "../../../../actions/okrActions";
import TeamForm from "../../../Tool/ToolTabs/CustomFields/Okr/tabs/forms/TeamForm";
import ConfirmationDialog from "../../ConfirmationDialog";

const defaultParams = {
  type: "",
  params: {},
};

const defaultConfirmDialog = {
  title: "",
  onConfirm: null,
  onCancel: null,
  open: false,
  params: {},
};

const ThemeGroupSelectDialog = (props) => {
  const [modalSlug, setModalSlug] = useState([]);
  const [editingForm, setEditingForm] = useState(defaultParams);
  const [confirmDialog, setConfirmDialog] = useState(defaultConfirmDialog);

  const {
    open = false,
    afterCancel,
    allThemes,
    okrPlans,
    cycleBase,
    createOrUpdatePlan,
    getDefaultAddTableLine,
    getDefaultRemoveColumn,
    selectedPlan,
  } = props;

  useEffect(() => {
    if (open) {
      setEditingForm({
        type: "planModal",
        params: {
          plan: selectedPlan,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  function openFormDelete(index, getParams) {
    const newConfirmDialog = mountDialog(index, getParams);

    setConfirmDialog(newConfirmDialog);
  }

  function resetEditingForm() {
    setEditingForm(defaultParams);
  }

  function handleOpenModal(slug, open = false, params = {}) {
    const newSlugs = [...modalSlug];
    const indexOfSlug = newSlugs.indexOf(slug);

    if (!open && indexOfSlug > -1) {
      newSlugs.splice(newSlugs.indexOf(slug), 1);
      resetEditingForm();
    } else if (open && indexOfSlug === -1) {
      newSlugs.push(slug);
    }

    if (Object.keys(params).length > 0) {
      setEditingForm(params);
    }

    setModalSlug(newSlugs);
  }

  function resetDialog() {
    setConfirmDialog(defaultConfirmDialog);
  }

  function mountDialog(index, params) {
    const { handleDelete, getOpen, getTitle, getDescription, getConfirmText } =
      params;

    function resetDelete(index) {
      handleDelete(index);
      resetDialog();
    }

    const append = getConfirmText ? { confirmText: getConfirmText() } : {};

    return {
      title: getTitle(),
      description: getDescription(),
      open: getOpen(),
      onCancel: () => resetDialog(),
      onConfirm: () => resetDelete(index),
      ...append,
    };
  }

  const handleCancel = () => {
    if (typeof afterCancel === "function") afterCancel();
  };

  const essencialProps = {
    allThemes,
    getDefaultAddTableLine,
    getDefaultRemoveColumn,
    modalSlug,
    isEditing: editingForm,
    openFormDelete,
    okrPlans,
    cycleBase,
  };

  return (
    <>
      <ConfirmationDialog
        open={confirmDialog.open}
        title={confirmDialog.title}
        description={confirmDialog.description}
        onConfirm={confirmDialog.onConfirm || (() => {})}
        confirmText={confirmDialog.confirmText}
        onCancel={confirmDialog.onCancel}
        hideCancel={confirmDialog.hideCancel}
        confirmColor="#ff3151"
      />
      <TeamForm
        open
        plans={okrPlans}
        cycleBase={cycleBase}
        essencialProps={essencialProps}
        createOrUpdatePlan={createOrUpdatePlan}
        openCicleForm={handleOpenModal}
        openFormDelete={openFormDelete}
        onCancel={handleCancel}
        isEditing={editingForm}
      />
    </>
  );
};

const mapDispatchToProps = {
  putPlan,
  postPlan,
  deletePlan,
};

export default connect(null, mapDispatchToProps)(ThemeGroupSelectDialog);
