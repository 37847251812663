import { useEffect } from "react";
import { connect } from "react-redux";
import {
  createSceneryProcess,
  updateCompAnalysisAdministration,
} from "../../../../actions/compAnalysisActions";
import {
  buildFormAdmConfigBody,
  buildRestAdmConfigBody,
} from "../../../../utils/cpAnalysis/entity";
import utils from "../../../../utils/toolUtils";
import ScenerieBoardSelection from "../../../Common/CpAnalysis/forms/ScenerieBoardSelection";
import {
  getCompAnalysisStates,
  getSelectedToolStates,
} from "../../../customMapStates";

const { getValidFunc } = utils;

const CompetitiveAnalysis = (props) => {
  const {
    type,
    clearViewCallback,
    selectedTool = {},
    currentAdministration = [],
    allSceneries = [],
    params = {},
    createSceneryProcess,
    updateCompAnalysisAdministration,
    userProfile,
  } = props;

  useEffect(() => {
    const formConfig = buildFormAdmConfigBody(currentAdministration);

    const { id = false } = formConfig;

    if (!id) {
      updateCompAnalysisAdministration(
        buildRestAdmConfigBody(formConfig),
        selectedTool.id
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAdministration, selectedTool.id]);

  const cancelCallback = getValidFunc(params.cancelCallback);

  const handleSaveScenerie = (entityInfo) => {
    createSceneryProcess(
      selectedTool,
      entityInfo,
      userProfile,
      params.nextScenerieOrder
    );

    clearDialogControll();
  };

  const clearDialogControll = () => {
    if (type) clearViewCallback(type);
    cancelCallback();
  };

  const essentialProps = {
    open: true,
    afterConfirm: handleSaveScenerie,
    afterCancel: clearDialogControll,
    cpAdministration: currentAdministration,
    allSceneries,
  };

  return <ScenerieBoardSelection {...essentialProps} />;
};

const mapStateToProps = (state) => {
  const { selectedTool, allSceneries } = getSelectedToolStates(state);
  const { currentAdministration } = getCompAnalysisStates(state);

  const { user } = state;

  return {
    userProfile: user,
    selectedTool,
    currentAdministration,
    allSceneries,
  };
};

export default connect(mapStateToProps, {
  createSceneryProcess,
  updateCompAnalysisAdministration,
})(CompetitiveAnalysis);
