import { useEffect } from "react";
import { connect } from "react-redux";
import { setCurrentAnalysisToolType } from "../../../../actions/compAnalysisActions";
import { getCurrentAnalysisTool } from "../../../../utils/cpAnalysis/entity";
import {
  getCompAnalysisStates,
  getSceneryStates,
} from "../../../customMapStates";

const CompetitiveAnalysisWatcher = (props) => {
  const {
    selectedCenary = {},
    currentAdministration = {},
    setCurrentAnalysisToolType,
  } = props;

  useEffect(() => {
    const { competitiveAnalysisId = false } = selectedCenary || {};

    if (competitiveAnalysisId) {
      const currentAnalysisTool = getCurrentAnalysisTool(
        currentAdministration,
        competitiveAnalysisId
      );

      setCurrentAnalysisToolType(currentAnalysisTool.structure.type);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCenary]);

  return null;
};

const mapStateToProps = (state) => {
  const { currentAdministration = {} } = getCompAnalysisStates(state);
  const { selectedCenary } = getSceneryStates(state);
  return { selectedCenary, currentAdministration };
};

export default connect(mapStateToProps, { setCurrentAnalysisToolType })(
  CompetitiveAnalysisWatcher
);
