import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { defineMessages } from "react-intl";
import _ from "lodash";
import { Fab } from "@material-ui/core";
import { Tabs, Tab } from "react-bootstrap";
import CheckIcon from "@material-ui/icons/Check";
import ConfirmationDialog from "../../../../../Common/ConfirmationDialog";
import {
  getCompanyMembersAndParticipants,
  getSelectedToolStates,
  getKpiStates,
} from "../../../../../customMapStates";

import TargetAdministrationTable from "./fields/TargetAdministrationTable";
import PeriodConfigTable from "./fields/PeriodConfigTable";
import ThemesConfigTable from "./fields/ThemesConfigTable";
import GroupsConfigTable from "./fields/GroupsConfigTable";
import ProcessConfigTable from "./fields/ProcessConfigTable";
import IndicatorsTable from "./fields/IndicatorsTable";
import DisplayOpenForms from "./DisplayOpenForms";
import {
  getKpiConfiguration,
  updateKpiAdministration,
} from "../../../../../../actions/kpiActions";
import utils from "../../../../../../utils/toolUtils";
import kpiUtils from "../../../../../../utils/kpis";

import {
  getPageAccessRulesOptions,
  getRelationshipTypeOptions,
} from "../../../../../../constants/kpis";
import { useWindowSize } from "../../../../../../utils/customHooks";
import { useAdministrationStyles } from "../../../../../../utils/administration/styles";
import { Errors } from "../../../../../../utils/validations";
import { getStyledErrorMessage } from "../../../../../../utils/formValidation";
import { buildKpiAdministrationFinalBody } from "../../../../../../utils/kpis/entity";

const { getUpdatedDeleteArrayByIndex, getObjectDifference, translatedText } =
  utils;

const {
  getDefaultTargetCols,
  buildFormAdmConfigBody,
  buildRestAdmConfigBody,
  getKpiConfigBySelectedToolId,
} = kpiUtils;

const messages = defineMessages({
  kpi_tab_administration_title: { id: "kpi_tab_administration_title" },
  kpi_sub_title_config: { id: "kpi_sub_title_config" },
  kpi_sub_title_period_config: { id: "kpi_sub_title_period_config" },
  kpi_sub_title_dates: { id: "kpi_sub_title_dates" },
  kpi_sub_title_triggers: { id: "kpi_sub_title_triggers" },
  kpi_sub_title_guardians: { id: "kpi_sub_title_guardians" },
  kpi_sub_title_themes: { id: "kpi_sub_title_themes" },
  kpi_sub_title_subthemes: { id: "kpi_sub_title_subthemes" },
  kpi_sub_title_scenario: { id: "kpi_sub_title_scenario" },
  kpi_sub_title_indicators: { id: "kpi_sub_title_indicators" },
  kpi_tab_administration_config: { id: "kpi_tab_administration_config" },
  kpi_tab_administration_planning: { id: "kpi_tab_administration_planning" },
  kpi_tab_administration_indicators: {
    id: "kpi_tab_administration_indicators",
  },
  global_add: { id: "global.add" },
  kpi_table_text_year: { id: "kpi_table_text_year" },
  kpi_table_text_start_registrations: {
    id: "kpi_table_text_start_registrations",
  },
  kpi_table_text_end_registrations: { id: "kpi_table_text_end_registrations" },
  kpi_table_text_start_approval: { id: "kpi_table_text_start_approval" },
  kpi_table_text_end_approval: { id: "kpi_table_text_end_approval" },
  kpi_table_text_start_audit: { id: "kpi_table_text_start_audit" },
  kpi_table_text_end_audit: { id: "kpi_table_text_end_audit" },
  kpi_error_invalid_same_month: { id: "kpi_error_invalid_same_month" },
});

const translation = (id, values = {}) => translatedText(id, messages, values);

const defaultConfirmDialog = {
  title: "",
  onConfirm: null,
  onCancel: null,
  open: false,
  params: {},
};

const defaultEditingForm = {
  type: "",
  params: {},
};

const defaultFormKpiConfig = buildFormAdmConfigBody({});

const PlanningTabView = (props) => {
  const { administrationErrors } = props;

  return (
    <div className="tab-content" style={{ margin: "0px" }}>
      <div className="row">
        <div className="col-xs-12 col-lg-6">
          <h4>{translation("kpi_sub_title_config")}</h4>
          <div className="row">
            <TargetAdministrationTable {...props} />
          </div>
        </div>
        <div className="col-xs-12 col-lg-6">
          <h4>{translation("kpi_sub_title_period_config")}</h4>
          <div className="row">
            <PeriodConfigTable
              {...props}
              title="Atualizar o Período dos Indicadores."
              description="Você quer atualizar o período de todos os indicadores? Se não quiser, poderá atualizar individualmente aqueles que forem necessários."
              confirmText="Atualizar Todos Indicadores"
            />
            {getStyledErrorMessage(["sameMonthError"], administrationErrors)}
          </div>
        </div>
      </div>
      <div className="col-xs-12 col-md-4">
        <h4>{translation("kpi_sub_title_scenario")}</h4>
        <div className="row">
          <ThemesConfigTable {...props} />
        </div>
      </div>
      <div className="col-xs-12 col-md-4">
        <h4>{translation("kpi_sub_title_themes")}</h4>
        <div className="row">
          <GroupsConfigTable {...props} />
        </div>
      </div>
      <div className="col-xs-12 col-md-4">
        <h4>{translation("kpi_sub_title_subthemes")}</h4>
        <div className="row">
          <ProcessConfigTable {...props} />
        </div>
      </div>
    </div>
  );
};

const IndicatorsTableView = (props) => {
  return (
    <div className="tab-content" style={{ margin: "0px" }}>
      <div className="col-xs-12">
        <h4>{translation("kpi_sub_title_indicators")}</h4>
        <div className="row">
          <IndicatorsTable {...props} />
        </div>
      </div>
    </div>
  );
};

const Administration = (props) => {
  const [adminController, setAdminController] = useState({
    changes: [],
  });
  const [kpiAdministration, setKpiAdministration] =
    useState(defaultFormKpiConfig);
  const [kpiAdminHistory, setKpiAdminHistory] = useState(defaultFormKpiConfig);
  const [confirmationDialog, setConfirmationDialog] = useState({
    ...defaultConfirmDialog,
  });
  const [openFormDialogs, setOpenFormDialogs] = useState([]);
  const [editingForm, setEditingForm] = useState(defaultEditingForm);
  const [activeTab, setActiveTab] = useState("planningTab");
  const windowSize = useWindowSize();

  const screenWidth = windowSize[0];
  const applyScroll = screenWidth <= 1370;

  const {
    selectedTool = {},
    updateKpiAdministration,
    allKpiAdministration,
    isLoading,
  } = props;

  const { periodCycle } = kpiAdministration;

  const getErrors = () => {
    const errors = new Errors();

    if (periodCycle.start === periodCycle.end)
      errors.add("sameMonthError", translation("kpi_error_invalid_same_month"));

    return errors.getErrors();
  };

  const handleSubmitAdminConfig = (newKpiConfig, callbacks) => {
    const validNewKpiConfig =
      newKpiConfig.id || newKpiConfig.id === null
        ? newKpiConfig
        : kpiAdministration;

    const saveKpiConfig = _.cloneDeep(validNewKpiConfig);

    if (selectedTool && selectedTool.id) {
      const restTrasformedBody = buildRestAdmConfigBody(saveKpiConfig);
      updateKpiAdministration(selectedTool.id, restTrasformedBody, callbacks);
    }
  };

  useEffect(() => {
    if (allKpiAdministration) {
      const restConfig = getKpiConfigBySelectedToolId(
        selectedTool.id,
        allKpiAdministration
      );

      const formConfig = buildFormAdmConfigBody(restConfig);

      if (formConfig && !_.isEqual(formConfig, kpiAdminHistory)) {
        setKpiAdminHistory(formConfig);

        if (!isLoading && !_.isEqual(formConfig, kpiAdministration)) {
          setKpiAdministration(formConfig);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allKpiAdministration, isLoading, selectedTool.id]);

  useEffect(() => {
    const restAndFormDiff = getObjectDifference(
      kpiAdministration,
      kpiAdminHistory
    );
    let newAdminController = adminController;
    const anyChanges = [];

    Object.keys(restAndFormDiff).forEach((objKey) => {
      anyChanges.push(objKey);
    });

    if (!isLoading) {
      newAdminController = {
        ...adminController,
        changes: anyChanges,
      };
    }

    if (!_.isEqual(adminController, newAdminController)) {
      setAdminController(newAdminController);
    }
  }, [kpiAdministration, kpiAdminHistory, adminController, isLoading]);

  const pageAccessRulesOptions = getPageAccessRulesOptions();
  const relationshipTypeOptions = getRelationshipTypeOptions();

  const administrationErrors = getErrors(kpiAdministration);

  const getKpiConfig = async () => {
    if (selectedTool && selectedTool.id) {
      const { data } = await getKpiConfiguration(selectedTool.id);
      return buildKpiAdministrationFinalBody(data);
    }
  };

  const handleAutoSave = (newKpiConfig, callbacks) => {
    if (newKpiConfig) handleSubmitAdminConfig(newKpiConfig, callbacks);
  };

  const updateConfirmationDialog = (newDialogConfig) => {
    if (!_.isEqual(confirmationDialog, newDialogConfig)) {
      setConfirmationDialog(newDialogConfig);
    }
  };

  const resetConfirmDialog = () => {
    setConfirmationDialog({ ...defaultConfirmDialog });
  };

  const resetEditingDialog = () => {
    setEditingForm({ ...defaultEditingForm });
  };

  const toggleOpenFormDialogs = (slug, toggleTo = null) => {
    if (slug) {
      const slugIndex = openFormDialogs.indexOf(slug);
      let updatedOpenFormDialogs = [...openFormDialogs];

      const closeOpenForm = (index) => {
        return getUpdatedDeleteArrayByIndex(openFormDialogs, index);
      };

      if (toggleTo !== null) {
        if (toggleTo === false) {
          if (slugIndex > -1) {
            updatedOpenFormDialogs = closeOpenForm(slugIndex);
          }
        } else {
          updatedOpenFormDialogs.push(slug);
        }
      } else if (slugIndex > -1) {
        updatedOpenFormDialogs = closeOpenForm(slugIndex);
      } else {
        updatedOpenFormDialogs.push(slug);
      }

      setOpenFormDialogs(updatedOpenFormDialogs);
    }
  };

  const closeFormAndCallback = (slug, callback) => {
    toggleOpenFormDialogs(slug, false);

    if (typeof callback === "function") callback();
  };

  const handleSetEditingForm = (dialogSlug, params = {}) => {
    setEditingForm({
      type: dialogSlug,
      params,
    });

    toggleOpenFormDialogs(dialogSlug);
  };

  const checkSaveButton = () => {
    if (
      isLoading ||
      adminController.changes.length === 0 ||
      administrationErrors.length > 0
    ) {
      return false;
    }

    return true;
  };

  const essencialProps = {
    kpiAdministration,
    setKpiAdministration,
    administrationErrors,
    openFormDialogs,
    setOpenFormDialogs,
    setConfirmationDialog: updateConfirmationDialog,
    resetConfirmDialog,
    getDefaultTargetCols,
    toggleOpenFormDialogs,
    pageAccessRulesOptions,
    relationshipTypeOptions,
    companyMembersAndParticipants: props.companyMembersAndParticipants,
    editingForm,
    handleSetEditingForm,
    resetEditingDialog,
    closeFormAndCallback,
    handleAutoSave,
    allSceneries: props.allSceneries,
    allThemes: props.allThemes,
    allQuestions: props.allQuestions,
    allAnswers: props.allAnswers,
    applyScroll,
    getKpiConfig,
    selectedToolId: selectedTool.id,
  };

  const classes = useAdministrationStyles();
  const saveButtonEnabled = checkSaveButton() || false;

  return (
    <div className="row" style={{ paddingBottom: "100px" }}>
      <Fab
        className={classes.saveButton}
        disabled={!saveButtonEnabled}
        onClick={
          saveButtonEnabled
            ? () => handleSubmitAdminConfig(kpiAdministration)
            : () => {}
        }
      >
        <CheckIcon />
      </Fab>
      {confirmationDialog.open && confirmationDialog.title && (
        <ConfirmationDialog
          open={confirmationDialog.open}
          title={confirmationDialog.title}
          description={confirmationDialog.description}
          onConfirm={confirmationDialog.onConfirm}
          confirmText={confirmationDialog.confirmText}
          cancelText={confirmationDialog.cancelText}
          onCancel={confirmationDialog.onCancel}
          hideCancel={confirmationDialog.hideCancel}
          confirmColor={confirmationDialog.confirmColor || "#ff3151"}
        />
      )}
      <DisplayOpenForms essencialProps={essencialProps} />
      <div className="col-xs-12">
        <h2 style={{ color: "#6b42a9", paddingBottom: "10px" }}>
          {translation("kpi_tab_administration_title")}
        </h2>
      </div>
      <div className="custom-primary-tabs-adm">
        <Tabs
          id="administrationTabs"
          activeKey={activeTab}
          onSelect={(key) => setActiveTab(key)}
          style={{ marginTop: "15px" }}
        >
          <Tab
            eventKey="planningTab"
            title={translation("kpi_tab_administration_planning")}
          >
            {activeTab === "planningTab" && (
              <PlanningTabView {...essencialProps} />
            )}
          </Tab>
          <Tab
            eventKey="indicatorsTab"
            title={translation("kpi_tab_administration_indicators")}
          >
            {activeTab === "indicatorsTab" && (
              <IndicatorsTableView {...essencialProps} />
            )}
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { companyMembersAndParticipants } =
    getCompanyMembersAndParticipants(state);
  const {
    selectedTool,
    allSceneries = [],
    allThemes = {},
    allQuestions = {},
    allAnswers = {},
  } = getSelectedToolStates(state, {
    sceneries: false,
    themes: false,
    questions: false,
    answers: false,
  });
  const { allKpiAdministration, isLoading } = getKpiStates(state);

  return {
    companyMembersAndParticipants,
    selectedTool,
    allKpiAdministration,
    isLoading,
    allSceneries,
    allThemes,
    allQuestions,
    allAnswers,
  };
};

export default connect(mapStateToProps, {
  updateKpiAdministration,
})(Administration);
