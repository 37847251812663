import _ from "lodash";
import moment from "moment";
import { Component } from "react";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { injectIntl } from "react-intl";
import { checkAccess } from "../../utils/accessLevels";
import { formatDate, SMALL_DATE_FORMAT } from "../../utils/DateUtils";
import { globalMessages } from "../../utils/global";

class InsightFollowUpTable extends Component {
  state = {
    insightFollowUpData: [],
    touched: [],
    newFollowUp: {
      description: "",
      beginDate: "",
      endDate: "",
    },
  };

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      !_.isEqual(nextProps.insightFollowUpData, this.state.insightFollowUpData)
    ) {
      this.setState({
        insightFollowUpData: nextProps.insightFollowUpData,
      });
    }
  }

  componentWillUnmount() {
    this.setState({
      insightFollowUpData: [],
    });
  }

  UNSAFE_componentWillUpdate(nextProps, nextState) {
    const { newFollowUp, touched } = this.state;
    if (!_.isEqual(newFollowUp, nextState.newFollowUp)) {
      let newTouched = [];
      Object.keys(newFollowUp).forEach((fieldName) => {
        const fieldValue = newFollowUp[fieldName];

        if (
          fieldValue &&
          fieldValue.length > 0 &&
          touched.indexOf(fieldName) === -1
        )
          newTouched.push(fieldName);
      });
      this.setState({ touched: [...touched, ...newTouched] });
    }
  }

  handleChangeFollowUp(e) {
    const { name, value } = e.target;

    this.setState({
      newFollowUp: { ...this.state.newFollowUp, [name]: value },
    });
  }

  validFormFollowUp() {
    const { newFollowUp, touched, insightFollowUpData } = this.state;
    const { intl } = this.props;

    const requiredFields = ["description", "beginDate", "endDate"];
    let errors = {
      required: [],
      validation: {},
    };

    const addError = (slug, message) => {
      if (touched.indexOf(slug) > -1) {
        errors.validation = {
          ...errors.validation,
          [slug]: message,
        };
      }
    };

    if (newFollowUp.description.length <= 3) {
      addError("description", intl.formatMessage({ id: "form_min_length" }));
    }

    requiredFields.forEach((slugName) => {
      if (
        !newFollowUp[slugName] ||
        newFollowUp[slugName].length <= 0 ||
        newFollowUp[slugName] === ""
      ) {
        errors.required.push(slugName);
        addError(slugName, intl.formatMessage({ id: "form_required_field" }));
      }
    });

    insightFollowUpData.forEach((insightFollow) => {
      if (insightFollow.status === "ORIGINAL") {
        let dateValue = new Date(insightFollow.endDate);
        let value = formatDate(dateValue, "YYYY-MM-DD");

        if (
          newFollowUp.beginDate &&
          moment(value).isAfter(newFollowUp.beginDate)
        ) {
          errors.validation = {
            ...errors.validation,
            beginDate: "Data inicial tem q ser maior ou igual a original",
          };
        } else {
          errors.validation = {
            ...errors.validation,
            beginDate: "",
          };
        }
      }
    });

    if (
      (newFollowUp.endDate.length &&
        newFollowUp.endDate < newFollowUp.beginDate) ||
      (newFollowUp.endDate.length &&
        newFollowUp.endDate === newFollowUp.beginDate)
    ) {
      errors.validation = {
        ...errors.validation,
        endDate: intl.formatMessage({ id: "form_date_valid" }),
      };
    } else {
      errors.validation = { ...errors.validation, endDate: "" };
    }

    return errors;
  }

  handleCancelUpdate() {
    this.setState({ touched: [] });
    this.props.toggleFollowUpForm(false);
  }

  render() {
    const {
      intl,
      cellEditProp,
      deleteButtonFollowUp,
      showFollowUpForm,
      onSubmitFollowUpForm,
      followUpLabelDescription,
      followUpLabelBeginDate,
      followUpLabelEndDate,
      followUpLabelReason,
      language,
    } = this.props;
    const dateFormatter = (cell) => {
      if (!cell) {
        return "";
      }
      return formatDate(cell, SMALL_DATE_FORMAT, language);
    };

    const formErrorsFollowUp = this.validFormFollowUp();

    const createInputDateFormatted = (onUpdate, props) => {
      const { defaultValue } = props;

      function transformDate(date) {
        let dateValue = new Date(date);
        let value = formatDate(dateValue, "DD/MM/YYYY");

        return value;
      }

      let dateText = transformDate(defaultValue);

      return <span>{dateText}</span>;
    };
    const createfollowUpForm = (showFollowUpForm, onSubmitFollowUpForm) => {
      if (showFollowUpForm) {
        return (
          <div>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label>{followUpLabelDescription}</label>
                  <input
                    name="description"
                    className="form-control"
                    onChange={(e) => this.handleChangeFollowUp(e)}
                  />
                  <span className="text-danger">
                    {formErrorsFollowUp.validation.description &&
                      formErrorsFollowUp.validation.description}
                  </span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label>{followUpLabelBeginDate}</label>
                  <input
                    name="beginDate"
                    className="form-control"
                    type="date"
                    onChange={(e) => this.handleChangeFollowUp(e)}
                  />
                  <span className="text-danger">
                    {formErrorsFollowUp.validation &&
                      formErrorsFollowUp.validation.beginDate}
                  </span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label>{followUpLabelEndDate}</label>
                  <input
                    name="endDate"
                    className="form-control"
                    type="date"
                    onChange={(e) => this.handleChangeFollowUp(e)}
                  />
                  <span className="text-danger">
                    {formErrorsFollowUp.validation &&
                      formErrorsFollowUp.validation.endDate}
                  </span>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <label>{followUpLabelReason}</label>
                  <input
                    name="reason"
                    className="form-control"
                    type="text"
                    onChange={(e) => this.handleChangeFollowUp(e)}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-6 col-md-6">
                <button
                  className="btn btn-danger"
                  onClick={() => this.handleCancelUpdate()}
                >
                  <i className="fa fa-times" />{" "}
                  {intl.formatMessage(globalMessages.cancel)}
                </button>
              </div>
              <div className="col-xs-6 col-md-6">
                <button
                  className={
                    formErrorsFollowUp.required &&
                    (formErrorsFollowUp.required.length > 0
                      ? "btn btn-success pull-right disabled"
                      : "btn btn-success pull-right")
                  }
                  onClick={(e) => {
                    e.preventDefault();
                    onSubmitFollowUpForm(
                      this.state.newFollowUp,
                      this.state.insightFollowUpData
                    );
                  }}
                >
                  <i className="fa fa-check" />{" "}
                  {intl.formatMessage(globalMessages.confirm)}
                </button>
              </div>
            </div>
            <br />
            <hr />
            <br />
          </div>
        );
      }
    };
    const options = {
      noDataText: intl.formatMessage(globalMessages.noDataTable),
    };

    return (
      <div>
        {createfollowUpForm(showFollowUpForm, onSubmitFollowUpForm)}
        <div className="InsightResumeTable">
          <BootstrapTable
            bordered={false}
            search={false}
            insertRow={false}
            options={options}
            data={this.state.insightFollowUpData}
            striped
            hover
            cellEdit={cellEditProp}
            tableHeaderClass="my-header-class"
          >
            <TableHeaderColumn
              hidden
              isKey
              dataField="id"
              autoValue
              dataSort={true}
              searchable={false}
            >
              {intl.formatMessage(globalMessages.id)}
            </TableHeaderColumn>
            {checkAccess(
              [
                "CREATOR",
                "RESPONSIBLE",
                "COLLABORATOR",
                "MANAGER",
                "LEADER",
                "MODERATOR",
                "ADMIN",
                "OWNER",
              ],
              <TableHeaderColumn
                dataAlign="center"
                headerAlign="center"
                editable={false}
                dataSort={false}
                dataFormat={(cell, row, enumObject, rowIndex, rowLength) =>
                  deleteButtonFollowUp(
                    cell,
                    row,
                    enumObject,
                    rowIndex,
                    this.state.insightFollowUpData.length
                  )
                }
                width="40"
              />
            )}

            <TableHeaderColumn
              dataAlign="left"
              headerAlign="left"
              dataField="description"
              dataSort={true}
            >
              {followUpLabelDescription}
            </TableHeaderColumn>
            <TableHeaderColumn
              dataAlign="left"
              headerAlign="left"
              dataField="beginDate"
              dataSort={true}
              dataFormat={dateFormatter}
              editable={{ type: "date" }}
              customEditor={{
                getElement: createInputDateFormatted,
              }}
            >
              {followUpLabelBeginDate}
            </TableHeaderColumn>
            <TableHeaderColumn
              dataField="endDate"
              headerAlign="left"
              dataAlign="left"
              searchable={true}
              dataFormat={dateFormatter}
              editable={{ type: "date" }}
              customEditor={{
                getElement: createInputDateFormatted,
              }}
            >
              {followUpLabelEndDate}
            </TableHeaderColumn>
            <TableHeaderColumn
              dataAlign="left"
              headerAlign="left"
              dataField="reason"
              dataSort={true}
            >
              {followUpLabelReason}
            </TableHeaderColumn>
          </BootstrapTable>
        </div>
      </div>
    );
  }
}
export default injectIntl(InsightFollowUpTable);
