import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import {
  createGoalsBonusInsightGoal,
  updateGoalBonusAdministration,
} from "../../../../actions/goalsAndBonusActions";
import { getObjectInfoById } from "../../../../utils/ArrayUtils";
import { getGoalInfoById } from "../../../../utils/goalsBonus/filter";
import { useSimpleToolAdministration } from "../../../../utils/goalsBonus/hooks";
import InsightGoalSelectDialog from "../../../Common/GoalsAndBonus/forms/InsightGoalSelectDialog";
import {
  getCompanyMembersAndParticipants,
  getGoalsBonusStates,
  getSelectedToolStates,
} from "../../../customMapStates";

const GoalsAndBonus = (props) => {
  const [addedGoals, setAddedGoals] = useState([]);

  const {
    type,
    clearViewCallback,
    selectedTool = {},
    isLoading = false,
    allGoalBonusAdministration = [],
    allAnswers = [],
    createGoalsBonusInsightGoal,
    params = {},
  } = props;

  const { goalBonusAdministration, setGoalBonusAdministration } =
    useSimpleToolAdministration({
      selectedTool,
      allGoalBonusAdministration,
      isLoading,
    });

  const { insightQuestion = {} } = params;
  const questionInsights = useMemo(
    () => allAnswers[insightQuestion.id] || [],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    const newAddedGoals = [];

    if (questionInsights && questionInsights.length > 0) {
      questionInsights.forEach(({ goalsBonusProgramGoalId }) => {
        if (goalsBonusProgramGoalId)
          newAddedGoals.push(goalsBonusProgramGoalId);
      });
    }

    if (!_.isEqual(newAddedGoals, addedGoals)) setAddedGoals(newAddedGoals);
  }, [addedGoals, questionInsights]);

  const clearDialogControll = () => {
    if (type) clearViewCallback(type);
  };

  const handleSaveInsight = ({ goals }) => {
    if (insightQuestion && Array.isArray(goals)) {
      goals.forEach((goalId) => {
        const goalInfo = getGoalInfoById(goalId, goalBonusAdministration.goals);
        const { goalsBonusProgramParticipantId } = insightQuestion;
        const participantInfo = getObjectInfoById(
          goalsBonusProgramParticipantId,
          goalBonusAdministration.participants,
          "id"
        );
        const memberParticipant = participantInfo.memberParticipant || {};

        createGoalsBonusInsightGoal(
          goalInfo,
          selectedTool.id,
          insightQuestion.id,
          params.order,
          memberParticipant
        );
      });
    }

    clearDialogControll();
  };

  return (
    goalBonusAdministration?.id && (
      <InsightGoalSelectDialog
        open
        afterConfirm={handleSaveInsight}
        afterCancel={clearDialogControll}
        goalBonusAdministration={goalBonusAdministration}
        setGoalBonusAdministration={setGoalBonusAdministration}
        companyMembersAndParticipants={props.companyMembersAndParticipants}
        addedGoals={addedGoals}
      />
    )
  );
};

const mapStateToProps = (state) => {
  const { companyMembersAndParticipants } =
    getCompanyMembersAndParticipants(state);
  const { selectedTool, allAnswers } = getSelectedToolStates(state);
  const { allGoalBonusAdministration, isLoading } = getGoalsBonusStates(state);
  const { user } = state;

  return {
    companyMembersAndParticipants,
    userProfile: user,
    selectedTool,
    allGoalBonusAdministration,
    allAnswers,
    isLoading,
  };
};

export default connect(mapStateToProps, {
  updateGoalBonusAdministration,
  createGoalsBonusInsightGoal,
})(GoalsAndBonus);
