import AvatarEditor from "react-avatar-editor";

const CropImage = ({ imageSrc, setEditorRef, scaleValue }) => {
  return (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <div>
        <AvatarEditor
          image={imageSrc}
          ref={setEditorRef}
          color={[240, 240, 240, 0.6]}
          border={60}
          width={212}
          height={212}
          borderRadius={100}
          scale={scaleValue}
          rotate={0}
        />
      </div>
    </div>
  );
};

export default CropImage;
