/* eslint-disable react-hooks/exhaustive-deps */
import "@fortawesome/fontawesome-free/css/all.min.css";
import MuiThemeProvider from "@material-ui/styles/ThemeProvider";
import "admin-lte/dist/css/AdminLTE.css";
import "admin-lte/dist/js/adminlte.min";
import "axios-progress-bar/dist/nprogress.css";
import "bootstrap-sass/assets/javascripts/bootstrap.min";
import "fastclick/lib/fastclick";
import "foundation-icons/foundation-icons.css";
import "jquery-slimscroll/jquery.slimscroll.min";
import _ from "lodash";
import moment from "moment";
import "moment/locale/es";
import "moment/locale/pt-br";
import { SnackbarProvider } from "notistack";
import { Suspense, useEffect, useState } from "react";
import "react-bootstrap-table/dist/react-bootstrap-table-all.min.css";
import { defineMessages } from "react-intl";
import { connect } from "react-redux";
import ReduxToaster, { toastr } from "react-redux-toastr";
import "react-redux-toastr/lib/css/react-redux-toastr.min.css";
import { Route, HashRouter as Router, Switch } from "react-router-dom";
import "typeface-montserrat/index.css";
import { grantOwnerAccess } from "../actions/accessControllerActions";
import { logout as localLogout } from "../actions/authActions";
import { sendPrivacyPolicyAcceptance } from "../actions/userActions";
import themeProvider from "../assets/jss/themeProvider";
import "../scss/app-index.scss";
import { checkAccess } from "../utils/accessLevels";

import { getExpiredContract } from "../actions/domain";
import { getRenderComponent } from "../utils/app/display";
import AppController from "./AppController";
import SnackMessage from "./Common/SnackMessage";
import ErrorBoundaries from "./ErrorBoundaries";
import PrivacyPolicy from "./Home/PrivacyPolicy";
import Footer from "./Layouts/Footer";
import Header from "./Layouts/Header/Header";
import LoadingDialog from "./Layouts/LoadingDialog";
import MainSideBar from "./Layouts/MainSideBar";
import NoInternetConnection from "./Layouts/NoInternetConnection";
import Notifier from "./Notifier";
import AppLoader from "./Placeholders/App";
import StructureMiddleware from "./StructureMiddleware";
import { appRoutes } from "./routes";

const packageJson = require("../../package.json");

const loggedUserDefault = {
  loggedUserId: null,
  loggedCompanyId: null,
};

const App = (props) => {
  const [action, setAction] = useState({});
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [authController, setAuthController] = useState(loggedUserDefault);
  const [loadedHelpDeskWidget, setLoadedHelpDeskWidget] = useState(false);
  const [currentDomainUser, setCurrentDomainUser] = useState({});
  const [isExpired, setIsExpired] = useState(false);
  const [domainUserInactive, setDomainUserInactive] = useState(false);

  const {
    intl,
    user = {},
    access = {},
    isLoading = false,
    authEssentials = {},
    selectedCompany = {},
    sendPrivacyPolicyAcceptance,
    hideDomainNavigation,
    loading: loadingState,
  } = props;

  const { id: userId = false } = user || {};
  const { id: selectedCompanyId = false } = selectedCompany || {};
  const { isAuthenticated = false, logout = () => null } = authEssentials || {};
  const { currentDomain = {} } = access;

  const messages = defineMessages({
    inativeUser: {
      id: "domain.inactiveUser",
    },
  });

  useEffect(() => {
    if (domainUserInactive) {
      toastr.error(intl.formatMessage(messages.inativeUser), {
        timeOut: 10000,
      });
    }
  }, [domainUserInactive]);

  useEffect(() => {
    const { id: userId = false } = user || {};
    const { id: domainId = false } = currentDomain || {};
    const { domainUsers = [] } = access || {};

    if (!domainId || domainUsers.length === 0 || !userId) return;

    const domainUser = domainUsers.filter(({ id }) => id === userId)[0] || {};

    if (domainUser.active === false) {
      setDomainUserInactive(true);
    }

    const { domainUserId = 0 } = currentDomainUser || {};
    const { domainUserId: currentDomainUserId = 0 } = domainUser || {};

    if (currentDomainUserId !== domainUserId) setCurrentDomainUser(domainUser);
  }, [currentDomain, user, access]);

  useEffect(() => {
    if (currentDomain?.id) {
      getExpiredContract(currentDomain?.id).then((data) => {
        setIsExpired(data.isExpired);
      });
    }
  }, []);

  useEffect(() => {
    if (!loadedHelpDeskWidget && user && user.email) {
      const jiraHelpdesk = (callback) => {
        const jhdScript = document.createElement("script");
        jhdScript.type = "text/javascript";
        jhdScript.setAttribute("data-email", user.email);
        jhdScript.setAttribute(
          "data-key",
          "9f037242-dedd-454c-9d3f-21a87ec4375a"
        );
        jhdScript.setAttribute(
          "data-base-url",
          "https://jsd-widget.atlassian.com"
        );

        jhdScript.setAttribute("data-jsd-embedded", null);

        jhdScript.src = "https://jsd-widget.atlassian.com/assets/embed.js";
        if (jhdScript.readyState) {
          jhdScript.onreadystatechange = function () {
            if (
              jhdScript.readyState === "loaded" ||
              jhdScript.readyState === "complete"
            ) {
              jhdScript.onreadystatechange = null;
              callback();
            }
          };
        } else {
          jhdScript.onload = function () {
            callback();
          };
        }
        document.body.appendChild(jhdScript);
      };

      jiraHelpdesk(() => {
        const DOMContentLoaded_event = document.createEvent("Event");
        DOMContentLoaded_event.initEvent("DOMContentLoaded", true, true);
        window.document.dispatchEvent(DOMContentLoaded_event);
      });

      setLoadedHelpDeskWidget(true);
    }
  }, [user, loadedHelpDeskWidget]);

  //Verifica se o usuário pode acessar uma selectedCompany
  const companyAccessConditions = () => {
    const { buildedAccess = false } = access || {};
    const { loggedUserId, loggedCompanyId } = authController;

    if (
      !selectedCompanyId ||
      buildedAccess !== true ||
      !userId ||
      isAuthenticated === false ||
      (_.isEqual(loggedUserId, userId) &&
        _.isEqual(loggedCompanyId, selectedCompanyId))
    )
      return false;

    return true;
  };

  const canUserAccessCompany = () => {
    if (checkAccess(["OWNER"])) return true;

    const { userAccess = {} } = access || {};
    const { companies = [] } = userAccess || {};

    return (
      companies.filter(({ id = 0 }) => {
        return id === selectedCompanyId;
      }).length > 0
    );
  };

  useEffect(() => {
    if (!companyAccessConditions()) return;

    const companyAccessOpen = canUserAccessCompany();

    if (companyAccessOpen) {
      setAuthController({
        loggedUserId: userId,
        loggedCompanyId: selectedCompanyId,
      });
    } else logout();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCompanyId, user]);

  useEffect(() => {
    const { id = false, acceptedPrivacyPolicy = false } = user;

    if (id && !acceptedPrivacyPolicy) setShowPrivacyPolicy(true);
  }, [user]);

  const toggleCollapsed = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handlePolicyAcceptance = (accepted = true) => {
    const { id: userId } = user;

    sendPrivacyPolicyAcceptance(userId, {
      accepted,
      date: moment().format("YYYY-MM-DD"),
      version: "1.0.0",
    });

    setShowPrivacyPolicy(false);
  };

  const handleCancelPrivacyPolicy = () => {
    setShowPrivacyPolicy(false);
  };

  const privacyPolicyProps = {
    showPrivacyPolicy,
    handlePolicyAcceptance,
    handleCancelPrivacyPolicy,
  };

  let locale = intl && intl.locale ? intl.locale : "";
  locale = locale === "pt" ? "pt-br" : locale;

  moment.locale(locale);

  const essencialProps = {
    appAction: ({ type, payload }) =>
      setAction({
        type,
        payload,
      }),
    toggleCollapsed,
    isCollapsed,
  };

  const date = new Date();

  return (
    <MuiThemeProvider theme={themeProvider}>
      {loadingState && loadingState.loading && (
        <LoadingDialog
          open={loadingState.loading}
          message={loadingState.message}
        />
      )}
      <SnackbarProvider
        maxSnack={8}
        content={(key, notification) => (
          <SnackMessage id={key} notification={notification} />
        )}
      >
        <Suspense fallback={<AppLoader />}>
          {showPrivacyPolicy && <PrivacyPolicy {...privacyPolicyProps} />}
          <NoInternetConnection />
          <Notifier />
          <StructureMiddleware />
          <Router>
            {!hideDomainNavigation && (
              <Header logoLg="ROOKAU" toggleCollapsed={toggleCollapsed} />
            )}
            {isAuthenticated && !hideDomainNavigation && (
              <MainSideBar {...essencialProps} />
            )}
            {!isLoading && (
              <>
                <AppController
                  {...props}
                  isAuthenticated={isAuthenticated}
                  action={action}
                />

                <Switch>
                  {appRoutes().map((routeInfo) => {
                    const RenderComponent = getRenderComponent({
                      access,
                      isAuthenticated,
                      selectedCompany,
                      routeInfo,
                      isExpired,
                      user,
                      currentDomainUser,
                    });

                    return (
                      <Route
                        path={routeInfo.path}
                        exact={routeInfo.exact || false}
                        render={(props) => (
                          <ErrorBoundaries {...props}>
                            <RenderComponent
                              {...props}
                              {...essencialProps}
                              isAuthenticated={isAuthenticated}
                            />
                          </ErrorBoundaries>
                        )}
                        key={routeInfo}
                      />
                    );
                  })}
                </Switch>
                <ReduxToaster />
              </>
            )}
            <Footer
              copyrightYear={`2017-${date.getFullYear()}`}
              company="Rookau® | Strategyplace"
              site="http://www.rookau.com/"
              version={packageJson.version || ""}
              isAuthenticated={isAuthenticated && !hideDomainNavigation}
            />
          </Router>
        </Suspense>
      </SnackbarProvider>
    </MuiThemeProvider>
  );
};

const mapStateToProps = (state) => ({
  user: state.user,
  access: state.access,
  version: state.version,
  authEssentials: state.auth,
  selectedCompany: state.companyData?.selectedCompany,
  hideDomainNavigation: state.auth?.hideDomainNavigation,
  loading: state.loading,
});

export default connect(mapStateToProps, {
  localLogout,
  grantOwnerAccess,
  sendPrivacyPolicyAcceptance,
})(App);
