import { createStyles, makeStyles } from "@material-ui/core/styles";
import { useCallback, useEffect, useState } from "react";
import { defineMessages } from "react-intl";
import { connect } from "react-redux";

import { Fab } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import _ from "lodash";
import { Tab, Tabs } from "react-bootstrap";

import cpAnalysisUtils from "../../../../../utils/cpAnalysis";
import utils from "../../../../../utils/toolUtils";
import { translatedText } from "../../../../../utils/translationUtils";
import ConfirmationDialog from "../../../../Common/ConfirmationDialog";
import DeleteColumn from "../../../../Common/Tables/DeleteColumn";
import EditColumn from "../../../../Common/Tables/EditColumn";
import DisplayOpenForms from "./fields/DisplayOpenForms";

import {
  deleteAnalysisAmbient,
  deleteAnalysisArea,
  deleteAnalysisGroup,
  deleteEvaluationCriteria,
  updateCompAnalysisAdministration,
} from "../../../../../actions/compAnalysisActions";
import {
  getCompAnalysisStates,
  getSelectedCompanyStates,
  getSelectedToolStates,
} from "../../../../customMapStates";

import ConcurrenceTabView from "./tabs/ConcurrenceTabView";
import PestalTabView from "./tabs/PestalTabView";
import PrioritizationTabView from "./tabs/PrioritizationTabView";
import SwotTabView from "./tabs/SwotTabView";

const { getUpdatedDeleteArrayByIndex, getDefaultAddTableLine } = utils;
const { buildFormAdmConfigBody, buildRestAdmConfigBody } = cpAnalysisUtils;

const messages = defineMessages({
  administration_title: { id: "global.administration" },
});

const translation = (id, values = {}) => translatedText(id, messages, values);

const useStyles = makeStyles(() =>
  createStyles({
    addButton: {
      fontSize: "12px",
    },
    saveButton: {
      position: "fixed",
      bottom: "60px",
      right: "40px",
      color: "#fff",
      backgroundColor: "#1abd3b",
      zIndex: "1000",
      "&:hover": {
        backgroundColor: "#098341",
      },
      "& span svg": {
        fontSize: "24px",
      },
    },
  })
);

const defaultFormInitialCompAnalysis = buildFormAdmConfigBody({});
const defaultConfirmDialog = {
  title: "",
  onConfirm: null,
  onCancel: null,
  open: false,
  params: {},
};

const getDefaultEditColumn = (handleEdit) => {
  return <EditColumn handleEdit={handleEdit} />;
};

const getDefaultRemoveColumn = (handleDelete) => {
  return <DeleteColumn handleDelete={handleDelete} />;
};

const getEmptyTableRow = (array, message = null) => {
  if (array || array.length > 0) return null;

  return (
    <tr>
      <td colSpan="100">{message}</td>
    </tr>
  );
};

const Administration = (props) => {
  const [cpAnalysisAdministration, setCpAnalysisAdministration] = useState(
    defaultFormInitialCompAnalysis
  );
  const [activeTab, setActiveTab] = useState("pestalTab");
  const [openFormDialogs, setOpenFormDialogs] = useState([]);
  const [confirmationDialog, setConfirmationDialog] = useState({
    ...defaultConfirmDialog,
  });
  const [enableSaveButton, setEnableSaveButton] = useState(false);

  const {
    selectedTool = {},
    updateCompAnalysisAdministration,
    currentAdministration,
    allThemes,
    allAnswers,
    allQuestions,
    deleteAnalysisAmbient,
    deleteEvaluationCriteria,
    deleteAnalysisGroup,
    deleteAnalysisArea,
    currentToolType,
  } = props;

  useEffect(() => {
    const formConfig = buildFormAdmConfigBody(currentAdministration);

    if (!_.isEqual(formConfig, cpAnalysisAdministration))
      setCpAnalysisAdministration(formConfig);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAdministration, selectedTool.id]);

  const resetConfirmDialog = () => {
    setConfirmationDialog({ ...defaultConfirmDialog });
  };

  const toggleOpenFormDialogs = (slug, toggleTo = null) => {
    if (!slug) return;

    const slugIndex = openFormDialogs.indexOf(slug);
    let updatedOpenFormDialogs = [...openFormDialogs];

    const closeOpenForm = (index) => {
      return getUpdatedDeleteArrayByIndex(openFormDialogs, index);
    };

    if (toggleTo !== null) {
      if (toggleTo === false) {
        if (slugIndex > -1) {
          updatedOpenFormDialogs = closeOpenForm(slugIndex);
        }
      } else {
        updatedOpenFormDialogs.push(slug);
      }
    } else if (slugIndex > -1) {
      updatedOpenFormDialogs = closeOpenForm(slugIndex);
    } else {
      updatedOpenFormDialogs.push(slug);
    }

    setOpenFormDialogs(updatedOpenFormDialogs);
  };

  const closeFormAndCallback = (slug, callback) => {
    toggleOpenFormDialogs(slug, false);

    if (typeof callback === "function") callback();
  };

  const handleAutoSave = (newCpAnalysisConfig) => {
    if (!newCpAnalysisConfig) return;

    handleSubmitAdminConfig(newCpAnalysisConfig);
  };

  const setCpAnalysisAdministrationWithSave = (administration = {}) => {
    setCpAnalysisAdministration(administration);
    !enableSaveButton && setEnableSaveButton(true);
  };

  const resetSaveButton = () => setEnableSaveButton(false);

  const handleSubmitAdminConfig = useCallback(
    (newCompAnalysisConfig) => {
      if (!selectedTool || !selectedTool.id) return;

      const { id: selectedToolId = "" } = selectedTool;

      const cpAnalysisConfig = _.cloneDeep(newCompAnalysisConfig);

      const restForm = buildRestAdmConfigBody({
        ...cpAnalysisConfig,
        selectedToolId,
      });

      updateCompAnalysisAdministration(restForm, selectedToolId);
      resetSaveButton();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [cpAnalysisAdministration, selectedTool, updateCompAnalysisAdministration]
  );

  const classes = useStyles();

  const essentialProps = {
    cpAnalysisAdministration,
    setCpAnalysisAdministration: setCpAnalysisAdministrationWithSave,
    openFormDialogs,
    setOpenFormDialogs,
    setConfirmationDialog,
    resetConfirmDialog,
    getEmptyTableRow,
    getDefaultAddTableLine,
    getDefaultRemoveColumn,
    getDefaultEditColumn,
    toggleOpenFormDialogs,
    closeFormAndCallback,
    handleAutoSave,
    allThemes,
    allQuestions,
    deleteAnalysisAmbient,
    deleteEvaluationCriteria,
    deleteAnalysisGroup,
    deleteAnalysisArea,
    currentToolType,
    allAnswers,
    selectedTool,
  };

  const tabHandler = (tabValue = "", component) => {
    if (activeTab === tabValue) return component;

    return null;
  };

  const defaultFunction = () => {};

  return (
    <div className="row">
      <Fab
        className={classes.saveButton}
        disabled={!enableSaveButton}
        onClick={
          enableSaveButton
            ? () => handleSubmitAdminConfig(cpAnalysisAdministration)
            : () => {}
        }
      >
        <CheckIcon />
      </Fab>
      <ConfirmationDialog
        open={confirmationDialog.open}
        title={confirmationDialog.title}
        description={confirmationDialog.description}
        onConfirm={confirmationDialog.onConfirm || defaultFunction}
        confirmText={confirmationDialog.confirmText}
        onCancel={confirmationDialog.onCancel}
        hideCancel={confirmationDialog.hideCancel}
        confirmColor="#ff3151"
      />
      <DisplayOpenForms {...essentialProps} />
      <div className="col-xs-12">
        <h2 style={{ color: "#6b42a9", paddingBottom: "10px" }}>
          {translation("administration_title")}
        </h2>
      </div>
      <div className="custom-primary-tabs">
        <Tabs
          id="administrationTabs"
          activeKey={activeTab}
          onSelect={(key) => setActiveTab(key)}
          style={{ marginTop: "15px" }}
        >
          <Tab eventKey="pestalTab" title="Pestal">
            {tabHandler("pestalTab", <PestalTabView {...essentialProps} />)}
          </Tab>
          <Tab eventKey="swotTab" title="Swot">
            {tabHandler("swotTab", <SwotTabView {...essentialProps} />)}
          </Tab>
          <Tab eventKey="prioritizationMatrixTab" title="Matriz de Priorização">
            {tabHandler(
              "prioritizationMatrixTab",
              <PrioritizationTabView {...essentialProps} />
            )}
          </Tab>
          <Tab eventKey="concurrenceTab" title="Concorrentes">
            {tabHandler(
              "concurrenceTab",
              <ConcurrenceTabView {...essentialProps} />
            )}
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { selectedCompany = {} } = getSelectedCompanyStates(state);

  const {
    selectedTool,
    allSceneries = [],
    allThemes = {},
    allQuestions = {},
    allAnswers = {},
  } = getSelectedToolStates(state, {
    sceneries: false,
    themes: false,
    questions: false,
    answers: false,
  });

  const { currentAdministration = {}, currentToolType } =
    getCompAnalysisStates(state);

  return {
    selectedCompany,
    selectedTool,
    currentAdministration,
    allSceneries,
    allThemes,
    allQuestions,
    currentToolType,
    allAnswers,
  };
};

export default connect(mapStateToProps, {
  updateCompAnalysisAdministration,
  deleteAnalysisAmbient,
  deleteEvaluationCriteria,
  deleteAnalysisGroup,
  deleteAnalysisArea,
})(Administration);
