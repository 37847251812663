import _ from "lodash";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  addQuestionAndCreateLink,
  createAreaQuestionProcess,
} from "../../../../actions/compAnalysisActions";
import cpAnalysisUtils from "../../../../utils/cpAnalysis";
import utils from "../../../../utils/toolUtils";
import QuestionCreationAndLinkDialog from "../../../Common/CpAnalysis/forms/QuestionCreationAndLinkDialog";
import {
  getCompAnalysisStates,
  getSelectedToolStates,
} from "../../../customMapStates";

const { getOnlySublevelArrays } = utils;

const { buildFormAdmConfigBody, buildRestAdmConfigBody } = cpAnalysisUtils;

const defaultCpConfig = buildFormAdmConfigBody({});

const CompetitiveAnalysis = (props) => {
  const [cpAnalysisAdministration, setCpAnalysisAdministration] =
    useState(defaultCpConfig);
  const [addedAreas, setAddedAreas] = useState([]);

  const {
    type,
    clearViewCallback,
    selectedTool = {},
    params,
    allQuestions,
    currentToolType,
    currentAdministration,
    createAreaQuestionProcess,
    addQuestionAndCreateLink,
  } = props;

  const { questionTheme = {} } = params;

  let onlyQuestions = getOnlySublevelArrays(allQuestions);

  useEffect(() => {
    if (!_.isEqual(currentAdministration, cpAnalysisAdministration)) {
      setCpAnalysisAdministration(currentAdministration);
    }
  }, [currentAdministration, cpAnalysisAdministration, selectedTool.id]);

  useEffect(() => {
    let newAdded = [];

    onlyQuestions.forEach(({ competitiveAnalysisAreaId }) => {
      if (competitiveAnalysisAreaId) newAdded.push(competitiveAnalysisAreaId);
    });

    if (!_.isEqual(newAdded, addedAreas)) setAddedAreas(newAdded);
  }, [addedAreas, onlyQuestions]);

  const getAnalysisAreaInfo = (areaId) => {
    const { analysisAreas = [] } =
      cpAnalysisAdministration[currentToolType] || {};

    return (
      analysisAreas.filter((area) => {
        if (area.id === areaId) {
          return true;
        } else {
          return false;
        }
      })[0] || {}
    );
  };

  const handleLinkTheme = ({ analysisAreas = [] }) => {
    if (selectedTool.id && Array.isArray(analysisAreas)) {
      analysisAreas.forEach((areaId) => {
        const areaInfo = getAnalysisAreaInfo(areaId);

        createAreaQuestionProcess(areaInfo, questionTheme, params.order);
      });
    }

    clearDialogControll();
  };

  const handleCreateAndLinkTheme = (newCpAdministration) => {
    if (!_.isEqual(currentAdministration, newCpAdministration)) {
      const restBody = buildRestAdmConfigBody(newCpAdministration);

      addQuestionAndCreateLink(
        selectedTool,
        questionTheme,
        restBody,
        currentToolType,
        params.order
      );
    }

    clearDialogControll();
  };

  const clearDialogControll = () => {
    if (type) clearViewCallback(type);
  };

  return (
    <QuestionCreationAndLinkDialog
      open={true}
      afterConfirm={handleLinkTheme}
      afterCancel={clearDialogControll}
      currentToolType={currentToolType}
      cpAnalysisAdministration={cpAnalysisAdministration}
      setCpAnalysisAdministration={setCpAnalysisAdministration}
      alternativeSaveCallback={handleCreateAndLinkTheme}
      addedAreas={addedAreas}
    />
  );
};

const mapStateToProps = (state) => {
  const { selectedTool, allQuestions } = getSelectedToolStates(state);
  const { currentAdministration = {}, currentToolType = {} } =
    getCompAnalysisStates(state);

  const { user } = state;

  return {
    userProfile: user,
    selectedTool,
    currentToolType,
    currentAdministration,
    allQuestions,
  };
};

export default connect(mapStateToProps, {
  addQuestionAndCreateLink,
  createAreaQuestionProcess,
})(CompetitiveAnalysis);
