import { useEffect } from "react";
import { connect } from "react-redux";
import {
  getDomainLogo,
  getProviderInfo,
  setAuthInfo,
} from "../../actions/authActions";
import AuthRookau from "./Rookau/AuthRookau";

const AuthSelector = (props) => {
  useEffect(() => {
    getProviderInfo().then((info) => {
      props.setAuthInfo({ isAuthWithCpfAllowed: info.isAuthWithCpfAllowed });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    props.getDomainLogo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <AuthRookau />;
};

export default connect(null, { getDomainLogo, setAuthInfo })(AuthSelector);
