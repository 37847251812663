import React, { useState, useEffect } from "react";
import { injectIntl, defineMessages } from "react-intl";
import { connect } from "react-redux";

import PropTypes from "prop-types";
import _ from "lodash";
import { List, ListItem, ListItemText, Avatar } from "@material-ui/core";
import FormDialog from "./FormDialog";
import MaterialTextField from "./MaterialTextField";
import SimpleCheckbox from "./SimpleCheckbox";
import SimpleLegend from "./SimpleLegend";
import utils from "../../utils/toolUtils";
import { globalMessages } from "../../utils/global";

const { getComparableQuery, getObjectInfoById } = utils;

const messages = defineMessages({
  gb_options_rt_shared: { id: "gb_options_rt_shared" },
  gb_options_rt_individual: { id: "gb_options_rt_individual" },
  gb_options_rt_corporate: { id: "gb_options_rt_corporate" },
  global_search: { id: "global.search" },
  global_noData: { id: "global.noData" },
  global_selectedOnes: { id: "global.selectedOnes" },
});

const SelectGoalModal = (props) => {
  const [displayGoals, setDisplayGoals] = useState([]);
  const [selectedGoals, setSelectedGoals] = useState([]);
  const [filter, setFilter] = useState({
    filterValue: null,
    string: "",
  });
  const [initialPreset, setInitialPreset] = useState(false);

  const {
    open,
    title,
    description,
    goalsList,
    toggleOpen,
    onConfirm,
    onCancel,
    singleSelect,
    initialSelected,
    replaceSelected,
    replaceCallback,
    blockList,
    filterOptions,
    intl,
    kpi,
  } = props;

  const confirmText =
    !props.confirmText || props.confirmText === "defaultSelect"
      ? intl.formatMessage(globalMessages.select)
      : props.confirmText;
  const cancelText =
    !props.cancelText || props.cancelText === "defaultCancel"
      ? intl.formatMessage(globalMessages.cancel)
      : props.cancelText;

  useEffect(() => {
    if (!open) {
      setSelectedGoals([]);
    } else {
      setInitialPreset(false);
    }
  }, [open]);

  useEffect(() => {
    if (!_.isEqual(goalsList, displayGoals)) setDisplayGoals(goalsList);
  }, [goalsList, displayGoals]);

  useEffect(() => {
    if (
      initialSelected &&
      initialSelected.length > 0 &&
      (!initialPreset || replaceSelected)
    ) {
      setSelectedGoals(initialSelected);
      setInitialPreset(true);

      if (typeof replaceCallback === "function") replaceCallback();
    }
  }, [initialPreset, initialSelected, replaceSelected, replaceCallback]);

  const handleTypeSearch = (e) => {
    setFilter({ ...filter, string: e.target.value });
  };

  const resetFilterValue = () => {
    setFilter({ ...filter, filterValue: null });
  };

  const toggleFilterValue = ({ name, checked }) => {
    if (checked) {
      setFilter({ ...filter, filterValue: name });
    } else {
      resetFilterValue();
    }
  };

  const getFilteredByFilterValue = (items) => {
    let filtered = [];

    const defaultStatus = filter.filterValue ? false : true;
    items.forEach((goalInfo) => {
      goalInfo.show =
        goalInfo.filterValue === filter.filterValue ? true : defaultStatus;

      filtered = [...filtered, goalInfo];
    });

    return filtered;
  };

  const getFilteredBySearch = (items) => {
    let filtered = [];

    const compareString = getComparableQuery(filter.string || "");
    const match = (text) => getComparableQuery(text || "").match(compareString);

    items.forEach((goalInfo) => {
      const titleFound = match(goalInfo.title) ? true : false;
      const descriptionFound = match(goalInfo.description) ? true : false;

      if (goalInfo.show && !titleFound && !descriptionFound)
        goalInfo.show = false;

      filtered = [...filtered, goalInfo];
    });

    return filtered;
  };

  const getFilteredDisplay = () => {
    const filteredByType = getFilteredByFilterValue(displayGoals);
    const finalFiltered = getFilteredBySearch(filteredByType);

    return finalFiltered;
  };

  const handleToggleSelect = (goalID) => {
    const selectedListClone = [...selectedGoals];
    let finalList = selectedGoals;

    if (isSelected(goalID)) {
      selectedListClone.splice(selectedListClone.indexOf(goalID), 1);
      finalList = singleSelect ? [] : selectedListClone;
    } else {
      finalList = singleSelect ? [goalID] : [...selectedGoals, goalID];
    }

    setSelectedGoals(finalList);
  };

  const getTotalDisplayed = (list) => {
    let total = 0;

    if (list && list.length > 0) {
      list.forEach((goalInfo) => (total = goalInfo.show ? total + 1 : total));
    }

    return total;
  };

  const getSelectedGoalsList = (goals) => {
    const selected = [];

    if (goals && selectedGoals.length > 0) {
      goals.forEach((goalInfo) => {
        if (selectedGoals.indexOf(goalInfo.id) > -1) {
          selected.push(goalInfo);
        }
      });
    }

    return selected;
  };

  useEffect(() => {
    if (kpi.selectedGoal?.payload?.id) {
      setSelectedGoals((prevState) => [
        ...prevState,
        kpi.selectedGoal.payload.id,
      ]);
      onConfirm([kpi.selectedGoal.payload.id], getSelectedGoalsList(goalsList));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kpi]);

  const isSelected = (goalID) => {
    return selectedGoals.indexOf(goalID) > -1 ? true : false;
  };

  const displayTopFilters = (show = true) => {
    return show && filterOptions.length > 0 ? (
      <div className="row" style={{ borderBottom: "1px solid #ccc" }}>
        <div className="col-xs-12">
          <MaterialTextField
            id="goalSelect"
            label={intl.formatMessage(messages.global_search)}
            value={filter.string}
            onChange={(e) => handleTypeSearch(e)}
          />
          <div className="row">
            {filterOptions.map((filterInfo, index) => {
              return (
                <div className="col-xs-4" key={index}>
                  <SimpleCheckbox
                    label={filterInfo.label}
                    name={filterInfo.value}
                    checked={
                      filter.filterValue === filterInfo.value ? true : false
                    }
                    onChange={(e) =>
                      toggleFilterValue({ name: e.name, checked: !e.checked })
                    }
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    ) : null;
  };

  const displayItems = (list) => {
    const reorderedList = [...list].sort((a, b) =>
      a.title > b.title ? 1 : -1
    );

    const oveflowTextStyles = {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      maxWidth: "480px",
    };

    return getTotalDisplayed(reorderedList) > 0 ? (
      <List>
        {reorderedList.map((goalInfo, index) => {
          const avatarInfo = getObjectInfoById(
            goalInfo.filterValue,
            filterOptions,
            "value"
          );

          const borderColor = isSelected(goalInfo.id)
            ? avatarInfo.color
            : "transparent";

          const displayStyle = goalInfo.show
            ? {
                opacity: "1",
                height: "62px",
                padding: "11px 16px",
              }
            : {
                opacity: "0",
                height: "0px",
                padding: "0px 16px",
                pointerEvents: "none",
              };

          const blockStyles =
            blockList.indexOf(goalInfo.id) > -1
              ? { opacity: "0.4", pointerEvents: "none" }
              : {};

          return (
            <ListItem
              key={index}
              className="list-item-selection"
              style={{
                borderColor,
                ...displayStyle,
                ...blockStyles,
              }}
              onClick={() =>
                goalInfo.show ? handleToggleSelect(goalInfo.id) : null
              }
            >
              <Avatar
                style={{
                  backgroundColor: avatarInfo.color,
                  marginRight: "10px",
                }}
                title={avatarInfo.label}
              >
                {avatarInfo.icon}
              </Avatar>
              <ListItemText
                primary={
                  <div title={goalInfo.title} style={oveflowTextStyles}>
                    {goalInfo.title}
                  </div>
                }
                secondary={goalInfo.secondaryText}
                primaryTypographyProps={{
                  style: {
                    fontSize: "16px",
                  },
                }}
                secondaryTypographyProps={{
                  style: {
                    fontSize: "14px",
                  },
                }}
              />
            </ListItem>
          );
        })}
      </List>
    ) : (
      <h5 align="center" style={{ color: "#666" }}>
        {intl.formatMessage(messages.global_noData)}
      </h5>
    );
  };

  const filteredList = getFilteredDisplay();
  return (
    <FormDialog
      open={open}
      title={title}
      description={description}
      toggleOpen={toggleOpen}
      onConfirm={() =>
        onConfirm(selectedGoals, getSelectedGoalsList(goalsList))
      }
      onCancel={onCancel || toggleOpen}
      confirmText={confirmText}
      cancelText={cancelText}
      blockConfirm={selectedGoals.length === 0}
      bodyStyle={{
        padding: "0px",
      }}
    >
      <div className="row">
        {displayTopFilters()}
        <div className="row" style={{ maxHeight: "335px", overflow: "auto" }}>
          {displayItems(filteredList)}
        </div>
        <div className="row" style={{ clear: "both" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingTop: "15px",
              paddingBottom: "15px",
              paddingLeft: "25px",
              borderTop: "1px solid #ccc",
            }}
            className="col-xs-12"
          >
            <div className="col" align="center">
              {filterOptions.map(({ label, color }, index) => (
                <SimpleLegend
                  label={label}
                  fillColor={color}
                  size={10}
                  key={index}
                />
              ))}
              {selectedGoals.length > 0 && !singleSelect && (
                <h5
                  align="center"
                  style={{
                    color: "#666",
                    margin: "5px 0 0 0",
                  }}
                >
                  {selectedGoals.length}{" "}
                  {intl.formatMessage(messages.global_selectedOnes)}
                </h5>
              )}
            </div>
          </div>
        </div>
      </div>
    </FormDialog>
  );
};

SelectGoalModal.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.node.isRequired,
  description: PropTypes.node,
  goalsList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
      description: PropTypes.string,
      secondaryText: PropTypes.string,
      filterValue: PropTypes.any,
    })
  ),
  filterOptions: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.string,
      color: PropTypes.string,
    })
  ),
  toggleOpen: PropTypes.func,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  singleSelect: PropTypes.bool,
  initialSelected: PropTypes.arrayOf(PropTypes.number),
  replaceSelected: PropTypes.bool,
  replaceCallback: PropTypes.func,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  blockList: PropTypes.array,
};

SelectGoalModal.defaultProps = {
  open: false,
  singleSelect: false,
  replaceSelected: false,
  goalsList: [],
  initialSelected: [],
  filterOptions: [],
  blockList: [],
  confirmText: "defaultSelect",
  cancelText: "defaultCancel",
};

const mapStateToProps = (state) => {
  const { kpi } = state;

  return {
    kpi,
  };
};
export default connect(mapStateToProps)(injectIntl(SelectGoalModal));
