import PropTypes from "prop-types";
import { useState } from "react";
import { getStyledErrorMessage } from "../../../../../../../utils/formValidation";
import { getComplementaryScalesEntity } from "../../../../../../../utils/skillsAssessment/entity";
import {
  getComplementaryScalesByType,
  getScaleRelativeValues,
} from "../../../../../../../utils/skillsAssessment/filter";
import { addNewComplementaryScale } from "../../../../../../../utils/skillsAssessment/manipulation";
import {
  validateEvaluationScale,
  validateNewGenericScale,
} from "../../../../../../../utils/skillsAssessment/validation";
import { Errors } from "../../../../../../../utils/validations";
import FormDialog from "../../../../../../Common/FormDialog";
import MaterialTextField from "../../../../../../Common/MaterialTextField";

const defaultForm = getComplementaryScalesEntity([{}], {
  canInsertNewId: true,
})[0];

const NewComplementaryScale = ({
  open,
  afterConfirm,
  afterCancel,
  toolAdministration,
  params,
}) => {
  const { complementaryScales = [] } = toolAdministration;
  const { type: filterType } = params;

  const filteredScale = getComplementaryScalesByType(
    complementaryScales,
    filterType
  );
  const { nextValue } = getScaleRelativeValues(filteredScale);

  const [form, setForm] = useState({
    ...defaultForm,
    value: nextValue,
  });

  const handleConfirm = () => {
    if (params?.type) {
      const newSaveScale = {
        ...form,
        type: params.type,
      };

      const newAdministration = addNewComplementaryScale(
        toolAdministration,
        newSaveScale
      );

      afterConfirm(newAdministration);
    }
  };

  const handleCancel = () => {
    afterCancel();
  };

  const validateForm = () => {
    const errors = new Errors();

    errors.addMultiple(validateNewGenericScale(form, filteredScale));
    errors.addMultiple(validateEvaluationScale([form]));

    return { isValid: !errors.hasErrors(), errors: errors.getErrors() };
  };

  const updateField = (slug, newValue) => {
    setForm({ ...form, [slug]: newValue });
  };

  const formTitle = "Adicionar Escala Complementar";
  const { isValid, errors } = validateForm();

  return (
    <FormDialog
      open={open}
      title={formTitle}
      onConfirm={handleConfirm}
      onCancel={handleCancel}
      blockConfirm={!isValid}
    >
      <div className="row">
        <div className="col-xs-3">
          <MaterialTextField
            id="scaleValue"
            type="number"
            label="Valor"
            value={form.value}
            onChange={(e) => updateField("value", e.target.value)}
          />
        </div>
        <div className="col-xs-9">
          <MaterialTextField
            id="scaleTitle"
            label="Título"
            value={form.title}
            onChange={(e) => updateField("title", e.target.value)}
          />
        </div>
        <div className="col-xs-12">
          {getStyledErrorMessage(
            ["duplicated_value", "duplicated_title"],
            errors
          )}
        </div>
      </div>
    </FormDialog>
  );
};

NewComplementaryScale.propTypes = {
  open: PropTypes.bool,
  afterConfirm: PropTypes.func.isRequired,
  afterCancel: PropTypes.func.isRequired,
  toolAdministration: PropTypes.object.isRequired,
  params: PropTypes.any.isRequired,
};

NewComplementaryScale.defaultProps = {
  open: false,
};

export default NewComplementaryScale;
