import PropTypes from "prop-types";
import { useCallback, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";

import CommentEditor from "../CommentEditor";

import {
  deleteComment,
  fetchInsightComments,
} from "../../../actions/insightTimelineActions";
import {
  CancelButton,
  DisplayButtons,
  DisplayTextView,
  SaveButton,
  TextView,
  WrapperEditable,
} from "../EditableTextArea/styles";
import FormDialog from "../FormDialog";

const useOutsideToCloseEdit = (ref, next) => {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        next();
      }
    }

    document.addEventListener("mouseup", handleClickOutside);

    return () => {
      document.removeEventListener("mouseup", handleClickOutside);
    };
  }, [ref, next]);
};

const EditableComment = ({
  id: commentId,
  insightId,
  selfComment,
  text,
  onSave,
  isEditable,
  maxLength,
  onCancel,
  actionButtons,
  actionIcons,
  width = "100%",
  editing = false,
  mentionedPeople,
  fetchInsightComments,
}) => {
  const [editableText, setEditableText] = useState(text);
  const [isEditing, setIsEditing] = useState(editing);
  const [confirmOpen, setConfirmOpen] = useState(false);

  useEffect(() => {
    setIsEditing(editing);
  }, [editing]);

  const wrapperRef = useRef();

  const handleTextArea = useCallback(() => {
    setIsEditing(!isEditing);
    setEditableText(text);

    if (onCancel) {
      onCancel();
    }
  }, [isEditing, onCancel, text]);

  useOutsideToCloseEdit(wrapperRef, handleTextArea);

  const handleChangeText = useCallback((value) => {
    setEditableText(value);
  }, []);

  const handleSaveAndCloseText = useCallback(
    (text) => {
      onSave(text);

      handleTextArea();
    },
    [onSave, handleTextArea]
  );

  const handleKeyPress = useCallback(
    (e) => {
      if (!e.shiftKey && e.key === "Enter") {
        onSave(editableText);

        handleTextArea();
      }
    },
    [onSave, handleTextArea, editableText]
  );

  const renderActionIcons = useCallback(() => {
    return (
      <div
        style={{ display: "flex", alignItems: "center", marginRight: "20px" }}
      >
        <i
          className="fas fa-times"
          onClick={handleTextArea}
          style={{ margin: "0 5px", fontSize: "18px", color: "#333" }}
        />
        <i
          className="fas fa-check"
          onClick={() => handleSaveAndCloseText(editableText)}
          style={{ margin: "0 5px", fontSize: "18px", color: "green" }}
        />
      </div>
    );
  }, [handleTextArea, handleSaveAndCloseText, editableText]);

  const renderActionButtons = useCallback(() => {
    return (
      <DisplayButtons>
        <CancelButton onClick={handleTextArea}>Cancelar</CancelButton>
        <SaveButton onClick={() => handleSaveAndCloseText(editableText)}>
          Salvar alterações
        </SaveButton>
      </DisplayButtons>
    );
  }, [handleTextArea, handleSaveAndCloseText, editableText]);

  const renderEditableView = useCallback(() => {
    return (
      <WrapperEditable ref={wrapperRef}>
        <div
          onKeyPress={handleKeyPress}
          role="button"
          tabIndex={0}
          style={{ display: "flex", width }}
        >
          <CommentEditor
            editing
            onChange={handleChangeText}
            text={text || ""}
            container="#editToolbar"
            maxLength={maxLength}
            mentions={mentionedPeople}
          />
          {actionIcons && renderActionIcons()}
        </div>

        {actionButtons && renderActionButtons()}
      </WrapperEditable>
    );
  }, [
    mentionedPeople,
    maxLength,
    width,
    renderActionIcons,
    renderActionButtons,
    actionButtons,
    handleChangeText,
    handleKeyPress,
    actionIcons,
    text,
  ]);

  async function handleDeleteComment() {
    setConfirmOpen(false);
    await deleteComment(commentId, insightId);
    fetchInsightComments(insightId);
  }

  const renderTextView = useCallback(() => {
    return (
      <DisplayTextView>
        <TextView
          dangerouslySetInnerHTML={{
            __html: text,
          }}
        />
        {!!isEditable && (
          <div style={{ display: "flex", gap: "12px", alignItems: "center" }}>
            <i
              onClick={handleTextArea}
              className="fas fa-pen"
              aria-hidden="true"
              style={{ display: "block", cursor: "pointer", padding: "5px" }}
            />
            {selfComment && (
              <i
                onClick={() => setConfirmOpen(true)}
                className="fas fa-times"
                aria-hidden="true"
                style={{ display: "block", cursor: "pointer", padding: "5px" }}
              />
            )}
          </div>
        )}
      </DisplayTextView>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text, handleTextArea, isEditable]);

  return (
    <div>
      <FormDialog
        open={confirmOpen}
        title="Tem certeza que deseja remover este comentário?"
        onConfirm={handleDeleteComment}
        onCancel={() => setConfirmOpen(false)}
      />
      {isEditing ? renderEditableView() : renderTextView()}
    </div>
  );
};

export default connect(null, { fetchInsightComments })(EditableComment);

EditableComment.propTypes = {
  id: PropTypes.any.isRequired,
  maxLength: PropTypes.number,
  text: PropTypes.string.isRequired,
  onSave: PropTypes.func.isRequired,
  isEditable: PropTypes.bool,
};

EditableComment.defaultProps = {
  isEditable: false,
};
