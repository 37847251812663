import { useState } from "react";
import { injectIntl } from "react-intl";
import FormDialog from "../../FormDialog";
import MaterialTextField from "../../MaterialTextField";

const initialForm = {
  id: null,
  title: "",
};

const NewInsightDialog = (props) => {
  const [form, setForm] = useState(initialForm);
  const { open = false, afterCancel, afterConfirm } = props;

  const resetForm = () => {
    setForm(initialForm);
  };

  const handleConfirm = () => {
    if (typeof afterConfirm === "function") afterConfirm(form);

    resetForm();
  };

  const handleCancel = () => {
    resetForm();

    if (typeof afterCancel === "function") afterCancel();
  };

  const isValidForm = () => {
    if (form.title < 2) return false;

    return true;
  };

  return (
    <FormDialog
      open={open}
      title={"Novo Insight"}
      onConfirm={handleConfirm}
      onCancel={handleCancel}
      blockConfirm={!isValidForm()}
    >
      <div className="row">
        <div className="col-xs-12">
          <MaterialTextField
            id="title"
            label={"Título"}
            value={form.title}
            onChange={(e) => setForm({ ...form, title: e.target.value })}
          />
        </div>
      </div>
    </FormDialog>
  );
};

export default injectIntl(NewInsightDialog);
