import _ from "lodash";
import { useEffect, useState } from "react";
import { defineMessages, injectIntl } from "react-intl";
import { connect } from "react-redux";
import {
  getAllExcept,
  getOnlySlugValues,
  iterationSearch,
} from "../../../../utils/ArrayUtils";
import { useCompanyPositionsTree } from "../../../../utils/customHooks";
import { getErrorMessage } from "../../../../utils/formValidation";
import {
  getCompanyStructureStates,
  getSelectedCompanyStates,
} from "../../../customMapStates";
import FormDialog from "../../FormDialog";
import MaterialTextField from "../../MaterialTextField";
import SelectTreeModal from "../../SelectTreeModal";
import SimpleManagementList from "../../SimpleManagementList";

const messages = defineMessages({
  gb_form_job_role: { id: "gb_form_job_role" },
  modalTextCancel: {
    id: "btn_modal_cancel",
  },
  modalTextConfirm: {
    id: "btn_modal_confirm",
  },
});

const initialForm = {
  id: "",
  gate: 0,
  appropriated: 0,
  exceeded: 0,
  role: "",
  roleAccess: [],
  roleViewAccess: [],
  companyPositionId: 0,
};

const NewMultipleRoles = (props) => {
  const [selectPositionType, setSelectPositionType] = useState({ type: null });
  const [customPositionText, setCustomPositionText] = useState("");
  const { companyPositionsTree } = useCompanyPositionsTree({
    selectedCompany: props.selectedCompany,
    allPositions: props.allPositions,
  });
  const [newRoles, setNewRoles] = useState([]);
  const [newCompanyRoles, setNewCompanyRoles] = useState([]);
  const [newCustomRoles, setNewCustomRoles] = useState([]);
  const [duplicatedRoles, setDuplicatedRoles] = useState([]);

  const {
    open = false,
    afterCancel,
    afterConfirm,
    goalBonusAdministration,
    getToolConfig,
    intl,
  } = props;

  useEffect(() => {
    const updatedNewRoles = [];

    if (Array.isArray(newCompanyRoles)) {
      newCompanyRoles.forEach((companyPositionInfo) => {
        updatedNewRoles.push({
          ...initialForm,
          role: companyPositionInfo.title,
          companyPositionId: companyPositionInfo.id,
        });
      });
    }

    if (Array.isArray(newCustomRoles)) {
      newCustomRoles.forEach((customRoleInfo) => {
        updatedNewRoles.push({
          ...initialForm,
          role: customRoleInfo.title,
        });
      });
    }

    if (!_.isEqual(updatedNewRoles, newRoles)) {
      const newDuplicatedRoles = [];

      updatedNewRoles.forEach((newRoleInfo) => {
        const onlyTitles = getOnlySlugValues(
          goalBonusAdministration.roleBonuses,
          "role"
        );
        const duplicationSearch = iterationSearch(newRoleInfo.role, onlyTitles);

        if (duplicationSearch) newDuplicatedRoles.push(newRoleInfo);
      });

      if (!_.isEqual(newDuplicatedRoles, duplicatedRoles))
        setDuplicatedRoles(newDuplicatedRoles);

      setNewRoles(updatedNewRoles);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newCompanyRoles, newCustomRoles]);

  const resetSelectPositionType = () => {
    setSelectPositionType({ type: null });
  };

  const cancelSelectPosition = () => {
    resetSelectPositionType();
    setCustomPositionText("");
  };

  const handleAddPositions = (newSelectedPositions) => {
    setNewCompanyRoles(newSelectedPositions);
    cancelSelectPosition();
  };

  const removeSelectedCompayPosition = (positionInfo) => {
    if (positionInfo.id) {
      const newSelected = getAllExcept(
        [positionInfo.id],
        newCompanyRoles,
        "id"
      );

      setNewCompanyRoles(newSelected);
    }
  };

  const removeSelectedCustomPosition = (positionInfo) => {
    if (positionInfo.id) {
      const newSelected = getAllExcept(
        [positionInfo.index],
        newCustomRoles,
        "index"
      );

      setNewCustomRoles(newSelected);
    }
  };

  const handleAddCustomPosition = (newPosition = {}) => {
    const newIndex = newCustomRoles.length + 1;
    setNewCustomRoles([
      ...newCustomRoles,
      { ...newPosition, id: newIndex, index: newIndex },
    ]);

    cancelSelectPosition();
  };

  const handleSave = async () => {
    const data = await getToolConfig();

    if (data && data.id && typeof afterConfirm === "function") {
      const updatedAdministration =
        newRoles.length > 0
          ? {
              ...data,
              roleBonuses: [...data.roleBonuses, ...newRoles],
            }
          : data;

      afterConfirm(updatedAdministration);
    }
  };

  const handleCancel = () => {
    if (typeof afterCancel === "function") afterCancel();
  };

  const getTypeButton = (title, next = () => {}) => {
    return (
      <div className="simple-selection-button" onClick={next}>
        {title}
      </div>
    );
  };

  const getDisplayRoles = (roles) => {
    let displayTitles = [];

    roles.forEach((userInfo) => {
      if (userInfo.role) displayTitles.push(userInfo.role);
    });

    return displayTitles.length > 0 ? displayTitles.join(", ") + "." : "";
  };

  const displayDuplicatedPositions = () => {
    if (duplicatedRoles.length > 0) {
      return (
        <div
          className="col-xs-12"
          style={{
            backgroundColor: "#ff6347",
            color: "#fff",
            margin: "10px 0px",
            paddingTop: "5px",
            paddingBottom: "5px",
            borderRadius: "4px",
          }}
        >
          Estes cargos já foram adicionados à ferramenta:{" "}
          <b>{getDisplayRoles(duplicatedRoles)}</b>
        </div>
      );
    }

    return null;
  };

  const displaySelectPositionTypeModal = () => {
    return (
      <FormDialog
        open={open}
        title="Selecione os cargos"
        onConfirm={handleSave}
        onCancel={handleCancel}
        blockConfirm={newRoles.length === 0 || duplicatedRoles.length > 0}
      >
        <div className="row">
          <div className="col-xs-12">
            <h5>Buscar cargo por:</h5>
          </div>
          <div className="col-xs-6">
            {getTypeButton("Estrutura de Cargos", () =>
              setSelectPositionType({ type: "COMPANY_POSITION" })
            )}
          </div>
          <div className="col-xs-6">
            {getTypeButton("Customizado", () =>
              setSelectPositionType({ type: "CUSTOM_POSITION" })
            )}
          </div>
          {displayNewPositions(
            "Cargos da Estrutura da Empresa",
            newCompanyRoles,
            removeSelectedCompayPosition
          )}
          {displayNewPositions(
            "Cargos Customizados",
            newCustomRoles,
            removeSelectedCustomPosition
          )}
          {displayDuplicatedPositions()}
        </div>
      </FormDialog>
    );
  };

  const displayCustomPositionModal = () => {
    const onlyTitles = getOnlySlugValues(newCustomRoles, "title");
    const duplicationSearch = iterationSearch(customPositionText, onlyTitles);

    return (
      <FormDialog
        open={selectPositionType.type === "CUSTOM_POSITION"}
        title="Cargo customizado"
        onConfirm={() => handleAddCustomPosition({ title: customPositionText })}
        onCancel={cancelSelectPosition}
        blockConfirm={!customPositionText || duplicationSearch}
      >
        <MaterialTextField
          id="customPositionModal"
          label={intl.formatMessage(messages.gb_form_job_role)}
          value={customPositionText}
          onChange={(e) => setCustomPositionText(e.target.value)}
          required
        />
        {duplicationSearch &&
          getErrorMessage(
            ["duplicationError"],
            [
              {
                slug: "duplicationError",
                message: "Este cargo já foi adicionado",
              },
            ]
          )}
      </FormDialog>
    );
  };

  const displayNewPositions = (title, list, removeFunction) => {
    if (list.length > 0) {
      return (
        <div className="col-xs-12" style={{ margin: "15px 0px" }}>
          <h4>{title}</h4>
          <div
            className="row"
            style={{ border: "1px solid #ccc", display: "block" }}
          >
            <SimpleManagementList
              items={list}
              handleRemove={(positionInfo) => removeFunction(positionInfo)}
            />
          </div>
        </div>
      );
    }

    return null;
  };

  return (
    <div className="row">
      <div className="col-xs-12">
        {selectPositionType.type === "COMPANY_POSITION" && (
          <SelectTreeModal
            open={true}
            title="Selecione o Cargo"
            modalTextCancel={intl.formatMessage(messages.modalTextCancel)}
            modalTextConfirm={intl.formatMessage(messages.modalTextConfirm)}
            treeList={companyPositionsTree.treeList}
            treeOrder={companyPositionsTree.treeOrder}
            initialSelected={newCompanyRoles}
            onConfirm={(selected) => handleAddPositions(selected)}
            onCancel={cancelSelectPosition}
            canSelectAnyLevel={true}
            singleSelect={false}
            dialogClassName="select-position-dialog"
          />
        )}
        {displaySelectPositionTypeModal()}
        {displayCustomPositionModal()}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { selectedCompany = {} } = getSelectedCompanyStates(state);
  const { allPositions } = getCompanyStructureStates(state);

  return {
    allPositions,
    selectedCompany,
  };
};

export default injectIntl(connect(mapStateToProps)(NewMultipleRoles));
