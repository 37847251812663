import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { defineMessages } from "react-intl";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";

import { setSelectedDomainId } from "../../../actions/accessLevelsActions";
import { resetSelectedEntityStorage } from "../../../actions/companyActions";
import { translatedText } from "../../../utils/translationUtils";
import FormDialog from "../../Common/FormDialog";
import GenericTable from "../../Common/GenericTable";
import { getAccessStates } from "../../customMapStates";

const defaultDomainConfig = {
  showModal: false,
  domain: null,
};

const messages = defineMessages({
  domain: {
    id: "global.domain",
  },
  select: {
    id: "global.select",
  },
  selectDomain: {
    id: "global.selectDomain",
  },
  youAreSelectingDomain: {
    id: "global.youAreSelectingDomain",
  },
});

const translation = (id, values) => translatedText(id, messages, values);

const DomainSelection = ({
  userDomains = [],
  setSelectedDomainId,
  currentDomain,
  resetSelectedEntityStorage,
  showModal = false,
  setShowModal,
}) => {
  const [domains, setDomains] = useState();
  const [searchWords, setSearchWords] = useState("");
  const [selectedDomainConfig, setSelectedDomainConfig] =
    useState(defaultDomainConfig);
  const history = useHistory();

  useEffect(() => {
    setDomains([...userDomains]);
  }, [userDomains]);

  const headers = {
    namespace: translation("domain"),
  };

  const domainSearch = (entity = [], searchWords = "") => {
    if (searchWords.length > 0) {
      return entity.filter((entityLine) =>
        entityLine.namespace.toUpperCase().includes(searchWords.toUpperCase())
      );
    }
    return entity;
  };

  const cleanSelectedDomainConfig = () => {
    setSelectedDomainConfig(defaultDomainConfig);
  };

  const handleSelectedDomain = (domain = {}) => {
    setSelectedDomainConfig({
      domain,
      showModal: true,
    });
  };

  const selectDomain = () => {
    const { domain = {} } = selectedDomainConfig || {};
    const { id: domainId = null } = domain || {};

    setSelectedDomainId(domainId);
    localStorage.setItem("SELECTED_DOMAIN_ID", domainId);

    resetSelectedEntityStorage();
    cleanSelectedDomainConfig();
    setShowModal(false);
    history.push("/companies");
  };

  const selectDomainButton = (domain = {}) => {
    const { id: domainId = null } = domain || {};
    const { id: currentDomainId = null } = currentDomain || {};

    if (Number(domainId) === Number(currentDomainId)) return <div />;

    return (
      <div>
        <Button
          className="btn btn-select btn-sm"
          onClick={() => handleSelectedDomain(domain)}
        >
          <i className="fas fa-arrow-right" />
        </Button>
      </div>
    );
  };

  const GenericModal = () => {
    const { showModal = false, domain = {} } = selectedDomainConfig;

    const { namespace = "" } = domain || {};

    return (
      <FormDialog
        open={showModal}
        title={translation("youAreSelectingDomain") + namespace}
        onConfirm={() => selectDomain()}
        onCancel={() => cleanSelectedDomainConfig()}
        bodyStyle={{ padding: "0px" }}
      >
        <div />
      </FormDialog>
    );
  };

  const customActions = [
    {
      actions: selectDomainButton,
    },
  ];

  const title = (
    <div className="domainTableTitle">
      <span>&nbsp;</span>
    </div>
  );

  return (
    <>
      <FormDialog
        open={showModal}
        onCancel={() => setShowModal(false)}
        onConfirm={() => console.log("confirm")}
        bodyStyle={{ padding: "0px" }}
        hideConfirm
        title={translation("selectDomain")}
      >
        <GenericTable
          title={title}
          headers={headers}
          showActionButtons={false}
          onSearch={setSearchWords}
          customActions={customActions}
          customActionsFirst
          data={domainSearch(domains, searchWords)}
        />
      </FormDialog>
      <GenericModal />
    </>
  );
};

const mapStateToProps = (state) => {
  const { userDomains = [], currentDomain = {} } = getAccessStates(state);

  return { userDomains, currentDomain };
};

export default connect(mapStateToProps, {
  setSelectedDomainId,
  resetSelectedEntityStorage,
})(DomainSelection);
