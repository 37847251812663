import React, { useState, useEffect } from "react";
import { injectIntl, defineMessages } from "react-intl";
import { connect } from "react-redux";
import moment from "moment";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { translatedText } from "../../../utils/translationUtils";
import Avatar from "../../Common/Avatar";
import { getBuildedNotifications } from "../../../utils/notificationUtils";
import {
  fetchUserNotifications,
  updateNotification,
} from "../../../actions/notifications/actions";
import { getCurrentUserInfo } from "../../../utils/accessLevels";

const buildSettingsDefault = {
  size: 5,
  build: true,
};

const translation = (id, values) => translatedText(id, messages, values);

const messages = defineMessages({
  notifications: {
    id: "global.notifications",
  },
  seeMore: {
    id: "global.seeMore",
  },
});

const NotificationsMenu = ({
  notifications = [],
  buildedAccess = false,
  updateNotification,
  fetchUserNotifications,
}) => {
  const [currentNotifications, setCurrentNotifications] = useState([]);
  const [buildedNotifications, setBuildedNotifications] = useState([]);
  const [buildSettings, setBuildSettings] = useState(buildSettingsDefault);

  useEffect(() => {
    if (currentNotifications.length !== notifications.length) {
      setBuildSettings({ ...buildSettings, build: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications]);

  useEffect(() => {
    const { build = false } = buildSettings;

    const callBuildNotification = async () =>
      await buildNotifications(notifications);

    if (buildedAccess === true && build === true) {
      setCurrentNotifications(notifications);
      callBuildNotification();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifications, buildedAccess, buildSettings]);

  const buildNotifications = async (notifications) => {
    const { size = 5 } = buildSettings;

    const builded = await getBuildedNotifications(notifications, size);

    const filtered = builded.filter(({ description }) => {
      const splitedDescription = description.split("</>");

      const senderName = splitedDescription[0] || "";

      if (senderName === "") return false;

      return true;
    });

    setBuildedNotifications(filtered);
    setBuildSettings({ ...buildSettings, build: false });
  };

  const showNonReadedLength = () => {
    const nonReaded = notifications.length;

    if (nonReaded > 9) return "9+";

    return nonReaded;
  };

  const mountParentDetails = (parentDetails = []) => {
    const sequence = [
      "COMPANY",
      "WORKSPACE",
      "SELECTED_TOOL",
      "CENARY",
      "INSIGHT",
    ];

    const sequencialDetails = [];

    parentDetails.forEach((parentDetail, index) => {
      const detail =
        parentDetails.filter(
          (parentDetail) => parentDetail.entityType === sequence[index]
        )[0] || null;

      if (detail) sequencialDetails.push(detail);
    });

    return sequencialDetails;
  };

  const entitysBreadcrumb = (parentDetails = []) => {
    const mountedDetails = mountParentDetails(parentDetails);

    return (
      <Breadcrumbs
        separator={<NavigateNextIcon fontSize="small" />}
        aria-label="breadcrumb"
      >
        {mountedDetails.map(({ entityName }, index) => {
          return (
            <div className="entityBreadcrumb fontSize12 ellipsis" key={index}>
              {entityName}
            </div>
          );
        })}
      </Breadcrumbs>
    );
  };

  const markAsRead = (id = "") => {
    const notification =
      notifications.filter((not) => not.id === id)[0] || null;

    if (!notification) return;

    notification.readed = true;

    updateNotification(notification);
  };

  const listNotifications = () => {
    const haveNotifications =
      buildedNotifications && buildedNotifications.length > 0;

    return haveNotifications ? (
      buildedNotifications.map(
        ({ id, description, sendDate, base64, parentDetails }, index) => {
          let relativeDate = moment(sendDate, "YYYY-MM-DD")
            .calendar()
            .split(" ")[0];
          if (index !== 0) {
            const { sendDate } = buildedNotifications[index - 1];
            const previousDate = moment(sendDate, "YYYY-MM-DD")
              .calendar()
              .split(" ")[0];

            if (relativeDate === previousDate) relativeDate = false;
          }
          const splitedDescription = description.split("</>");
          const senderName = splitedDescription[0] || false;
          const story = splitedDescription[1] || "";
          const entityName = splitedDescription[2] || "";

          return (
            <li className="notification-li" key={index}>
              {relativeDate && (
                <div className="relativeNotTime">{relativeDate}</div>
              )}
              <div className="not-li-container">
                <Avatar
                  avatar={
                    base64 ? `data:image/png;base64,${base64}` : undefined
                  }
                  name=""
                  style={{
                    width: "30px",
                    height: "30px",
                    marginRight: "5px",
                  }}
                />
                <p className="notification-description">
                  <strong>{senderName}</strong> {story}
                  <strong>{entityName}</strong>
                </p>
                <div className="markAsRead" onClick={() => markAsRead(id)}>
                  <i className="far fa-trash-alt" />
                </div>
              </div>
              <div className="not-li-container entityContainer">
                {entitysBreadcrumb(parentDetails)}
              </div>
            </li>
          );
        }
      )
    ) : (
      <h5 align="center" style={{ color: "#333" }}>
        Nenhum notificação para mostrar
      </h5>
    );
  };

  const viewMore = () => {
    const { size: currentSize = 5 } = buildSettings || {};

    setBuildSettings({
      size: currentSize + 5,
      build: true,
    });
  };

  const showViewMore = buildSettings.size < notifications.length;
  const user = getCurrentUserInfo();

  return (
    <li
      className="dropdown notificationsHeaderLi"
      onClick={() => fetchUserNotifications(user?.id)}
    >
      <a className="dropdown-toggle bellContainer" data-toggle="dropdown">
        <i className="fas fa-bell notificationIcon" />
        <div className="notificationCount">{showNonReadedLength()}</div>
      </a>
      <ul className="dropdown-menu notifications-ul fixedScroll notificationsScroll">
        <form>
          {/*
                    <li className="notifications-li-title">
            <div>
              <h4>{translation("notifications")}</h4>
            </div>
          </li>
          */}
          <div>{listNotifications()}</div>
          {showViewMore && (
            <div className="notificationsViewMore">
              <a onClick={() => viewMore()}>{translation("seeMore")}..</a>
            </div>
          )}
        </form>
      </ul>
    </li>
  );
};

const mapStateToProps = (state) => {
  const { notifications = [], access = {} } = state || {};
  const { buildedAccess = false } = access || {};

  return { notifications, buildedAccess };
};

export default injectIntl(
  connect(mapStateToProps, { updateNotification, fetchUserNotifications })(
    NotificationsMenu
  )
);
