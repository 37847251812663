import axios from "axios";

import utils from "../utils/cpAnalysis";
import { grantSceneryAccess } from "./accessControllerActions";
import { putScenery, selectCenary } from "./cenaryActions";
import { putQuestion } from "./questionsActions";
import { putTheme } from "./themesActions";
import {
  DELETE_ANALYSIS_AMBIENT,
  DELETE_ANALYSIS_AREA,
  DELETE_ANALYSIS_GROUP,
  DELETE_EVALUATION_CRITERIA,
  GET_ALL_COMPETITIVE_ANALYSIS_EVALUATIONS,
  GET_COMPETITIVE_ANALYSIS_POINTS,
  GET_CP_ANALYSIS_MANAGEMENT_DATA,
  GET_CP_OVERVIEW_MANAGEMENT_DATA,
  POST_COMPETITIVE_ANALYSIS_EVALUATION,
  SET_ANALYSIS_TOOL_TYPE,
  UPDATE_ALL_CP_ANALYSIS_CONFIGURATION,
  UPDATE_CENARY,
  UPDATE_CENARY_THEME,
  UPDATE_COMPANY_DETAILS,
  UPDATE_COMPETITIVE_ANALYSIS_EVALUATION,
  UPDATE_THEME_QUESTION,
} from "./types";

const { buildFormAdmConfigBody, buildRestAdmConfigBody } = utils;

export const linkAnalysisGroup = (insightId, competitiveAnalysisGroupId) => {
  return axios
    .post(
      `/api/competitive-analysis/insight/${insightId}/CompetitiveAnalysisGroup/${competitiveAnalysisGroupId}`
    )
    .then((res) => {
      return res.data;
    });
};

export const getCompetitiveAnalysisPoints = (
  selectedToolId = "",
  fetchFiledOption = false
) => {
  return axios
    .get(
      `/api/selected-tools/competitiveAnalysisPoints/${selectedToolId}?fetchFiledOption=${fetchFiledOption}`
    )
    .then((res) => res.data);
};

export const fetchCompetitiveAnalysisPoints = async (
  selectedToolId = "",
  fetchFiledOption = false
) => {
  return async (dispatch) => {
    const points = await getCompetitiveAnalysisPoints(
      selectedToolId,
      fetchFiledOption
    );

    dispatch({
      type: GET_COMPETITIVE_ANALYSIS_POINTS,
      payload: points,
    });
  };
};

export const postEvaluation = async (insightId = "", evaluation = {}) => {
  const { evaluations = [], type = "" } = evaluation;

  return await axios
    .post(
      `/api/insights/${insightId}/competitiveAnalysisEvaluation/${type}`,
      evaluations
    )
    .then((res) => res.data);
};

export const putEvaluation = async (
  evaluations = [],
  insightId = 0,
  selectedToolId = 0
) => {
  return await Promise.all(
    evaluations.map(async (evaluation) => {
      return await axios
        .put(
          `/api/competitive-analysis-evaluation/insight/${insightId}/selectedTool/${selectedToolId}`,
          evaluation
        )
        .then((res) => res.data);
    })
  );
};

export const sendCompetitiveAnalysisEvaluation = (
  insightId = "",
  evaluation = {},
  selectedToolId = "",
  call = "POST"
) => {
  return async (dispatch) => {
    const { evaluations = [] } = evaluation;

    let putReturn = false;
    let postReturn = false;

    if (call === "PUT")
      putReturn = await putEvaluation(evaluations, insightId, selectedToolId);

    if (!putReturn) {
      postReturn = await postEvaluation(insightId, evaluation);
    }

    if (postReturn) {
      dispatch({
        type: POST_COMPETITIVE_ANALYSIS_EVALUATION,
        payload: { ...postReturn, insightId, selectedToolId },
      });
    } else if (putReturn) {
      const { type = "" } = putReturn[0] || {};

      dispatch({
        type: UPDATE_COMPETITIVE_ANALYSIS_EVALUATION,
        payload: {
          type,
          evaluations: [...putReturn],
          selectedToolId,
          insightId,
        },
      });
    }

    await dispatch(fetchCompetitiveAnalysisPoints(selectedToolId));
  };
};

export const sendCompanyDetails = (
  competitiveAnalysisId = "",
  companyDetails = {},
  insightId = "",
  sendDispatch = true
) => {
  return async (dispatch) => {
    const { id: companyDetailsId = false } = companyDetails;

    let putReturn = false;
    let postReturn = false;

    if (companyDetailsId) putReturn = await putCompanyDetails(companyDetails);

    if (!putReturn) {
      postReturn = await postCompanyDetails(
        competitiveAnalysisId,
        companyDetails
      );
    }

    if (postReturn) {
      return dispatch({
        type: POST_COMPETITIVE_ANALYSIS_EVALUATION,
        payload: { ...postReturn, insightId },
      });
    }

    if (putReturn && sendDispatch) {
      return dispatch({
        type: UPDATE_COMPANY_DETAILS,
        payload: { companyDetailsInfo: { ...putReturn }, insightId },
      });
    }
  };
};

export const postCompanyDetails = (
  competitiveAnalysisEvaluationId = "",
  companyDetails = {}
) => {
  return axios
    .post(
      `/api/competitive-analysis-evaluations/${competitiveAnalysisEvaluationId}/addCompanyDetails`,
      companyDetails
    )
    .then((res) => res.data);
};

export const putCompanyDetails = (companyDetails = {}) => {
  return axios
    .put(`/api/company-details`, companyDetails)
    .then((res) => res.data);
};

export const getEvaluation = (insightId = "") => {
  return axios
    .get(`/api/insights/${insightId}/competitiveAnalysisEvaluation`)
    .then((res) => res.data);
};

export const getAllCompetitiveEvaluations = (
  selectedToolIds = [],
  fetchFiledOption = false
) => {
  return axios
    .get(
      `/api/competitive-analysis-evaluations/selectedTools/${selectedToolIds}?fetchFiledOption=${fetchFiledOption}`
    )
    .then((res) => res.data);
};

export const getManagementDataByThemeIds = (
  selectedToolId = "",
  themeIds = [],
  fetchFiledOption = false
) => {
  return axios
    .get(
      `/api/selected-tools/competitiveAnalysisOverview/${selectedToolId}/filterThemes/${themeIds}?fetchFiledOption=${fetchFiledOption}`
    )
    .then((res) => res.data);
};
export const getManagementData = (selectedToolId = "") => {
  return axios
    .get(`/api/selected-tools/competitiveAnalysisOverview/${selectedToolId}`)
    .then((res) => res.data);
};

export const getCompetitiveAnalysisEvaluation = (
  insightId = "",
  selectedToolId = ""
) => {
  return async (dispatch) => {
    try {
      const evaluation = await getEvaluation(insightId);

      dispatch({
        type: POST_COMPETITIVE_ANALYSIS_EVALUATION,
        payload: { ...evaluation, insightId, selectedToolId },
      });
    } catch (err) {
      dispatch({
        type: POST_COMPETITIVE_ANALYSIS_EVALUATION,
        payload: { insightId, selectedToolId },
      });
    }
  };
};

export const uploadLogo = (file, s3bucket = "IMAGES") => {
  return axios
    .post(`/api/s3/upload-file?s3bucket=${s3bucket}`, file)
    .then((res) => {
      return res.data;
    })
    .catch((err) => console.log({ err }));
};

export const getBase64 = (fileName = "", s3Bucket = "IMAGES") => {
  return axios
    .get(`/api/s3/get-file?fileName=${fileName}&s3bucket=${s3Bucket}`)
    .then((res) => {
      return res.data;
    });
};

export const setCurrentAnalysisToolType = (analysisToolType = "") => {
  return (dispatch) => {
    dispatch({
      type: SET_ANALYSIS_TOOL_TYPE,
      payload: analysisToolType.toLowerCase(),
    });
  };
};

export const dispatchCenary = (newScenery) => {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_CENARY,
      payload: { ...newScenery, isNew: true },
    });
    await dispatch(selectCenary(newScenery.id));
    dispatch(grantSceneryAccess(null, "LEADER", "EDIT"));
  };
};

export const dispatchTheme = (cenaryId, newTheme) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_CENARY_THEME,
      payload: {
        cenaryId,
        theme: {
          ...newTheme,
          isNew: true,
        },
      },
    });
  };
};

export const createSceneryProcess = (
  selectedTool = {},
  entityInfo = {},
  userProfile,
  order = 0
) => {
  return async (dispatch) => {
    if (selectedTool.id && entityInfo.entityId) {
      try {
        const { entityId = "", entityName = "" } = entityInfo;

        const { data: newSceneryData } = await dispatch(
          createScenery(selectedTool, entityName, userProfile, order)
        );

        if (newSceneryData.id) {
          const postedLink = await linkBoardScenerie(
            newSceneryData.id,
            entityId
          );

          if (postedLink.status === 204 || postedLink.status === 200) {
            const updatedScenaryData = {
              ...newSceneryData,
              competitiveAnalysisId: entityId,
            };

            dispatch(dispatchCenary(updatedScenaryData));
          }
        }
      } catch (err) {
        console.log({ err });
      }
    }
  };
};

export const addThemesToAdministration = async (
  selectedToolId,
  newCpAnalysisAdministration = {},
  currentAnalysisTool,
  dispatch
) => {
  const { type = "" } = currentAnalysisTool;
  const currentToolType = type.toLowerCase();
  const newCpConfig = buildRestAdmConfigBody(newCpAnalysisAdministration);

  const { analysisAmbient = [] } = newCpConfig[currentToolType] || {};

  let currentThemeIds = [];
  let newAddedThemes = [];

  analysisAmbient.forEach((themeInfo) => {
    if (themeInfo.id > -1) currentThemeIds.push(themeInfo.id);
  });

  const updatedAdministration = await putCompAnalysisConfiguration(newCpConfig);
  const fetchedConfiguration = buildFormAdmConfigBody(updatedAdministration);

  dispatch({
    type: UPDATE_ALL_CP_ANALYSIS_CONFIGURATION,
    payload: [
      {
        ...fetchedConfiguration,
        selectedToolId,
      },
    ],
  });

  if (updatedAdministration.id) {
    const updatedThemes =
      updatedAdministration[currentToolType].analysisAmbient;

    updatedThemes.forEach((themeInfo) => {
      if (currentThemeIds.indexOf(themeInfo.id) === -1)
        newAddedThemes.push(themeInfo);
    });
  }

  return newAddedThemes;
};

export const addQuestionsToAdministration = async (
  selectedToolId,
  newCpAnalysisAdministration = {},
  currentToolType,
  dispatch
) => {
  const newCpConfig = buildRestAdmConfigBody(newCpAnalysisAdministration);

  const { analysisAreas = [] } = newCpConfig[currentToolType] || {};

  let currentQuestionIds = [];
  let newAddedQuestions = [];

  analysisAreas.forEach((questionInfo) => {
    if (questionInfo.id > -1) currentQuestionIds.push(questionInfo.id);
  });

  const updatedAdministration = await putCompAnalysisConfiguration(newCpConfig);
  const fetchedConfiguration = buildFormAdmConfigBody(updatedAdministration);

  dispatch({
    type: UPDATE_ALL_CP_ANALYSIS_CONFIGURATION,
    payload: [
      {
        ...fetchedConfiguration,
        selectedToolId,
      },
    ],
  });

  if (updatedAdministration.id) {
    const updatedQuestions =
      updatedAdministration[currentToolType].analysisAreas;

    updatedQuestions.forEach((questionInfo) => {
      if (currentQuestionIds.indexOf(questionInfo.id) === -1)
        newAddedQuestions.push(questionInfo);
    });
  }

  return newAddedQuestions;
};

export const addThemeAndCreateLink = (
  selectedTool,
  themeScenerie,
  newCpAnalysisAdministration = {},
  currentAnalysisTool = {},
  order
) => {
  return async (dispatch) => {
    const newAddedThemes = await addThemesToAdministration(
      selectedTool.id,
      newCpAnalysisAdministration,
      currentAnalysisTool,
      dispatch
    );

    let nextOrder = order;

    if (newAddedThemes.length > 0) {
      newAddedThemes.forEach((themeInfo) => {
        dispatch(
          createThemeProcess(
            themeInfo,
            themeScenerie,
            currentAnalysisTool,
            nextOrder
          )
        );
        nextOrder++;
      });
    }
  };
};

export const addQuestionAndCreateLink = (
  selectedTool,
  questionTheme,
  newCpAnalysisAdministration = {},
  currentAnalysisToolType = "",
  order
) => {
  return async (dispatch) => {
    const newAddedQuestions = await addQuestionsToAdministration(
      selectedTool.id,
      newCpAnalysisAdministration,
      currentAnalysisToolType,
      dispatch
    );

    let nextOrder = order;

    if (newAddedQuestions.length > 0) {
      newAddedQuestions.forEach((questionInfo) => {
        dispatch(
          createAreaQuestionProcess(questionInfo, questionTheme, nextOrder)
        );
        nextOrder++;
      });
    }
  };
};

export const handleQuestionCreation = (
  currentAnalysisTool = {},
  themeBody = {}
) => {
  const { type = "", factors = [] } = currentAnalysisTool || {};
  const currentToolType = type.toLowerCase() || "";

  const customQuestionTool = ["pestal", "swot"];

  if (customQuestionTool.indexOf(currentToolType) === -1) return;

  const factorIds = factors.map((factor) => factor.id);

  return async (dispatch) => {
    dispatch(createFactorQuestionProcess(factorIds, themeBody));
  };
};

export const createThemeProcess = (
  ambientInfo = {},
  cenaryBody = {},
  currentAnalysisTool = {},
  order = 0
) => {
  return async (dispatch) => {
    if (cenaryBody.id && ambientInfo.id) {
      try {
        const newTheme = await createTheme(
          cenaryBody,
          ambientInfo.title,
          order
        );
        const newThemeData = newTheme.data;

        if (newTheme.data.id) {
          const postedLink = await linkBoardTheme(
            newTheme.data.id,
            ambientInfo.id
          );

          if (postedLink.status === 204 || postedLink.status === 200) {
            const updatedThemeData = {
              ...newThemeData,
              competitiveAnalysisAmbientId: ambientInfo.id,
            };

            dispatch(dispatchTheme(cenaryBody.id, updatedThemeData));
            dispatch(
              handleQuestionCreation(currentAnalysisTool, updatedThemeData)
            );
          }
        }
      } catch (err) {
        console.log("err", err);
      }
    }
  };
};

export const createScenery = (
  selectedTool,
  sceneryName,
  owner = {},
  order = 0
) => {
  return (dispatch) =>
    dispatch(
      putScenery(
        {
          id: null,
          name: sceneryName,
          fixed: false,
          selectedTool,
          editing: true,
          type: "cenary",
          owner,
          order,
        },
        selectedTool.id
      )
    );
};

export const createQuestion = (themeBody, factorTitle, order) => {
  return putQuestion({
    id: null,
    type: "question",
    theme: themeBody,
    text: factorTitle,
    order,
  });
};

export const dispatchQuestion = (themeId, newQuestion) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_THEME_QUESTION,
      payload: {
        themeId,
        question: {
          ...newQuestion,
          isNew: true,
        },
      },
    });
  };
};

export const createFactorQuestionProcess = (
  competitiveAnalysisFactorsIds = [],
  themeBody = {},
  order = 0
) => {
  return async (dispatch) => {
    if (themeBody.id) {
      try {
        const createdQuestions = await CreateAndLinkBoardQuestions(
          themeBody.id,
          competitiveAnalysisFactorsIds
        );

        if (createdQuestions?.length > 0) {
          createdQuestions.forEach((question) => {
            dispatch(dispatchQuestion(themeBody.id, question));
          });
        }
      } catch (err) {
        console.log({ err });
      }
    }
  };
};

export const createAreaQuestionProcess = (
  areaInfo = {},
  themeBody = {},
  order = 0
) => {
  return async (dispatch) => {
    if (themeBody.id && areaInfo.id) {
      try {
        const newQuestion = await createQuestion(
          themeBody,
          areaInfo.title,
          order
        );

        const newQuestionData = newQuestion.data;

        if (newQuestionData.id) {
          const postedLink = await linkAnalysisAreaBoardQuestion(
            newQuestionData.id,
            areaInfo.id
          );

          if (postedLink.status === 204 || postedLink.status === 200) {
            const updatedQuestionData = {
              ...newQuestionData,
              competitiveAnalysisAreaId: areaInfo.id,
            };

            dispatch(dispatchQuestion(themeBody.id, updatedQuestionData));
          }
        }
      } catch (err) {
        console.log("createAreaQuestionProcess: ", { err });
      }
    }
  };
};

export const createTheme = (cenaryBody, ambientTitle, order) => {
  return putTheme({
    id: null,
    type: "theme",
    cenary: cenaryBody,
    name: ambientTitle,
    order,
  });
};

export const linkBoardScenerie = (
  cenaryId = "",
  competitiveAnalysisId = ""
) => {
  return axios
    .post(
      `/api/competitive-analysis/cenary/${cenaryId}/CompetitiveAnalysis/${competitiveAnalysisId}`
    )
    .then((res) => res);
};

export const linkBoardTheme = (
  themeId = "",
  competitiveAnalysisAmbientId = ""
) => {
  return axios
    .post(
      `/api/competitive-analysis/theme/${themeId}/CompetitiveAnalysisAmbient/${competitiveAnalysisAmbientId}`
    )
    .then((res) => res);
};

export const CreateAndLinkBoardQuestions = (
  themeId = "",
  competitiveAnalysisFactorsIds = ""
) => {
  return axios
    .post(
      `/api/competitive-analysis/theme/${themeId}/createQuestions/${competitiveAnalysisFactorsIds}`
    )
    .then((res) => res.data);
};

export const linkAnalysisAreaBoardQuestion = (
  questionId = "",
  competitiveAnalysisAreaId = ""
) => {
  return axios
    .post(
      `/api/competitive-analysis/question/${questionId}/CompetitiveAnalysisArea/${competitiveAnalysisAreaId}`
    )
    .then((res) => res);
};

export const putCompAnalysisConfiguration = (body) => {
  return axios
    .put("/api/competitive-analysis-configurations", body)
    .then((res) => res.data);
};

export const getCompAnalysisConfiguration = (selectedToolId) => {
  return axios
    .get(
      `/api/selected-tools/${selectedToolId}/competitiveAnalysisConfiguration`
    )
    .then((res) => res.data);
};

export const setCompAnalysisConfiguration = (
  updatedConfig = {},
  selectedToolId = ""
) => {
  return axios
    .post(
      `/api/selected-tools/${selectedToolId}/competitiveAnalysisConfiguration`,
      updatedConfig
    )
    .then((res) => res.data);
};

export const updateCompAnalysisAdministration = (
  updatedConfig = {},
  selectedToolId = ""
) => {
  return async (dispatch) => {
    const { id = false } = updatedConfig;

    let callFunction = () =>
      setCompAnalysisConfiguration(updatedConfig, selectedToolId);

    if (id) callFunction = () => putCompAnalysisConfiguration(updatedConfig);

    const configuration = await callFunction();

    dispatch({
      type: UPDATE_ALL_CP_ANALYSIS_CONFIGURATION,
      payload: [{ ...configuration, selectedToolId }],
    });
  };
};

export const fetchCompAnalysisAdministration = (selectedToolId) => {
  return async (dispatch) => {
    const configuration =
      (await getCompAnalysisConfiguration(selectedToolId)) || {};

    dispatch({
      type: UPDATE_ALL_CP_ANALYSIS_CONFIGURATION,
      payload: [buildFormAdmConfigBody({ ...configuration, selectedToolId })],
    });
  };
};

export const killManagementProcess = () => {
  return async (dispatch) => {
    dispatch({
      type: GET_CP_ANALYSIS_MANAGEMENT_DATA,
      payload: {},
    });
  };
};

export const fetchCompAnalysisManagementData = (
  selectedToolId,
  themes = [],
  fetchFiledOptions = false
) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: GET_CP_ANALYSIS_MANAGEMENT_DATA,
        payload: await getManagementDataByThemeIds(
          selectedToolId,
          themes,
          fetchFiledOptions
        ),
      });
    } catch (err) {
      killManagementProcess();
    }
  };
};

export const fetchCompAnalysisManagementDataOverview = (selectedToolIds) => {
  return async (dispatch) => {
    try {
      const data = [];

      await Promise.all(
        selectedToolIds.map(async (selectedToolId) => {
          const managementData = await getManagementData(selectedToolId);

          data.push(managementData);
        })
      );

      dispatch({
        type: GET_CP_OVERVIEW_MANAGEMENT_DATA,
        payload: data,
      });
    } catch (err) {
      killManagementProcess();
    }
  };
};

export const deleteAnalysisAmbient = (
  analysisAmbientId = "",
  currentToolType = "",
  selectedToolId = ""
) => {
  return async (dispatch) => {
    await axios.delete(
      `/api/competitive-analysis-ambients/${analysisAmbientId}`
    );

    dispatch({
      type: DELETE_ANALYSIS_AMBIENT,
      payload: { analysisAmbientId, currentToolType, selectedToolId },
    });
  };
};

export const deleteEvaluationCriteria = (
  evaluationCriteriaId = "",
  currentToolType = "",
  selectedToolId = ""
) => {
  return async (dispatch) => {
    await axios.delete(
      `/api/competitive-analysis-evaluation-criteria/${evaluationCriteriaId}`
    );

    dispatch({
      type: DELETE_EVALUATION_CRITERIA,
      payload: { evaluationCriteriaId, currentToolType, selectedToolId },
    });
  };
};

export const deleteAnalysisGroup = (
  analysisGroupId = "",
  currentToolType = "",
  selectedToolId = ""
) => {
  return async (dispatch) => {
    await axios.delete(`/api/competitive-analysis-groups/${analysisGroupId}`);

    dispatch({
      type: DELETE_ANALYSIS_GROUP,
      payload: { analysisGroupId, currentToolType, selectedToolId },
    });
  };
};

export const deleteAnalysisArea = (
  analysisAreaId = "",
  currentToolType = "",
  selectedToolId = ""
) => {
  return async (dispatch) => {
    await axios.delete(`/api/competitive-analysis-areas/${analysisAreaId}`);

    dispatch({
      type: DELETE_ANALYSIS_AREA,
      payload: { analysisAreaId, currentToolType, selectedToolId },
    });
  };
};

export const fetchAllCompetitiveEvaluations = (
  selectedToolIds = [],
  fetchFiledOption = false
) => {
  return async (dispatch) => {
    const allEvaluations = await getAllCompetitiveEvaluations(
      selectedToolIds,
      fetchFiledOption
    );

    dispatch({
      type: GET_ALL_COMPETITIVE_ANALYSIS_EVALUATIONS,
      payload: allEvaluations,
    });
  };
};
