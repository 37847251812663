import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { Button, Row } from "react-bootstrap";
import { defineMessages } from "react-intl";
import { translatedText } from "../../../utils/translationUtils";
import { SheetJSFT } from "../Import/types";
import { readFile } from "../Import/utils";

const translation = (id, values) => translatedText(id, messages, values);

const messages = defineMessages({
  measu_import: {
    id: "measu_import",
  },
  measu_export: {
    id: "measu_export",
  },
});

const DataFlowMenu = ({
  handleExport,
  handleFileChange,
  showImport = true,
  showExport = true,
  formatDates = true,
}) => {
  const [importedData, setImportedData] = useState(null);
  const [currentFile, setCurrentFile] = useState(null);

  const inputRef = useRef(null);

  const formatDateValues = (data = {}) => {
    let updatedData = { ...data };

    Object.keys(data).forEach((sheetName, index) => {
      const { internalData = [] } = data[sheetName] || {};

      internalData.forEach((line) => {
        Object.keys(line).forEach((field) => {
          if (typeof line[field] === "object" && line[field] !== null)
            updatedData[sheetName].data[index][field] = moment(
              line[field]
            ).format("YYYY-MM-DD");
        });
      });
    });

    return updatedData;
  };

  useEffect(() => {
    if (importedData) {
      const { data = {} } = importedData.fileData || {};

      const formattedData = formatDateValues(data);

      const newFileData = formatDates
        ? { data: { ...formattedData } }
        : { data: { ...data } };

      handleFileChange(currentFile, newFileData);

      setImportedData(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [importedData, handleFileChange, currentFile]);

  const handleImport = () => {
    inputRef.current.click();
  };

  const fileChanger = (e) => {
    e.stopPropagation();
    e.preventDefault();

    setCurrentFile(e.target.files[0]);

    readFile(e.target.files[0], setImportedData);
    e.target.value = "";
  };

  return (
    <Row className="flex">
      {showImport && (
        <Button onClick={() => handleImport()}>
          {translation("measu_import")}
        </Button>
      )}
      {showExport && (
        <Button onClick={() => handleExport()}>
          {translation("measu_export")}
        </Button>
      )}
      <>
        <input
          type="file"
          id="file"
          ref={inputRef}
          style={{ display: "none" }}
          onChange={(e) => fileChanger(e)}
          accept={SheetJSFT}
        />
      </>
    </Row>
  );
};

export default DataFlowMenu;
