import PropTypes from "prop-types";
import { injectIntl } from "react-intl";
import { connect } from "react-redux";
import {
  getReadinessOptions,
  getTableCarrearPathLabels,
} from "../../../../constants/peopleEvaluation";
import actionsPlanUtils from "../../../../utils/actionsPlan";
import SubtitleBadge from "../../../Common/SubtitleBadge";
import { getActionsPlanStates } from "../../../customMapStates";

import { getOnlySlugValues } from "../../../../utils/ArrayUtils";
import utils from "../../../../utils/toolUtils";
import ActionsCounterBadge from "../../../Common/ActionPlan/evaluations/ActionsCounterBadge";
import AverageBadge from "../../../Common/AverageBadge";

const { getEvaluationsById } = utils;
const { getTotalActionsByInsights, getDefaultEvaluationBadgeConfig } =
  actionsPlanUtils;

const PeopleBigPicture = (props) => {
  const readinessOptions = getReadinessOptions(props.intl);
  const carrearPathLabels = getTableCarrearPathLabels(props.intl);

  const { insightsToCalc, allActionsPlans, from } = props;
  const onlyInsightIds = getOnlySlugValues(insightsToCalc, "id");

  const getInsight = () => {
    let finalInsight = null;

    if (from === "answer" || from === "score") {
      insightsToCalc.forEach((insight) => {
        finalInsight = insight;
      });
    }

    return finalInsight;
  };

  const getInsightEvaluation = (insightID) => {
    const { allPeopleEvaluation } = props;
    let finalEvaluation = null;

    allPeopleEvaluation.forEach((evaluation) => {
      if (evaluation.insightId === insightID) {
        finalEvaluation = evaluation.peopleEvaluation;
      }
    });

    return finalEvaluation;
  };

  const getReadinessInfo = (readiness) => {
    let finalInfo = {};

    readinessOptions.forEach(({ value, color, label }) => {
      if (readiness === value) finalInfo = { label, color };
    });

    return finalInfo;
  };

  const TrailBadgeDiv = ({ title, path, readiness }) => {
    const readinessInfo = getReadinessInfo(readiness);
    return (
      <div className="average_tool pull-right" style={{ marginLeft: "5px" }}>
        <SubtitleBadge
          title={title + ": " + readinessInfo.label}
          caption={path === "JOB" ? "J" : "T"}
          color={readinessInfo.color}
        />
      </div>
    );
  };

  const displayAverageBadge = () => {
    const insightInfo = getInsight();
    const evaluations = getEvaluationsById(
      insightInfo && insightInfo.id ? insightInfo.id : 0,
      props.allPeopleEvaluation
    );
    const finalEvaluation =
      evaluations && evaluations.current ? evaluations.current : 0;
    return (
      <div className="averageBadgeScore">
        <AverageBadge
          hideColor
          borderedBadge
          width={"15px"}
          average={finalEvaluation}
        />
      </div>
    );
  };

  const displayCarrearPath = () => {
    const insightInfo = getInsight();
    let finalDisplay = [];

    if (insightInfo) {
      const insightEvaluation = getInsightEvaluation(insightInfo.id);
      if (insightEvaluation) {
        const { evaluationTarget } = insightEvaluation;
        const { carrearPath } = evaluationTarget;

        if (carrearPath) {
          if (carrearPath.mainTrail) {
            const Badge = (
              <TrailBadgeDiv
                path={carrearPath.mainTrail.path}
                readiness={carrearPath.mainTrail.readiness}
                title={carrearPathLabels.mainTrail}
              />
            );
            finalDisplay = [...finalDisplay, Badge];
          }

          if (carrearPath.secondTrail) {
            const Badge = (
              <TrailBadgeDiv
                path={carrearPath.secondTrail.path}
                readiness={carrearPath.secondTrail.readiness}
                title={carrearPathLabels.secondTrail}
              />
            );
            finalDisplay = [...finalDisplay, Badge];
          }

          if (carrearPath.thirdTrail) {
            const Badge = (
              <TrailBadgeDiv
                path={carrearPath.thirdTrail.path}
                readiness={carrearPath.thirdTrail.readiness}
                title={carrearPathLabels.thirdTrail}
              />
            );
            finalDisplay = [...finalDisplay, Badge];
          }

          if (carrearPath.fourthTrail) {
            const Badge = (
              <TrailBadgeDiv
                path={carrearPath.fourthTrail.path}
                readiness={carrearPath.fourthTrail.readiness}
                title={carrearPathLabels.fourthTrail}
              />
            );
            finalDisplay = [...finalDisplay, Badge];
          }

          if (carrearPath.fifthTrail) {
            const Badge = (
              <TrailBadgeDiv
                path={carrearPath.fifthTrail.path}
                readiness={carrearPath.fifthTrail.readiness}
                title={carrearPathLabels.fifthTrail}
              />
            );
            finalDisplay = [...finalDisplay, Badge];
          }
        }
      }
    }

    finalDisplay.reverse();

    return (
      <>
        {finalDisplay.map((BadgeItem, index) => (
          <span key={index}>{BadgeItem}</span>
        ))}
      </>
    );
  };

  const { hideBadge: hideActionsCounter, hideTitle: hideActionsCounterTitle } =
    getDefaultEvaluationBadgeConfig(from);

  return (
    <div
      className="goals-bonus-evaluation-container"
      style={{ display: "flex" }}
    >
      {insightsToCalc && insightsToCalc.length > 0 && (
        <div className="flex-between-center" style={{ marginRight: "10px" }}>
          {props.displayAverage && displayAverageBadge()}
          <div>
            {from === "answer" || from === "score"
              ? displayCarrearPath()
              : null}
          </div>
        </div>
      )}
      {!hideActionsCounter && (
        <ActionsCounterBadge
          actions={
            getTotalActionsByInsights(onlyInsightIds, allActionsPlans).length
          }
          hideTitle={hideActionsCounterTitle}
        />
      )}
    </div>
  );
};

PeopleBigPicture.propTypes = {
  from: PropTypes.any.isRequired,
  insightsToCalc: PropTypes.array.isRequired,
};

const mapStateToProps = (state) => {
  const { questionAnswers, peopleEvaluation } = state;

  const allAnswers =
    questionAnswers && questionAnswers.allAnswers
      ? questionAnswers.allAnswers
      : {};

  const allPeopleEvaluation =
    peopleEvaluation && peopleEvaluation.allPeopleEvaluation
      ? peopleEvaluation.allPeopleEvaluation
      : [];

  const { allActionsPlans = [] } = getActionsPlanStates(state);

  return {
    allAnswers,
    allPeopleEvaluation,
    allActionsPlans,
  };
};

export default injectIntl(connect(mapStateToProps, null)(PeopleBigPicture));
