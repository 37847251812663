import {
  getOnlySublevelArrays,
  getUpdatedObjectInArray,
} from "../../../../../../../utils/ArrayUtils";
import utils from "../../../../../../../utils/cpAnalysis";
import SimpleExcelCollumn from "../../../../../../Common/SimpleExcelCollumn";

const { getPestalCriteriaTranslation } = utils;

const EvaluationCriterias = (props) => {
  const {
    cpAnalysisAdministration = {},
    setCpAnalysisAdministration,
    getEmptyTableRow,
    getDefaultRemoveColumn,
    getDefaultAddTableLine,
    setConfirmationDialog,
    resetConfirmDialog,
    toggleOpenFormDialogs,
    deleteEvaluationCriteria,
    allAnswers,
    selectedTool,
  } = props;

  const { pestal = [] } = cpAnalysisAdministration || {};
  const { evaluationCriterias = [] } = pestal || {};

  const updatePestalField = (field = false, value = "") => {
    if (!field) return;

    const pestalConfig = cpAnalysisAdministration.pestal || {};

    setCpAnalysisAdministration({
      ...cpAnalysisAdministration,
      pestal: {
        ...pestalConfig,
        [field]: [...value],
      },
    });
  };

  const handleUpdateCriteria = (value = "", field = "", index = null) => {
    const updated = getUpdatedObjectInArray(
      value,
      evaluationCriterias,
      field,
      index
    );

    updatePestalField("evaluationCriterias", updated);
  };

  const handleDeleteCriteria = (index) => {
    const { id = "" } = evaluationCriterias[index];

    deleteEvaluationCriteria(id, "pestal", selectedTool.id);

    resetConfirmDialog();
  };

  const getBoardConnection = () => {
    let haveBoardConnection = false;

    const onlyAnswers = getOnlySublevelArrays(allAnswers);

    onlyAnswers.forEach((insight) => {
      const { question = {} } = insight;
      const { theme = {} } = question;
      const { cenary = {} } = theme;
      const { name = "" } = cenary;

      if (name.toUpperCase() === "PESTAL") haveBoardConnection = true;
    });

    return haveBoardConnection;
  };

  const openDeleteCriteria = (index) => {
    const checkBoardConnection = getBoardConnection();

    if (checkBoardConnection)
      return setConfirmationDialog({
        title:
          "Você precisa apagar todos os insights do Cenário PESTAL para continuar com a exclusão.",
        onConfirm: () => resetConfirmDialog(),
        onCancel: () => resetConfirmDialog(),
        open: true,
      });

    setConfirmationDialog({
      title: "Você tem certeza?",
      onConfirm: () => handleDeleteCriteria(index),
      onCancel: () => resetConfirmDialog(),
      open: true,
    });
  };

  const maxLength = evaluationCriterias.length > 2;

  return (
    <table className="simple-table">
      <thead>
        <tr>
          <td colSpan="2">Titulo</td>
          <td />
        </tr>
      </thead>
      <tbody>
        {evaluationCriterias.map((criteriaInfo, index) => {
          const title = getPestalCriteriaTranslation(criteriaInfo.title || "");

          return (
            <tr className="block-hover" key={index}>
              <td align="center" style={{ width: "40px" }}>
                {index + 1}
              </td>
              <SimpleExcelCollumn
                id="criteriaTitle"
                label="Titulo"
                type="text"
                value={title}
                onChange={(e) =>
                  handleUpdateCriteria(e.target.value, "title", index)
                }
              />
              {getDefaultRemoveColumn(() => openDeleteCriteria(index))}
            </tr>
          );
        })}
        {getEmptyTableRow(evaluationCriterias, "Nada para mostrar!")}
        {!maxLength &&
          getDefaultAddTableLine(() =>
            toggleOpenFormDialogs("newPestalCriteria")
          )}
      </tbody>
    </table>
  );
};

export default EvaluationCriterias;
