import { useState, useEffect } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import utils from "../../../../utils/toolUtils";
import okrUtils from "../../../../utils/okr";
import { updateTheme } from "../../../../actions/themesActions";

const {
  getObjectDifference,
  getObjectInfoById,
  getOnlySlugValues,
  removeObjSlug,
} = utils;

const { biuldAdmRestBody, getAllCyclesFromPlans } = okrUtils;

const defaultAllCicles = [];

const OkrWatcher = (props) => {
  const [okrCicles, setOkrCicles] = useState(defaultAllCicles);
  const [firstValidation, setFirstValidation] = useState(false);

  const {
    originOkrPlans = [],
    selectedTool = {},
    allThemes = {},
    updateTheme,
  } = props;

  useEffect(() => {
    const restOkrAdministration = biuldAdmRestBody({ plans: originOkrPlans });
    const restOkrPlansCicles = getAllCyclesFromPlans(
      restOkrAdministration.plans
    );
    const isAdmObjDiff = !_.isEqual(restOkrPlansCicles, okrCicles);

    if (restOkrPlansCicles && Object.keys(allThemes).length > 0) {
      if (isAdmObjDiff || !firstValidation) {
        const compareBase = removeObjSlug(okrCicles, "id");

        const diff = getObjectDifference(restOkrPlansCicles, compareBase);

        if (diff) {
          if (!firstValidation) setFirstValidation(true);

          const watchCicles = getOnlySlugValues(diff, "id");
          if (watchCicles.length > 0) {
            Object.keys(allThemes).forEach((scenaryId) => {
              const cenaryThemes = allThemes[scenaryId];

              cenaryThemes.forEach((themeInfo) => {
                const { okrConfigurationCycleId } = themeInfo;

                const relatedAdmCicleId =
                  watchCicles[watchCicles.indexOf(okrConfigurationCycleId)] ||
                  null;

                if (relatedAdmCicleId && themeInfo.name) {
                  const admCicleInfo = getObjectInfoById(
                    relatedAdmCicleId,
                    restOkrPlansCicles,
                    "id"
                  );

                  if (admCicleInfo && admCicleInfo.title !== themeInfo.name) {
                    updateTheme(
                      {
                        ...themeInfo,
                        name: admCicleInfo.title,
                      },
                      themeInfo.cenary.id
                    );
                  }
                }
              });
            });
          }
        }

        if (isAdmObjDiff) setOkrCicles(restOkrPlansCicles);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [originOkrPlans, allThemes, firstValidation, selectedTool.id]);

  return null;
};

const mapStateToProps = (state) => {
  const { okr } = state;

  return { originOkrPlans: okr.okrPlans };
};

export default connect(mapStateToProps, { updateTheme })(OkrWatcher);
