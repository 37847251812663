import { getSwotStructure } from "../../../../../../../utils/cpAnalysis/entity";
import SingleTable from "../SingleTable";

const SwotManagement = ({ swotData = [], intl, selectAndOpenInsight }) => {
  const {
    strongPoints = {},
    weakPoints = {},
    opportunities = {},
    threats = {},
    headers = [],
  } = getSwotStructure(swotData);

  const essentialProps = { intl, headers, selectAndOpenInsight };

  return (
    <div>
      <div className="row">
        <div className="col-xs-12 col-lg-12" style={{ padding: "0px" }}>
          <SingleTable
            title="Pontos Fortes"
            {...essentialProps}
            data={strongPoints.evaluations}
            result={strongPoints.result}
            colorClass="pipe_tool_green"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-lg-12" style={{ padding: "0px" }}>
          <SingleTable
            title="Pontos Fracos"
            {...essentialProps}
            data={weakPoints.evaluations}
            result={weakPoints.result}
            colorClass="pipe_tool_red"
          />
        </div>
      </div>

      <div className="row">
        <div className="col-xs-12 col-lg-12">
          <SingleTable
            title="Oportunidades"
            {...essentialProps}
            data={opportunities.evaluations}
            result={opportunities.result}
            colorClass="pipe_tool_green"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-xs-12 col-lg-12">
          <SingleTable
            title="Ameaças"
            {...essentialProps}
            data={threats.evaluations}
            result={threats.result}
            colorClass="pipe_tool_red"
          />
        </div>
      </div>
    </div>
  );
};

export default SwotManagement;
