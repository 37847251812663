import React, { useState, useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { defineMessages } from "react-intl";
import _ from "lodash";

import {
  getConcurrenceScaleTranslation,
  getConcurrenceCriteriaTranslation,
} from "../../../../utils/cpAnalysis/display";
import utils from "../../../../utils/cpAnalysis";
import CropImage from "../../../Profile/CropImage";
import FormDialog from "../../../Common/FormDialog";
import { b64toBlob } from "../../../../utils/Base64Utils";
import { getAllExcept } from "../../../../utils/ArrayUtils";
import { checkAccess } from "../../../../utils/accessLevels";
import MaterialTextField from "../../../Common/MaterialTextField";
import MaterialSingleSelect from "../../../Common/MaterialSingleSelect";
import SimpleManagementList from "../../../Common/SimpleManagementList";

const {
  getCompanyDetails,
  handleEmptyConfiguration,
  getConcurrenceEvaluations,
  getConcurrenceEvaluationFields,
} = utils;

const defaultSpecializationForm = {
  title: "",
};

const getEmptyConfiguration = (evaluationCriterias = [], scales = []) => {
  const scaleConfiguration = scales.length > 1;

  if (evaluationCriterias.length > 0 && scaleConfiguration) return false;

  return true;
};

const ConcurrenceEvaluation = ({
  insight,
  currentToolType = {},
  currentAdministration = {},
  currentInsightEvaluation = {},
  sendCompetitiveAnalysisEvaluation,
  sendCompanyDetails,
  uploadLogo,
  intl,
  selectedToolId,
}) => {
  const [editor, setEditor] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [cropModal, setCropModal] = useState(false);
  const [evaluations, setEvaluations] = useState({});
  const [companyDetails, setCompanyDetails] = useState({});
  const [specializationForm, setSpecializationForm] = useState(
    defaultSpecializationForm
  );
  const [showSpecializationDialog, setShowSpecializationDialog] =
    useState(false);
  const [editSpecializationModal, setEditSpecializationModal] = useState(false);

  const messages = defineMessages({
    title: {
      id: "profile.title",
    },
    subtitle: {
      id: "profile.subtitle",
    },
    tab_config: {
      id: "profile.tab.config",
    },
    tab_delete: {
      id: "profile.tab.delete",
    },
    confirmText: {
      id: "btn_modal_confirm",
    },
    cancelText: {
      id: "btn_modal_cancel",
    },
    selectImageArea: {
      id: "select_image_area",
    },
  });

  useEffect(() => {
    if (currentInsightEvaluation && !currentInsightEvaluation.id) {
      handleFirstSave();
      return;
    }

    const copyInsightEvaluation = _.cloneDeep(currentInsightEvaluation);

    const syncEvaluation = getConcurrenceEvaluations(copyInsightEvaluation);

    const syncCompanyDetails = getCompanyDetails(copyInsightEvaluation);

    setCompanyDetails({
      ...syncCompanyDetails,
      specializations: [...syncCompanyDetails.specializations],
    });

    setEvaluations(syncEvaluation);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentInsightEvaluation]);

  const { evaluationCriterias = [], scales = [] } =
    getConcurrenceEvaluationFields(currentAdministration, currentToolType);

  const emptyConfiguration = getEmptyConfiguration(evaluationCriterias, scales);

  const scalesOptions = scales.map((scale) => {
    return {
      value: scale.id,
      label: getConcurrenceScaleTranslation(scale.title),
    };
  });

  const handleChangeEvaluation = ({ id }, { value }) => {
    setEvaluations({ ...evaluations, [id]: value });
  };

  const getFirstEvaluationBody = () => {
    return evaluationCriterias.map(({ id: evaluationCriteriaId }) => {
      const scale = Number(evaluations[evaluationCriteriaId]);

      return {
        evaluationCriteriaId,
        scaleId: scale === 0 ? null : scale,
        type: "CONCURRENCE",
      };
    });
  };

  const handleFirstSave = async () => {
    await sendCompetitiveAnalysisEvaluation(
      insight.id,
      {
        type: "CONCURRENCE",
        evaluations: getFirstEvaluationBody(),
      },
      selectedToolId
    );
  };

  const searchEvaluation = (evaluationCriteriaId = 0) => {
    const { concurrence = [] } = currentInsightEvaluation;

    return (
      concurrence.filter(
        (evaluation) =>
          Number(evaluation.evaluationCriteriaId) ===
          Number(evaluationCriteriaId)
      )[0] || false
    );
  };

  const getRestEvaluationBody = () => {
    let restEvaluationBody = [];
    let reSendEvaluation = false;

    Object.keys(evaluations).forEach((criteriaId) => {
      const scaleId = evaluations[criteriaId];

      const { scaleId: currentScaleId, id = false } =
        searchEvaluation(criteriaId);

      if (!id) reSendEvaluation = true;
      if (Number(currentScaleId) === Number(scaleId)) return;

      restEvaluationBody.push({
        evaluationCriteriaId: Number(criteriaId),
        scaleId,
        id,
        type: "CONCURRENCE",
      });
    });

    return { restEvaluationBody, reSendEvaluation };
  };

  const handleEvaluationSave = () => {
    const { restEvaluationBody = [], reSendEvaluation = false } =
      getRestEvaluationBody();

    sendCompetitiveAnalysisEvaluation(
      insight.id,
      {
        type: "CONCURRENCE",
        evaluations: reSendEvaluation
          ? getFirstEvaluationBody()
          : restEvaluationBody,
      },
      selectedToolId,
      reSendEvaluation ? "POST" : "PUT"
    );
  };

  const handleCompanyDetailsSave = async (sendDispatch = true) => {
    const restBody = { ...companyDetails };

    const { id: competitiveAnalysisId } = currentInsightEvaluation || {};

    await sendCompanyDetails(
      competitiveAnalysisId,
      restBody,
      insight.id,
      sendDispatch
    );
  };

  const canEvaluationSaveAppears = () => {
    if (emptyConfiguration) return false;

    const syncEvaluation = getConcurrenceEvaluations(currentInsightEvaluation);

    if (_.isEqual(syncEvaluation, evaluations)) return false;

    return true;
  };

  const canSaveAppears = () => {
    if (!canEvaluationSaveAppears() && !canCompanyDetailsSaveAppears())
      return false;

    return true;
  };

  const canCompanyDetailsSaveAppears = () => {
    const syncCompanyDetails = getCompanyDetails(currentInsightEvaluation);

    if (_.isEqual(syncCompanyDetails, companyDetails)) return false;

    return true;
  };

  const handleSaveAll = async () => {
    await handleCompanyDetailsSave(true);
    await handleEvaluationSave();
  };

  const evaluationSaveHandler = () => {
    if (!canSaveAppears()) return null;

    return (
      <Button
        className="btn btn-purple pull-right"
        onClick={() => handleSaveAll()}
      >
        Salvar
      </Button>
    );
  };

  const resetFormHideDialog = () => {
    setSpecializationForm(defaultSpecializationForm);
    setShowSpecializationDialog(false);
  };

  const handleCancel = () => {
    resetFormHideDialog();
  };

  const handleConfirm = () => {
    let updated = companyDetails.specializations;
    updated.push(specializationForm);

    setCompanyDetails({ ...companyDetails, specializations: [...updated] });
    resetFormHideDialog();
  };

  const specializationFormChange = (value = "") => {
    setSpecializationForm({ ...specializationForm, title: value });
  };

  const handleFormChange = (field = "", value = "") => {
    setCompanyDetails({ ...companyDetails, [field]: value });
  };

  const handleRemoveSpecialization = (itemInfo = {}) => {
    const { specializations = [] } = companyDetails;

    const updatedList = getAllExcept(
      [itemInfo.title],
      specializations,
      "title"
    );

    setCompanyDetails({ ...companyDetails, specializations: updatedList });
  };

  const handleEditSpecialization = (itemInfo = {}, index = "") => {
    setEditSpecializationModal({
      open: true,
      itemInfo,
      index,
    });
  };

  const callFormModal = () => {
    setShowSpecializationDialog(true);
  };

  const newSpecializationDialog = () => {
    return (
      <FormDialog
        open={showSpecializationDialog}
        title={"Nova Especialização"}
        onConfirm={handleConfirm}
        onCancel={handleCancel}
      >
        <div className="row">
          <div className="col-xs-12">
            <MaterialTextField
              id="title"
              label={"Título"}
              value={specializationForm.title}
              onChange={(e) => specializationFormChange(e.target.value)}
            />
          </div>
        </div>
      </FormDialog>
    );
  };

  const SelectEvaluationFields = (props) => {
    return (
      <div className="box box-primary">
        <div className="box-header with-border">
          <i className="fas fa-tasks" />
          <h3 className="box-title">Avaliação Concorrente</h3>
          {evaluationSaveHandler()}
        </div>
        <div className="box-body">
          <Row>
            {evaluationCriterias.map((criteria, index) => {
              const { id } = criteria;

              return (
                <Col md={6} key={index}>
                  <MaterialSingleSelect
                    id={criteria.title}
                    label={getConcurrenceCriteriaTranslation(criteria.title)}
                    value={evaluations[id] || ""}
                    options={scalesOptions}
                    onChange={(e) =>
                      checkAccess([
                        "CREATOR",
                        "RESPONSIBLE",
                        "COLLABORATOR",
                        "MANAGER",
                        "LEADER",
                        "MODERATOR",
                        "ADMIN",
                        "OWNER",
                      ]) && handleChangeEvaluation(criteria, e.target)
                    }
                  />
                </Col>
              );
            })}
          </Row>
          <Row>{handleEmptyConfiguration(emptyConfiguration)}</Row>
        </div>
      </div>
    );
  };

  const showCompanyDetailsForm = () => {
    const { specializations = [] } = companyDetails || {};

    return (
      <>
        <div className="box box-primary">
          <div className="box-header with-border">
            <i className="fas fa-tasks" />
            <h3 className="box-title">Dados Gerais</h3>
            {evaluationSaveHandler()}
          </div>
          <div className="box-body">
            <Row>
              <Col md={4}>{LogoForm()}</Col>
              <Col md={8}>
                <Row>
                  <MaterialTextField
                    id="name"
                    type="text"
                    label="Nome"
                    inputStyle={{
                      fontSize: "16px",
                      padding: "15px 10px",
                    }}
                    value={companyDetails.name}
                    onChange={(e) => handleFormChange("name", e.target.value)}
                  />
                </Row>
                <Row>
                  <MaterialTextField
                    id="site"
                    type="text"
                    label="Site"
                    inputStyle={{
                      fontSize: "16px",
                      padding: "15px 10px",
                    }}
                    value={companyDetails.site}
                    onChange={(e) => handleFormChange("site", e.target.value)}
                  />
                </Row>
                <Row>
                  <MaterialTextField
                    id="address"
                    type="text"
                    label="Endereço"
                    inputStyle={{
                      fontSize: "16px",
                      padding: "15px 10px",
                    }}
                    value={companyDetails.address}
                    onChange={(e) =>
                      handleFormChange("address", e.target.value)
                    }
                  />
                </Row>
                <Row>
                  <Col md={6} style={{ padding: "0px 5px 0px 0px" }}>
                    <MaterialTextField
                      id="foundationYear"
                      type="text"
                      label="Ano Fundação"
                      inputStyle={{
                        fontSize: "16px",
                        padding: "15px 10px",
                      }}
                      value={companyDetails.foundationYear || ""}
                      onChange={(e) =>
                        handleFormChange("foundationYear", e.target.value)
                      }
                    />
                  </Col>
                  <Col md={6} style={{ padding: "0px" }}>
                    <MaterialTextField
                      id="employees"
                      type="text"
                      label="Funcionários"
                      inputStyle={{
                        fontSize: "16px",
                        padding: "15px 10px",
                      }}
                      value={companyDetails.employees}
                      onChange={(e) =>
                        handleFormChange("employees", e.target.value)
                      }
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md={4}></Col>
              <Col md={8}>
                <div className="specialization-title">
                  <span>Especializações</span>
                  <div className="box-tools pull-right">
                    <button
                      type="button"
                      className="btn btn-box-tool"
                      onClick={() => callFormModal()}
                    >
                      <i className="fa fa-plus" />
                    </button>
                  </div>
                </div>
                <div className="specializations-view">
                  <SimpleManagementList
                    items={specializations}
                    handleRemove={(itemInfo) =>
                      handleRemoveSpecialization(itemInfo)
                    }
                    handleEdit={(info, index) =>
                      handleEditSpecialization(info, index)
                    }
                  />
                </div>
              </Col>
              <Col md={6}></Col>
            </Row>
          </div>
        </div>
      </>
    );
  };

  const handleSelectNewAvatar = (fileChangeEvent) => {
    const file = fileChangeEvent.target.files[0] || {};
    const { type } = file;

    if (type) {
      if (
        type.endsWith("jpeg") ||
        type.endsWith("jpg") ||
        type.endsWith("png") ||
        type.endsWith("gif")
      ) {
        setImageFile(file);
      }
    } else {
      handleToggleCropModal(false);
    }
  };

  const handleToggleCropModal = (setOpen = false) => {
    setCropModal(setOpen);
  };

  const handleToggleSpecializationModal = (setOpen = false) => {
    setEditSpecializationModal({ ...editSpecializationModal, open: setOpen });
  };

  const ImgRender = () => {
    const { logo = false } = companyDetails;

    const styleConfig = {
      width: "162px",
      height: "162px",
      padding: "3px",
      borderRadius: "50%",
      border: "3px solid #6b42a9",
      marginTop: "10px",
    };

    if (logo) return <img src={logo} alt="" style={styleConfig} />;

    return <div style={styleConfig} />;
  };

  const LogoForm = () => {
    return (
      <>
        {ImgRender()}
        <div>
          <label
            className="labelInputFile patternPurpleButton"
            htmlFor="inputNewAvatar"
          >
            Selecionar Logo
          </label>

          <input
            id="inputNewAvatar"
            className="inputFile"
            type="file"
            accept="image/png, image/jpeg, image/gif"
            value={null}
            onClick={() => handleToggleCropModal(true)}
            onChange={(e) => handleSelectNewAvatar(e)}
          />
        </div>
      </>
    );
  };

  const setEditorRef = (editor) => setEditor(editor);

  const toggleCloseCrop = () => {
    onCrop();
    handleToggleCropModal(false);
  };

  const toggleCloseEditSpecialization = () => {
    const { itemInfo = "", index = "" } = editSpecializationModal;
    const { specializations = [] } = companyDetails;

    let newSpecializations = [...specializations];

    newSpecializations[index] = itemInfo;

    setCompanyDetails({
      ...companyDetails,
      specializations: [...newSpecializations],
    });

    handleToggleSpecializationModal(false);
  };

  const onCrop = async () => {
    if (editor === null) return;

    const url = editor.getImageScaledToCanvas().toDataURL();

    var block = url.split(";");
    var contentType = block[0].split(":")[1];
    var realData = block[1].split(",")[1];
    var blob = b64toBlob(realData, contentType);
    var formDataToUpload = new FormData();
    formDataToUpload.append("file", blob, "logoConcorrente.jpg");

    let uploadReturn = await uploadLogo(formDataToUpload, "IMAGES");
    uploadReturn = String(
      "https://splace-images.s3-sa-east-1.amazonaws.com/" + uploadReturn
    );
    setCompanyDetails({ ...companyDetails, logo: uploadReturn });
  };

  const specializationEditFormChange = (newValue = "") => {
    const { itemInfo = {} } = editSpecializationModal;

    setEditSpecializationModal({
      ...editSpecializationModal,
      itemInfo: { ...itemInfo, title: newValue },
    });
  };

  const editSpecializationDialog = () => {
    const { itemInfo = {} } = editSpecializationModal;
    const { title = "" } = itemInfo;

    return (
      <FormDialog
        open={editSpecializationModal.open}
        title={"Editar Especialização"}
        confirmText={intl.formatMessage(messages.confirmText)}
        cancelText={intl.formatMessage(messages.cancelText)}
        onCancel={() => handleToggleSpecializationModal(false)}
        onConfirm={toggleCloseEditSpecialization}
      >
        <div className="row">
          <div className="col-xs-12">
            <MaterialTextField
              id="title"
              label={"Título"}
              value={title}
              onChange={(e) => specializationEditFormChange(e.target.value)}
            />
          </div>
        </div>
      </FormDialog>
    );
  };

  return (
    <>
      <SelectEvaluationFields />
      {showCompanyDetailsForm()}
      {newSpecializationDialog()}
      {editSpecializationDialog()}
      <FormDialog
        open={cropModal}
        title={intl.formatMessage(messages.selectImageArea)}
        confirmText={intl.formatMessage(messages.confirmText)}
        cancelText={intl.formatMessage(messages.cancelText)}
        onCancel={() => handleToggleCropModal(false)}
        onConfirm={toggleCloseCrop}
      >
        <CropImage
          imageSrc={imageFile}
          setEditorRef={setEditorRef}
          onCrop={onCrop}
          scaleValue={1}
        />
      </FormDialog>
    </>
  );
};

export default ConcurrenceEvaluation;
