import { Popover } from "react-bootstrap";
import { defineMessages } from "react-intl";

import _ from "lodash";
import {
  ACTION_PLAN_ID,
  GOALS_AND_BONUS,
  PEOPLE_BIG_PICTURE,
  PROJECT_PORTFOLIO,
  SKILLS_ASSESSMENT,
} from "../constants/tools.constants";

import {
  getAccessStates,
  getActionsPlanStates,
  getAlternateToolStates,
  getGoalsBonusStates,
  getInsightStates,
  getProjectStates,
  getSceneryStates,
  getSelectedCompanyStates,
  getSelectedToolStates,
  getSkillAssessmentStates,
  getWorkspacesStates,
} from "../components/customMapStates";

import {
  fetchEntityUsersWithRoleWithReturn,
  getUserAccessWithReturn,
} from "../actions/accessLevelsActions";
import { getEncryptedLinkBy } from "../actions/appActions";
import { getPeopleEvaluation } from "../actions/peopleEvaluationActions";
import { getProjectEvaluation } from "../actions/projectEvaluationActions";
import { COMPANY, DOMAIN, INSIGHT, TOOL, WORKSPACE } from "../actions/types";
import store from "../Store";
import { getInsightActionsPlanRelatedUsers } from "./actionsPlan/filter";
import { getObjectInfoById } from "./ArrayUtils";
import { getGoalsBonusBySelectedToolId } from "./goalsBonus/filter";
import { peopleToolUsers } from "./peopleEvaluationUtils";
import { projectToolUsers } from "./projectUtils";
import { getSkillAssessmentCollaborators } from "./skillsAssessment/access";
import { translatedText } from "./translationUtils";

const translation = (id, values) => translatedText(id, messages, values);

const messages = defineMessages({
  member: {
    id: "global.member",
  },
  participant: {
    id: "global.participant",
  },
  type: {
    id: "global.type",
  },
  manageUsersPermissions: {
    id: "manageUsersPermissions",
  },
  youCantDeleteLast: {
    id: "youCantDeleteLast",
  },
  add: {
    id: "global.add",
  },
  delete: {
    id: "global.remove",
  },
});

const getUserAccess = () => {
  const state = store.getState();

  const { userAccess = {} } = getAccessStates(state);

  return userAccess;
};

const getAccess = () => {
  const state = store.getState();

  const { access = {} } = getAccessStates(state);

  return access;
};

const handleOwnerAccess = () => {
  const { domain } = getUserAccess();

  if (domain) return true;

  return false;
};

const handleAdminAccess = () => {
  const state = store.getState();

  const { companies = [] } = getUserAccess();

  const { selectedCompany = {} } = getSelectedCompanyStates(state);

  const { id = 0 } = selectedCompany || {};

  return entityRoleExists(companies, "ADMIN", id);
};

const handleModeratorAccess = () => {
  const state = store.getState();

  const { workspaces = [] } = getUserAccess();

  const { workspacesData = {} } = state;

  const { selectedWorkspace = {} } = workspacesData;

  const { id = 0 } = selectedWorkspace || {};

  return entityRoleExists(workspaces, "MODERATOR", id);
};

const handleManagerAccess = () => {
  const state = store.getState();

  const { tools = [] } = getUserAccess();

  const { selectedToolID = 0 } = getAlternateToolStates(state);

  return entityRoleExists(tools, "MANAGER", selectedToolID);
};

const handleLeaderAccess = (scenarioId = null) => {
  const state = store.getState();

  const { selectedSceneryID = 0 } = getSceneryStates(state);

  const { scenarios = [] } = getUserAccess();

  const currentScenery = scenarioId || selectedSceneryID;

  return entityRoleExists(scenarios, "LEADER", currentScenery);
};

const handleCreatorAccess = (insightId = null) => {
  let entityId = insightId;

  const selectedInsight = getSelectedInsight();

  if (!insightId && selectedInsight) entityId = selectedInsight.id;

  const { insights = [] } = getUserAccess();

  return entityRoleExists(insights, "CREATOR", entityId);
};

const handleResponsibleAccess = (insightId = null) => {
  let entityId = insightId;
  const selectedInsight = getSelectedInsight();

  if (!insightId && selectedInsight) entityId = selectedInsight.id;

  const { insights = [] } = getUserAccess();

  return entityRoleExists(insights, "RESPONSIBLE", entityId);
};

const handleCollaboratorAccess = (insightId = null) => {
  let entityId = insightId;

  const selectedInsight = getSelectedInsight();

  if (!insightId && selectedInsight) entityId = selectedInsight.id;

  const { insights = [] } = getUserAccess();

  return entityRoleExists(insights, "COLLABORATOR", entityId);
};

const handleToolViewerAccess = () => {
  const state = store.getState();

  const { tools = [] } = getUserAccess();

  const { selectedToolID = 0 } = getAlternateToolStates(state);

  return entityRoleExists(tools, "VIEWER", selectedToolID);
};

const handleSceneryViewerAccess = (sceneryId = null) => {
  const state = store.getState();

  const { selectedSceneryID = 0 } = getSceneryStates(state);

  const currentSceneryId = sceneryId || selectedSceneryID;

  const { scenarios = [] } = getUserAccess();

  return entityRoleExists(scenarios, "VIEWER", currentSceneryId);
};

export const checkAccess = (requiredAccess = [], child, id = 0) => {
  const validated = Array.isArray(requiredAccess) ? [...requiredAccess] : [];

  const { lockdown = false } = getAccess();

  if (!lockdown) {
    if (child) return child;

    return true;
  }

  let verify = false;

  validated.forEach((access) => {
    if (!verify)
      switch (access.toUpperCase()) {
        case "OWNER":
          verify = handleOwnerAccess();
          break;
        case "ADMIN":
          verify = handleAdminAccess();
          break;
        case "MODERATOR":
          verify = handleModeratorAccess();
          break;
        case "MANAGER":
          verify = handleManagerAccess();
          break;
        case "LEADER":
          verify = handleLeaderAccess(id);
          break;
        case "CREATOR":
          verify = handleCreatorAccess(id);
          break;
        case "RESPONSIBLE":
          verify = handleResponsibleAccess(id);
          break;
        case "COLLABORATOR":
          verify = handleCollaboratorAccess(id);
          break;
        case "TOOLVIEWER":
          verify = handleToolViewerAccess();
          break;
        case "SCENERYVIEWER":
          verify = handleSceneryViewerAccess(id);
          break;
        default:
          break;
      }
  });

  if (child && verify) return child;

  return verify;
};

export const getNotificationReceiversInfo = ({
  receivers = [],
  everyoneReceives = false,
  senderId = "",
  entityType = "",
}) => {
  const entityAccess = getEntityAccess(entityType.toLowerCase()).filter(
    (access) => access.id !== senderId
  );

  const getAllAccesses = () => {
    return entityAccess.map(({ email, id }) => {
      return { id, email };
    });
  };

  const getAccessesByRole = () => {
    const roleReceivers = receivers.filter((receiver) => isNaN(receiver));
    const idReceivers = receivers.filter((receiver) => !isNaN(receiver));

    const filteredUsersByRole = entityAccess.filter((access) => {
      return roleReceivers.indexOf(access.entityRole.role) !== -1;
    });

    const filteredUsersById = entityAccess.filter((access) => {
      return idReceivers.indexOf(access.id) !== -1;
    });

    return [...filteredUsersByRole, ...filteredUsersById].map(
      ({ email, id }) => {
        return { id, email };
      }
    );
  };

  if (everyoneReceives) return _.uniqBy(getAllAccesses(), "id");

  return _.uniqBy(getAccessesByRole(), "id");
};

export const checkRedirect = () => {
  const fullHostUrl = window.location.host;

  if (!isLocalHost(fullHostUrl)) return true;

  return false;
};

export const isDefaultNamespace = (namespace) => {
  const defaultNamespaces = ["app", "dev", "hml"];

  return defaultNamespaces.includes(namespace);
};

export const isLocalHost = (host) => {
  const defaultLocalhostUrls = [
    "localhost",
    "localhost:3000",
    "127.0.0.1",
    "127.0.0.1:3000",
  ];

  return defaultLocalhostUrls.includes(host);
};

export const handleCustomDomain = (allUserDomains = []) => {
  const selectedDomainId = localStorage.getItem("SELECTED_DOMAIN_ID");

  if (!allUserDomains) return null;

  if (selectedDomainId && selectedDomainId !== "false")
    return allUserDomains.filter(
      (domain) => Number(domain.id) === Number(selectedDomainId)
    )[0];

  let customDomain = null;

  const namespace = getCurrentNamespace();

  if (!namespace) return allUserDomains[0];

  allUserDomains.forEach((domain) => {
    if (domain.namespace.includes(namespace)) customDomain = { ...domain };
  });

  return customDomain;
};

export const getNamespaceConfig = () => {
  const fullHostUrl = window.location.host;

  const parts = fullHostUrl.split(".");

  const namespace = parts[0];

  if (isLocalHost(fullHostUrl) || isDefaultNamespace(namespace))
    return { isAppHmlorDev: true, namespace };

  return { isCustomUrl: true, namespace };
};

export const getPlatformLevels = () => {
  const {
    selectedToolID = 0,
    selectedDomainID = 0,
    selectedCompanyID = 0,
    selectedInsightID = 0,
    selectedWorkspaceID = 0,
  } = getCascadeIDs();

  const selectedToolId = selectedToolID;
  const domainId = selectedDomainID;
  const companyId = selectedCompanyID;
  const insightId = selectedInsightID;
  const workspaceId = selectedWorkspaceID;

  const toolLevel = {
    selectedToolId,
    domainId,
    companyId,
    workspaceId,
  };

  return {
    DOMAIN: { domainId, target: DOMAIN },
    COMPANY: { domainId, companyId, target: COMPANY },
    WORKSPACE: {
      domainId,
      companyId,
      workspaceId,
      target: WORKSPACE,
    },
    SELECTED_TOOL: { ...toolLevel, target: TOOL },
    CENARY: toolLevel,
    INSIGHT: { ...toolLevel, insightId, target: INSIGHT },
  };
};

export const getCurrentNamespace = (defaultOption = false) => {
  const fullHostUrl = window.location.host;

  if (isLocalHost(fullHostUrl)) return false;

  const parts = fullHostUrl.split(".");

  const namespace = parts[0] || false;

  if (!namespace) return false;

  if (defaultOption) return namespace;

  if (!isDefaultNamespace(namespace)) return namespace;

  return false;
};

export const getDomainLink = () => {
  const namespace = getCurrentNamespace(true);

  if (!namespace) return "http://localhost:3000";

  return `https://${namespace}.rookau.com`;
};

export const getCustomUrlLink = async (level = false) => {
  const domainLink = getDomainLink();

  if (!level) return domainLink;

  const levels = getPlatformLevels();

  const currentLevel = levels[level] || {};

  const concatedLink = `${domainLink}/#/redirect`;

  const encryptedLink = await getEncryptedLinkBy({
    ...currentLevel,
    details: { pathUrl: concatedLink },
  });

  return encryptedLink;
};

export const whereUserIs = () => {
  const {
    companyData = {},
    workspacesData = {},
    selectedTool = {},
    selectedCenary = {},
    selectedInsight: insightData = {},
    access = {},
  } = store.getState();

  const {
    currentDomain: selectedDomain = {},
    userDomains = [],
    domainUsers = [],
  } = access || {};
  const { selectedCompany = {} } = companyData || {};
  const { selectedWorkspace = {} } = workspacesData || {};
  const { selectedInsight = {} } = insightData || {};

  // Arrumar atualizações de states ao sair do insight chamar unselectInsight() e afins...

  return {
    selectedCompany,
    selectedWorkspace,
    selectedTool,
    selectedCenary,
    selectedInsight,
    selectedDomain,
    userDomains,
    domainUsers,
  };
};

export const canUserAccessCompany = () => {
  if (checkAccess(["OWNER"])) return true;

  const state = store.getState();

  const { selectedCompany = {} } = getSelectedCompanyStates(state);

  const { companies = [] } = getUserAccess();

  return entityAccessExists(companies, selectedCompany.id);
};

export const canUserAccessWorkspace = () => {
  if (checkAccess(["OWNER", "ADMIN"])) return true;

  const state = store.getState();

  const { selectedWorkspaceID = null } = getWorkspacesStates(state);

  const { workspaces = [] } = getUserAccess();

  return entityAccessExists(workspaces, selectedWorkspaceID);
};

export const getEntityAccess = (type = null) => {
  const state = store.getState();

  const { entityAccess = {} } = getAccessStates(state);

  if (type && entityAccess[type])
    return entityAccess[type].filter((user) => user.id !== null);

  return [];
};

export const getAllEntityAccess = () => {
  const state = store.getState();

  const accessStates = getAccessStates(state);

  const { entityAccess = {} } = accessStates;

  return entityAccess;
};

export const getPrevUserUpdateStatus = (
  insightId = false,
  userId = false,
  role = ""
) => {
  const customRoles = userToolCustomRoles(userId, role);

  if (insightId || !userId || customRoles) return {};

  const removeBody = {
    entityId: insightId,
    entityType: "INSIGHT",
    role,
  };

  const { domainUserId = 0 } = getDomainInfo(userId);

  return {
    removeBody,
    domainUserId,
  };
};

export const getNewUserUpdateStatus = (
  userId = false,
  role = "",
  access = "EDIT"
) => {
  const customRoles = userToolCustomRoles(userId, role);

  if (!userId || customRoles) return {};

  return [
    ...getCascadeUpInfo(userId, 0, {
      passEntityRole: role,
      passEntityAccess: access,
    }),
  ];
};

export const getToolID = () => {
  const state = store.getState();

  const { selectedTool = {} } = getSelectedToolStates(state);
  const { tool = {} } = selectedTool;
  const { id: toolID = null } = tool || {};

  return toolID;
};

export const getCurrentDomainUsers = () => {
  const state = store.getState();

  const { domainUsers = [] } = getAccessStates(state);

  return domainUsers;
};

export const getUserById = ({ id }) => {
  const users = getCurrentDomainUsers();

  const userFiltered = users.find((user) => user.id === id);

  return userFiltered;
};

const getInsightActionPlanUsers = (insightId = null) => {
  const state = store.getState();

  const { allActionsPlans = [] } = getActionsPlanStates(state);

  return getInsightActionsPlanRelatedUsers(insightId, allActionsPlans);
};

const getParticipantsInfo = (allParticipants = []) => {
  return allParticipants.map(({ memberParticipant }) => {
    const { id = 0 } = memberParticipant || {};

    return { id, role: "MANAGER", access: "EDIT" };
  });
};

const getAdmRolesInfo = (allAdmRoles = []) => {
  let adminsInfo = [];

  allAdmRoles.forEach(({ members }) => {
    members.forEach((member) => {
      adminsInfo = [
        ...adminsInfo,
        { id: member, role: "MANAGER", access: "EDIT" },
      ];
    });
  });

  return adminsInfo;
};

const getGoalsAndBonusToolUsers = () => {
  const state = store.getState();

  const { selectedToolID = 0 } = getSelectedToolStates(state);

  const { allGoalBonusAdministration = [] } = getGoalsBonusStates(state);

  const currentGbAdministration = getGoalsBonusBySelectedToolId(
    selectedToolID,
    allGoalBonusAdministration
  );

  const { participants = [], administrativeRoles = [] } =
    currentGbAdministration || {};

  const participantsInfo = getParticipantsInfo(participants);

  const admRoles = getAdmRolesInfo(administrativeRoles);

  const allToolUsers = _.uniqBy([...participantsInfo, ...admRoles], "id") || [];

  return allToolUsers;
};

const getEssentialToolUsers = (selectedInsight = {}) => {
  if (!selectedInsight) return [];

  const { id: ownerId = null } = selectedInsight.owner || {};

  const { id: responsibleId = null } = selectedInsight.responsibleMember || {};

  let toolUsers = [{ id: ownerId, role: "CREATOR" }];

  if (responsibleId)
    toolUsers = [
      ...toolUsers,
      {
        id: responsibleId,
        role: "RESPONSIBLE",
      },
    ];

  return toolUsers;
};

const getGbEssentialToolUsers = (selectedInsight = {}) => {
  if (!selectedInsight) return [];

  const { id: ownerId = null } = selectedInsight.owner || {};

  const { id: responsibleId = null } = selectedInsight.responsibleMember || {};

  let toolUsers = [{ id: ownerId, role: "CREATOR" }];

  const { id: insightId = null } = selectedInsight || {};

  if (responsibleId)
    toolUsers = [
      ...toolUsers,
      {
        id: responsibleId,
        role: "RESPONSIBLE",
        typeInsight: true,
        entityId: insightId,
      },
    ];

  return toolUsers;
};

export const getCustomUsersWithActionsTypes = async (insightIds = false) => {
  const toolUsers = await getCustomInsightUsers();

  let usersWithActions = [];

  await Promise.all(
    toolUsers.map(async (userEntity) => {
      const { insight = {}, users = [] } = userEntity;
      const { id: insightId = "" } = insight;

      if (insightIds && insightIds.indexOf(insightId) === -1) return;

      const entityAccess = await fetchEntityUsersWithRoleWithReturn(
        insightId,
        "INSIGHT"
      );

      const diff = getUsersDifference(users, entityAccess);

      usersWithActions = [...usersWithActions, ...diff];
    })
  );

  return usersWithActions;
};

export const getCustomInsightUsers = async (onlyToolAccess = false) => {
  const tool = getToolID();

  const state = store.getState();

  let toolUsers = [];

  switch (tool) {
    case SKILLS_ASSESSMENT:
      const { selectedToolID, dirtyAllAnswers = {} } = getSelectedToolStates(
        state,
        { answers: true }
      );

      const { allSkillAssessment = [] } = getSkillAssessmentStates(state);
      const objInfo = getObjectInfoById(
        selectedToolID,
        allSkillAssessment,
        "selectedToolId"
      );

      const { participants = [] } = objInfo || {};

      const usersEntity = getSkillAssessmentCollaborators(
        dirtyAllAnswers,
        participants,
        onlyToolAccess
      );

      toolUsers = [...toolUsers, ...usersEntity];

      break;
    default:
      break;
  }

  return toolUsers;
};

export const getInsightUsers = async (selectedInsight) => {
  // Adiciona Criador e Responsável do insight se existir
  let toolUsers = [...getEssentialToolUsers(selectedInsight)];

  const { id: selectedInsightId = false } = selectedInsight || {};

  if (!selectedInsightId) return [];

  const actionPlanUsers = getInsightActionPlanUsers(selectedInsightId) || [];

  switch (getToolID()) {
    case PROJECT_PORTFOLIO:
      const projectEvaluation = await getProjectEvaluation(selectedInsight.id);

      toolUsers = [...toolUsers, ...projectToolUsers(projectEvaluation.data)];
      break;
    case PEOPLE_BIG_PICTURE:
      const peopleEvaluation = await getPeopleEvaluation(selectedInsight.id);

      toolUsers = [...toolUsers, ...peopleToolUsers(peopleEvaluation.data)];
      break;
    case GOALS_AND_BONUS:
      const gbUsers = getGoalsAndBonusToolUsers();

      toolUsers = [...gbUsers];
      break;
    default:
      break;
  }

  return [...toolUsers, ...(actionPlanUsers || [])];
};

export const getStructuredAccess = (access = []) => {
  return access.map((current) => {
    const { id = null, entityRole = {} } = current || {};
    const { role = null, entityId = null } = entityRole || {};

    return {
      id,
      role,
      entityId,
    };
  });
};

export const handleExchanges = (entity = [], contained = []) => {
  let toReturn = [];

  entity.forEach((entityUser) => {
    let isOk = false;

    contained.forEach(({ id, role }) => {
      if (entityUser.id === id && entityUser.role === role) isOk = true;
    });

    if (!isOk) toReturn = [...toReturn, entityUser];
  });

  return toReturn;
};

export const getUsersDifference = (toolUsers = [], access = []) => {
  const structuredAccess = getStructuredAccess(access);

  return [
    ...handleExchanges(toolUsers, structuredAccess),
    ...handleExchanges(structuredAccess, toolUsers),
  ];
};

export const getGbAdmUsersWithActionTypes = async () => {
  const toolUsers = await getGoalsAndBonusToolUsers();

  const toolAccess = getEntityAccess("selected_tool");

  const usersWithActions = getUsersDifference(toolUsers, toolAccess);

  return usersWithActions;
};

export const getSkillsAdmUsersWithActionTypes = async () => {
  const toolUsers = await getCustomInsightUsers(true);

  const toolAccess = getEntityAccess("selected_tool");

  const filteredPerManager = toolAccess.filter(({ entityRole, access }) => {
    const { role = "" } = entityRole || {};

    if (role === "MANAGER" || (role === "VIEWER" && access === "READ"))
      return false;

    return true;
  });

  const usersWithActions = getUsersDifference(toolUsers, filteredPerManager);

  return usersWithActions;
};

export const getGbInsightUsersWithActionTypes = async () => {
  const selectedInsight = getSelectedInsight();

  const toolUsers = [...getGbEssentialToolUsers(selectedInsight)];

  const insightAccess = getEntityAccess("insight");

  const usersWithActions = getUsersDifference(toolUsers, insightAccess);

  return usersWithActions;
};

export const getUsersWithActionTypes = async (selectedInsight) => {
  const toolUsers = await getInsightUsers(selectedInsight);
  const insightAccess = await fetchEntityUsersWithRoleWithReturn(
    selectedInsight?.id,
    "INSIGHT"
  );
  const usersWithActions = getUsersDifference(toolUsers, insightAccess);

  return usersWithActions;
};

export const userToolCustomRoles = (userId = 0, role = "") => {
  const toolID = getToolID();

  switch (toolID) {
    case PROJECT_PORTFOLIO:
      return userProjectToolCustomRoles(userId, role);
    default:
      return true;
  }
};

export const userProjectToolCustomRoles = (userId = 0, role = "") => {
  const state = store.getState();

  const { currentProjectEvaluation = {} } = getProjectStates(state);

  const {
    responsibleMember = {},
    executantMember = {},
    involved = [],
  } = currentProjectEvaluation.peopleInvolved || {};

  const rolesRelations = {
    RESPONSIBLE: [responsibleMember, executantMember],
    COLLABORATOR: [...involved],
  };

  const roleTypeRelations = rolesRelations[role] || [];

  const filteredRelations =
    roleTypeRelations.filter((user) => Number(user.id) === Number(userId))[0] ||
    false;

  return filteredRelations;
};

export const customHideActions = (actionInfo = {}) => {
  const { responsible = {} } = actionInfo || {};
  const { id: actionResponsibleId = false } = responsible || {};
  const { id: loggedUserId = "" } = getCurrentUserInfo();

  if (
    checkAccess([
      "ADMIN",
      "MODERATOR",
      "MANAGER",
      "LEADER",
      "RESPONSIBLE",
      "COLLABORATOR",
    ]) ||
    (checkAccess(["COLLABORATOR"]) && loggedUserId === actionResponsibleId)
  )
    return false;

  return true;
};

export const getCurrentInsightResponsible = () => {
  const state = store.getState();

  const { selectedInsight = {} } = getInsightStates(state);

  const { responsibleMember = null } = selectedInsight || {};

  return responsibleMember;
};

export const isLoggedUser = (userId = 0) => {
  const currentUserId = getCurrentDomainUserId();
  const selectedUserId = getCurrentDomainUserId(userId);
  const loggedUser =
    selectedUserId === currentUserId || currentUserId === userId;

  return { loggedUser, user: getUserInfo(userId) };
};

export const getSelectedTool = () => {
  const state = store.getState();

  const { selectedTool = {} } = getSelectedToolStates(state);

  return selectedTool;
};

export const getSelectedInsight = () => {
  const state = store.getState();

  const { selectedInsight = {} } = getInsightStates(state);

  return selectedInsight;
};

export const getCascadeIDs = () => {
  const state = store.getState();

  const { selectedDomainId = 0 } = getAccessStates(state);
  const { selectedSceneryID = 0 } = getSceneryStates(state);
  const { selectedInsightID = 0 } = getInsightStates(state);
  const { selectedToolID = 0 } = getSelectedToolStates(state);
  const { selectedWorkspaceID = 0 } = getWorkspacesStates(state);
  const { selectedCompanyId = 0 } = getSelectedCompanyStates(state);

  return {
    selectedToolID,
    selectedDomainID: selectedDomainId,
    selectedCompanyID: selectedCompanyId,
    selectedSceneryID,
    selectedInsightID,
    selectedWorkspaceID,
  };
};

export const getCurrentUserInfo = () => {
  const state = store.getState();

  const { user = {} } = state;

  return user;
};

export const getUserInfo = (userId = 0) => {
  const domainUserId = getCurrentDomainUserId(userId);
  const domainUsers = getCurrentDomainUsers();

  return (
    domainUsers.filter(
      (user) =>
        Number(user.domainUserId) === Number(domainUserId) ||
        Number(user.domainUserId) === Number(userId)
    )[0] || null
  );
};

export const getCascadeUpInfo = (
  userId = null,
  currentLevel = 0,
  passEntity = null
) => {
  let currentUserId = userId;

  if (!userId || userId === 0) {
    const userInfo = getCurrentUserInfo();
    const { id = 0 } = userInfo || {};
    currentUserId = id;
  }

  if (currentLevel < 0 || currentLevel > 3) return;
  const {
    passEntityId = null,
    passEntityRole = "VIEWER",
    passEntityAccess = "EDIT",
  } = passEntity || {};

  const entityInfos = [];

  const domainInfo = getDomainInfo(currentUserId);

  const {
    selectedWorkspaceID = 0,
    selectedSceneryID = 0,
    selectedInsightID = 0,
    selectedToolID = 0,
  } = getCascadeIDs();

  const entityTypes = ["INSIGHT", "CENARY", "SELECTED_TOOL", "WORKSPACE"];
  const entityIds = [
    selectedInsightID,
    selectedSceneryID,
    selectedToolID,
    selectedWorkspaceID,
  ];

  let index = 0;

  for (let i = currentLevel; i < 4; i++, index++) {
    entityInfos[index] = {
      entityType: entityTypes[i],
      entityId: index === 0 && passEntityId ? passEntityId : entityIds[i],
      role: index === 0 ? passEntityRole : "VIEWER",
      userAccess: index === 0 ? passEntityAccess : "RESTRICT",
      ...domainInfo,
    };
  }

  return entityInfos;
};

// const filterUserEntityAccess = (
//   entityAccess = [],
//   searchRole = null,
//   searchAccess = null
// ) => {
//   if (!searchRole || searchAccess) return [];

//   return entityAccess.filter((register) => {
//     const { access = null, entityRole = {} } = register || {};
//     const { role = null } = entityRole || {};

//     if (access === searchAccess && role === searchRole) {return true;} else {return false;}
//   });
// };

const getUserAccessesInEntity = (entity = [], userId = null) => {
  if (!userId) return [];

  return entity.filter((entityAccess) => entityAccess.id === userId);
};

export const getUserEntityAccesses = (userId = 0, entityType = null) => {
  if (!entityType) return [];

  const entityAccess = getEntityAccess(entityType);

  const entityUserAccess = getUserAccessesInEntity(entityAccess, userId);

  return entityUserAccess;
};

export const getInsightLevelEntitys = () => {
  let allLevelInsights = [];

  const state = store.getState();

  const { dirtyAllAnswers = [] } = getSelectedToolStates(state);

  const { selectedSceneryID = 0 } = getSceneryStates(state);

  Object.keys(dirtyAllAnswers).forEach((questionId) => {
    dirtyAllAnswers[questionId].forEach((insight) => {
      const { id = null, question = {} } = insight || {};
      const { theme = {} } = question || {};
      const { cenary = {} } = theme || {};

      if (!id || cenary.id !== selectedSceneryID) return;

      allLevelInsights = [...allLevelInsights, insight];
    });
  });

  return allLevelInsights;
};

export const getCenaryLevelEntitys = () => {
  const state = store.getState();

  const { dirtyAllSceneries = [] } = getSelectedToolStates(state);

  return dirtyAllSceneries;
};

export const getToolLevelEntitys = () => {
  const state = store.getState();

  const { allUsersWorkspaceTools = [] } = getWorkspacesStates(state);

  return allUsersWorkspaceTools;
};

export const getWorkspaceLevelEntitys = () => {
  const state = store.getState();

  const { dirtyAllCompanyWorkspaces = [] } = getWorkspacesStates(state);

  return dirtyAllCompanyWorkspaces;
};

export const getLevelEntitys = (entityType = null) => {
  let allLevelEntitys = [];

  switch (entityType.toUpperCase()) {
    case "INSIGHT":
      allLevelEntitys = [...getInsightLevelEntitys()];
      break;
    case "CENARY":
      allLevelEntitys = [...getCenaryLevelEntitys()];
      break;
    case "SELECTED_TOOL":
      allLevelEntitys = [...getToolLevelEntitys()];
      break;
    default:
    case "WORKSPACE":
      allLevelEntitys = [...getWorkspaceLevelEntitys()];
      break;
  }

  return allLevelEntitys;
};

const getHasAccess = (entity = [], compareId = "") => {
  if (entity.length === 0) return false;

  return entity.filter(({ id = 0 }) => id === compareId).length > 0;
};

export const getValidatedAccessIds = (
  userAccess = {},
  {
    selectedToolId,
    companyId: selectedCompanyId,
    insightId: selectedInsightId,
    workspaceId: selectedWorkspaceId,
  }
) => {
  let finalBody = {};

  const doAdd = (field = "", value = 0) => {
    finalBody = { ...finalBody, [field]: value };
  };

  const validateInsight = () => {
    const { insights = [] } = userAccess || {};

    const hasAccess = getHasAccess(insights, selectedInsightId);

    if (hasAccess) doAdd("insightId", selectedInsightId);
  };

  const validateTool = () => {
    const { tools = [] } = userAccess || {};

    const hasAccess = getHasAccess(tools, selectedToolId);

    if (hasAccess) doAdd("selectedToolId", selectedToolId);
  };

  const validateWorkspace = () => {
    const { workspaces = [] } = userAccess || {};

    const hasAccess = getHasAccess(workspaces, selectedWorkspaceId);

    if (hasAccess) doAdd("workspaceId", selectedWorkspaceId);
  };

  const validateCompany = () => {
    const { companies = [] } = userAccess || {};

    const hasAccess = getHasAccess(companies, selectedCompanyId);

    if (hasAccess) doAdd("companyId", selectedCompanyId);
  };

  if (selectedToolId) validateTool();
  if (selectedCompanyId) validateCompany();
  if (selectedInsightId) validateInsight();
  if (selectedWorkspaceId) validateWorkspace();

  return finalBody;
};

const getLevelUserAccesses = (
  userAccess,
  entityType = null,
  allEntitys = []
) => {
  let onlyContained = [];

  const userAccessSlugs = {
    insight: "insights",
    cenary: "scenarios",
    selected_tool: "tools",
    workspace: "workspaces",
  };

  const levelAccesses = userAccess[userAccessSlugs[entityType]];

  levelAccesses.forEach((access) => {
    allEntitys.forEach((entity) => {
      if (entity.id === access.id) onlyContained = [...onlyContained, access];
    });
  });

  return onlyContained;
};

export const getOverallLevelUserAccesses = (
  userAccess = {},
  entityType = ""
) => {
  const levelEntitys = getLevelEntitys(entityType);

  const userLevelAccesses = getLevelUserAccesses(
    userAccess,
    entityType,
    levelEntitys
  );

  return userLevelAccesses;
};

export const getCascadeUpAccesses = async (
  userId = null,
  currentLevel = 0,
  currentLevelRemoveBody = {}
) => {
  let currentUserId = userId;

  if (!userId || userId === 0) {
    const userInfo = getCurrentUserInfo();
    const { id = 0 } = userInfo || {};
    currentUserId = id;
  }

  if (currentLevel < 0 || currentLevel > 3) return;

  const {
    selectedWorkspaceID = 0,
    selectedSceneryID = 0,
    selectedInsightID = 0,
    selectedToolID = 0,
  } = getCascadeIDs();

  const entityTypes = ["INSIGHT", "CENARY", "SELECTED_TOOL", "WORKSPACE"];

  const entityIds = [
    selectedInsightID,
    selectedSceneryID,
    selectedToolID,
    selectedWorkspaceID,
  ];

  let stopLoop = false;

  let accessesToRemove = [currentLevelRemoveBody];

  let i = currentLevel;

  const domainUserId = getCurrentDomainUserId(currentUserId);

  const userAccess = await getUserAccessWithReturn(domainUserId);

  const filterByDiff = (userAccess) =>
    userAccess.filter((entityAccess) => entityAccess.id !== entityIds[i]);
  const filterByEqual = (userAccess) =>
    userAccess.filter((entityAccess, entity) => entityAccess.id === entity);

  while (i < 3 && !stopLoop) {
    const entityType = entityTypes[i].toLowerCase();

    const userLevelAccesses = getOverallLevelUserAccesses(
      userAccess,
      entityType
    );

    let multipleAccesses = 0;

    const verifyOtherEntityAccesses = filterByDiff(
      userLevelAccesses,
      entityIds[i]
    );

    const currentEntityAccesses = filterByEqual(
      userLevelAccesses,
      entityIds[i]
    );

    multipleAccesses = currentEntityAccesses.length;

    if (verifyOtherEntityAccesses.length > 0 || multipleAccesses > 1) {
      stopLoop = true;
    } else {
      const nextEntityType = entityTypes[i + 1].toLowerCase();

      const nextLevelAccesses = getOverallLevelUserAccesses(
        userAccess,
        nextEntityType
      );

      const userRelevantRole = nextLevelAccesses.filter(
        (nextLevel) => nextLevel.access !== "RESTRICT"
      );

      if (i > 0) {
        let newI = i - 1;

        while (newI >= 0 && !stopLoop) {
          const belowEntityType = entityTypes[newI].toLowerCase();
          const belowLevelAccesses = getOverallLevelUserAccesses(
            userAccess,
            belowEntityType
          );
          const userRelevantRole = belowLevelAccesses.filter(
            (nextLevel) => nextLevel.access !== "RESTRICT"
          );

          if (userRelevantRole.length > 0) stopLoop = true;

          newI--;
        }
      }
      if (userRelevantRole.length > 0) {
        stopLoop = true;
      } else if (!stopLoop) {
        accessesToRemove = [
          ...accessesToRemove,
          {
            entityId: entityIds[i + 1],
            entityType: entityTypes[i + 1],
            role: "VIEWER",
            accessType: "RESTRICT",
          },
        ];
      }
    }

    i++;
  }

  return accessesToRemove;
};

export const userHaveRole = (entityAccessType = "") => {
  return true;
};

export const getRolesBelow = async (user = {}) => {
  let rolesBelow = false;
  const { domainUserId = 0 } = user;
  const allEntitysAccess = await getUserAccessWithReturn(domainUserId);
  if (userHaveRolesBelow(allEntitysAccess))
    rolesBelow = rolesFullInfo(allEntitysAccess);
  console.log("getRolesBelow, rolesBelow=", rolesBelow);
  return true;
};

export const userHaveRolesBelow = ({
  workspaces = [],
  tools = [],
  scenarios = [],
  insights = [],
}) => {
  if (
    workspaces.length > 0 ||
    tools.length > 0 ||
    scenarios.length > 0 ||
    insights.length > 0
  )
    return true;

  return false;
};

const getWorkspacesDetails = (workspaces = []) => {
  return [];
};

const getToolsDetails = (tools = []) => {
  return [];
};
const getScenariosDetails = (scenarios = []) => {
  return [];
};
const getInsightsDetails = (insights = []) => {
  return [];
};

export const rolesFullInfo = (allEntitysAccess = {}) => {
  const filteredUserAccess = { ...allEntitysAccess };
  const fullDetails = {
    workspaces: [...getWorkspacesDetails(filteredUserAccess.workspaces)],
    tools: [...getToolsDetails(filteredUserAccess.tools)],
    scenarios: [...getScenariosDetails(filteredUserAccess.scenarios)],
    insights: [...getInsightsDetails(filteredUserAccess.insights)],
  };
  console.log("rolesFullInfo, fullDetails=", fullDetails);
  return null;
};

export const getTipMessage = (messageType = "", complement = "") => {
  const space = " ";

  const localMessages = {
    youCantDeleteLast: translation("youCantDeleteLast") + space + complement,
    delete: translation("delete") + space + complement,
    add: translation("add") + space + complement,
  };

  return localMessages[messageType];
};

export const isLastRole = (currentUser = {}, users = [], role) => {
  const usersToFilter = [...users];

  if (!currentUser.role || currentUser.role !== role) return false;

  const roleUsers =
    usersToFilter.filter((user) => user.role === role)[1] || false;

  if (roleUsers) return false;

  return true;
};

export const genericTip = (tipMessage = "") => {
  return (
    <Popover id="information" className="tooltip_messages2">
      {tipMessage}
    </Popover>
  );
};

export const validatePermissions = (allAccess = []) => {
  let users = [];

  // MUDAR PARA FILTER DEPOIS DO GLODZINSKI ALTERAR NOME PARA NAME NO BODY
  // REMOVE ACESSOS DO TIPO RESTRICT QUE NÃO SÃO GERENCIADOS PELO USUÀRIOS

  allAccess.forEach((currentAccess) => {
    const { entityRole = {} } = currentAccess;

    const { id = null, domainUserId = null, access = null } = currentAccess;

    let isRestrictViewer = false;

    if (
      entityRole.role &&
      entityRole.role.toUpperCase() === "VIEWER" &&
      access &&
      access.toUpperCase() === "RESTRICT"
    )
      isRestrictViewer = true;

    if (domainUserId && id && !isRestrictViewer)
      users = [...users, { ...currentAccess, name: currentAccess.nome }];
  });

  return users;
};

const simpleRoleAtt = (userPerm = {}) => {
  const { entityRole = {} } = userPerm || {};

  const { role = false } = entityRole || {};

  userPerm.role = role.toLowerCase();

  return userPerm;
};

export const mountToolPermissions = (allAccess = []) => {
  const userPermissions = validatePermissions(allAccess);

  return userPermissions.map((userPerm) => simpleRoleAtt(userPerm));
};

export const mountCenaryPermissions = (allAccess = [], users = []) => {
  const userPermissions = validatePermissions(allAccess);

  return userPermissions.map((userPerm) => {
    const { domainUserId: currentDomainUserId, entityRole = {} } =
      userPerm || {};
    const { role = false } = entityRole || {};

    const { role: currentAccessRole = "" } =
      users.filter(
        ({ domainUserId }) => domainUserId === currentDomainUserId
      )[0] || {};

    if (role) {
      userPerm.role = role.toLowerCase();
      return userPerm;
    }

    userPerm.role = currentAccessRole;

    return userPerm;
  });
};

export const mountDomainUsers = (domainUsers = [], domainAccess = []) => {
  const validatedAccess = validatePermissions(domainAccess);

  const updatedDomainUsers = [...domainUsers];

  domainUsers.forEach((user, index) => {
    const { id: userId } = user;

    const access = validatedAccess.filter(({ id }) => id === userId)[0] || null;

    if (access) {
      const { entityRole = {} } = access;

      updatedDomainUsers[index].role = entityRole.role.toLowerCase();
      return;
    }

    updatedDomainUsers[index].role = null;
  });

  return updatedDomainUsers;
};

export const mountCompanyUsers = (
  companyAndParticipants = [],
  companyAccess = [],
  companyUsers = []
) => {
  const validatedAccess = validatePermissions(companyAccess);

  const updatedCompanyUsers = [...companyAndParticipants];

  companyAndParticipants.forEach(({ id }, index) => {
    const currentDomainUserId = getCurrentDomainUserId(id);
    updatedCompanyUsers[index].domainUserId = currentDomainUserId;

    const access =
      validatedAccess.filter(
        ({ domainUserId }) => domainUserId === currentDomainUserId
      )[0] || null;

    const { role: currentAccessRole = "" } =
      companyUsers.filter(
        ({ domainUserId }) => domainUserId === currentDomainUserId
      )[0] || {};

    if (access) {
      const { entityRole = {} } = access;

      updatedCompanyUsers[index].role = entityRole.role.toLowerCase();
      return;
    }

    if (currentAccessRole === "choose") {
      updatedCompanyUsers[index].role = "choose";
      return;
    }

    updatedCompanyUsers[index].role = null;
  });

  return updatedCompanyUsers;
};

const entityInfos = {
  DOMAIN: {
    entityType: "DOMAIN",
    role: "OWNER",
    userAccess: "EDIT",
  },
  COMPANY: {
    entityType: "COMPANY",
    role: "ADMIN",
    userAccess: "EDIT",
  },
  COMPANY_RESTRICT: {
    entityType: "COMPANY",
    role: "VIEWER",
    userAccess: "RESTRICT",
  },
  WORKSPACE: {
    entityType: "WORKSPACE",
    role: "MODERATOR",
    userAccess: "EDIT",
  },
  SELECTED_TOOL: {
    entityType: "SELECTED_TOOL",
    role: "MANAGER",
    userAccess: "EDIT",
  },
  CENARY: {
    entityType: "CENARY",
    role: "LEADER",
    userAccess: "EDIT",
  },
  INSIGHT: {
    entityType: "INSIGHT",
    role: "CREATOR",
    userAccess: "EDIT",
  },
};

export const getAccessBody = (entityType = "", userId = null) => {
  const domainInfo = getDomainInfo(userId);

  return {
    ...domainInfo,
    ...entityInfos[entityType],
  };
};

export const getCascadeAccessBody = (entitysInfo = [], userId = null) => {
  let finalCascadeBody = [];

  const domainInfo = getDomainInfo(userId);

  entitysInfo.forEach((entityInfo) => {
    finalCascadeBody = [
      ...finalCascadeBody,
      { ...entityInfo, ...domainInfo, role: "VIEWER", userAccess: "READ" },
    ];
  });

  return finalCascadeBody;
};

export const getDomainInfo = (userId = null) => {
  const state = store.getState();

  const { user = {} } = state;

  const currentId = userId || user.id;

  const domainUserId = getCurrentDomainUserId(currentId);

  const { id: domainId } = getCurrentDomain();

  return { domainUserId, domainId };
};

export const getCurrentDomainUserId = (userId = null) => {
  const state = store.getState();

  const { user: currentUser = {} } = state;

  const currentId = userId || currentUser.id;

  const { domainUsers = [] } = getAccess();

  const { domainUserId: domainIdResource = null } =
    domainUsers.filter(({ domainUserId }) => domainUserId === currentId)[0] ||
    {};

  const { domainUserId: userIdResource = null } =
    domainUsers.filter(({ id }) => id === currentId)[0] || {};

  if (domainIdResource || userIdResource)
    return userIdResource || domainIdResource;

  return 0;
};

export const getCurrentDomain = () => {
  const { currentDomain = [] } = getAccess();

  return currentDomain;
};

const entityAccessExists = (entity = [], foreignId = null) => {
  if (!foreignId) return false;

  const selectedEntity =
    entity.filter((ent) => {
      const { id = 0 } = ent || {};

      if (id === foreignId) {
        return true;
      } else {
        return false;
      }
    })[0] || false;

  if (selectedEntity) return true;

  return false;
};

const entityRoleExists = (entity = [], role = "", id = 0) => {
  const filteredEntity =
    entity.filter((obj) => {
      if (Number(obj.id) === Number(id) && obj.role === role) {
        if (role === "VIEWER" && obj.access === "RESTRICT") return false;
        return true;
      } else {
        return false;
      }
    })[0] || false;

  if (filteredEntity && filteredEntity.role.indexOf(role) !== -1) return true;
  return false;
};

const getOnlyContained = (entity = [], containedEntity = []) => {
  const { lockdown = false } = getAccess();

  if (!lockdown) return entity;

  const cleanedEntity = _.uniqBy(entity, "id") || [];

  const onlyIds = containedEntity.map(({ id = 0 }) => {
    return id;
  });

  const filteredEntity = cleanedEntity.filter((filtered) => {
    const { id = false } = filtered || {};

    if (id && onlyIds.indexOf(id) !== -1) return true;

    return false;
  });

  return filteredEntity;
};

export const getSceneryId = (insight = null) => {
  if (!insight) return null;

  const { question = {} } = insight;

  const { theme = {} } = question;

  const { cenary = {} } = theme;

  const { id: cenaryId = 0 } = cenary;

  return cenaryId;
};

export const filterAllAnswersByAccess = (allAnswers = {}) => {
  if (checkAccess(["OWNER", "ADMIN", "MODERATOR", "MANAGER", "TOOLVIEWER"])) {
    return allAnswers;
  }

  let filteredThemeAnswers = {};
  let filteredAnswers = [];

  Object.keys(allAnswers).forEach((questionId) => {
    filteredAnswers = filterInsightsByAccess(allAnswers[questionId]);

    if (filteredAnswers.length > 0)
      filteredThemeAnswers = {
        ...filteredThemeAnswers,
        [questionId]: [...filteredAnswers],
      };
  });

  return filteredThemeAnswers;
};

export const filterInsightsByAccess = (allInsights = []) => {
  const currentSceneryId = getSceneryId(allInsights[0] || null);

  if (checkAccess(["MANAGER", "MODERATOR", "ADMIN", "OWNER"], null)) {
    return allInsights;
  }

  const leaderFiltered = [];

  allInsights.forEach((insight) => {
    const { question } = insight;
    const { theme } = question;
    const { cenary } = theme;

    if (cenary?.id && checkAccess(["LEADER", "SCENERYVIEWER"], null, cenary.id))
      leaderFiltered.push(insight);
  });

  // Retorna apenas insights, excluindo as answers
  if (checkAccess(["TOOLVIEWER"], null, currentSceneryId))
    return allInsights.filter((insight) => insight.type !== "information");

  const { insights = [] } = getUserAccess();

  const filteredInsights = getOnlyContained(allInsights, insights);
  const filteredIds = filteredInsights.map(({ id }) => id);

  const result = [...filteredInsights];
  leaderFiltered.forEach((insight) => {
    if (filteredIds.indexOf(insight.id) === -1) result.push(insight);
  });

  return result;
};

export const filterScenariosByAccess = (allScenarios = []) => {
  if (checkAccess(["TOOLVIEWER", "MANAGER", "MODERATOR", "ADMIN", "OWNER"]))
    return allScenarios;

  const { scenarios = [] } = getUserAccess();

  const filteredScenarios = getOnlyContained(allScenarios, scenarios);

  return _.uniqBy(filteredScenarios, "id") || [];
};

export const filterWorkspacesByAccess = (allWorkspaces = []) => {
  if (checkAccess(["OWNER", "ADMIN"]))
    return _.uniqBy(allWorkspaces, "id") || [];

  const { workspaces = [] } = getUserAccess();

  const filteredWorkspaces = getOnlyContained(allWorkspaces, workspaces);

  return _.uniqBy(filteredWorkspaces, "id") || [];
};

export const filterToolsByAccess = (allTools = []) => {
  if (checkAccess(["MODERATOR", "ADMIN", "OWNER"])) return allTools;

  const { tools = [] } = getUserAccess();

  const filteredTools = getOnlyContained(allTools, tools);

  const actionPlanTool =
    allTools.filter(({ tool }) => tool?.id === ACTION_PLAN_ID)[0] || false;

  if (actionPlanTool) {
    return _.uniqBy([...filteredTools, actionPlanTool], "id") || [];
  }

  return _.uniqBy(filteredTools, "id") || [];
};

export const filterDomainCompanies = (allCompanies = []) => {
  const state = store.getState();

  const { companies: userOwnerCompanies = [] } = getUserAccess();

  const { userMemberCompanies = [] } = state.companyData;

  const allUserCompanies = [...userMemberCompanies, ...userOwnerCompanies];

  const filteredCompanies = getOnlyContained(allCompanies, allUserCompanies);

  if (checkAccess(["OWNER"])) return _.uniqBy(allCompanies, "id") || [];

  return _.uniqBy(filteredCompanies, "id") || [];
};

export const filterToolTypesByAccess = (
  toolsTypes = [],
  allCompanyToolsType = {}
) => {
  if (checkAccess(["OWNER", "ADMIN"])) return toolsTypes;

  let selectedTools = [];

  const getSelectedTools = (toolsArray = []) => {
    selectedTools = [
      ...selectedTools,
      ...toolsArray.map((tool) => {
        return { id: tool.selectedToolId, ...tool };
      }),
    ];
  };

  const { tools: accessTools = [] } = getUserAccess();

  Object.keys(allCompanyToolsType).forEach((toolTypeId) =>
    getSelectedTools(allCompanyToolsType[toolTypeId])
  );

  const containedTools = getOnlyContained(selectedTools, accessTools);

  const toolTypeIds = containedTools.map(({ toolInfo = {} }) =>
    Number(toolInfo.id)
  );

  let filtered = {};

  Object.keys(allCompanyToolsType).forEach((toolType) => {
    if (toolTypeIds.indexOf(Number(toolType)) !== -1) {
      filtered = {
        ...filtered,
        [toolType]: [...allCompanyToolsType[toolType]],
      };
    }
  });

  return filtered;
};

export const filterCompanyDataByAccess = () => {
  const state = store.getState();
  const { domainCompanies = [] } = getAccessStates(state);

  const { companyData } = state;

  const filteredDomainCompanies = filterDomainCompanies(domainCompanies);

  return {
    ...companyData,
    allCompanies: filteredDomainCompanies,
  };
};

const getMemberByID = (membersEntity = [], memberId = "") => {
  return (
    membersEntity.filter(({ id = 0 }) => Number(id) === Number(memberId))[0] ||
    false
  );
};

const getMembersByIds = (userIds = [], membersEntity = []) => {
  let allSelectedMembers = [];

  userIds.forEach((userId) => {
    const user = getMemberByID(membersEntity, userId);

    if (user) {
      user.domainUserId = getCurrentDomainUserId(user.id);
      allSelectedMembers = [...allSelectedMembers, user];
    }
  });

  return allSelectedMembers;
};

const handleRepeatedMembers = (
  membersEntity = [],
  selectedMembersEntity = []
) => {
  const onlyIds = selectedMembersEntity.map(({ id = 0 }) => {
    return id;
  });

  const onlyAvailable = membersEntity.filter(
    ({ id = 0 }) => onlyIds.indexOf(id) === -1
  );

  return onlyAvailable;
};

const getHeaderSlugs = (headers = {}) => {
  return Object.keys(headers).map((header) => header);
};

const patternSearch = (entity = [], searchWords = "") => {
  let filteredEntity = [];

  if (searchWords.length > 0) {
    entity.forEach((entityLine) => {
      if (
        entityLine.name.toUpperCase().includes(searchWords.toUpperCase()) ||
        entityLine.email.toUpperCase().includes(searchWords.toUpperCase())
      ) {
        filteredEntity = [...filteredEntity, entityLine];
      }
    });

    return filteredEntity;
  }

  return entity;
};

const changeUserField = (
  entity = [],
  userId = "",
  fieldName = "",
  fieldValue = ""
) => {
  const updatedEntity = [...entity];
  let indexToUpdate = null;

  const filteredEntity =
    updatedEntity.filter(({ id }, index) => {
      if (Number(id) === Number(userId)) {
        indexToUpdate = index;
        return true;
      } else {
        return false;
      }
    })[0] || false;

  if (!filteredEntity) return [];

  if (filteredEntity[fieldName] !== fieldValue) {
    filteredEntity[fieldName] = fieldValue;
  } else {
    filteredEntity[fieldName] = null;
  }

  updatedEntity[indexToUpdate] = filteredEntity;

  return updatedEntity;
};

const getUserRoleStyle = (role = "", type = "") => {
  let className = "";

  if (String(role).toUpperCase() === String(type).toUpperCase())
    className = "selected-user-role";

  return className;
};

export const getOwnerLevels = () => {
  return {
    roleName: "Proprietário",
    greater: [],
    greaterAndEqual: ["OWNER"],
    lower: [
      "ADMIN",
      "MODERATOR",
      "MANAGER",
      "LEADER",
      "CREATOR",
      "RESPONSIBLE",
      "COLLABORATOR",
    ],
    userGrantedAccess: "Você foi adicionado como Proprietário do Domínio",
    genericGranted: "Novo Proprietário adicionado ao Domínio",
    userDeniedAccess: "Você foi removido como Proprietário do Domínio",
    genericDenied: "Proprietário removido do Domínio",
  };
};

export const getAdminLevels = () => {
  const greater = getOwnerLevels().greaterAndEqual;

  return {
    roleName: "Administrador",
    greater,
    greaterAndEqual: [...greater, "ADMIN"],
    lower: [
      "MODERATOR",
      "MANAGER",
      "LEADER",
      "CREATOR",
      "RESPONSIBLE",
      "COLLABORATOR",
    ],
    userGrantedAccess: "Você foi adicionado como Administrador da Empresa",
    genericGranted: "Novo Administrador adicionado à Empresa",
    userDeniedAccess: "Você foi removido como Administrador da Empresa",
    genericDenied: "Administrador removido da Empresa",
  };
};

export const getModeratorLevels = () => {
  const greater = getAdminLevels().greaterAndEqual;

  return {
    roleName: "Moderador",
    greater,
    greaterAndEqual: [...greater, "MODERATOR"],
    lower: ["MANAGER", "LEADER", "CREATOR", "RESPONSIBLE", "COLLABORATOR"],
    userGrantedAccess: "Você foi adicionado como Moderador do Workspace",
    genericGranted: "Novo Moderador adicionado ao Workspace",
    userDeniedAccess: "Você foi removido como Moderador do Workspace",
    genericDenied: "Moderador removido do Workspace",
  };
};

export const getManagerLevels = () => {
  const greater = getModeratorLevels().greaterAndEqual;

  return {
    roleName: "Gestor",
    greater,
    greaterAndEqual: [...greater, "MANAGER"],
    lower: ["LEADER", "CREATOR", "RESPONSIBLE", "COLLABORATOR"],
    userGrantedAccess: "Você foi adicionado como Gestor da Ferramenta",
    genericGranted: "Novo Gestor adicionado á Ferramenta",
    userDeniedAccess: "Você foi removido como Gestor da Ferramenta",
    genericDenied: "Gestor removido da Ferramenta",
  };
};

export const getToolViewerLevels = () => {
  const greater = getManagerLevels().greaterAndEqual;

  return {
    roleName: "Visualizador",
    greater,
    greaterAndEqual: [...greater, "VIEWER"],
    lower: [],
    userGrantedAccess: "Você foi adicionado como Visualizador da Ferramenta",
    genericGranted: "Novo Visualizador adicionado á Ferramenta",
    userDeniedAccess: "Você foi removido como Visualizador da Ferramenta",
    genericDenied: "Visualizador removido da Ferramenta",
  };
};

export const getLeaderLevels = () => {
  const greater = getManagerLevels().greaterAndEqual;

  return {
    roleName: "Líder",
    greater,
    greaterAndEqual: [...greater, "LEADER"],
    lower: ["CREATOR", "RESPONSIBLE", "COLLABORATOR"],
    userGrantedAccess: "Você foi adicionado como Líder do Cenário",
    genericGranted: "Novo Líder adicionado ao Cenário",
    userDeniedAccess: "Você foi removido como Líder do Cenário",
    genericDenied: "Líder removido do Cenário",
  };
};

export const getSceneryViewerLevels = () => {
  const greater = getLeaderLevels().greaterAndEqual;

  return {
    roleName: "Visualizador do Cenário",
    greater,
    greaterAndEqual: [...greater, "SCENERYVIEWER"],
    lower: [],
    userGrantedAccess: "Você foi adicionado como Visualizador do Cenário",
    genericGranted: "Novo Visualizador adicionado ao Cenario",
    userDeniedAccess: "Você foi removido como Visualizador do Cenário",
    genericDenied: "Visualizador removido do Cenário",
  };
};

export const getCreatorLevels = () => {
  const greater = getLeaderLevels().greaterAndEqual;

  return {
    roleName: "Criador",
    greater,
    greaterAndEqual: [...greater, "CREATOR"],
    lower: ["RESPONSIBLE", "COLLABORATOR"],
    userGrantedAccess: "Você foi adicionado como Criador do Insight",
    genericGranted: "Novo Criador adicionado ao Insight",
    userDeniedAccess: "Você foi removido como Criador do Insight",
    genericDenied: "Criador removido do Insight",
  };
};

export const getResponsibleLevels = () => {
  const greater = getCreatorLevels().greaterAndEqual;

  return {
    roleName: "Responsável",
    greater,
    greaterAndEqual: [...greater, "RESPONSIBLE"],
    lower: [],
    userGrantedAccess: "Você foi adicionado como Responsável do Insight",
    genericGranted: "Novo Responsável adicionado ao Insight",
    userDeniedAccess: "Você foi removido como Responsável do Insight",
    genericDenied: "Responsável removido do Insight",
  };
};

export const getCollaboratorLevels = () => {
  const greater = getCreatorLevels().greaterAndEqual;

  return {
    roleName: "Colaborador",
    greater,
    greaterAndEqual: [...greater, "COLLABORATOR"],
    lower: [],
    userGrantedAccess: "Você foi adicionado como Colaborador do Insight",
    genericGranted: "Novo Colaborador adicionado ao Insight",
    userDeniedAccess: "Você foi removido como Colaborador do Insight",
    genericDenied: "Colaborador removido do Insight",
  };
};

export const getRoleLevelInformations = (checkRole) => {
  switch (checkRole) {
    case "OWNER":
      return getOwnerLevels();
    case "ADMIN":
      return getAdminLevels();
    case "MODERATOR":
      return getModeratorLevels();
    case "MANAGER":
      return getManagerLevels();
    case "VIEWER":
      return getToolViewerLevels();
    case "LEADER":
      return getLeaderLevels();
    case "SCENERYVIEWER":
      return getSceneryViewerLevels();
    case "CREATOR":
      return getCreatorLevels();
    case "RESPONSIBLE":
      return getResponsibleLevels();
    case "COLLABORATOR":
      return getCollaboratorLevels();
    default:
      return null;
  }
};

export const getGreaterOrEqualRoles = (checkRole = "", includeEqual = true) => {
  const roleInfo = getRoleLevelInformations(checkRole);

  if (roleInfo.greaterAndEqual) {
    return includeEqual ? roleInfo.greaterAndEqual : roleInfo.greater;
  }

  return [];
};

export const getGreaterAndEqualRoles = (checkRole) => {
  return getGreaterOrEqualRoles(checkRole, true);
};

export const getOnlyGreaterRoles = (checkRole) => {
  return getGreaterOrEqualRoles(checkRole, false);
};

export {
  changeUserField,
  getHeaderSlugs,
  getMemberByID,
  getMembersByIds,
  getUserRoleStyle,
  handleRepeatedMembers,
  patternSearch,
};
