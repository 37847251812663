import { withStyles } from "@material-ui/styles";
import PropTypes, { shape } from "prop-types";
import { Component } from "react";
import { OverlayTrigger } from "react-bootstrap";
import { defineMessages, injectIntl } from "react-intl";
import styled from "styled-components";
import BadgeProgressBar from "./ReportCards/components/BadgeProgressBar";

import MaterialCustomMenu from "./MaterialCustomMenu";

const styles = (theme) => ({
  tooltip: {
    fontSize: "12px",
    fontWeight: "bold",
  },
});

const messages = defineMessages({
  clickToChangeValue: {
    id: "evaluationTable.clickToChangeValue",
  },
});

const StatusBar = styled.div`
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : ""} !important;

  @media print {
    background-color: ${(props) =>
      props.backgroundColor ? props.backgroundColor : ""} !important;
  }
`;

class TableEvaluation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: props.title,
      titlePopover: props.titlePopover,
      tableValues: props.values,
      options: props.options,
    };
  }

  UNSAFE_componentWillReceiveProps({ title, values, options, titlePopover }) {
    this.setState({
      title,
      tableValues: values,
      options,
      titlePopover,
    });
  }

  handleSave = (id, newValue) => {
    if (!this.props.readOnly) {
      const newOptions = this.state.options;
      Object.keys(newOptions).forEach((key) => {
        if (newOptions[key].id === id) newOptions[key].value = newValue;
      });

      if (this.props.onSave) {
        this.props.onSave(newOptions);
      }

      this.setState({ options: [...newOptions] });
    }
  };

  render() {
    const { title, titlePopover, tableValues, options } = this.state;

    const {
      bottomDescription,
      hideTopNumbers,
      hideTopLabels,
      readOnly,
      headTitleWidth,
      showCustomMenu,
      intl,
    } = this.props;

    const tableClasses = !readOnly
      ? "table-evaluation"
      : "table-evaluation readOnly";

    const thTitleWidth = headTitleWidth || "auto";

    const alternativePopover = () => {
      return <div />;
    };

    const handleMenuActions = (labels = [], tableValues = [], id = null) => {
      let mountedLabels = [];

      mountedLabels = labels.map(({ label, index }) => {
        const tableHead = tableValues.filter(
          (tableHead) => tableHead.valueNumber === index
        );

        return {
          icon: <h5>{index}</h5>,
          text: label || "",
          action: () => this.handleSave(id, tableHead[0].value),
        };
      });

      return mountedLabels;
    };

    return (
      <table
        className={`${tableClasses} simple-table`}
        style={this.props.fullWidth ? { width: "100%" } : {}}
      >
        <thead>
          <tr>
            <th style={{ padding: "0 15px", width: thTitleWidth }}>
              {titlePopover ? (
                <OverlayTrigger
                  placement="right"
                  overlay={titlePopover || alternativePopover}
                >
                  <h4 className="cursorPointer">
                    {title}&nbsp;
                    <span>
                      <i className={`fas fa-info-circle`}></i>
                    </span>
                  </h4>
                </OverlayTrigger>
              ) : (
                <h4>{title}</h4>
              )}
            </th>
            {tableValues.map(
              (
                {
                  valueNumber,
                  topLabel,
                  bottomLabel,
                  bottomLabelPopover,
                  color,
                },
                index
              ) => {
                return (
                  <th
                    style={{
                      margin: "0 2px",
                      minWidth: "100px",
                      textAlign: "center",
                    }}
                    key={index}
                  >
                    <div>
                      <div style={{ height: "20px" }}>
                        {topLabel && !hideTopLabels && (
                          <h5 style={{ margin: "0" }}>{topLabel}</h5>
                        )}
                      </div>
                      {!hideTopNumbers && <h4>{valueNumber}</h4>}
                      {color && (
                        <StatusBar
                          backgroundColor={color}
                          style={{
                            width: "calc(100% - 2px)",
                            margin: "1px",
                            height: "8px",
                            backgroundColor: color,
                          }}
                        />
                      )}
                    </div>
                    {bottomLabelPopover ? (
                      <OverlayTrigger
                        placement="top"
                        overlay={bottomLabelPopover || alternativePopover}
                      >
                        <h5 className="cursorPointer">
                          {bottomLabel}&nbsp;
                          <span>
                            <i className={`fas fa-info-circle`}></i>
                          </span>
                        </h5>
                      </OverlayTrigger>
                    ) : (
                      <h5>{bottomLabel}</h5>
                    )}
                  </th>
                );
              }
            )}
          </tr>
        </thead>
        <tbody>
          {options.map(
            (
              { id = null, label, value, popover, selectedLabel, labels },
              index
            ) => {
              let listOfBodyValues = [];
              listOfBodyValues =
                typeof value !== "string" ? value : [{ slug: value }];

              return (
                <tr key={index}>
                  <td style={{ padding: "10px 20px" }}>
                    {popover ? (
                      <OverlayTrigger
                        placement="top"
                        overlay={popover || alternativePopover}
                      >
                        <div>{label}</div>
                      </OverlayTrigger>
                    ) : (
                      <div>{label}</div>
                    )}
                  </td>
                  {showCustomMenu && (
                    <td
                      style={{
                        paddingTop: "10px",
                        width: "auto",
                      }}
                    >
                      <MaterialCustomMenu
                        menuIcon={
                          <div
                            style={{
                              display: "flex",
                              width: "100%",
                              justifyContent: "center",
                            }}
                          >
                            {selectedLabel}
                          </div>
                        }
                        menuActions={handleMenuActions(labels, tableValues, id)}
                      />
                    </td>
                  )}
                  {tableValues.map((tableHead, index) => {
                    let isSelected = false;
                    let displayValue = <i className="fas fa-check" />;
                    let displayProgressValue = "";

                    listOfBodyValues.forEach((itemObj) => {
                      if (itemObj.slug === tableHead.value) {
                        isSelected = true;
                        displayValue =
                          itemObj.displayValue || itemObj.displayValue === 0
                            ? itemObj.displayValue
                            : displayValue;
                      }

                      if (
                        itemObj.slug === tableHead.value &&
                        itemObj.slug === "AVERAGE" &&
                        !!displayValue
                      ) {
                        const newValue = displayValue?.replace(".", "");
                        const progressValue = parseInt(newValue, 10) * 2;

                        displayProgressValue = (
                          <>
                            <span>{displayValue}</span>
                            <BadgeProgressBar
                              widthBar="100%"
                              leftText={progressValue}
                              barColor="blue"
                              heightBar={13}
                            />
                          </>
                        );
                      }
                    });

                    return (
                      <td
                        style={{ textAlign: "center" }}
                        title={
                          !isSelected && !readOnly
                            ? intl.formatMessage(messages.clickToChangeValue)
                            : null
                        }
                        onClick={() => this.handleSave(id, tableHead.value)}
                        key={index}
                      >
                        {displayProgressValue || (isSelected && displayValue)}
                      </td>
                    );
                  })}
                </tr>
              );
            }
          )}
        </tbody>
        {bottomDescription && (
          <tfoot>
            <tr>
              <td />
              <td colSpan="10" align="center">
                {bottomDescription}
              </td>
            </tr>
          </tfoot>
        )}
      </table>
    );
  }
}

TableEvaluation.propTypes = {
  title: PropTypes.string,
  bottomDescription: PropTypes.string,
  values: PropTypes.arrayOf(
    // Valores do TABLE HEAD
    shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      valueNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      topLabel: PropTypes.string,
      bottomLabel: PropTypes.string,
      color: PropTypes.string,
    })
  ).isRequired,
  options: PropTypes.arrayOf(
    // Valores do TABLE BODY
    shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.arrayOf(
          PropTypes.objectOf(
            PropTypes.oneOfType([
              PropTypes.string,
              PropTypes.object,
              PropTypes.number,
            ])
          )
        ),
      ]).isRequired,
    })
  ).isRequired,
  onSave: PropTypes.func,
  hideTopNumbers: PropTypes.bool,
  hideTopLabels: PropTypes.bool,
  fullWidth: PropTypes.bool,
  readOnly: PropTypes.bool,
  headTitleWidth: PropTypes.string,
  showCustomMenu: PropTypes.bool,
  titlePopover: PropTypes.node,
  levelTitle: PropTypes.string,
  alternativePopover: PropTypes.node,
  handleSave: PropTypes.func,
};

TableEvaluation.defaultProps = {
  showCustomMenu: false,
};

export default injectIntl(withStyles(styles)(TableEvaluation));
