import { defineMessages } from "react-intl";
import { translatedText } from "../../../../../../../utils/translationUtils";
import LeadershipCard from "./LeadershipCard";

const messages = defineMessages({
  pb_leading_1: {
    id: "pb_leading_1",
  },
  pb_leading_2: {
    id: "pb_leading_2",
  },
  pb_leading_3: {
    id: "pb_leading_3",
  },
  pb_leading_4: {
    id: "pb_leading_4",
  },
  pb_leading_5: {
    id: "pb_leading_5",
  },
  pb_leading_6: {
    id: "pb_leading_6",
  },
  pb_leading_7: {
    id: "pb_leading_7",
  },
  pb_leading_8: {
    id: "pb_leading_8",
  },
  pb_leading_9: {
    id: "pb_leading_9",
  },
});

const translation = (id) => translatedText(id, messages);

const defaultNineboxEvaluation = [
  {
    number: 6,
    value: "EFFECTIVE",
    color: "#5df0a3",
    label: translation("pb_leading_6"),
  },
  {
    number: 3,
    value: "GOOD_POTENTIAL",
    color: "#3ab361",
    label: translation("pb_leading_3"),
  },
  {
    number: 1,
    value: "HIGH_POTENTIAL",
    color: "#0b8b34",
    label: translation("pb_leading_1"),
  },
  {
    number: 8,
    value: "LOW_PERFORMANCE",
    color: "#f0f579",
    label: translation("pb_leading_8"),
  },
  {
    number: 5,
    value: "GROWING",
    color: "#5df0a3",
    label: translation("pb_leading_5"),
  },
  {
    number: 2,
    value: "HIGH_PERFORMANCE",
    color: "#3ab361",
    label: translation("pb_leading_2"),
  },
  {
    number: 9,
    value: "OUTPUT_POSITION",
    color: "#ffc736",
    label: translation("pb_leading_9"),
  },
  {
    number: 7,
    value: "NEW",
    color: "#f0f579",
    label: translation("pb_leading_7"),
  },
  {
    number: 4,
    value: "MEDIUM_PERFORMANCE",
    color: "#5df0a3",
    label: translation("pb_leading_4"),
  },
];

export default function LeadershipMap({
  filteredEvaluations,
  selectAndOpenInsight,
}) {
  const separatedEvaluation = {};

  filteredEvaluations.forEach((item) => {
    const { evaluationTarget } = item?.peopleEvaluation || {};
    const { lastEvaluations } = evaluationTarget;
    const { leading } = lastEvaluations;

    if (leading?.current) {
      if (!separatedEvaluation[leading?.current]) {
        separatedEvaluation[leading?.current] = [];
      }

      separatedEvaluation[leading?.current].push(item);
    }
  });

  return (
    <div style={{ display: "flex", gap: "1%", flexWrap: "wrap" }}>
      {defaultNineboxEvaluation.map((item) => {
        const evaluation = item.value;
        const evaluationData = separatedEvaluation[evaluation];

        return (
          <LeadershipCard
            key={item.value}
            label={item.label}
            color={item.color}
            evaluation={evaluationData}
            percentage={
              (evaluationData?.length / filteredEvaluations?.length) * 100
            }
            onClick={(item) => selectAndOpenInsight(item?.insightId)}
          />
        );
      })}
    </div>
  );
}
