import axios from "axios";
import { selectInsight, unselectInsight } from "./insightActions";
import {
  CHANGE_STATUS_ANSWER,
  DELETE_QUESTION_ANSWER,
  FETCH_ALL_QUESTIONS_ANSWERS,
  FETCH_QUESTION_ANSWERS,
  UPDATE_QUESTION_ANSWER,
  UPDATE_SINGLE_INSIGHT,
} from "./types";

export const fetchQuestionAnswers = (questionId) => {
  return (dispatch) => {
    return axios.get(`/api/answers/question/${questionId}`).then((res) => {
      const data = {
        questionId,
        answers: res.data,
      };
      dispatch({ type: FETCH_QUESTION_ANSWERS, payload: data });
    });
  };
};

const getAnswerWithProfessionalMember = async (
  allAnswers,
  relatedData = {}
) => {
  function getProfessionalMember(answers, data) {
    const newinsights = answers.map((insight) => {
      let newInsight = { ...insight, professionalMember: true };

      const findedEvaluation = data?.find(
        (dataInfo) => dataInfo.insightId === insight.id
      );

      if (findedEvaluation) {
        const { peopleEvaluation = {} } = findedEvaluation;
        const { professionalProfile = {} } = peopleEvaluation;
        const { professional = {} } = professionalProfile;

        if (professional.entityId) {
          const { member = {} } = professional;

          if (member && member.userData) {
            newInsight = {
              ...insight,
              professionalMember: member,
            };
          }
        }
      }

      return newInsight;
    });

    return newinsights;
  }

  async function mountProfessionalWithAvatar(data, allAnswers) {
    let newAnswers = {};

    Object.keys(allAnswers).forEach((questionId) => {
      newAnswers[questionId] = getProfessionalMember(
        allAnswers[questionId],
        data
      );
    });

    return newAnswers;
  }

  const newAnswers = await mountProfessionalWithAvatar(
    relatedData.data,
    allAnswers
  );

  return newAnswers;
};

export const fetchAllQuestionAnswers = async (questionsList, relatedData) => {
  return async (dispatch) => {
    const sendDispatch = (answers) => {
      dispatch({ type: FETCH_ALL_QUESTIONS_ANSWERS, payload: answers });
    };

    let finalAnswers = {};

    const { data: answersArray } = await axios.post(
      "/api/answers/questions",
      questionsList
    );

    const filteredAnswers = answersArray.filter(
      (answer) => answer.type === "insight"
    );

    filteredAnswers.forEach((answerInfo) => {
      const { question = {} } = answerInfo;
      const { id: questionId = null } = question;

      if (questionId) {
        if (!finalAnswers[questionId]) finalAnswers[questionId] = [];
        finalAnswers[questionId].push(answerInfo);
      }
    });

    if (relatedData) {
      finalAnswers = await getAnswerWithProfessionalMember(
        finalAnswers,
        relatedData
      );
    }

    sendDispatch(finalAnswers);

    return finalAnswers;
  };
};

export const fetchInsightsWithReturn = (questionId) => {
  return (dispatch) => {
    return axios.get(`/api/answers/question/${questionId}`).then((res) => {
      return res.data;
    });
  };
};

export const addNewInsight = (answerId, questionId) => {
  unselectInsight();
  return (dispatch) => {
    return axios.post(`/api/answers/${answerId}/addNewInsight`).then((res) => {
      const data = {
        questionId,
        answer: { ...res.data, questionId },
      };
      dispatch({ type: UPDATE_QUESTION_ANSWER, payload: data });
      dispatch(selectInsight(answerId));
    });
  };
};

export const changeStatus = (answer, status, questionId) => {
  return (dispatch) => {
    axios.post(`/api/answers/${answer.id}/changeStatus/${status}`);
    const data = {
      answer: { ...answer, status, isNew: false },
      questionId,
    };
    dispatch({ type: CHANGE_STATUS_ANSWER, payload: data });
    return data;
  };
};

export const deleteAnswer = (answer, callbacks) => {
  const { onSuccess = () => {}, onError = () => {} } = callbacks || {};

  return (dispatch) => {
    try {
      return axios.delete(`/api/answers/${answer.id}`).then((res) => {
        if (res.data)
          dispatch({
            type: DELETE_QUESTION_ANSWER,
            payload: { answer },
          });
        onSuccess(res.data);
      });
    } catch (err) {
      onError(err);
      return false;
    }
  };
};

export const fetchAnswerTags = (id) => {
  return (dispatch) => {
    /* return axios.get(`/api/insight/${id}/tags`).then(res => {
      dispatch({ type: FETCH_INSIGHT_TAGS, payload: res.data });
    }); */
  };
};

export const updateSingleInsight = (insight) => {
  return (dispatch) => {
    dispatch({ type: UPDATE_SINGLE_INSIGHT, payload: insight });
  };
};

export const onAddTag = (answer, text) => {
  return (dispatch) => {
    return axios.post(`/api/insights/${answer}/tags/${text}`).then((res) => {
      dispatch(fetchAnswerTags(answer));
    });
  };
};

export const onRemoveTag = (insightId, tag) => {
  return (dispatch) => {
    return axios
      .delete(`/api/insights/${insightId}/tags/${tag}`)
      .then((res) => {
        dispatch(fetchAnswerTags(insightId));
      });
  };
};
