import { sortArrayByAttribute } from "../projectEvaluation";
import { updateAllList } from "../reducerUtils";

export const getParentDistribuition = (projectConfiguration = {}) => {
  const { stages = [] } = projectConfiguration || {};
  let parents = [];
  let sons = [];

  stages.forEach((stage) => {
    const { parentId = false } = stage || {};

    if (!parentId) return parents.push(stage);

    sons.push(stage);
  });

  return { parents, sons };
};

export const getStagesConfiguration = (projectConfiguration = {}) => {
  const { parents = [], sons = [] } =
    getParentDistribuition(projectConfiguration);

  const stagesDistribuitions = parents.map((parent) => {
    const { id = "" } = parent || {};

    const allSons = sons.filter((son) => {
      const { parentId = "" } = son || {};

      if (parentId === id) {
        return true;
      } else {
        return false;
      }
    });

    return {
      ...parent,
      sons: [...(sortArrayByAttribute(allSons, "id") || [])],
    };
  });

  return sortArrayByAttribute(stagesDistribuitions, "id");
};

export const getProjectConfiguration = (
  allProjectConfigurations = [],
  selectedToolId = ""
) => {
  return (
    allProjectConfigurations.filter(
      (projectConfiguration) =>
        projectConfiguration.selectedToolId === selectedToolId
    )[0] || {}
  );
};

export const getProjectConfigurationById = (
  allProjectConfigurations = [],
  projectConfigurationId = ""
) => {
  return (
    allProjectConfigurations.filter(
      ({ id = "" }) => projectConfigurationId === id
    )[0] || {}
  );
};

export const updateProjectStage = (
  allProjectConfigurations = [],
  payload = {}
) => {
  const { projectConfigurationId = "" } = payload || {};

  let currentProjectConfiguration = getProjectConfigurationById(
    allProjectConfigurations,
    projectConfigurationId
  );

  const { stages = [] } = currentProjectConfiguration || {};

  const updatedStages = updateAllList(stages, [payload], "id");

  currentProjectConfiguration = {
    ...currentProjectConfiguration,
    stages: [...updatedStages],
  };

  return updateAllList(
    allProjectConfigurations,
    [currentProjectConfiguration],
    "selectedToolId"
  );
};

export const deleteProjectStage = (
  allProjectConfigurations = [],
  { projectConfigurationId = "", projectStageId = "" }
) => {
  let currentProjectConfiguration = getProjectConfigurationById(
    allProjectConfigurations,
    projectConfigurationId
  );

  const { stages = [] } = currentProjectConfiguration || {};

  const updatedStages = stages.filter((stage) => stage.id !== projectStageId);

  currentProjectConfiguration = {
    ...currentProjectConfiguration,
    stages: [...updatedStages],
  };

  return updateAllList(
    allProjectConfigurations,
    [currentProjectConfiguration],
    "selectedToolId"
  );
};
