import { useState } from "react";
import styled from "styled-components";
import { putCustomPeriod } from "../../../../actions/customPeriodActions";
import { formatDates } from "../../../../utils/customPeriods/validations";
import FormDialog from "../../../Common/FormDialog";
import MaterialTextField from "../../../Common/MaterialTextField";
import SimpleCheckbox from "../../../Common/SimpleCheckbox";

const Wrapper = styled.div`
  width: 25%;
`;

const Card = styled.div`
  margin: 10px;
  border-radius: 5px;
  overflow: hidden;

  h3 {
    background-color: ${({ is_default }) => (is_default ? "#c4e16c" : "#222")};
    color: ${({ is_default }) => (is_default ? "#111" : "#fff")};
    text-align: center;
    font-size: 18px;
    height: 25px;
    line-height: 25px;
    margin: 0px;
  }
`;

const CardBody = styled.div`
  background-color: #f0f0f0;
  padding: 15px;
`;

const Actions = styled.div`
  display: flex;
  gap: 15px;
  margin: 10px 0px;
  justify-content: flex-end;
`;

const Button = styled.button`
  background-color: #ddd;
  border: none;
  padding: 2px 5px;
  border-radius: 5px;
`;

const PeriodCard = ({ customPeriod, fetchPeriods }) => {
  const [form, setForm] = useState({});
  const [editOpen, setEditOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  function openEditing() {
    setEditOpen(true);
    setForm({ ...customPeriod });
  }

  function closeEdit() {
    setEditOpen(false);
  }

  function inputChange(field, newValue) {
    setForm((current) => {
      return {
        ...current,
        [field]: newValue,
      };
    });
  }

  function dateChange(field, newDate) {
    inputChange(field, `${newDate}T03:00:00.000Z`);
  }

  async function handleUpdatePeriod() {
    if (form?.id) {
      setIsLoading(true);
      await putCustomPeriod(form);
      await fetchPeriods();
      setIsLoading(false);
      closeEdit();
    }
  }

  const { title, is_default } = customPeriod;

  const formDates = formatDates({
    start_date: form.start_date,
    end_date: form.end_date,
  });

  const currentDates = formatDates({
    start_date: customPeriod.start_date,
    end_date: customPeriod.end_date,
  });

  return (
    <Wrapper>
      {editOpen && (
        <FormDialog
          open
          onCancel={() => closeEdit()}
          onConfirm={handleUpdatePeriod}
          title="Editar Período"
          blockConfirm={isLoading}
        >
          <div className="col-xs-12">
            <MaterialTextField
              type="text"
              label="Título do período"
              onChange={(e) => inputChange("title", e.target.value)}
              value={form.title}
            />
          </div>
          <div className="col-xs-12 col-md-6">
            <MaterialTextField
              type="date"
              label="Data de início"
              onChange={(e) => dateChange("start_date", e.target.value)}
              value={formDates.start_date}
            />
          </div>
          <div className="col-xs-12 col-md-6">
            <MaterialTextField
              type="date"
              label="Data final"
              onChange={(e) => dateChange("end_date", e.target.value)}
              value={formDates.end_date}
            />
          </div>
          <div className="col-xs-12" style={{ paddingTop: "15px" }}>
            <SimpleCheckbox
              checked={form.is_default}
              onChange={() => {
                inputChange("is_default", !form.is_default);
              }}
              label="Tornar período padrão"
            />
          </div>
        </FormDialog>
      )}
      <Card is_default={is_default}>
        <h3>{title}</h3>
        <CardBody>
          <MaterialTextField
            type="date"
            label="Data de início"
            onChange={() => {}}
            value={currentDates.start_date}
          />
          <MaterialTextField
            type="date"
            label="Data final"
            onChange={() => {}}
            value={currentDates.end_date}
          />
          <Actions>
            <Button onClick={() => openEditing()}>Editar</Button>
          </Actions>
        </CardBody>
      </Card>
    </Wrapper>
  );
};

export default PeriodCard;
