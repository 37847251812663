import React, { useState, useEffect } from "react";
import { injectIntl, defineMessages } from "react-intl";
import { connect } from "react-redux";
import _ from "lodash";
import AddIcon from "@material-ui/icons/Add";
import InsightBox from "./InsightBox";
import { customAddInsight } from "../../../actions/structureActions";
import {
  addNewInsight,
  convertAnswerInsight,
  updateInsightData,
  updateInsightName,
  archiveInsights,
  selectInsight,
} from "../../../actions/insightActions";
import { deleteAnswer } from "../../../actions/answersActions";
import {
  getSelectedToolStates,
  getCompAnalysisStates,
} from "../../customMapStates";
import EditableTextArea from "../../Common/EditableTextArea";
import UnarchiveDialog from "../../Common/UnarchiveDialog";
import ArchiveDialog from "../../Common/ArchiveDialog";
import DeleteDialog from "../../Common/DeleteDialog";
import DragnDropContainer from "../../Common/DragnDropContainer";
import utils from "../../../utils/toolUtils";
import { checkAccess } from "../../../utils/accessLevels";
import {
  OKR_ID,
  KPIS,
  PEOPLE_BIG_PICTURE,
  PROJECT_PORTFOLIO,
  PROCESSES_BIG_PICTURE,
  COMPETITIVE_ANALYSIS,
} from "../../../constants/tools.constants";
import { getCompetitiveAnalysisConfig } from "../../../utils/entityUtils";

const {
  getEntityConfigurations,
  getAddEntityLabel,
  getEntityLabel,
  iterationSearch,
  getOnlySlugValues,
  getCustomInsightsOrder,
} = utils;

const messages = defineMessages({
  insight_main_title: {
    id: "insight_main_title",
  },
  alertMessage: {
    id: "equal_insight_name_alert",
  },
  minLength: {
    id: "form_min_length",
  },
});

const defaultInsightForm = {
  id: null,
  text: "",
  name: "",
  type: "insight",
};

const defaultInsightDialog = {
  type: "",
  onConfirm: () => {},
};

const InsightContainer = (props) => {
  const [insightForm, setInsightForm] = useState(defaultInsightForm);
  const [insightDialog, setInsightDialog] = useState(defaultInsightDialog);
  const [orderedInsights, setOrderedInsights] = useState([]);

  const {
    questionInfo = {},
    insights = [],
    selectedTool,
    toggleInsightModal,
    intl,
    loggedUser = {},
    replaceConfig = {},
    addButton = true,
    currentToolType,
    reorderEntity,
    editModeOn,
    toggleBoardEditMode,
  } = props;

  const toolId =
    selectedTool.tool && selectedTool.tool.id ? selectedTool.tool.id : null;

  const insightsConfig = getEntityConfigurations(
    "insight",
    toolId,
    replaceConfig
  );

  useEffect(() => {
    if (insights?.length > 0) {
      const newOrdered = getCustomInsightsOrder(insights, toolId);

      if (!_.isEqual(newOrdered, orderedInsights))
        setOrderedInsights(
          newOrdered.filter((insight) => insight.type === "insight")
        );
    } else if (orderedInsights.length > 0) {
      setOrderedInsights([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insights, toolId]);

  const handleReorder = (reorderedItems) => {
    if (Array.isArray(reorderedItems)) {
      if (!editModeOn) toggleBoardEditMode();
      const reoderedInsights = [];

      reorderedItems.forEach(({ content, order }) => {
        if (content.props && content.props.insight)
          reoderedInsights.push({
            ...content.props.insight,
            order,
          });
      });

      setOrderedInsights(reoderedInsights);
      reorderEntity("insights", reoderedInsights, questionInfo.id);
    }
  };

  const resetInsightForm = () => {
    setInsightForm(defaultInsightForm);
  };

  const resetInsightDialog = () => {
    setInsightDialog(defaultInsightDialog);
  };

  function handleMiddlewareCustomization() {
    let custom = false;

    const params = {
      insightQuestion: questionInfo,
      order: insights.length + 1,
    };

    const competitiveAnalysisConfig =
      toolId === COMPETITIVE_ANALYSIS
        ? getCompetitiveAnalysisConfig("insight", currentToolType)
        : insightsConfig;

    if (
      insightsConfig.registrationMiddleware &&
      competitiveAnalysisConfig.registrationMiddleware
    ) {
      custom = () => props.customAddInsight(toolId, { ...params });
    }

    return custom;
  }

  const handleNewInsight = () => {
    const middlewareCustomization = handleMiddlewareCustomization();

    if (typeof middlewareCustomization === "function") {
      middlewareCustomization();
    } else {
      setInsightForm({
        ...defaultInsightForm,
        editing: true,
        new: true,
        question: questionInfo,
        order: insights.length,
      });
    }
  };

  const saveUpdateInsight = async (insightInfo = {}) => {
    const insightsValidation = [...insights].filter(
      (ins) => ins.id !== insightInfo.id
    );
    const insightsIteration = getOnlySlugValues(insightsValidation, "text");

    const isDuplicated = await iterationSearch(
      insightInfo.text,
      insightsIteration
    );

    const validatedInsightText = insightInfo.text.trim().replace(/\s/g, "");

    if (!isDuplicated && validatedInsightText.length > 3) {
      if (insightInfo.text && insightForm.new) {
        props.addNewInsight(
          {
            ...insightForm,
            text: insightInfo.text,
            name: insightInfo.text,
            owner: loggedUser,
          },
          questionInfo.id
        );
      } else if (insightInfo.text && insightInfo.id) {
        if (insightInfo.type === "information")
          await props.convertAnswerInsight(insightInfo, questionInfo);

        props.updateInsightName(
          insightInfo.questionId,
          insightInfo.id,
          insightInfo.text
        );
      }
    } else if (
      isDuplicated &&
      insightInfo.text.trim().replace(/\s/g, "").length > 3
    ) {
      alert(intl.formatMessage(messages.alertMessage));
    } else {
      alert(intl.formatMessage(messages.minLength));
    }

    resetInsightForm();
  };

  const cancelUpdate = () => {
    resetInsightForm();
  };

  const handleSelectInsight = async (answerInsightInfo = null) => {
    const {
      type = "",
      owner: currentOwner = {},
      responsibleMember = false,
    } = answerInsightInfo || {};

    answerInsightInfo = {
      ...answerInsightInfo,
      owner: currentOwner || loggedUser,
    };

    if (type !== "insight" || !responsibleMember) {
      await props.convertAnswerInsight(answerInsightInfo, questionInfo);
    } else {
      await props.selectInsight(answerInsightInfo.id, answerInsightInfo);
    }

    toggleInsightModal(true);
  };

  function handleEditInsight(insightInfo = {}) {
    if (insightInfo.id) setInsightForm({ ...insightInfo, editing: true });
  }

  function handleArchiveInsight(insightInfo) {
    if (insightInfo && insightInfo.id) {
      props.archiveInsights([insightInfo.id]);
    }
  }

  function handleDeleteInsight(insightInfo) {
    if (insightInfo && insightInfo.id)
      props.deleteAnswer(insightInfo, questionInfo.id);
  }

  function openArchiveDialog(insightInfo) {
    setInsightDialog({
      type: "ARCHIVE_INSIGHT",
      onConfirm: () => handleArchiveInsight(insightInfo),
    });
  }

  function openUnarchiveDialog(insightInfo) {
    setInsightDialog({
      type: "UNARCHIVE_INSIGHT",
      insight: insightInfo,
      onConfirm: () => handleArchiveInsight(insightInfo),
    });
  }

  function openDeleteDialog(insightInfo) {
    setInsightDialog({
      type: "DELETE_INSIGHT",
      onConfirm: () => handleDeleteInsight(insightInfo),
    });
  }

  function closeDialogAndConfirm() {
    if (insightDialog.onConfirm) insightDialog.onConfirm();

    resetInsightDialog();
  }

  const appendProps = {
    selectInsight: handleSelectInsight,
    selectedTool,
    insightsConfig,
    editModeOn,
  };

  const isAddingNewInsight = insightForm.editing && insightForm.new;
  const insightTranslation = getEntityLabel("insight", toolId, intl);

  const toolPermission = [
    OKR_ID,
    KPIS,
    PEOPLE_BIG_PICTURE,
    PROJECT_PORTFOLIO,
    PROCESSES_BIG_PICTURE,
  ];

  return (
    <>
      <div style={{ backgroundColor: "#f3f3f3" }}>
        <DragnDropContainer
          droppableId={`insightsDropabbleListQID-${questionInfo.id}`}
          handleReorder={handleReorder}
          items={orderedInsights.map((insightInfo, index) => {
            const isEditingInsight =
              insightForm.editing && insightForm.id === insightInfo.id;

            return {
              id: insightInfo.id,
              order: insightInfo.order,
              content: (
                <InsightBox
                  key={index}
                  insight={insightInfo}
                  editing={isEditingInsight}
                  avatarPermission={toolPermission.includes(toolId)}
                  saveUpdateInsight={({ text }) =>
                    saveUpdateInsight({ ...insightInfo, text })
                  }
                  handleEditInsight={() => handleEditInsight(insightInfo)}
                  openArchiveDialog={() => openArchiveDialog(insightInfo)}
                  openUnarchiveDialog={() => openUnarchiveDialog(insightInfo)}
                  openDeleteDialog={() => openDeleteDialog(insightInfo)}
                  cancelUpdate={() => resetInsightForm()}
                  themeInfo={props.themeInfo}
                  {...appendProps}
                />
              ),
            };
          })}
        />
      </div>
      {insightsConfig.registrations &&
        !editModeOn &&
        addButton &&
        checkAccess(["MANAGER", "LEADER", "MODERATOR", "ADMIN", "OWNER"]) && (
          <div
            className="add-insight-button"
            onClick={!isAddingNewInsight ? handleNewInsight : () => {}}
          >
            {!isAddingNewInsight && (
              <div style={{ userSelect: "none" }}>
                <AddIcon /> {getAddEntityLabel("insight", toolId, intl)}
              </div>
            )}
            {isAddingNewInsight && (
              <EditableTextArea
                id="newInsight"
                actionIcons
                text={insightForm.text}
                onSave={(newName) => saveUpdateInsight({ text: newName })}
                onCancel={() => cancelUpdate()}
                editing={insightForm.editing}
                filed={insightForm.filed}
                width="300px"
                margin="none"
              />
            )}
          </div>
        )}
      <ArchiveDialog
        entityName={insightTranslation}
        show={insightDialog.type === "ARCHIVE_INSIGHT"}
        onConfirm={closeDialogAndConfirm}
        onCancel={resetInsightDialog}
      />
      <UnarchiveDialog
        action={intl.formatMessage({ id: "global.unArchive" })}
        entityName={insightTranslation}
        show={insightDialog.type === "UNARCHIVE_INSIGHT"}
        onConfirm={closeDialogAndConfirm}
        onCancel={resetInsightDialog}
      />
      <DeleteDialog
        entityName={insightTranslation}
        show={insightDialog.type === "DELETE_INSIGHT"}
        onConfirm={closeDialogAndConfirm}
        onCancel={resetInsightDialog}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  const { user } = state;
  const { selectedTool } = getSelectedToolStates(state);
  const { currentToolType = "" } = getCompAnalysisStates(state);

  return {
    selectedTool,
    loggedUser: user,
    currentToolType,
  };
};

export default injectIntl(
  connect(mapStateToProps, {
    customAddInsight,
    addNewInsight,
    updateInsightData,
    updateInsightName,
    convertAnswerInsight,
    selectInsight,
    archiveInsights,
    deleteAnswer,
  })(InsightContainer)
);
