import React, { useEffect } from "react";
import PropTypes from "prop-types";
import SimpleCheckbox from "../../../../../../Common/SimpleCheckbox";

const TableGeneralOptionsConfig = ({
  evaluationType,
  transparency,
  updateTransparencyConfig,
  generalOptions,
  toggleGeneralOptions,
  enableEvaluatorsSelectionWorkflow,
  updatedEvaluatorsSelectionWorkflow,
}) => {
  useEffect(() => {
    if (evaluationType !== "TYPE_360") {
      updatedEvaluatorsSelectionWorkflow(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [evaluationType]);

  return (
    <table className="simple-table">
      <thead>
        <tr>
          <td colSpan="100">CONFIGURAÇÕES GERAIS</td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td width="35">
            <SimpleCheckbox
              checked={generalOptions.disableRequiredQuestionsValidation}
              onChange={() =>
                toggleGeneralOptions("disableRequiredQuestionsValidation")
              }
            />
          </td>
          <td>Desativar validação para perguntas obrigatórias do workflow</td>
        </tr>
        <tr>
          <td width="35">
            <SimpleCheckbox
              checked={generalOptions.restrictViewAccess}
              onChange={() => toggleGeneralOptions("restrictViewAccess")}
            />
          </td>
          <td>
            Esconder o Dashboard, Quadro, Insight e Avaliações Gerais para
            Participantes e Avaliadores
          </td>
        </tr>
        <tr>
          <td width="35">
            <SimpleCheckbox
              checked={transparency !== true}
              onChange={() => updateTransparencyConfig(!transparency)}
            />
          </td>
          <td>Modo anônimo para avaliações e comentários</td>
        </tr>
        {evaluationType === "TYPE_360" && (
          <tr>
            <td width="35">
              <SimpleCheckbox
                checked={enableEvaluatorsSelectionWorkflow !== false}
                onChange={() =>
                  updatedEvaluatorsSelectionWorkflow(
                    !enableEvaluatorsSelectionWorkflow
                  )
                }
              />
            </td>
            <td>Habilitar seleção de avaliadores pelo participante</td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

TableGeneralOptionsConfig.propTypes = {
  transparency: PropTypes.object.isRequired,
  updateTransparencyConfig: PropTypes.func.isRequired,
};

export default TableGeneralOptionsConfig;
