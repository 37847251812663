import momemt from "moment";
import { useCallback, useEffect, useState } from "react";
import { defineMessages, injectIntl } from "react-intl";

import { Tooltip } from "@material-ui/core";
import _ from "lodash";
import { Col, OverlayTrigger, Popover, Row } from "react-bootstrap";
import { getUserById } from "../../../../../../../utils/accessLevels";
import {
  getActivityStage,
  getActivityStatus,
  getSchedulePercentage,
  getStatusProjectByClassName,
} from "../../../../../../../utils/projectEvaluation";
import Avatar from "../../../../../../Common/Avatar";
import "./styles.scss";

import { getToFixed } from "../../../../../../../utils/MathUtils";
import { getStageParentInfo } from "../../../../../../../utils/projectUtils";
import MaterialMultiSelect from "../../../../../../Common/MaterialMultiSelect";

const messages = defineMessages({
  selectResponsible: {
    id: "select.responsible",
  },
  selectMilestoneResponsible: {
    id: "select.milestone.responsible",
  },
  stage: {
    id: "tool.project.stage",
  },
});

const Activities = ({
  reportMapData,
  selectAndOpenInsight,
  activitiesSubstages,
  activitiesResponsibles,
  companyMembersAndParticipants,
  intl,
}) => {
  const [data, setData] = useState([]);
  const [milestoneReponsibles, setMilestoneReponsibles] = useState([]);
  const [selectedResponsibles, setSelectedResponsibles] = useState([]);
  const [hiddenActivities, setHiddenActivities] = useState(() => {
    const hiddenActivitiesStorage = JSON.parse(
      localStorage.getItem("hiddenActivities")
    );

    if (hiddenActivitiesStorage) {
      return hiddenActivitiesStorage;
    }

    return [];
  });

  useEffect(() => {
    localStorage.setItem("hiddenActivities", JSON.stringify(hiddenActivities));
  }, [hiddenActivities]);

  const handleExpansiveActivities = useCallback(
    (id) => {
      hiddenActivities.includes(id)
        ? setHiddenActivities((oldHiddenActivities) =>
            oldHiddenActivities.filter((activityId) => activityId !== id)
          )
        : setHiddenActivities((oldHiddenActivities) => [
            ...oldHiddenActivities,
            id,
          ]);
    },
    [hiddenActivities]
  );

  useEffect(() => {
    const equalStates = _.isEqual(data, reportMapData);

    if (!equalStates) setData(reportMapData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportMapData]);

  useEffect(() => {
    const responsibles = [];

    reportMapData.forEach(({ evaluation = {} }) => {
      const { projectEvaluation = {} } = evaluation || {};
      const { activities = {} } = projectEvaluation || {};

      activities.forEach(({ responsible = {} }) => {
        const { id: responsibleId = false } = responsible || {};

        if (!responsibleId) return;

        const { id = "", name = "" } =
          companyMembersAndParticipants.filter(
            (line) => line.id === responsibleId
          )[0] || {};

        if (responsibles.filter((resp) => resp.id === id).length > 0) return;

        responsibles.push({ id, name: name.toUpperCase() });
      });
    });

    setMilestoneReponsibles(responsibles);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportMapData]);

  const responsibleOptions = activitiesResponsibles.map(
    ({ id, responsibleName }) => {
      return { value: id, text: responsibleName };
    }
  );

  const initialOptions = [...responsibleOptions.map(({ value }) => value)];

  const milestoneResponsibleOptions = milestoneReponsibles.map(
    ({ id, name }) => {
      return { value: id, text: name };
    }
  );

  const initialMilestoneRespOptions = [
    ...milestoneResponsibleOptions.map(({ value }) => value),
  ];

  const substageOptions = activitiesSubstages.map(({ id, substageName }) => {
    return { value: id, text: substageName };
  });

  const substageInitialOptions = [...substageOptions.map(({ value }) => value)];

  const handleResponsibleSelection = (selectedOptions = []) => {
    const filteredData = reportMapData.filter(({ evaluation = {} }) => {
      const { projectEvaluation = {} } = evaluation || {};
      const { answer = {} } = projectEvaluation || {};
      const { responsibleMember = {} } = answer || {};
      const { id = false } = responsibleMember || {};

      if (selectedOptions.indexOf(id) > -1) {
        return true;
      } else {
        return false;
      }
    });

    setData(filteredData);
    setSelectedResponsibles(selectedOptions);
  };

  const handleMilestoneResponsibleSelection = (selectedOptions = []) => {
    if (selectedOptions.length === 0) {
      handleResponsibleSelection(selectedResponsibles);
      return;
    }

    const filteredData = [];
    let activitiesWithResponsibles = [];

    reportMapData.forEach((line) => {
      const { evaluation = {} } = line || {};
      const { projectEvaluation = {} } = evaluation || {};
      const { activities = {} } = projectEvaluation || {};

      activities.forEach((activity) => {
        const { responsible = {} } = activity || {};
        const { id = false } = responsible || {};

        if (!id) return;

        if (selectedOptions.indexOf(id) > -1)
          activitiesWithResponsibles.push(activity);
      });

      activitiesWithResponsibles.length > 0 &&
        filteredData.push({
          ...line,
          evaluation: {
            ...evaluation,
            projectEvaluation: {
              ...projectEvaluation,
              activities: [...activitiesWithResponsibles],
            },
          },
        });

      activitiesWithResponsibles = [];
    });

    setData(filteredData);
  };

  const essentialInfo = ({
    projectStageRelation = {},
    projectStage = false,
  }) => {
    const { description = false, parent = {} } = projectStageRelation || {};

    const { description: parentDescription = false } = parent || {};

    const name = description || projectStage || false;

    if (!name) return {};

    const parentName = parentDescription
      ? parentDescription?.toUpperCase()
      : false;

    return { name: name?.toUpperCase(), parentName };
  };

  const handleSubstages = (selectedOptions = []) => {
    if (selectedOptions.length === 0) return setData(reportMapData);

    const filteredData = reportMapData.filter(({ evaluation = {} }) => {
      const { projectEvaluation = {} } = evaluation || {};
      const { name = false, parentName = false } =
        essentialInfo(projectEvaluation);

      if (!name) return false;

      let include = false;

      selectedOptions.forEach((option) => {
        const splittedOption = option.split("[separator]");

        const newName = splittedOption[0] || "0";
        const newParentName = splittedOption[1] || "0";

        if (newParentName !== "0") {
          if (newName === name) include = true;
          return;
        }

        if (
          newName === name ||
          newName === parentName ||
          newParentName === name ||
          newParentName === parentName
        )
          include = true;
      });

      if (include) return true;

      return false;
    });

    setData(filteredData);
  };

  return (
    <div>
      <Row>
        <Col md={4} style={{ paddingLeft: "0px" }}>
          <MaterialMultiSelect
            allButton
            selectAll
            setDefaultValue
            id="responsiblesSelector"
            options={responsibleOptions}
            initialOptions={initialOptions}
            label={intl.formatMessage(messages.selectResponsible)}
            getData={(selectedOptions) =>
              handleResponsibleSelection(selectedOptions)
            }
          />
        </Col>
        <Col md={4}>
          <MaterialMultiSelect
            allButton
            selectAll
            setDefaultValue
            id="milestoneResponsiblesSelector"
            options={milestoneResponsibleOptions}
            initialOptions={initialMilestoneRespOptions}
            label={intl.formatMessage(messages.selectMilestoneResponsible)}
            getData={(selectedOptions) =>
              handleMilestoneResponsibleSelection(selectedOptions)
            }
          />
        </Col>
        <Col md={4} className="projectSubstageSelect">
          <MaterialMultiSelect
            allButton
            selectAll
            setDefaultValue
            id="substageSelector"
            options={substageOptions}
            initialOptions={substageInitialOptions}
            label="Selecione um subestágio"
            getData={(selectedOptions) => handleSubstages(selectedOptions)}
          />
        </Col>
      </Row>
      <div className="container-activities">
        {data?.map((reportMap, index) => {
          const {
            projectName,
            schedule,
            scopeDotClass,
            statusClass,
            financialStatusDot,
            evaluation,
            projectId,
            physicalProgress,
            activities,
          } = reportMap;

          const { projectEvaluation = {} } = evaluation || {};

          const {
            activities: activitiesEvaluation = [],
            projectStageRelation = {},
            projectStage = false,
          } = projectEvaluation || {};

          const { sonDescription = false, parentDescription = false } =
            getStageParentInfo(projectStageRelation);

          const orderedActivities =
            activitiesEvaluation.length > 0 ? [...activitiesEvaluation] : [];

          orderedActivities.sort((a, b) => {
            const a_attr = a.indexOrder;
            const b_attr = b.indexOrder;

            return a_attr > b_attr ? 1 : -1;
          });

          return (
            <div key={index}>
              <table
                key={index}
                className="table simple-table"
                style={{ marginBottom: 0 }}
              >
                <thead className="thead-first">
                  <tr>
                    <td>Descrição</td>
                    <td align="center">
                      <div className="fix-70">
                        {intl.formatMessage(messages.stage)}
                      </div>
                    </td>
                    <td className="fix-20">
                      <i className="fas fa-list" />
                    </td>
                    <td className="fix-20">
                      <i className="fas fa-clock" />
                    </td>
                    <td className="fix-20">
                      <i className="fas fa-dollar-sign" />
                    </td>

                    <td className="td-plan">Plano</td>
                    <td className="td-plan">Real</td>
                    <td className="td-progress">Avanço físico</td>
                    <td className="td-date">Baseline</td>
                    <td className="td-date">Deadline</td>
                    <td className="td-date">Entrega</td>
                    <td width="93">Cronograma</td>
                  </tr>
                </thead>
                <thead className="thead-project">
                  <tr>
                    <td>
                      <div className="box-project-name">
                        <div
                          aria-hidden="true"
                          onClick={() => handleExpansiveActivities(projectId)}
                        >
                          {hiddenActivities.includes(projectId) ? (
                            <i className="fas fa-angle-down" />
                          ) : (
                            <i className="fas fa-angle-up" />
                          )}
                        </div>
                        <Avatar
                          src={projectEvaluation.avatar}
                          name={projectEvaluation.responsibleName}
                          style={{
                            width: "25px",
                            height: "25px",
                            marginRight: "5px",
                          }}
                        />
                        <span
                          className="project-name"
                          style={{ cursor: "pointer" }}
                          aria-hidden="true"
                          onClick={() =>
                            selectAndOpenInsight(projectEvaluation.answer.id)
                          }
                        >
                          {projectName}
                        </span>
                      </div>
                    </td>
                    <td align="center" className="fix-13pxFont">
                      <Row className="fix-13pxFont">
                        {projectStage || parentDescription || sonDescription}
                      </Row>
                      {parentDescription && (
                        <Row
                          className="fix-11pxFont nameEllipsis"
                          title={sonDescription}
                        >
                          {sonDescription}
                        </Row>
                      )}
                    </td>
                    <td>
                      <Tooltip
                        title={getStatusProjectByClassName(scopeDotClass)}
                      >
                        <div className={scopeDotClass} />
                      </Tooltip>
                    </td>
                    <td>
                      <Tooltip title={getStatusProjectByClassName(statusClass)}>
                        <div className={statusClass} />
                      </Tooltip>
                    </td>
                    <td>
                      <Tooltip
                        title={getStatusProjectByClassName(financialStatusDot)}
                      >
                        <div className={financialStatusDot} />
                      </Tooltip>
                    </td>
                    <td>{activities.planned}</td>
                    <td>{activities.realized}</td>
                    <td>
                      <div className="box-bar-progress">
                        <span>
                          {physicalProgress
                            ? `${getToFixed(physicalProgress, 1)}%`
                            : "0%"}
                        </span>
                        <div className="bar">
                          <div
                            className="progress"
                            style={{
                              width: physicalProgress
                                ? `${physicalProgress}%`
                                : 0,
                            }}
                          />
                        </div>
                      </div>
                    </td>
                    <td>{schedule.baseline}</td>
                    <td>{schedule.deadline || "-"}</td>
                    <td>{schedule.realized || "-"}</td>
                    <td>{getToFixed(getSchedulePercentage(schedule), 1)}%</td>
                  </tr>
                </thead>
              </table>
              {!hiddenActivities.includes(projectId) && (
                <div style={{ padding: "20px", border: "1px solid #ccc" }}>
                  <table
                    className="table table-striped simple-table"
                    style={{ marginTop: 0, marginBottom: 0 }}
                  >
                    {projectEvaluation.activitiesType && (
                      <thead className="thead-description">
                        <tr>
                          <td
                            colSpan="7"
                            style={{
                              padding: "1px 0px",
                              background: "#fff",
                              border: 0,
                            }}
                          >
                            <span style={{ marginRight: "121px" }}>
                              {projectEvaluation.activitiesType}
                            </span>
                          </td>
                        </tr>
                      </thead>
                    )}
                    <thead>
                      <tr>
                        <td>Atividade</td>
                        <td width="100">Previsto</td>
                        <td width="100">Realizado</td>
                        <td width="265">Avanço</td>
                        <td width="159">status</td>
                        <td width="159">Prevista</td>
                        <td width="159">Entrega</td>
                      </tr>
                    </thead>
                    <tbody className="tbody-activities">
                      {orderedActivities.map((activity, index) => {
                        if (activity) {
                          const {
                            description = "",
                            progress = "",
                            deliveryDate = "",
                            expectedDate = "",
                            responsible = "",
                            effort = "",
                            note = "",
                          } = activity;

                          const formatedDeliveryDate =
                            momemt(deliveryDate).format("DD/MM/YYYY");
                          const formatedExpectedDate =
                            momemt(expectedDate).format("DD/MM/YYYY");

                          const user =
                            (responsible && getUserById(responsible)) || {};

                          return (
                            <tr key={index}>
                              <td>
                                <OverlayTrigger
                                  placement="bottom"
                                  overlay={
                                    <Popover
                                      id="popoverInformation"
                                      style={{ zIndex: "99999" }}
                                      title="Observação"
                                    >
                                      <Row style={{ marginLeft: "5px" }}>
                                        {note}
                                      </Row>
                                    </Popover>
                                  }
                                >
                                  <div className="box-avatar-activitie">
                                    <Avatar
                                      src={responsible?.avatarBlobId}
                                      name={user?.name}
                                      style={{
                                        width: "35px",
                                        height: "35px",
                                        marginRight: "5px",
                                      }}
                                    />
                                    <span>{description}</span>
                                  </div>
                                </OverlayTrigger>
                              </td>
                              <td align="center">
                                {(effort && effort.foreseen) || "- "}
                              </td>
                              <td align="center">
                                {(effort && effort.achieved) || "- "}
                              </td>

                              <td>
                                <div className="box-bar-progress">
                                  <span style={{ width: "50px" }}>
                                    {progress
                                      ? `${getToFixed(progress, 1)}%`
                                      : "0%"}
                                  </span>
                                  <div className="bar">
                                    <div
                                      className="progress"
                                      style={{
                                        width: progress ? `${progress}%` : 0,
                                      }}
                                    />
                                  </div>
                                </div>
                              </td>
                              <td align="center">
                                {
                                  getActivityStage({ ...activity, intl })
                                    .translation
                                }
                              </td>
                              <td align="center">
                                {expectedDate ? formatedExpectedDate : "-"}
                              </td>
                              <td
                                align="center"
                                style={{
                                  borderRight: `3px solid ${
                                    getActivityStatus(activity).color || "#ccc"
                                  }`,
                                }}
                              >
                                {deliveryDate ? formatedDeliveryDate : "-"}
                              </td>
                            </tr>
                          );
                        }
                        return "";
                      })}
                      {!projectEvaluation.activities.length && (
                        <tr>
                          <td colSpan="20">
                            <span className="alert-without-activities">
                              Nenhuma atividade cadastrada
                            </span>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default injectIntl(Activities);
