import AppBar from "@material-ui/core/AppBar";
import AddIcon from "@material-ui/icons/Add";
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import { useCallback, useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { defineMessages, injectIntl } from "react-intl";
import { connect } from "react-redux";
import { fetchSelectedToolActionsPlans } from "../../../actions/actionsPlan";
import { appRefreshSelectedTool } from "../../../actions/appActions";
import { toggleBoardEditMode } from "../../../actions/boardActions";
import {
  archiveCenary,
  deleteCenarys,
  selectCenary,
  setTemplate,
  updateCenary,
} from "../../../actions/cenaryActions";
import { fetchCompetitiveAnalysisPoints } from "../../../actions/compAnalysisActions";
import { entityReducerReorder } from "../../../actions/orderedEntityActions";
import { customAddScenerie } from "../../../actions/structureActions";
import {
  COMPETITIVE_ANALYSIS,
  GOALS_AND_BONUS,
  OKR_ID,
  SKILLS_ASSESSMENT,
} from "../../../constants/tools.constants";
import { useMainStyles } from "../../../jcss/scenerieStyles";
import { checkAccess } from "../../../utils/accessLevels";
import { customCenaryName } from "../../../utils/cpAnalysis/display";
import { useFilteredEntities } from "../../../utils/entities/hooks";
import { getThemesOrderByTool } from "../../../utils/entities/themeFilters";
import utils from "../../../utils/toolUtils";
import ArchiveDialog from "../../Common/ArchiveDialog";
import DeleteDialog from "../../Common/DeleteDialog";
import EditableTextArea from "../../Common/EditableTextArea";
import MaterialCustomMenu from "../../Common/MaterialCustomMenu";
import MaterialCustomTab from "../../Common/MaterialCustomTab";
import MaterialScrollTabs from "../../Common/MaterialScrollTabs";
import PermissionModal from "../../Common/PermissionModal";
import UnarchiveDialog from "../../Common/UnarchiveDialog";
import { getSelectedToolStates } from "../../customMapStates";
import EvaluationBadge from "../EvaluationBadge/EvaluationBadge";
import ThemeContainer from "../Theme/ThemeContainer";
import SceneriePanel from "./SceneriePanel";

const {
  getObjectInfoById,
  getEntityMenuActions,
  getAddEntityLabel,
  getOnlySlugValues,
  iterationSearch,
} = utils;

const getTabProps = (index) => {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
};

const messages = defineMessages({
  no_scenario_added: {
    id: "no_scenario_added",
  },
  scenario_main_title: {
    id: "scenario_main_title",
  },
  alertMessage: {
    id: "equal_scenario_name_alert",
  },
  minLength: {
    id: "form_min_length",
  },
  globalFiled: {
    id: "global.filed",
  },
});

const defaultSceneryForm = {
  id: null,
  name: "",
  fixed: false,
  selectedTool: {},
  editing: false,
  new: false,
  type: "cenary",
  owner: {},
};

const defaultSceneryDialog = {
  type: "",
  onConfirm: () => {},
};

const defaultHReordered = {
  themes: [],
  questions: [],
  insights: [],
};

const SceneriesContainer = ({
  selectedTool = {},
  toolsConfigurations = {},
  showHiddenCenarys = false,
  showHiddenThemes = false,
  showHiddenQuestions = false,
  showHiddenAnswers = false,
  intl,
  selectCenary,
  fetchCompetitiveAnalysisPoints,
  fetchSelectedToolActionsPlans,
  fetchFiledOption,
  toggleBoardEditMode,
  editModeOn,
  ...props
}) => {
  const classes = useMainStyles();

  const [activeTab, setActiveTab] = useState("empty");
  const [controller, setController] = useState({ prevTab: "empty" });
  const [sceneryForm, setSceneryForm] = useState(defaultSceneryForm);
  const [sceneryDialog, setSceneryDialog] = useState(defaultSceneryDialog);
  const { entities: store, entitiesConfig } = useFilteredEntities({
    selectedTool,
    allSceneries: props.allSceneries,
    allThemes: props.allThemes,
    allQuestions: props.allQuestions,
    allAnswers: props.allAnswers,
  });
  const [historyOrdered, setHistoryOrdered] = useState({
    themes: {},
    questions: {},
    insights: {},
  });
  const [reordered, setReordered] = useState({
    themes: {},
    questions: {},
    insights: {},
  });
  const [haveReordered, setHaveReordered] = useState(defaultHReordered);
  const [displaySceneries, setDisplaySceneries] = useState([]);

  const {
    scenariosConfig = {},
    replaceConfig = {},
    questionRegistrations,
  } = entitiesConfig;

  const {
    allSceneries = [],
    allThemes: originalAllThemes = {},
    allQuestions = {},
    allAnswers = {},
  } = store;

  const toolId = selectedTool?.tool?.id || null;
  const isGoalsAndBonusTool = toolId === GOALS_AND_BONUS;

  const getOrderedThemes = useCallback(() => {
    return getThemesOrderByTool({ toolId, originalAllThemes });
  }, [toolId, originalAllThemes]);

  const allThemes = getOrderedThemes();

  useEffect(() => {
    const allOrdered = {
      themes: allThemes,
      questions: allQuestions,
      insights: allAnswers,
    };
    setReordered(allOrdered);
    setHistoryOrdered(allOrdered);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allSceneries, originalAllThemes, allQuestions, allAnswers]);

  useEffect(() => {
    // Remover, mover para um middleware específico da ferramenta
    const { id: selectedToolId = "" } = selectedTool;

    if (toolId === COMPETITIVE_ANALYSIS)
      fetchCompetitiveAnalysisPoints(selectedToolId, fetchFiledOption);
    if (toolId === SKILLS_ASSESSMENT) {
      fetchSelectedToolActionsPlans(selectedToolId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (activeTab === "empty" && allSceneries?.length > 0)
      handleTab(allSceneries[0].id);

    setDisplaySceneries(
      allSceneries.sort((a, b) =>
        a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allSceneries]);

  const toggleInsightModal =
    typeof props.toggleInsightModal === "function"
      ? props.toggleInsightModal
      : () => {};

  const getSceneriesHistory = useCallback(() => {
    if (Array.isArray(allSceneries))
      return [...allSceneries].map(({ id }) => id);
  }, [allSceneries]);

  function cancelEditMode() {
    setReordered(historyOrdered);
    toggleBoardEditMode();
  }

  async function handleToggleBoardEditMode() {
    if (editModeOn) {
      setHaveReordered(defaultHReordered);
      if (haveReordered.themes.length > 0 && activeTab) {
        const selectedSceneryId = activeTab;
        await props.entityReducerReorder(
          reordered.themes[selectedSceneryId],
          selectedSceneryId,
          false
        );
      }
      if (haveReordered.questions.length > 0) {
        await Promise.all(
          haveReordered.questions.map(async (themeId) => {
            await props.entityReducerReorder(
              reordered.questions[themeId],
              themeId,
              false
            );
          })
        );
      }
      if (haveReordered.insights.length > 0) {
        await Promise.all(
          haveReordered.insights.map(async (questionId) => {
            await props.entityReducerReorder(
              reordered.insights[questionId],
              questionId,
              false
            );
          })
        );
      }
      props.appRefreshSelectedTool(false);
    } else {
      setHistoryOrdered(reordered);
    }
    toggleBoardEditMode();
  }

  function reorderEntity(entity, orderedChildren, parentId) {
    if (haveReordered[entity].indexOf(parentId) === -1)
      setHaveReordered((current) => {
        return {
          ...current,
          [entity]: [...current[entity], parentId],
        };
      });
    setReordered((current) => {
      return {
        ...current,
        [entity]: {
          ...current[entity],
          [parentId]: orderedChildren,
        },
      };
    });
  }

  function resetSceneryForm() {
    setSceneryForm(defaultSceneryForm);

    if (activeTab === "NEW_SCENERIE" || controller.prevTab === "empty") {
      const newTab =
        controller.prevTab === "empty" ? activeTab : controller.prevTab;
      handleTab(newTab);
    }
  }

  function resetSceneryDialog() {
    setSceneryDialog(defaultSceneryDialog);
  }

  function handleTab(newTab) {
    if (!editModeOn) {
      setController({ ...controller, prevTab: activeTab });
      setActiveTab(newTab);

      if (newTab && newTab !== "empty" && newTab !== "NEW_SCENERIE")
        selectCenary(newTab);
    }
  }

  function handleMiddlewareCustomization() {
    let custom = false;

    const params = {
      selectedTool,
    };

    if (scenariosConfig.registrationMiddleware) {
      custom = (nextScenerieOrder) =>
        props.customAddScenerie(toolId, {
          ...params,
          nextScenerieOrder,
          cancelCallback: resetSceneryForm,
        });
    }

    return custom;
  }

  function handleNewScenerie() {
    const middlewareCustomization = handleMiddlewareCustomization();
    const currentSelectedScenerie = getObjectInfoById(
      activeTab,
      allSceneries,
      "id"
    );
    const currentScenerieOrder = currentSelectedScenerie.order || 0;
    const nextScenerieOrder = currentScenerieOrder + 1;

    handleTab("NEW_SCENERIE");

    const addCallback = (id = null) => {
      setSceneryForm({
        ...defaultSceneryForm,
        id,
        new: true,
        editing: true,
        order: nextScenerieOrder,
        selectedTool,
        owner: props.user,
      });
    };

    if (typeof middlewareCustomization === "function") {
      middlewareCustomization(nextScenerieOrder);
    } else if (selectedTool.id) {
      addCallback();
    }
  }

  function handleSaveUpdateScenerie(scenerieName, form = {}, reorder = false) {
    if (scenerieName) {
      props.updateCenary(
        { ...form, name: scenerieName },
        selectedTool.id,
        reorder,
        ({ data }) => handleTab(data.id)
      );
    }

    resetSceneryForm();
  }

  async function handleScenerieCreation(scenerieName) {
    function saveDefault() {
      handleSaveUpdateScenerie(scenerieName, sceneryForm, !sceneryForm.id);
    }

    const sceneriesValidation = [...allSceneries].filter(
      (cenar) => cenar.id !== sceneryForm.id
    );
    const sceneriesIteration = getOnlySlugValues(sceneriesValidation, "name");
    const isDuplicated = await iterationSearch(
      scenerieName,
      sceneriesIteration
    );

    if (!isDuplicated && scenerieName.trim().replace(/\s/g, "").length > 3) {
      if (
        scenerieName &&
        selectedTool.id &&
        !scenariosConfig.blockTemplate &&
        !sceneryForm.id
      ) {
        props.setTemplate(selectedTool.id).then((newSceneryTemplate) => {
          if (newSceneryTemplate.data) {
            if (newSceneryTemplate.data.name && !sceneryForm.id) {
              handleSaveUpdateScenerie(
                scenerieName,
                newSceneryTemplate.data,
                true
              );
            } else if (sceneryForm.id) {
              saveDefault();
            }
          } else {
            saveDefault();
          }
        });
      } else {
        saveDefault();
      }
    } else if (
      isDuplicated &&
      scenerieName.trim().replace(/\s/g, "").length > 3
    ) {
      alert(intl.formatMessage(messages.alertMessage));
    } else {
      alert(intl.formatMessage(messages.minLength));
    }

    resetSceneryForm();
  }

  function selectNextAvailableScenery(exclude = []) {
    let selectedOne = "empty";

    const currentSceneriesHistory = getSceneriesHistory();

    if (currentSceneriesHistory.length > 0) {
      for (const sceneryId of currentSceneriesHistory) {
        if (exclude.indexOf(sceneryId) === -1) {
          selectedOne = sceneryId;
          break;
        }
      }
    }

    handleTab(selectedOne);
  }

  function handleEditScenery(sceneryInfo = {}) {
    if (sceneryInfo.id) setSceneryForm({ ...sceneryInfo, editing: true });
  }

  function handleArchiveScenery(sceneryInfo) {
    if (!showHiddenCenarys) selectNextAvailableScenery([sceneryInfo.id]);

    setTimeout(() => {
      if (sceneryInfo && sceneryInfo.id) props.archiveCenary(sceneryInfo);
    }, [600]);
  }

  function handleDeleteScenery(sceneryInfo) {
    selectNextAvailableScenery([sceneryInfo.id]);

    setTimeout(() => {
      if (sceneryInfo && sceneryInfo.id) props.deleteCenarys(sceneryInfo);
    }, [600]);
  }

  function openArchiveDialog(sceneryInfo) {
    setSceneryDialog({
      type: "ARCHIVE_SCENERY",
      onConfirm: () => handleArchiveScenery(sceneryInfo),
    });
  }

  function openDeleteDialog(sceneryInfo) {
    setSceneryDialog({
      type: "DELETE_SCENERY",
      onConfirm: () => handleDeleteScenery(sceneryInfo),
    });
  }

  function openUnarchiveDialog(sceneryInfo) {
    setSceneryDialog({
      type: "UNARCHIVE_SCENERY",
      scenery: sceneryInfo,
      onConfirm: () => handleArchiveScenery(sceneryInfo),
    });
  }

  async function openPermissionModal(sceneryInfo = {}) {
    await selectCenary(sceneryInfo.id);

    setSceneryDialog({
      type: "PERMISSION",
      scenerieData: sceneryInfo,
      onConfirm: () => console.log("Confirm!!!!"),
    });
  }

  function closeDialogAndConfirm() {
    if (sceneryDialog.onConfirm) sceneryDialog.onConfirm();

    resetSceneryDialog();
  }

  const addingNewScenerie = sceneryForm.editing && sceneryForm.new;

  const selectedCustomTool = {
    isOkr: toolId === OKR_ID,
  };

  const appendProps = {
    showHiddenThemes,
    showHiddenQuestions,
    showHiddenAnswers,
    toggleInsightModal,
    toolsConfigurations,
    allSceneries,
    allThemes: reordered.themes,
    allQuestions: reordered.questions,
    allAnswers: reordered.insights,
    replaceConfig,
    questionRegistrations,
    selectedCustomTool,
    editModeOn,
    reorderEntity,
  };
  const addButtonTooltip = (
    <Tooltip id="addTooltip">
      {getAddEntityLabel("cenary", toolId, intl)}
    </Tooltip>
  );

  const editModeTooltip = () => {
    if (!editModeOn)
      return (
        <Tooltip id="editModeTooltip">
          Alternar modo de edição do quadro
        </Tooltip>
      );
    return <Tooltip id="editModeTooltip">Salvar edições de ordenação</Tooltip>;
  };

  const sceneriePermissions = (sceneryInfo) => () =>
    openPermissionModal(sceneryInfo);

  return (
    <div
      className={`sceneries-container ${
        editModeOn ? "sceneries-container-edit-mode" : ""
      }`}
      style={{ padding: "15px 20px" }}
    >
      <div className={classes.root}>
        <AppBar position="static" elevation={0} className={classes.appBar}>
          {scenariosConfig.registrations &&
            checkAccess(["MANAGER", "MODERATOR", "ADMIN", "OWNER"]) && (
              <OverlayTrigger placement="bottom" overlay={addButtonTooltip}>
                <div
                  className="scenerie-button-add"
                  onClick={!editModeOn ? handleNewScenerie : () => {}}
                  style={
                    editModeOn ? { opacity: "0.5", cursor: "not-allowed" } : {}
                  }
                >
                  <AddIcon />
                </div>
              </OverlayTrigger>
            )}
          <MaterialScrollTabs
            value={activeTab}
            onChange={(event, tab) =>
              tab !== "NEW_SCENERIE" ? handleTab(tab) : () => {}
            }
            style={{ display: "flex" }}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="Sceneries Tab"
          >
            <MaterialCustomTab
              value="empty"
              label=""
              {...getTabProps("empty")}
              hidetab="true"
            />
            <MaterialCustomTab
              value="NEW_SCENERIE"
              label={
                <EditableTextArea
                  onSave={handleScenerieCreation}
                  width="300px"
                  actionIcons
                  onCancel={resetSceneryForm}
                  margin="none"
                  id="NEW_SCENERIE"
                  editing={!!addingNewScenerie}
                  text={sceneryForm.name}
                />
              }
              {...getTabProps("NEW_SCENERIE")}
              hidetab={addingNewScenerie ? "false" : "true"}
            />

            {displaySceneries.map((sceneryInfo, index) => {
              const scenerieName = customCenaryName(sceneryInfo.name, toolId);
              const scenerieThemes = allThemes[sceneryInfo.id];
              const isEditingScenerie =
                sceneryForm.editing && sceneryForm.id === sceneryInfo.id;

              const additionalStyles = sceneryInfo.filed
                ? {
                    color: "#888",
                  }
                : {};

              return (
                <MaterialCustomTab
                  value={sceneryInfo.id}
                  label={
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        fontSize: "12px",
                        ...additionalStyles,
                      }}
                      title={
                        sceneryInfo.filed
                          ? intl.formatMessage(messages.globalFiled)
                          : null
                      }
                    >
                      {sceneryInfo.filed && (
                        <i
                          className="fas fa-archive"
                          style={{ color: "#888" }}
                        />
                      )}

                      <EditableTextArea
                        onSave={(newScenerieName) =>
                          handleScenerieCreation(newScenerieName)
                        }
                        width="300px"
                        actionIcons
                        onCancel={resetSceneryForm}
                        margin="none"
                        id={`editScenerie${sceneryInfo.id}`}
                        editing={!!isEditingScenerie}
                        text={scenerieName}
                      />

                      <EvaluationBadge
                        from="cenary"
                        info={{
                          themes: scenerieThemes,
                          toolsConfigurations,
                          tool: selectedTool,
                          cenaryId: sceneryInfo.id,
                        }}
                      />
                      {checkAccess(
                        ["MANAGER", "LEADER", "MODERATOR", "ADMIN", "OWNER"],
                        null,
                        sceneryInfo.id
                      ) && (
                        <div style={{ padding: "0px 0px 0px 10px" }}>
                          <MaterialCustomMenu
                            menuActions={getEntityMenuActions(
                              () => handleEditScenery(sceneryInfo),
                              () => openArchiveDialog(sceneryInfo),
                              () => openDeleteDialog(sceneryInfo),
                              sceneryInfo.filed,
                              scenariosConfig,
                              intl,
                              () => openUnarchiveDialog(sceneryInfo),
                              !isGoalsAndBonusTool &&
                                sceneriePermissions(sceneryInfo)
                            )}
                          />
                        </div>
                      )}
                    </div>
                  }
                  {...getTabProps(sceneryInfo.id)}
                  key={index}
                />
              );
            })}
          </MaterialScrollTabs>
          {editModeOn && (
            <div style={{ display: "flex", gap: "10px" }}>
              <div className="button-cancel-edit-mode" onClick={cancelEditMode}>
                <CloseIcon /> Cancelar
              </div>
              <OverlayTrigger placement="bottom" overlay={editModeTooltip()}>
                <div
                  className="scenerie-button-edit-mode"
                  onClick={handleToggleBoardEditMode}
                >
                  <div>
                    <SaveIcon /> Salvar edição
                  </div>
                </div>
              </OverlayTrigger>
            </div>
          )}
        </AppBar>
        <SceneriePanel activeTab={activeTab} index="empty">
          <h6>{intl.formatMessage(messages.no_scenario_added)}</h6>
        </SceneriePanel>
        {displaySceneries.map((sceneryInfo, index) => {
          const scenerieThemes = reordered.themes[sceneryInfo.id] || [];
          const selected = activeTab === sceneryInfo.id;

          return (
            <SceneriePanel
              activeTab={activeTab}
              index={sceneryInfo.id}
              key={index}
            >
              {selected && (
                <ThemeContainer
                  sceneryInfo={sceneryInfo}
                  themes={scenerieThemes}
                  {...appendProps}
                />
              )}
            </SceneriePanel>
          );
        })}
      </div>
      <ArchiveDialog
        entityName={intl.formatMessage(messages.scenario_main_title)}
        show={sceneryDialog.type === "ARCHIVE_SCENERY"}
        onConfirm={closeDialogAndConfirm}
        onCancel={resetSceneryDialog}
      />
      <UnarchiveDialog
        entityName={intl.formatMessage(messages.scenario_main_title)}
        action={intl.formatMessage({ id: "global.unArchive" })}
        show={sceneryDialog.type === "UNARCHIVE_SCENERY"}
        onConfirm={closeDialogAndConfirm}
        onCancel={resetSceneryDialog}
      />
      <DeleteDialog
        entityName={intl.formatMessage(messages.scenario_main_title)}
        show={sceneryDialog.type === "DELETE_SCENERY"}
        onConfirm={closeDialogAndConfirm}
        onCancel={resetSceneryDialog}
      />
      <PermissionModal
        show={sceneryDialog.type === "PERMISSION"}
        onConfirm={resetSceneryDialog}
        onCancel={resetSceneryDialog}
        scenerieData={sceneryDialog.scenerieData}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  const { user, toolsConfigurations = {}, board } = state;
  const { showHiddenAnswers: fetchFiledOption = false } = toolsConfigurations;
  const { selectedTool } = getSelectedToolStates(state);

  return {
    selectedTool,
    toolsConfigurations,
    user,
    fetchFiledOption,
    editModeOn: board.editModeOn,
  };
};

export default injectIntl(
  connect(mapStateToProps, {
    customAddScenerie,
    updateCenary,
    archiveCenary,
    deleteCenarys,
    setTemplate,
    selectCenary,
    fetchCompetitiveAnalysisPoints,
    fetchSelectedToolActionsPlans,
    toggleBoardEditMode,
    entityReducerReorder,
    appRefreshSelectedTool,
  })(SceneriesContainer)
);
