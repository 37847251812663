import { getUpdatedObjectInArray } from "../../../../../../../utils/ArrayUtils";
import { getPrioritizationScaleTranslation } from "../../../../../../../utils/cpAnalysis/display";
import SimpleExcelCollumn from "../../../../../../Common/SimpleExcelCollumn";

const Scales = (props) => {
  const { cpAnalysisAdministration = {}, setCpAnalysisAdministration } = props;

  const { prioritization = [] } = cpAnalysisAdministration || {};
  const { scales = [] } = prioritization || {};

  const updatePrioritizationField = (field = false, value = "") => {
    if (!field) return;

    const prioritizationConfig = cpAnalysisAdministration.prioritization || {};

    setCpAnalysisAdministration({
      ...cpAnalysisAdministration,
      prioritization: {
        ...prioritizationConfig,
        [field]: [...value],
      },
    });
  };

  const handleUpdateScale = (value = "", field = "", index = null) => {
    const updated = getUpdatedObjectInArray(value, scales, field, index);

    updatePrioritizationField("scales", updated);
  };

  return (
    <table className="simple-table">
      <thead>
        <tr>
          <td colSpan="2">Título</td>
        </tr>
      </thead>
      <tbody>
        {scales.map((scaleInfo, index) => {
          const title = getPrioritizationScaleTranslation(
            scaleInfo.title || ""
          );

          return (
            <tr className="block-hover" key={index}>
              <td align="center" style={{ width: "40px" }}>
                {index + 1}
              </td>
              <SimpleExcelCollumn
                id="scaleTitle"
                label="Titulo"
                type="text"
                value={title}
                onChange={(e) =>
                  handleUpdateScale(e.target.value, "title", index)
                }
              />
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default Scales;
