import _ from "lodash";
import { useEffect, useState } from "react";

import {
  defaultEnabledWorkflowActions,
  defaultEnabledWorkflowFields,
} from "../../../../../../../../utils/skillsAssessment/defaults";
import {
  getEnabledWorkflowByUser,
  getWorkflowActionsByUser,
} from "../../../../../../../../utils/skillsAssessment/filter";

export function useWorkflowFields({
  selectedParticipant = {},
  filterStage = "SELECTION_EVALUATORS",
  evaluationType,
  managerConfig,
  composition,
  loggedUser = {},
  transparency,
}) {
  const [enabledFields, setEnableFields] = useState(
    defaultEnabledWorkflowFields
  );
  const [enabledActions, setEnabledActions] = useState(
    defaultEnabledWorkflowActions
  );

  useEffect(() => {
    if (selectedParticipant && selectedParticipant.id) {
      const newEnabledFields = getEnabledWorkflowByUser(
        loggedUser,
        selectedParticipant,
        evaluationType,
        composition,
        filterStage,
        transparency
      );
      const newEnabledActions = getWorkflowActionsByUser(
        loggedUser,
        selectedParticipant,
        filterStage,
        managerConfig
      );

      setEnableFields(newEnabledFields);
      setEnabledActions(newEnabledActions);
    } else if (!_.isEqual(defaultEnabledWorkflowFields, enabledFields)) {
      setEnableFields(defaultEnabledWorkflowFields);
      setEnabledActions(defaultEnabledWorkflowActions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedParticipant, filterStage]);

  console.log("enabledFields=", enabledFields);
  console.log("enabledFields=", enabledActions);

  return { enabledFields, enabledActions };
}
