import { Avatar, List, ListItem, ListItemText } from "@material-ui/core";
import _ from "lodash";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { defineMessages, injectIntl } from "react-intl";
import { getComparableQuery } from "../../utils/StringUtils";
import { globalMessages } from "../../utils/global";
import FormDialog from "./FormDialog";
import MaterialTextField from "./MaterialTextField";
import NewMemberDialog from "./NewMemberDialog";
import SimpleCheckbox from "./SimpleCheckbox";
import SimpleLegend from "./SimpleLegend";

const defaultNewMemberModal = {
  open: false,
  form: {},
};

const defaultFilter = {
  onlyType: null,
  string: "",
};

const SelectMemberModal = (props) => {
  const [displayMembers, setDisplayMembers] = useState([]);
  const [selectedMembers, setSelectedMembers] = useState([]);
  const [filter, setFilter] = useState(defaultFilter);
  const [initialPreset, setInitialPreset] = useState(false);
  const [newMemberModal, setNewMemberModal] = useState(defaultNewMemberModal);

  const {
    open,
    title,
    description,
    membersList,
    toggleOpen,
    onConfirm,
    onCancel,
    singleSelect,
    initialSelected,
    replaceSelected,
    replaceCallback,
    searchUsersAction,
    intl,
    teamID = null,
    canSaveUnselected,
  } = props;

  const confirmText =
    !props.confirmText || props.confirmText === "defaultSelect"
      ? intl.formatMessage(globalMessages.select)
      : props.confirmText;
  const cancelText =
    !props.cancelText || props.cancelText === "defaultCancel"
      ? intl.formatMessage(globalMessages.cancel)
      : props.cancelText;

  function resetAll() {
    setSelectedMembers([]);
    setFilter(defaultFilter);
  }

  useEffect(() => {
    if (!open) {
      resetAll([]);
    } else {
      setInitialPreset(false);
    }
  }, [open]);

  useEffect(() => {
    const activeMembers = membersList.filter(
      (member) => !member.active || (member.active && member.active === true)
    );
    setDisplayMembers(activeMembers);
  }, [membersList]);

  useEffect(() => {
    if (initialSelected && (!initialPreset || replaceSelected)) {
      setSelectedMembers(initialSelected.filter((memberId) => memberId));
      setInitialPreset(true);

      if (typeof replaceCallback === "function") replaceCallback();
    }
  }, [initialPreset, initialSelected, replaceSelected, replaceCallback]);

  const messages = defineMessages({
    inputSearch: { id: "ph_search" },
    onlyMembers: { id: "checkbox_only_members" },
    onlySelected: { id: "checkbox_only_selected" },
    selectAll: { id: "checkbox_select_all" },
    resultsFound: { id: "text_results_found" },
    members: { id: "global.members" },
    participants: { id: "global.participants" },
    registerNew: { id: "btn_modal_register_new" },
    global_selectedOnes: { id: "global.selectedOnes" },
  });

  const handleTypeSearch = (e) => {
    const filterValue = e.target.value;
    if (searchUsersAction) {
      searchUsersAction(filterValue);
    }
    setFilter({ ...filter, string: filterValue });
  };

  const resetOnlyType = () => {
    setFilter({ ...filter, onlyType: null });
  };

  const toggleOnlyType = ({ name, checked }) => {
    if (checked) {
      setFilter({ ...filter, onlyType: name });
    } else {
      resetOnlyType();
    }
  };

  const getFilteredDisplayMembers = () => {
    let filteredByType = [];

    displayMembers.forEach((memberInfo) => {
      if (filter.onlyType) {
        memberInfo.show = false;
        if (
          filter.onlyType === "onlyMembers" &&
          (memberInfo.type === "M" || memberInfo.type === "MEMBER")
        ) {
          memberInfo.show = true;
        }

        if (
          filter.onlyType === "onlyParticipants" &&
          (memberInfo.type === "P" || memberInfo.type === "PARTICIPANT")
        ) {
          memberInfo.show = true;
        }

        if (
          filter.onlyType === "onlySelected" &&
          selectedMembers.includes(memberInfo.id)
        )
          memberInfo.show = true;

        filteredByType = [...filteredByType, memberInfo];
      } else {
        filteredByType = [
          ...displayMembers.map((itemInfo) => {
            return { ...itemInfo, show: true };
          }),
        ];
      }
    });

    let finalFiltered = [];
    filteredByType.forEach((memberInfo) => {
      const updatedMember = { ...memberInfo };
      const compareString = getComparableQuery(filter.string);
      const nameFound =
        getComparableQuery(memberInfo.name).match(compareString) || false;
      const emailFound =
        getComparableQuery(memberInfo.email).match(compareString) || false;

      if (memberInfo.show && !nameFound && !emailFound)
        updatedMember.show = false;

      finalFiltered = [...finalFiltered, updatedMember];
    });

    return finalFiltered;
  };

  const isSelected = (memberID) => {
    return selectedMembers.indexOf(memberID) > -1;
  };

  const handleToggleSelect = (memberID) => {
    const selectedListClone = _.cloneDeep(selectedMembers) || [];
    let finalList = [];

    if (memberID && isSelected(memberID)) {
      selectedListClone.splice(selectedListClone.indexOf(memberID), 1);
      finalList = singleSelect ? [] : selectedListClone;
    } else {
      finalList = singleSelect ? [memberID] : [...selectedMembers, memberID];
    }

    setSelectedMembers(finalList);
  };

  const getTotalDisplayed = (list) => {
    let total = 0;

    if (list && list.length > 0) {
      list.forEach((memberInfo) => {
        total = memberInfo.show ? total + 1 : total;
      });
    }

    return total;
  };

  function toggleSelectAll() {
    if (displayMembers.length === selectedMembers.length) {
      setSelectedMembers([]);
    } else {
      setSelectedMembers(displayMembers.map((member) => member.id));
    }
  }

  const displayTopFilters = (show = true) => {
    return show ? (
      <div className="row" style={{ borderBottom: "1px solid #ccc" }}>
        <div className="col-xs-12">
          <MaterialTextField
            id="memberSelect"
            label={intl.formatMessage(messages.inputSearch)}
            value={filter.string}
            onChange={(e) => handleTypeSearch(e)}
          />
          <div className="row">
            <SimpleCheckbox
              label={intl.formatMessage(messages.selectAll)}
              name="selectAll"
              checked={displayMembers.length === selectedMembers.length}
              onChange={() => toggleSelectAll()}
            />
            <div
              className="col-xs-6"
              style={{ padding: "0px", margin: "5px 0px" }}
            >
              <SimpleCheckbox
                label={intl.formatMessage(messages.onlySelected)}
                name="onlySelected"
                checked={filter.onlyType === "onlySelected"}
                onChange={(e) =>
                  toggleOnlyType({ name: e.name, checked: !e.checked })
                }
              />
            </div>
          </div>
        </div>
      </div>
    ) : null;
  };

  const displayMembersList = (list) => {
    const reorderedList = [...list].sort((a, b) => (a.name > b.name ? 1 : -1));

    return getTotalDisplayed(reorderedList) > 0 ? (
      <List>
        {reorderedList.map((memberInfo, index) => {
          const avatarInfo =
            memberInfo.type === "MEMBER"
              ? {
                  title: "Member",
                  icon: <i className="fas fa-user" />,
                  color: "#6b42a9",
                }
              : {
                  title: "Participant",
                  icon: <i className="fas fa-user-lock" />,
                  color: "#6eac16",
                };
          const borderColor = isSelected(memberInfo.id)
            ? avatarInfo.color
            : "transparent";

          const displayStyle = memberInfo.show
            ? {
                opacity: "1",
                height: "62px",
                padding: "11px 16px",
              }
            : {
                opacity: "0",
                height: "0px",
                padding: "0px 16px",
                pointerEvents: "none",
              };

          return (
            <ListItem
              key={index}
              className="list-item-selection"
              style={{
                borderColor,
                ...displayStyle,
              }}
              onClick={() =>
                memberInfo.show ? handleToggleSelect(memberInfo.id) : null
              }
            >
              <Avatar
                style={{
                  backgroundColor: avatarInfo.color,
                  marginRight: "10px",
                }}
                title={avatarInfo.title}
              >
                {avatarInfo.icon}
              </Avatar>
              <ListItemText
                primary={memberInfo.name}
                secondary={memberInfo.email}
                primaryTypographyProps={{
                  style: {
                    fontSize: "16px",
                  },
                }}
                secondaryTypographyProps={{
                  style: {
                    fontSize: "14px",
                  },
                }}
              />
            </ListItem>
          );
        })}
      </List>
    ) : (
      <h5 align="center" style={{ color: "#666" }}>
        {intl.formatMessage(messages.resultsFound)}
      </h5>
    );
  };

  const toggleNewMemberModal = (toggleTo = true) => {
    setNewMemberModal({
      ...defaultNewMemberModal,
      open: toggleTo,
    });
  };

  const getSelectedMembersList = (membersAndParticipants) => {
    const selected = [];

    if (membersAndParticipants) {
      membersAndParticipants.forEach((memberInfo) => {
        if (selectedMembers.indexOf(memberInfo.id) > -1) {
          selected.push(memberInfo);
        }
      });
    }

    return selected;
  };

  const filteredList = getFilteredDisplayMembers();

  return (
    <>
      <NewMemberDialog
        open={newMemberModal.open}
        onConfirm={() => toggleNewMemberModal(false)}
        onCancel={() => toggleNewMemberModal(false)}
        teamID={teamID}
      />
      <FormDialog
        open={open}
        title={title}
        description={description}
        toggleOpen={toggleOpen}
        onConfirm={() =>
          onConfirm(selectedMembers, getSelectedMembersList(membersList))
        }
        onCancel={onCancel || toggleOpen}
        confirmText={confirmText}
        cancelText={cancelText}
        blockConfirm={selectedMembers.length === 0 && !canSaveUnselected}
        bodyStyle={{
          padding: "0px",
        }}
      >
        <div className="row">
          {displayTopFilters()}
          <div className="row" style={{ maxHeight: "335px", overflow: "auto" }}>
            {displayMembersList(filteredList)}
          </div>
          <div className="row" style={{ clear: "both" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                paddingTop: "15px",
                paddingBottom: "15px",
                paddingLeft: "25px",
                borderTop: "1px solid #ccc",
              }}
              className="col-xs-12"
            >
              <div className="col" align="center">
                <SimpleLegend
                  label={intl.formatMessage(messages.members)}
                  fillColor="#6b42a9"
                  size={10}
                />
                <SimpleLegend
                  label={intl.formatMessage(messages.participants)}
                  fillColor="#6eac16"
                  size={10}
                />
                {selectedMembers.length > 0 && !singleSelect && (
                  <h5
                    align="center"
                    style={{
                      color: "#666",
                      margin: "10px 0 0 0",
                    }}
                  >
                    {selectedMembers.length}{" "}
                    {intl.formatMessage(messages.global_selectedOnes)}:<br />
                    {selectedMembers.length > 0 && (
                      <div>
                        {selectedMembers
                          ?.slice(0, 10)
                          .map((memberId, index) => {
                            const append =
                              index === selectedMembers.length - 1 ? "" : ", ";
                            const memberInfo = membersList.find(
                              (member) =>
                                member.id === memberId ||
                                member.id === memberId.id
                            );

                            return `${memberInfo?.name}${append}`;
                          })}
                        {selectedMembers.length > 10 && (
                          <span> +{selectedMembers.length - 10} </span>
                        )}
                      </div>
                    )}
                  </h5>
                )}
              </div>
            </div>
          </div>
        </div>
      </FormDialog>
    </>
  );
};

SelectMemberModal.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.node.isRequired,
  description: PropTypes.node,
  membersList: PropTypes.array.isRequired,
  toggleOpen: PropTypes.func,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
  singleSelect: PropTypes.bool,
  initialSelected: PropTypes.arrayOf(PropTypes.number),
  replaceSelected: PropTypes.bool,
  replaceCallback: PropTypes.func,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  searchUsersAction: PropTypes.func,
  canSaveUnselected: PropTypes.bool,
};

SelectMemberModal.defaultProps = {
  open: false,
  membersList: [],
  singleSelect: false,
  initialSelected: [],
  replaceSelected: false,
  confirmText: "defaultSelect",
  cancelText: "defaultCancel",
  canSaveUnselected: false,
};

export default injectIntl(SelectMemberModal);
