import moment from "moment";

export function validPlanForm(plan = {}, setPlan) {
  let titleError = "";
  let leaderError = "";

  if (plan.organization.title === "") {
    titleError = "O campo é requerido";
  }

  if (plan.organization.title.length < 3) {
    titleError = "O campo precisa de mais caracteres";
  }

  if (plan.leader !== null) {
    if (!plan.leader.type || plan.leader.type === "PARTICIPANT") {
      leaderError = "Selecione um líder membro";
    }
  }

  if (titleError || leaderError) {
    setPlan({ ...plan, titleError, leaderError });

    return false;
  }

  return true;
}

export function formatDateCycles(currentCycles) {
  const formatedCycles = [];

  const formatedMonth = (value) => {
    // eslint-disable-next-line no-extend-native
    Date.prototype.isDate = function () {
      return !!(this !== "Invalid Date" && !isNaN(this));
    };

    const newDate = new Date(value);

    if (!newDate.isDate()) {
      const month = moment().month(value).format("YYYY-MM-DD");
      return month;
    }
    return value;
  };

  currentCycles.forEach((yearCyclesInfo) => {
    const updatedYearCycles = [];

    if (yearCyclesInfo.cycles) {
      yearCyclesInfo.cycles.forEach((cicle) => {
        updatedYearCycles.push({
          ...cicle,
          startDate: formatedMonth(cicle.startDate),
          endDate: formatedMonth(cicle.endDate),
          checkDate: formatedMonth(cicle.checkDate),
        });
      });
    }

    formatedCycles.push({ ...yearCyclesInfo, cycles: updatedYearCycles });
  });

  return formatedCycles;
}
