import { useEffect, useState } from "react";
import { Button, OverlayTrigger } from "react-bootstrap";
import { defineMessages, injectIntl } from "react-intl";
import { connect } from "react-redux";

import {
  grantNewRoleAndAccess,
  grantToolAccess,
  removeToolAccess,
} from "../../../../actions/accessControllerActions";
import {
  changeUserField,
  genericTip,
  getMembersByIds,
  getTipMessage,
  getUserRoleStyle,
  handleRepeatedMembers,
  isLastRole,
  mountToolPermissions,
  patternSearch,
} from "../../../../utils/accessLevels";
import { translatedText } from "../../../../utils/translationUtils";
import FormDialog from "../../../Common/FormDialog";
import GenericTable from "../../../Common/GenericTable";
import {
  getAccessStates,
  getCompanyMembersAndParticipants,
  getSelectedToolStates,
  getWorkspacesStates,
} from "../../../customMapStates";

import { removeAccess } from "../../../../actions/accessLevelsActions";
import { syncRookauAuthRoles } from "../../../../actions/rookauRoleActions";

const defaultAccessOpt = {
  showModal: false,
  user: null,
  oldRole: null,
  newRole: null,
  isLast: false,
};

const messages = defineMessages({
  accessLevels_name: {
    id: "accessLevels_name",
  },
  accessLevels_permissions: {
    id: "global.permissions",
  },
  accessLevels_manager: {
    id: "global.manager",
  },
  accessLevels_viewer: {
    id: "global.viewer",
  },
  accessLevels_manage_permissions: {
    id: "accessLevels_manage_permissions",
  },
  new_access: {
    id: "accessLevels_new_access",
  },
  manageManagersAndViewers: {
    id: "manageManagersAndViewers",
  },
  youCantDeleteLast: {
    id: "youCantDeleteLast",
  },
  removeAccessFrom: {
    id: "removeAccessFrom",
  },
  changePermissionFrom: {
    id: "changePermissionFrom",
  },
});

const translation = (id, values) => translatedText(id, messages, values);

const Permissions = ({
  companyMembers = [],
  selectedToolID = 0,
  selectedTool,
  entityAccess = {},
  buildedAccess = false,
  removeAccess,
  grantToolAccess,
  removeToolAccess,
}) => {
  const [isLoading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [searchWords, setSearchWords] = useState("");
  const [removeAccessOpt, setRemoveAccessOpt] = useState(defaultAccessOpt);
  const [changeAccessOpt, setChangeAccessOpt] = useState(defaultAccessOpt);

  useEffect(() => {
    if (buildedAccess === true && users.length === 0) {
      const toolAccess = mountToolPermissions(entityAccess.selected_tool);

      setUsers(toolAccess);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buildedAccess, entityAccess]);

  const headers = { name: translation("accessLevels_name"), email: "E-mail" };

  const onAddUser = (userIds = "") => {
    const allSelectedMembers = getMembersByIds(userIds, companyMembers);

    setUsers([...users, ...allSelectedMembers]);
  };

  const isLastManager = (user = {}) => {
    return isLastRole(user, users, "manager");
  };

  const onDeleteUser = (user = {}) => {
    let isLast = false;

    if (isLastManager(user)) isLast = true;

    setRemoveAccessOpt({ showModal: true, user, isLast });
  };

  const cleanRemoveAccessOpt = () => {
    setRemoveAccessOpt(defaultAccessOpt);
  };

  const removeUserAccess = () => {
    const { user, isLast } = removeAccessOpt;

    if (isLast) return;

    const { id: userId = 0, role = "" } = user;

    let accessType = null;

    if (role === "viewer") accessType = "READ";

    const removeBody = {
      id: userId,
      entityId: selectedToolID,
      role: role.toUpperCase(),
      accessType,
    };

    removeToolAccess(removeBody);

    const filteredUsers = users.filter(
      ({ id }) => Number(id) !== Number(userId)
    );

    setUsers(filteredUsers);

    cleanRemoveAccessOpt();
  };

  const cleanChangeAccessOpt = () => {
    setChangeAccessOpt(defaultAccessOpt);
  };

  const changeUserAccess = () => {
    const {
      user = {},
      newRole = null,
      oldRole = null,
      access = null,
    } = changeAccessOpt;

    const { id: userId = 0, domainUserId = 0 } = user;
    /*
      const body = {
        entityId: selectedToolID,
        entityType: "SELECTED_TOOL",
        userId,
        role: newRole.toUpperCase(),
        newUserAccess: access
      };

      grantNewRoleAndAccess(body);
      */
    // Adiciona acessos em cascata
    grantToolAccess(userId, newRole, access);

    if (oldRole) {
      //Exclui a role antiga
      const removeBody = {
        entityId: selectedToolID,
        entityType: "SELECTED_TOOL",
        role: oldRole.toUpperCase(),
      };

      removeAccess(removeBody, domainUserId);
    }

    const updatedUsers = changeUserField(users, userId, "role", newRole);

    setUsers([...updatedUsers]);

    cleanChangeAccessOpt();
  };

  const handleRoleChange = (user = {}, newRole = "", access = "") => {
    if (isLastManager(user)) return;

    const { id: userId = 0, role = null } = user;

    if (role === "choose")
      grantToolAccess(userId, newRole.toUpperCase(), access);

    if (newRole !== "choose" && role !== "choose") {
      setChangeAccessOpt({
        showModal: true,
        user,
        oldRole: role,
        newRole: newRole.toUpperCase(),
        access,
      });

      return;
    }

    const updatedUsers = changeUserField(users, userId, "role", newRole);

    setUsers([...updatedUsers]);
  };

  const defaultPermissions = (user = {}) => {
    return (
      <div>
        <a
          onClick={() => handleRoleChange(user, "choose")}
          style={{ cursor: "pointer", fontSize: "15px", color: "#6b42a9" }}
        >
          {translation("accessLevels_manage_permissions")}
        </a>
      </div>
    );
  };

  const getCustomTipMessage = (
    user = {},
    role = "",
    complement = "",
    counterComplement = complement
  ) => {
    if (isLastManager(user))
      return getTipMessage("youCantDeleteLast", counterComplement);

    if (user.role === role) return getTipMessage("delete", complement);

    return getTipMessage("add", complement);
  };

  const customActionsButtons = (user = {}) => {
    const { role = null } = user;

    return (
      <div>
        <OverlayTrigger
          placement="top"
          overlay={genericTip(
            getCustomTipMessage(
              user,
              "manager",
              translation("accessLevels_manager")
            )
          )}
        >
          <Button
            className={getUserRoleStyle(role, "manager")}
            onClick={() => handleRoleChange(user, "manager", "EDIT")}
          >
            <i className="fas fa-user-tie" />{" "}
            {translation("accessLevels_manager")}
          </Button>
        </OverlayTrigger>
        <OverlayTrigger
          placement="top"
          overlay={genericTip(
            getCustomTipMessage(
              user,
              "viewer",
              translation("accessLevels_viewer"),
              translation("accessLevels_manager")
            )
          )}
        >
          <Button
            className={getUserRoleStyle(role, "viewer")}
            onClick={() => handleRoleChange(user, "viewer", "READ")}
          >
            <i className="fas fa-user" /> {translation("accessLevels_viewer")}
          </Button>
        </OverlayTrigger>
      </div>
    );
  };

  const handleAddPermissions = (user = {}) => {
    const { role = null } = user;

    if (role) return customActionsButtons(user);

    return defaultPermissions(user);
  };

  const customActions = [
    {
      title: translation("accessLevels_permissions"),
      actions: handleAddPermissions,
    },
  ];

  const title = (
    <div className="genericTableTitle">
      <span>
        {translation("manageManagersAndViewers")}
        <hr />
      </span>
    </div>
  );

  const RemoveUserModal = () => {
    const { showModal = false, user = {}, isLast = false } = removeAccessOpt;

    const { name: userName = "" } = user || {};

    let modalTitle = translation("removeAccessFrom") + userName;

    if (isLast)
      modalTitle =
        translation("youCantDeleteLast") + translation("accessLevels_manager");

    return (
      <FormDialog
        open={showModal}
        title={modalTitle}
        onConfirm={() => removeUserAccess()}
        onCancel={() => cleanRemoveAccessOpt()}
        bodyStyle={{ padding: "0px" }}
        hideConfirm={isLast}
      />
    );
  };

  const translations = {
    VIEWER: translation("accessLevels_viewer"),
    MANAGER: translation("accessLevels_manager"),
  };

  const getTranslatedRole = (role = "") => {
    if (!role) return "";

    return translations[role.toUpperCase()].toUpperCase();
  };

  const ChangeUserModal = () => {
    const {
      showModal = false,
      user = {},
      oldRole = null,
      newRole = null,
    } = changeAccessOpt;

    const { name: userName = "" } = user || {};

    const modalTitle = (
      <div>
        <i className="fas fa-user" /> {userName}
      </div>
    );

    const translatedOldRole = getTranslatedRole(oldRole);
    const translatedNewRole = getTranslatedRole(newRole);

    return (
      <FormDialog
        open={showModal}
        title={modalTitle}
        onConfirm={() => changeUserAccess()}
        onCancel={() => cleanChangeAccessOpt()}
        bodyStyle={{ padding: "0 15px 0 15px" }}
      >
        <div>
          <h5>
            {translation("changePermissionFrom")}
            {translatedOldRole} <i className="fas fa-arrow-right" />{" "}
            {translatedNewRole} ?
          </h5>
        </div>
      </FormDialog>
    );
  };

  async function handleSyncRookauRoles() {
    if (!isLoading) {
      setLoading(true);

      try {
        await syncRookauAuthRoles(selectedToolID, selectedTool.tool.id);
      } catch (err) {
        console.log("handleSyncRookauRoles", { err });
      }
      setLoading(false);
    }
  }

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          padding: "15px",
        }}
      >
        <button
          className="btn btn-primary"
          disabled={isLoading}
          onClick={handleSyncRookauRoles}
        >
          <i className="fas fa-sync" style={{ marginRight: "10px" }} />
          {`Sincronizar acessos${isLoading ? "..." : ""}`}
        </button>
      </div>
      <GenericTable
        headers={headers}
        data={patternSearch(users, searchWords)}
        memberModalTitle={translation("new_access")}
        memberModalData={handleRepeatedMembers(companyMembers, users)}
        onAdd={onAddUser}
        onDelete={onDeleteUser}
        onSearch={setSearchWords}
        customActions={customActions}
        title={title}
      />
      <RemoveUserModal />
      <ChangeUserModal />
    </div>
  );
};

const mapStateToProps = (state) => {
  const { companyMembers } = getCompanyMembersAndParticipants(state);
  const { selectedWorkspace = {} } = getWorkspacesStates(state);
  const { selectedToolID = {}, selectedTool } = getSelectedToolStates(state);
  const { entityAccess = {}, buildedAccess = false } = getAccessStates(state);

  return {
    companyMembers,
    selectedWorkspace,
    selectedToolID,
    selectedTool,
    entityAccess,
    buildedAccess,
  };
};

export default injectIntl(
  connect(mapStateToProps, {
    removeToolAccess,
    grantToolAccess,
    removeAccess,
    grantNewRoleAndAccess,
  })(Permissions)
);
