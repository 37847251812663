import * as entity from "./entity";
import * as filter from "./filter";
import * as valid from "./valid";

const defaultExport = {
  ...filter,
  ...entity,
  ...valid,
};

export default defaultExport;
