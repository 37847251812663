import _ from "lodash";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { defineMessages } from "react-intl";
import { connect } from "react-redux";

import { Tab, Tabs } from "react-bootstrap";

import LoadingDialog from "components/Layouts/LoadingDialog";
import { checkAccess } from "utils/accessLevels";
import {
  getSkillsWorkflowAverage,
  getTotalWorkflowAverage,
} from "utils/skillsAssessment/calc";
import { fetchSelectedToolActionsPlans } from "../../../../../actions/actionsPlan";
import { useFilterInsights } from "../../../../../utils/hooks/insightHooks";
import { checkIsAdmin } from "../../../../../utils/skillsAssessment/filter";
import {
  useInsightParticipants,
  useSimpleToolAdministration,
} from "../../../../../utils/skillsAssessment/hooks/administration";
import { translatedText } from "../../../../../utils/translationUtils";
import ActionsPlanOverview from "../../../../Common/ActionPlan/ActionsPlanOverview";
import ButtonGeneratePdf from "../../../../Common/ButtonGeneratePdf";
import {
  getActionsPlanStates,
  getCompanyMembersAndParticipants,
  getSelectedToolStates,
  getSkillAssessmentStates,
} from "../../../../customMapStates";
import CompetenceOverview from "./overview/CompetenceOverview";
import LeadershipMapOverview from "./overview/LeadershipMapOverview";
import PeopleOverview from "./overview/PeopleOverview";
import StatusOverview from "./overview/StatusOverview";

const messages = defineMessages({
  acp_main_title: { id: "acp_main_title" },
});

const translation = (id) => translatedText(id, messages);

const SkillsAssessment = ({
  selectedTool = {},
  allSkillAssessment,
  allActionsPlans,
  isLoading,
  selectAndOpenInsight,
  filterData,
  allQuestions,
  allAnswers,
  loggedUser,
  fetchSelectedToolActionsPlans,
}) => {
  const { toolAdministration } = useSimpleToolAdministration({
    selectedTool,
    allSkillAssessment,
    isLoading,
  });
  const [activeTab, setActiveTab] = useState("peopleOverview");
  const { filteredInsights } = useFilterInsights({
    filter: filterData,
    allQuestions,
    allInsights: allAnswers,
  });

  const { participants: filteredParticipants } = useInsightParticipants({
    insights: filteredInsights,
    participants: toolAdministration.participants,
    loggedUser,
  });

  const [displayAverage, setDisplayAverage] = useState({});

  const filteredAdministration = {
    ...toolAdministration,
    participants: filteredParticipants,
  };

  const { generalOptions } = filteredAdministration;
  const { restrictViewAccess } = generalOptions || {};
  const canSeeOverview = !restrictViewAccess || checkIsAdmin();
  const neverLoaded =
    (!toolAdministration?.id && isLoading) || !toolAdministration?.id;

  useEffect(() => {
    const { workflowBySkills } = getSkillsWorkflowAverage({
      ...toolAdministration,
      participants: filteredParticipants,
    });

    const { average: totalAverage } = getTotalWorkflowAverage(
      workflowBySkills,
      toolAdministration.roleSkillRelation,
      toolAdministration.evaluationType,
      toolAdministration.evaluationWeights
    );

    if (!_.isEqual(totalAverage, displayAverage))
      setDisplayAverage(totalAverage);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toolAdministration, filteredParticipants]);

  useEffect(() => {
    if (selectedTool?.id) fetchSelectedToolActionsPlans(selectedTool.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTool?.id]);

  const handleSetTab = (key) => {
    if (neverLoaded) return;

    setActiveTab(key);
  };

  const getTab = (tabSlug, title, icon, TabContent) => {
    const displayTitle = (
      <>
        <i className={icon} />
        &nbsp;&nbsp; {title}
      </>
    );

    if (!canSeeOverview) return null;

    return (
      <Tab eventKey={tabSlug} title={displayTitle}>
        <div className="name-tab-pdf">{displayTitle}</div>
        {activeTab === tabSlug && (
          <div className="tab-content" style={{ marginTop: "10px" }}>
            <div className="col-xs-12" style={{ padding: "0px" }}>
              {TabContent}
            </div>
          </div>
        )}
      </Tab>
    );
  };

  return (
    <>
      <div
        className="custom-primary-tabs"
        style={{ opacity: neverLoaded ? "0.4" : "1" }}
      >
        <Tabs
          id="skillsAssessmentManagement"
          activeKey={activeTab}
          onSelect={(key) => handleSetTab(key)}
          style={{ marginTop: "15px" }}
        >
          <ButtonGeneratePdf />
          {getTab(
            "peopleOverview",
            "Pessoas",
            "fas fa-users",
            <PeopleOverview
              toolAdministration={filteredAdministration}
              loggedUser={loggedUser}
              selectAndOpenInsight={selectAndOpenInsight}
              filteredInsights={filteredInsights}
            />
          )}
          {getTab(
            "competenceOverview",
            "Competências",
            "fas fa-award",
            <CompetenceOverview toolAdministration={filteredAdministration} />
          )}
          {getTab(
            "statusOverview",
            "Status",
            "fas fa-tachometer-alt",
            <StatusOverview toolAdministration={filteredAdministration} />
          )}
          {getTab(
            "actionsPlanTab",
            "PDI",
            "fas fa-list-ol",
            <ActionsPlanOverview
              allActionsPlans={allActionsPlans}
              filteredInsights={filteredInsights}
              overviewTableTitle="PDI"
              overviewTableInsightsLabel={translation("Insights").toUpperCase()}
              insightsTableTitle={translation(
                "Ações por Responsável"
              ).toUpperCase()}
              insightsTableInsightsLabel={translation(
                "Responsável"
              ).toUpperCase()}
              selectedTool={selectedTool}
              selectAndOpenInsight={selectAndOpenInsight}
            />
          )}
          {checkAccess(
            ["MANAGER", "TOOLVIEWER"],
            getTab(
              "LeadershipMap",
              "Mapa de Liderança",
              "fas fa-map",
              <LeadershipMapOverview
                toolAdministration={filteredAdministration}
                selectAndOpenInsight={selectAndOpenInsight}
              />
            )
          )}
        </Tabs>
      </div>
      <LoadingDialog open={isLoading} />
    </>
  );
};

const mapStateToProps = (state) => {
  const { companyMembersAndParticipants } =
    getCompanyMembersAndParticipants(state);
  const { selectedTool } = getSelectedToolStates(state);
  const { allActionsPlans = [] } = getActionsPlanStates(state);
  const { allSkillAssessment, isLoading } = getSkillAssessmentStates(state);

  return {
    selectedTool,
    allActionsPlans,
    companyMembersAndParticipants,
    allSkillAssessment,
    isLoading,
    loggedUser: state.user || {},
  };
};

SkillsAssessment.propTypes = {
  selectedTool: PropTypes.object.isRequired,
  allSkillAssessment: PropTypes.array.isRequired,
  allActionsPlans: PropTypes.array.isRequired,
  selectAndOpenInsight: PropTypes.func.isRequired,
  filterData: PropTypes.object.isRequired,
  allQuestions: PropTypes.object.isRequired,
  allAnswers: PropTypes.object.isRequired,
  isLoading: PropTypes.bool,
};

SkillsAssessment.defaultProps = {
  isLoading: false,
};

export default connect(mapStateToProps, { fetchSelectedToolActionsPlans })(
  SkillsAssessment
);
