import React, { useState } from "react";
import { defineMessages } from "react-intl";
import {
  getPestalCriteriaTranslation,
  getPestalFactorTranslation,
} from "../../../../../../utils/cpAnalysis/display";

const messages = defineMessages({
  impact: { id: "global.impact" },
  importance: { id: "global.importance" },
  reliability: { id: "global.reliability" },
  global_noData: { id: "global.noData" },
});

const OverallTable = ({
  headers = [],
  data = [],
  intl,
  minimalism = false,
}) => {
  const [expanded, setExpanded] = useState(true);

  const handleEmpty = (data = []) => {
    if (data.length > 0) return;

    return (
      <div style={{ padding: "15px 0px 0px 0px", textAlign: "center" }}>
        {intl.formatMessage(messages.global_noData)}
      </div>
    );
  };

  const renderHeaders = () => {
    if (!Array.isArray(headers)) return <></>;

    return headers?.map((header, index) => {
      const { type = "" } = header;
      const title = getPestalCriteriaTranslation(type).toUpperCase();

      return (
        <div key={index} className="overallTable-header-cell" title={title}>
          {title}
        </div>
      );
    });
  };

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const calcFieldPercentage = (biggerValue = 0, actualValue = 0) => {
    return (actualValue * 100) / biggerValue;
  };

  const generateLine = (evaluation, index, allBiggerValues = {}) => {
    const {
      title = "",
      insights = "",
      result = "",
      overallEvaluations = [],
      colorClass = "",
    } = evaluation || {};

    if (insights === 0) return <React.Fragment key={index} />;

    const { insightsBValue = 0, resultBValue = 0 } = allBiggerValues;

    const insightsPercentage = calcFieldPercentage(insightsBValue, insights);
    const resultPercentage = calcFieldPercentage(resultBValue, result);

    const getEvaluationByConfigId = (id = "") => {
      return (
        overallEvaluations.filter(
          ({ evaluationConfigId = 0 }) => id === evaluationConfigId
        )[0] || {}
      );
    };

    return (
      <div
        className="row"
        style={{ margin: "0 auto", display: "flex", alignItems: "center" }}
        key={index}
      >
        {minimalism && (
          <div
            className={colorClass}
            style={{ width: "5px", height: "30px", marginLeft: "10px" }}
          />
        )}

        <div
          style={{
            width: "auto",
            flex: 1,
            padding: "15px",
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            fontWeight: "500",
          }}
          title={title}
        >
          {getPestalFactorTranslation(title)}
        </div>
        <div
          style={{
            width: "150px",
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
        >
          <h5>{insights}</h5>
          <div
            className="progress-management progress"
            style={{
              width: "100px",
              height: "20px",
              textAlign: "center",
            }}
          >
            <div
              role="progressbar"
              className={"progress-bar "}
              style={{ width: insightsPercentage + "%" }}
            />
          </div>
        </div>
        <div
          style={{
            width: "150px",
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
        >
          <h5>{result}</h5>
          <div
            className="progress-management progress"
            style={{
              width: "100px",
              height: "20px",
              textAlign: "center",
            }}
          >
            <div
              role="progressbar"
              className={"progress-bar "}
              style={{ width: resultPercentage + "%" }}
            />
          </div>
        </div>
        {headers.map((header, index) => {
          const { id: evaluationConfigId = 0 } = header;

          const evaluation = getEvaluationByConfigId(evaluationConfigId);

          const { percentage = 0 } = evaluation;

          const realPercentage = String(percentage).split(".")[0];

          return (
            <div
              key={index}
              style={{
                width: "150px",
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
              }}
            >
              <h5>{realPercentage}%</h5>
              <div
                className="progress-management progress"
                style={{
                  width: "100px",
                  height: "20px",
                  textAlign: "center",
                }}
              >
                <div
                  role="progressbar"
                  className={"progress-bar "}
                  style={{ width: realPercentage + "%" }}
                />
              </div>
            </div>
          );
        })}
      </div>
    );
  };

  const getAllBiggerValues = (data = []) => {
    let insightsBValue = 0;
    let resultBValue = 0;

    data.forEach((line) => {
      const { result = 0, insights = 0 } = line;

      if (insights > insightsBValue) insightsBValue = insights;
      if (result > resultBValue) resultBValue = result;
    });

    return { insightsBValue, resultBValue };
  };

  const allBiggerValues = getAllBiggerValues(data);

  return (
    <div className="evaluation-card">
      <div
        className="card-header card-header-hover row overallTable"
        onClick={toggleExpanded}
      >
        <div className="card-title"></div>
      </div>
      <div
        className="card-body overallTable-body"
        style={{ display: expanded ? "block" : "none" }}
      >
        <div className="header">
          <div className="overallTable-first-cell" />
          <div className="overallTable-header-cell">N INSIGHTS</div>
          <div className="overallTable-header-cell">RESULTADO</div>
          {renderHeaders()}
        </div>
        <div>
          {data.map((evaluation, index) =>
            generateLine(evaluation, index, allBiggerValues)
          )}
          {handleEmpty(data)}
        </div>
      </div>
    </div>
  );
};

export default OverallTable;
