import { connect } from "react-redux";
import {
  getDefaultEvaluationBadgeConfig,
  getTotalActionsByInsights,
} from "../../../../utils/actionsPlan/filter";
import { getOnlySlugValues } from "../../../../utils/ArrayUtils";
import ActionsCounterBadge from "../../../Common/ActionPlan/evaluations/ActionsCounterBadge";
import AverageBadge from "../../../Common/AverageBadge";
import {
  getActionsPlanStates,
  getCompAnalysisStates,
  getSelectedToolStates,
} from "../../../customMapStates";

const CompetitiveAnalysis = (props) => {
  const {
    insightsToCalc,
    boardPoints,
    currentToolType = "",
    allActionsPlans,
    from = "",
  } = props;

  const customToolType = props.customToolType
    ? props.customToolType.toLowerCase()
    : currentToolType.toLowerCase();

  const onlyInsightIds = getOnlySlugValues(insightsToCalc, "id");

  const blockConditions = () => {
    if (
      customToolType === "concurrence" ||
      (from !== "question" && from !== "answer" && from !== "score")
    )
      return true;

    return false;
  };

  const getCurrentCustomization = () => {
    return boardPoints[customToolType.toUpperCase()] || [];
  };

  const getCurrentConfig = (insightId) => {
    const currentCustomization = getCurrentCustomization();

    return (
      currentCustomization.filter(
        (custom) => custom.insightId === insightId
      )[0] || false
    );
  };

  const { hideBadge: hideActionsCounter, hideTitle: hideActionsCounterTitle } =
    getDefaultEvaluationBadgeConfig(from);

  const displayBadge = () => {
    let finalPoints = 0;

    onlyInsightIds.forEach((insightId) => {
      const { points = 0 } = getCurrentConfig(insightId);

      finalPoints += Number(points);
    });

    const colorGreen = "#00b050";
    const colorRed = "#ff0000";

    const getCustomColor = () => {
      let customColor = false;

      const currentInsight = insightsToCalc[0] || {};

      const { question = "" } = currentInsight || {};
      const { text = "" } = question || {};

      if (text === "STRONG_POINTS" || text === "OPPORTUNITIES")
        customColor = colorGreen;
      if (text === "THREATS" || text === "WEAK_POINTS") customColor = colorRed;

      if (customColor) return customColor;

      const colorConfig = {
        pestal: {
          beforeMiddle: colorGreen,
          afterMiddle: colorRed,
        },
        prioritization: {
          beforeMiddle: colorGreen,
          afterMiddle: colorRed,
        },
      };

      const { beforeMiddle = "", afterMiddle = "" } =
        colorConfig[customToolType] || {};

      customColor = finalPoints > 62 ? afterMiddle : beforeMiddle;

      return customColor;
    };

    const customColor = getCustomColor();

    return (
      <div style={{ display: "flex", marginRight: "10px" }}>
        {!blockConditions() && (
          <AverageBadge
            average={finalPoints}
            borderedBadge
            maxValue={125}
            customBorderColor={customColor}
          />
        )}
        {!hideActionsCounter && (
          <ActionsCounterBadge
            actions={
              getTotalActionsByInsights(onlyInsightIds, allActionsPlans).length
            }
            hideTitle={hideActionsCounterTitle}
            marginLeft="12px"
          />
        )}
      </div>
    );
  };
  return <div style={{ fontFamily: "montserrat" }}>{displayBadge()}</div>;
};

const mapStateToProps = (state) => {
  const { selectedTool = {} } = getSelectedToolStates(state);
  const { boardPoints = [], currentToolType = "" } =
    getCompAnalysisStates(state);

  const { allActionsPlans = [] } = getActionsPlanStates(state);

  return {
    boardPoints,
    selectedTool,
    currentToolType,
    allActionsPlans,
  };
};

export default connect(mapStateToProps)(CompetitiveAnalysis);
