import _ from "lodash";
import { useState } from "react";
import { injectIntl } from "react-intl";
import FormDialog from "../../../FormDialog";
import MaterialTextField from "../../../MaterialTextField";

const initialForm = {
  id: null,
  title: "",
};

const NewCriteriasDialog = (props) => {
  const [form, setForm] = useState(initialForm);
  const {
    open = false,
    afterCancel,
    afterConfirm,
    cpAnalysisAdministration,
    setCpAnalysisAdministration,
  } = props;

  const resetForm = () => {
    setForm(initialForm);
  };

  const handleConfirm = () => {
    let submitCpAnalysisAdm = cpAnalysisAdministration;

    const { pestal = {} } = cpAnalysisAdministration || {};

    const { evaluationCriterias: currentCriterias } = pestal || {};

    submitCpAnalysisAdm = {
      ...cpAnalysisAdministration,
      pestal: {
        ...cpAnalysisAdministration.pestal,
        evaluationCriterias: [...currentCriterias, { ...form }],
      },
    };

    if (!_.isEqual(submitCpAnalysisAdm, submitCpAnalysisAdm))
      setCpAnalysisAdministration(submitCpAnalysisAdm);

    resetForm();

    if (typeof afterConfirm === "function") afterConfirm(submitCpAnalysisAdm);
  };

  const handleCancel = () => {
    resetForm();

    if (typeof afterCancel === "function") afterCancel();
  };

  const isValidForm = () => {
    if (form.title < 2) return false;

    return true;
  };

  return (
    <FormDialog
      open={open}
      title={"Novo Critério"}
      onConfirm={handleConfirm}
      onCancel={handleCancel}
      blockConfirm={!isValidForm()}
    >
      <div className="row">
        <div className="col-xs-12">
          <MaterialTextField
            id="title"
            label={"Título"}
            value={form.title}
            onChange={(e) => setForm({ ...form, title: e.target.value })}
          />
        </div>
      </div>
    </FormDialog>
  );
};

export default injectIntl(NewCriteriasDialog);
