import {
  whereUserIs,
  getCurrentUserInfo,
  getRoleLevelInformations,
  checkAccess,
} from "../../../../utils/accessLevels";
import { getObjectInfoById } from "../../../../utils/ArrayUtils";

export async function checkIsRelatedAccess(sameUser, domainUserId, entityRole) {
  const {
    selectedDomain,
    selectedCompany,
    selectedWorkspace,
    selectedTool,
    selectedCenary,
    domainUsers,
  } = whereUserIs();

  const inDomain =
    sameUser || checkUserInDomain(selectedDomain, domainUsers, domainUserId);

  if (inDomain) {
    if (
      entityRole?.role === "OWNER" &&
      selectedDomain?.id === entityRole?.entityId
    )
      return { relatedAccess: true };

    if (
      entityRole?.role === "ADMIN" &&
      selectedCompany &&
      selectedCompany?.id === entityRole?.entityId
    ) {
      return { relatedAccess: true };
    }

    if (
      entityRole?.role === "MODERATOR" &&
      selectedWorkspace &&
      selectedWorkspace?.id === entityRole?.entityId
    ) {
      return { relatedAccess: true };
    }

    if (
      (entityRole?.role === "MANAGER" || entityRole?.role === "VIEWER") &&
      selectedTool &&
      selectedTool?.id === entityRole?.entityId
    ) {
      return { relatedAccess: true };
    }

    if (
      (entityRole?.role === "LEADER" || entityRole?.role === "SCENERYVIEWER") &&
      selectedCenary &&
      selectedCenary?.id === entityRole?.entityId
    ) {
      return { relatedAccess: true };
    }

    if (["CREATOR", "RESPONSIBLE", "COLLABORATOR"].includes(entityRole?.role)) {
      return { relatedAccess: true, hideFromOthers: true };
    }

    return { relatedAccess: false };
  }

  return { relatedAccess: false };
}

export async function checkUserInDomain(
  selectedDomain = {},
  domainUsers = [],
  domainUserId
) {
  if (selectedDomain && domainUsers && domainUserId) {
    const index = getObjectInfoById(
      domainUserId,
      domainUsers,
      "domainUserId",
      true
    );
    return index > -1;
  }

  return false;
}

export async function getUserAddAcessMessage(newAccessInfo) {
  if (newAccessInfo.entityRole) {
    const { sameUser, domainUserId } = isLoggedSameUser(
      newAccessInfo,
      "addingSingle"
    );
    const { entityRole = {} } = newAccessInfo;
    const { relatedAccess, hideFromOthers } = await checkIsRelatedAccess(
      sameUser,
      domainUserId,
      entityRole
    );

    if (!relatedAccess && !sameUser) return {};
    const roleInformation = getRoleLevelInformations(entityRole.role);

    return {
      relatedAccess,
      sameUser,
      domainUserId,
      message: {
        id: `addingRole${entityRole.role}`,
        text: getGrantedEntityMessage(roleInformation, sameUser),
        show: checkShowMessage(relatedAccess, sameUser, hideFromOthers),
      },
    };
  }

  return {};
}

export async function getUserAddingMultipleAccessMessage(addingAccessInfo) {
  const addingInfo = buildUpdatingMainEntityRole(addingAccessInfo);

  if (addingInfo && addingInfo.entityRole) {
    const { sameUser, domainUserId } = isLoggedSameUser(
      addingInfo,
      "addingMultiple"
    );
    const { relatedAccess, hideFromOthers } = await checkIsRelatedAccess(
      sameUser,
      domainUserId,
      addingInfo.entityRole
    );

    if (!relatedAccess && !sameUser) return {};
    const roleInformation = getRoleLevelInformations(
      addingInfo.entityRole.role
    );

    return {
      relatedAccess,
      sameUser,
      domainUserId,
      message: {
        id: `addingRoles${addingInfo.entityRole.role}`,
        text: getGrantedEntityMessage(roleInformation, sameUser),
        show: checkShowMessage(relatedAccess, sameUser, hideFromOthers),
      },
    };
  }

  return {};
}

export async function getUserRemoveAcessMessage(removeAccessInfo) {
  const deletingInfo = buildUpdatingMainEntityRole(removeAccessInfo);

  if (deletingInfo && deletingInfo.entityRole) {
    const { sameUser, domainUserId } = isLoggedSameUser(
      deletingInfo,
      "removingSingle"
    );
    const { relatedAccess, hideFromOthers } = await checkIsRelatedAccess(
      sameUser,
      domainUserId,
      deletingInfo.entityRole
    );

    if (!relatedAccess && !sameUser) return {};
    const roleInformation = getRoleLevelInformations(
      deletingInfo.entityRole.role
    );

    return {
      relatedAccess,
      sameUser,
      domainUserId,
      message: {
        id: `removingRole${deletingInfo.entityRole.role}`,
        text: getDeniedEntityMessage(roleInformation, sameUser),
        show: checkShowMessage(relatedAccess, sameUser, hideFromOthers),
      },
    };
  }

  return {};
}

export async function getUserRemoveMultipleAcessMessage(removeAccessInfo) {
  const deletingInfo = buildUpdatingMainEntityRole(removeAccessInfo);

  if (deletingInfo && deletingInfo.entityRole) {
    const { sameUser, domainUserId } = isLoggedSameUser(
      deletingInfo,
      "removingMultiple"
    );
    const { relatedAccess, hideFromOthers } = await checkIsRelatedAccess(
      sameUser,
      domainUserId,
      deletingInfo.entityRole
    );

    if (!relatedAccess && !sameUser) return {};
    const roleInformation = getRoleLevelInformations(
      deletingInfo.entityRole.role
    );

    return {
      relatedAccess,
      sameUser,
      domainUserId,
      message: {
        id: `removingRoles${deletingInfo.entityRole.role}`,
        text: getDeniedEntityMessage(roleInformation, sameUser),
        show: checkShowMessage(relatedAccess, sameUser, hideFromOthers),
      },
    };
  }

  return {};
}

export function checkShowMessage(
  relatedAccess,
  sameUser,
  hideFromOthers = false
) {
  return relatedAccess && (sameUser || hideFromOthers !== true);
}

export function isLoggedSameUser(response, type = "addingSingle") {
  const userInfo = getCurrentUserInfo();

  const { userId, domainUserId, domainUser } = response;

  if (type === "addingSingle" && userId && domainUserId) {
    return {
      sameUser: userInfo.id === userId,
      domainUserId,
    };
  }

  if (type === "addingMultiple" && userId && domainUserId) {
    return {
      sameUser: userInfo.id === userId,
      domainUserId,
    };
  }

  if (
    (type === "removingSingle" || type === "removingMultiple") &&
    domainUser &&
    domainUser.id &&
    domainUser.userId
  ) {
    return {
      sameUser: userInfo.id === domainUser.userId,
      domainUserId: domainUser.id,
    };
  }

  return {};
}

export function getSingleEntityRole(updatedAccessInfo) {
  if (updatedAccessInfo) {
    return updatedAccessInfo.entityRole
      ? updatedAccessInfo.entityRole
      : updatedAccessInfo.entityRoles
      ? updatedAccessInfo.entityRoles[0]
      : null;
  }

  return null;
}

export function buildUpdatingMainEntityRole(accessArray = []) {
  const editAccess = getObjectInfoById("EDIT", accessArray, "access");
  const readAccess = getObjectInfoById("READ", accessArray, "access");

  if (editAccess && editAccess.access) {
    const entityRole = getSingleEntityRole(editAccess);
    return entityRole ? { entityRole, ...editAccess } : null;
  }

  if (readAccess && readAccess.access) {
    const entityRole = getSingleEntityRole(readAccess);
    return entityRole ? { entityRole, ...readAccess } : null;
  }

  return null;
}

export function getGrantedEntityMessage(roleInformation, sameUser) {
  if (roleInformation && sameUser) {
    return roleInformation.userGrantedAccess;
  } else if (roleInformation && checkAccess(roleInformation.greaterAndEqual)) {
    return roleInformation.genericGranted;
  }

  return null;
}

export function getDeniedEntityMessage(roleInformation, sameUser) {
  if (roleInformation && sameUser) {
    return roleInformation.userDeniedAccess;
  } else if (roleInformation && checkAccess(roleInformation.greaterAndEqual)) {
    return roleInformation.genericDenied;
  }

  return null;
}
