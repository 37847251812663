import Chart from "react-google-charts";

const graphDefaultStyles = {
  width: "120px",
  height: "105px",
};

const BarChart = ({ modalDisplay, barChartData }) => {
  return (
    <Chart
      width="100%"
      height={modalDisplay ? "300px" : graphDefaultStyles.height}
      chartType="ColumnChart"
      loader={<div>Loading Chart</div>}
      data={barChartData}
      options={{
        maintainAspectRatio: true,
        chartArea: {
          width: modalDisplay ? "90%" : "88%",
          height: "83%",
        },
        colors: ["#4285f4", "#ddd"],
        isStacked: true,
        backgroundColor: { fill: "transparent" },
        legend: { position: "none" },
      }}
    />
  );
};

export default BarChart;
